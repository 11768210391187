import React from 'react';
import {Button, Hexagon} from 'web-components';
import styled from 'styled-components';

import {
	GreyText,
	InputLineWrapper,
	PersonNameStatusWrapper,
	PersonStatusWrapper,
	PictureWrapper,
	StatusDateWrapper,
	SubSection,
} from './ProjectSettings_styled';
import {MODAL_TYPE, showModal} from '../../../shared/components/modals/generic_modal_conductor';
import Util from '../../../shared/util/util';
import ProjectManualProgressConfiguration from './ProjectManualProgressConfiguration';
import {useIntl} from 'react-intl';
import {profilePicSrc} from '../../../../directApi';

const ProgressPercentage = styled.div`
	font-size: 16px;
	font-weight: 500;
	padding-right: 8px;
`;

const ProjectProgressPerson = ({progressDetails}) => {
	const {formatMessage} = useIntl();
	const currentProjectProgressPerson = progressDetails.createdBy;
	const currentProjectProgressPersonFullName =
		currentProjectProgressPerson?.firstName + ' ' + currentProjectProgressPerson?.lastName;
	const currentProjectStatusPersonImage = (
		<Hexagon
			text={currentProjectProgressPersonFullName}
			imgUrl={profilePicSrc(currentProjectProgressPerson?.profilePictureId)}
		/>
	);
	return (
		<PersonStatusWrapper>
			<GreyText>{formatMessage({id: 'common.by_colon'})}</GreyText>
			<PictureWrapper>{currentProjectStatusPersonImage}</PictureWrapper>
			<PersonNameStatusWrapper>{currentProjectProgressPersonFullName}</PersonNameStatusWrapper>
		</PersonStatusWrapper>
	);
};

const ProjectProgress = React.memo(({project, viewer}) => {
	const {formatMessage} = useIntl();

	return (
		<>
			<SubSection title={formatMessage({id: 'project_settings.manual_entry_of_progress'})}>
				<ProjectManualProgressConfiguration
					project={project}
					sageIntacctTimeRegsFromForecast={viewer.company.sageIntacctTimeRegsFromForecast}
				/>
			</SubSection>

			{project.manualProgressOnProjectEnabled ? (
				<SubSection title={formatMessage({id: 'project_settings.project_progress'})}>
					{project.progressDetails &&
						project.progressDetails
							.createdBy /* Show only manual project progress (created by user and not calculated from phases or tasks)*/ && (
							<InputLineWrapper>
								<ProgressPercentage>
									{project.progressDetails ? project.progressDetails.progress : 0}%
								</ProgressPercentage>
								<StatusDateWrapper>
									<GreyText>{formatMessage({id: 'project_settings.updated_on_colon'})}</GreyText>
									{project.progressDetails?.createdAt
										? Util.CreateNonUtcMomentDateFromString(project.progressDetails.createdAt).format('ll')
										: ''}
								</StatusDateWrapper>
								<ProjectProgressPerson progressDetails={project.progressDetails}></ProjectProgressPerson>
							</InputLineWrapper>
						)}
					<InputLineWrapper>
						<div>
							<Button
								size={Button.SIZE.STANDARD}
								variant={Button.VARIANT.VERY_LIGHT_GRAY_OUTLINE}
								onClick={() =>
									showModal({
										type: MODAL_TYPE.PROJECT_PROGRESS,
										viewerId: viewer.id,
										project: project,
									})
								}
							>
								{formatMessage({id: 'project_settings.change_project_progress'})}
							</Button>
						</div>
					</InputLineWrapper>
				</SubSection>
			) : null}
		</>
	);
});

export default ProjectProgress;
