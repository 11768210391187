import { ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from './htmlToDraftjs/library';
export const convertFromHtmlToDraftJs = (html) => {
    if (!html) {
        return '';
    }
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    return JSON.stringify(convertToRaw(contentState));
};
