import {graphql} from 'graphql';
import {cloneDeep} from 'lodash';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {createFragmentContainer} from 'react-relay';
import {BasicTextInput, Button, Checkbox, Dropdown, KebabMenuIcon, RadioButton, Switch, Table} from 'web-components';
import AddButton from '../../../shared/components/add_button';
import ForecastTooltip from '../../../shared/components/tooltips/ForecastTooltip';
import InlineLoader from '../../../shared/components/inline-loader/inline_loader';
import ContextMenu from '../../../shared/components/context-menus/context_menu';
import Util from '../../../shared/util/util';
import {PageWrapper, SelectionElementLabel} from '../../../project-tab/projects/project-settings/ProjectSettings_styled';
import {createToast} from '../../../shared/components/toasts/toast';
import {
	DropdownAddButtonWrapper,
	DropdownWrapper,
	HorizontalFlexWrapper,
	RefNumberWrapper,
	TableHeaderWrapper,
} from '../salesforce/app_salesforce_style';
import DirectApi from '../../../../directApi';
import {hasModule} from '../../../shared/util/ModuleUtil';
import {MODULE_TYPES} from '../../../../constants';
import DeprecatedProjectIndicatorJS from '../../../shared/components/project-indicator/js/DeprecatedProjectIndicatorJS';

const integrationsUsingPlaceholders = ['hubspot'];

const integrationsUsingAllocations = ['hubspot'];

const genericProjectTemplatesTable = ({viewer, integrationName}) => {
	const intl = useIntl();
	const [projectToDuplicate, setProjectToDuplicate] = useState(null);
	const [referenceNumberToCreate, setReferenceNumberToCreate] = useState(null);
	const [contextMenuData, setContextMenuData] = useState(null);
	const [projectTemplates, setProjectTemplates] = useState(null);

	const displayPlaceholdersOption =
		integrationsUsingPlaceholders.includes(integrationName) && hasModule(MODULE_TYPES.PLACEHOLDERS);

	const displayAllocationsOption = integrationsUsingAllocations.includes(integrationName);

	const templateData = [
		{
			fieldName: 'project',
			headerColumn: (
				<Table.HeaderColumn flex={1} usePadding visible width="LARGE" align="left">
					<Table.HeaderColumn.Title>{intl.formatMessage({id: 'common.project'})}</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
			),
		},
		{
			fieldName: 'reference',
			headerColumn: (
				<Table.HeaderColumn flex={1} usePadding visible width="SMALL" align="center">
					<Table.HeaderColumn.Title>
						{intl.formatMessage({id: 'integration.project_templates.reference'})}
					</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
			),
		},
		{
			fieldName: 'settingsAndWorkflows',
			headerColumn: (
				<Table.HeaderColumn usePadding visible width="MEDIUM" align="center">
					<Table.HeaderColumn.Title>
						{intl.formatMessage({id: 'integration.project_templates.settings_workflow'})}
					</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
			),
		},
		{
			fieldName: 'allTasks',
			headerColumn: (
				<Table.HeaderColumn usePadding visible width="SMALL" align="center">
					<Table.HeaderColumn.Title>
						{intl.formatMessage({id: 'integration.project_templates.all_tasks'})}
					</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
			),
		},
		{
			fieldName: 'allPhases',
			headerColumn: (
				<Table.HeaderColumn usePadding visible width="SMALL" align="center">
					<Table.HeaderColumn.Title>
						{intl.formatMessage({id: 'integration.project_templates.all_phases'})}
					</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
			),
		},
		{
			fieldName: 'allTeamMembers',
			headerColumn: (
				<Table.HeaderColumn usePadding visible width="SMALL" align="center">
					<Table.HeaderColumn.Title>
						{intl.formatMessage({id: 'integration.project_templates.all_team_members'})}
					</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
			),
		},
		{
			fieldName: 'expenses',
			headerColumn: (
				<Table.HeaderColumn usePadding visible width="SMALL" align="center">
					<Table.HeaderColumn.Title>
						{intl.formatMessage({id: 'integration.project_templates.expenses'})}
					</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
			),
		},
		{
			fieldName: 'projectColor',
			headerColumn: (
				<Table.HeaderColumn usePadding visible width="SMALL" align="center">
					<Table.HeaderColumn.Title>
						{intl.formatMessage({id: 'integration.project_templates.project_color'})}
					</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
			),
		},
		...(displayAllocationsOption
			? [
					{
						fieldName: 'allocations',
						headerColumn: (
							<Table.HeaderColumn usePadding visible width="SMALL" align="center">
								<Table.HeaderColumn.Title>
									{intl.formatMessage({id: 'integration.project_templates.allocations'})}
								</Table.HeaderColumn.Title>
							</Table.HeaderColumn>
						),
					},
			  ]
			: []),
		...(displayPlaceholdersOption
			? [
					{
						fieldName: 'placeholders',
						headerColumn: (
							<Table.HeaderColumn usePadding visible width="SMALL" align="center">
								<Table.HeaderColumn.Title>
									{intl.formatMessage({id: 'integration.project_templates.placeholders'})}
								</Table.HeaderColumn.Title>
							</Table.HeaderColumn>
						),
					},
			  ]
			: []),
		{
			fieldName: 'default',
			headerColumn: (
				<Table.HeaderColumn usePadding visible width="EXTRA_SMALL" align="center">
					<Table.HeaderColumn.Title>{intl.formatMessage({id: 'common.default'})}</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
			),
		},
		{
			fieldName: 'contextMenu',
			headerColumn: (
				<Table.HeaderColumn align={'center'} visible={true} width={'EXTRA_SMALL'}>
					{/* context  menu*/}
				</Table.HeaderColumn>
			),
		},
	];

	useEffect(() => {
		DirectApi.Fetch(`${integrationName}/project_templates`).then(json => {
			//some users might have template made based on projects that got deleted, so delete those templates as well
			if (viewer.company.hubspotAccountId || viewer.company.pipedriveCompanyDomain) {
				let deletedProjects = [];
				const projectTemplates = json.projectTemplates;
				projectTemplates.templatesList = projectTemplates.templatesList.reduce((previousList, currentTemplate) => {
					const foundProject = viewer.projects.edges.find(
						project => Util.getIdFromBase64String(project.node.id) === currentTemplate.projectId
					);

					if (!foundProject) {
						deletedProjects.push(currentTemplate.projectId);
					} else {
						previousList.push(currentTemplate);
					}
					return previousList;
				}, []);

				if (deletedProjects.length > 0) {
					const headers = new Headers();
					headers.append('Content-Type', 'application/json');

					const deleteTemplateInit = {
						headers: headers,
						credentials: 'include',
						method: 'DELETE',
						body: JSON.stringify({
							isIdConverted: true,
						}),
					};
					fetch(
						DirectApi.graphqlServerEndpoint(
							`${integrationName}/project_templates?projectIds=${deletedProjects.toString()}`
						),
						deleteTemplateInit
					);
				}
			}

			setProjectTemplates(json.projectTemplates);
		});
	}, []);

	const saveTemplates = projectTemplates => {
		const headers = new Headers();
		headers.append('Content-Type', 'application/json');

		const init = {
			headers: headers,
			credentials: 'include',
			method: 'PUT',
			body: JSON.stringify({
				projectTemplates: projectTemplates,
			}),
		};

		return fetch(DirectApi.graphqlServerEndpoint(`${integrationName}/project_templates`), init).then(() => {
			createToast({
				duration: 2000,
				message: intl.formatMessage({id: 'common.changes_saved'}),
			});
		});
	};

	const handleSwitchChange = () => {
		const updatedProjectTemplates = {
			...projectTemplates,
			useProjectTemplates: !projectTemplates.useProjectTemplates,
		};
		setProjectTemplates(updatedProjectTemplates);
		saveTemplates(updatedProjectTemplates);
	};

	const getProjectIconAndNameFromProjectId = projectId => {
		const foundProject = viewer.projects.edges.find(project => Util.getIdFromBase64String(project.node.id) === projectId);
		return <DeprecatedProjectIndicatorJS clearSubPath={true} project={foundProject.node} showProjectName />;
	};

	const handleOpenContextMenu = (e, templateIndex) => {
		e.preventDefault();
		const newContextMenuData = {
			templateIndex,
		};
		const btn = document.elementFromPoint(e.pageX, e.pageY);
		if (btn != null && e.type !== 'contextmenu') {
			const btnBounds = btn.getBoundingClientRect();
			newContextMenuData.posX = btnBounds.right - btn.clientWidth - 72;
			newContextMenuData.posY = btnBounds.y + 23;
		} else {
			newContextMenuData.posX = e.pageX;
			//check if there is place for menu underneath cursor
			if (window.innerHeight - e.pageY < 250) {
				newContextMenuData.posY = e.pageY - 250;
			} else {
				newContextMenuData.posY = e.pageY;
			}
		}
		setContextMenuData(newContextMenuData);
	};

	const handleDefaultTemplateChange = index => {
		const currentDefaultTemplateIndex = projectTemplates.templatesList.findIndex(template => template.default === true);
		let updatedProjectTemplates = cloneDeep(projectTemplates);
		if (currentDefaultTemplateIndex > -1) {
			updatedProjectTemplates.templatesList[currentDefaultTemplateIndex].default = false;
		}
		updatedProjectTemplates.templatesList[index].default = true;
		saveTemplates(updatedProjectTemplates).then(() => {
			setProjectTemplates(updatedProjectTemplates);
		});
	};

	const genericHandleTemplatesCheckboxChange = (fieldsToUpdate, index) => {
		let updatedProjectTemplates = cloneDeep(projectTemplates);
		let templateToUpdate = updatedProjectTemplates.templatesList[index];
		fieldsToUpdate.forEach(fieldToUpdate => {
			templateToUpdate[fieldToUpdate] = !templateToUpdate[fieldToUpdate];
		});
		saveTemplates(updatedProjectTemplates).then(() => {
			setProjectTemplates(updatedProjectTemplates);
		});
	};

	const buildTemplateRow = (template, index) => {
		return {
			project: getProjectIconAndNameFromProjectId(template.projectId),
			reference: template.reference,
			settingsAndWorkflows: (
				<Checkbox
					checked={template.duplicateSettings}
					onClick={() => genericHandleTemplatesCheckboxChange(['duplicateSettings'], index)}
				/>
			),
			allTasks: (
				<Checkbox
					checked={template.duplicateTasks}
					onClick={() => genericHandleTemplatesCheckboxChange(['duplicateTasks'], index)}
				/>
			),
			allPhases: (
				<Checkbox
					checked={template.duplicatePhases}
					onClick={() => genericHandleTemplatesCheckboxChange(['duplicatePhases'], index)}
				/>
			),
			allTeamMembers: (
				<Checkbox
					checked={template.duplicateTeam}
					onClick={() => {
						const fields =
							template.duplicateTeam && template.duplicateAllocations
								? ['duplicateTeam', 'duplicateAllocations']
								: ['duplicateTeam'];
						genericHandleTemplatesCheckboxChange(fields, index);
					}}
					disabled={displayAllocationsOption && template.duplicateTeam && template.duplicateAllocations}
				/>
			),
			...(displayAllocationsOption
				? {
						allocations: (
							<Checkbox
								checked={template.duplicateAllocations}
								onClick={() => {
									const fields =
										!template.duplicateAllocations && !template.duplicateTeam
											? ['duplicateTeam', 'duplicateAllocations']
											: ['duplicateAllocations'];
									genericHandleTemplatesCheckboxChange(fields, index);
								}}
							/>
						),
				  }
				: {}),
			expenses: (
				<Checkbox
					checked={template.duplicateExpenses}
					onClick={() => genericHandleTemplatesCheckboxChange(['duplicateExpenses'], index)}
				/>
			),
			retainerPeriods: (
				<Checkbox
					checked={template.duplicateRetainerPeriods}
					onClick={() => genericHandleTemplatesCheckboxChange(['duplicateRetainerPeriods'], index)}
				/>
			),
			projectColor: (
				<Checkbox
					checked={template.duplicateColor}
					onClick={() => genericHandleTemplatesCheckboxChange(['duplicateColor'], index)}
				/>
			),
			default: <RadioButton checked={template.default} onClick={() => handleDefaultTemplateChange(index)} />,
			contextMenu: (
				<KebabMenuIcon
					onClick={e => {
						handleOpenContextMenu(e, index);
					}}
					cy={'project-template-row-context-menu'}
				/>
			),
			...(displayPlaceholdersOption
				? {
						placeholders: (
							<Checkbox
								checked={template.duplicatePlaceholders}
								onClick={() => genericHandleTemplatesCheckboxChange(['duplicatePlaceholders'], index)}
							/>
						),
				  }
				: {}),
		};
	};

	const checkRefAvailability = ref => {
		return projectTemplates.templatesList.filter(template => template.reference === ref.trim()).length === 0;
	};

	const generateRefSuggestion = projectId => {
		const foundProject = viewer.projects.edges.find(project => project.node.id === projectId);
		const projectName = foundProject.node.name;
		let nameSuggestion;

		const nameSplit = projectName.split(' ');
		if (nameSplit.length > 2) {
			nameSuggestion = `${nameSplit[0][0]}${nameSplit[1][0]}${nameSplit[2][0]}`;
		} else if (projectName.length > 2) {
			nameSuggestion = `${projectName[0]}${projectName[1]}${projectName[2]}`;
		} else {
			nameSuggestion = projectName;
		}

		if (!checkRefAvailability(nameSuggestion)) {
			let number = 1;
			while (true) {
				let suggestionWithNumber = `${nameSuggestion} - ${number}`;
				if (checkRefAvailability(suggestionWithNumber)) {
					return suggestionWithNumber;
				}
				number++;
			}
		}

		return nameSuggestion;
	};

	const getStateWithNullValues = () => {
		return {
			duplicateSettings: null,
			duplicateTasks: null,
			duplicatePhases: null,
			duplicateTeam: null,
			duplicateAllocations: null,
			duplicateExpenses: null,
			duplicateRetainerPeriods: null,
			duplicateColor: null,
			duplicatePlaceholders: null,
		};
	};
	const handleAddProject = () => {
		const projectTemplatesToAdd = {
			useProjectTemplates: true,
			templatesList: [
				...projectTemplates.templatesList,
				{
					projectId: Util.getIdFromBase64String(projectToDuplicate),
					default: projectTemplates.templatesList.length === 0,
					reference: referenceNumberToCreate,
					...getStateWithNullValues(),
				},
			],
		};
		saveTemplates(projectTemplatesToAdd).then(() => {
			setProjectTemplates(projectTemplatesToAdd);
			setReferenceNumberToCreate(null);
		});
	};

	const deleteTemplate = templateIndex => {
		const updatedProjectTemplates = {
			useProjectTemplates: true,
			templatesList: projectTemplates.templatesList.filter((template, index) => index !== templateIndex),
		};
		saveTemplates(updatedProjectTemplates).then(() => {
			setProjectTemplates(updatedProjectTemplates);
			setReferenceNumberToCreate(null);
		});
	};

	const handleCloseContextMenu = () => {
		setContextMenuData(null);
	};

	const synchronizeProjectTemplates = () => {
		const headers = new Headers();
		headers.append('Content-Type', 'application/json');

		const initPost = {
			headers: headers,
			credentials: 'include',
			method: 'POST',
		};

		fetch(DirectApi.graphqlServerEndpoint(`${integrationName}/sync_project_templates`), initPost).then(() => {
			//when syncing project templates, the lambda function will also update
			//some fields in the company settings which we need to know about
			DirectApi.Fetch(`${integrationName}/project_templates`).then(json => {
				setProjectTemplates(json.projectTemplates);
				createToast({
					duration: 2000,
					message: intl.formatMessage({
						id: `settings.app_catalog.${integrationName}.project_templates_synchronized`,
					}),
				});
			});
		});
	};

	const data = projectTemplates && {
		rows: projectTemplates.templatesList.map((template, index) => buildTemplateRow(template, index)),
	};

	const addDisabled =
		!projectToDuplicate ||
		!referenceNumberToCreate ||
		referenceNumberToCreate === '' ||
		!checkRefAvailability(referenceNumberToCreate);

	return projectTemplates ? (
		<>
			<TableHeaderWrapper>
				<HorizontalFlexWrapper>
					<Switch checked={projectTemplates.useProjectTemplates} onChange={value => handleSwitchChange(value)} />
					<SelectionElementLabel>{intl.formatMessage({id: 'common.enable'})}</SelectionElementLabel>
				</HorizontalFlexWrapper>

				<ForecastTooltip
					content={intl.formatMessage({
						id: `settings.app_catalog.${integrationName}.export_to_${integrationName}.tooltip`,
					})}
				>
					<Button
						size={Button.SIZE.STANDARD}
						variant={Button.VARIANT.GREEN_FILLED}
						onClick={synchronizeProjectTemplates}
					>
						{intl.formatMessage({id: `settings.app_catalog.${integrationName}.export_to_${integrationName}`})}
					</Button>
				</ForecastTooltip>
			</TableHeaderWrapper>
			<PageWrapper>
				{!!contextMenuData && (
					<div style={{zIndex: 600}}>
						<ContextMenu
							options={[
								{
									text: intl.formatMessage({id: 'common.delete'}),
									onClick: () => deleteTemplate(contextMenuData.templateIndex),
								},
							]}
							contextMenuPosition={{x: contextMenuData.posX, y: contextMenuData.posY}}
							closeContextMenu={handleCloseContextMenu}
							userpilot={'context-menu-wrapper'}
						/>
					</div>
				)}
				{projectTemplates.useProjectTemplates && (
					<>
						{projectTemplates.templatesList.length > 0 && (
							<Table>
								<Table.Header>{templateData.map(({headerColumn}) => headerColumn)}</Table.Header>
								<Table.Rows data={data} canExpand={false}>
									{({rowData, tableColumnsProps}) => {
										return (
											<Table.Row {...tableColumnsProps}>
												{templateData.map(({fieldName}) => (
													<Table.Column>{rowData[fieldName]}</Table.Column>
												))}
											</Table.Row>
										);
									}}
								</Table.Rows>
							</Table>
						)}

						<DropdownAddButtonWrapper>
							<DropdownWrapper className={'dropdown-component'}>
								<Dropdown
									width={250}
									dropdownAlignment="none"
									selectedItems={[projectToDuplicate]}
									name={'None'}
									onSelect={s => {
										setProjectToDuplicate(s[0]);
										setReferenceNumberToCreate(generateRefSuggestion(s[0]));
									}}
									onRemove={() => {
										setProjectToDuplicate(null);
										setReferenceNumberToCreate(null);
									}}
								>
									{viewer.projects.edges.map(project => (
										<Dropdown.SingleText
											key={project.node.id}
											value={project.node.id}
											searchString={project.node.name}
										>
											{project.node.name}
										</Dropdown.SingleText>
									))}
								</Dropdown>
							</DropdownWrapper>
							<RefNumberWrapper>
								{/* <ForecastTooltip content={intl.formatMessage({id: 'settings.app_catalog.pipedrive.ref_no_tooltip'})}> */}
								<ForecastTooltip
									content={intl.formatMessage({id: `settings.app_catalog.${integrationName}.ref_no_tooltip`})}
								>
									<BasicTextInput
										value={referenceNumberToCreate}
										onChange={option => {
											setReferenceNumberToCreate(option);
										}}
										width="122px"
										placeholder={'Ref.'}
										maxLength={6}
									/>
								</ForecastTooltip>
							</RefNumberWrapper>
							<AddButton onClick={handleAddProject} disabled={addDisabled} />
						</DropdownAddButtonWrapper>
					</>
				)}
			</PageWrapper>
		</>
	) : (
		<InlineLoader />
	);
};

const genericProjectTemplatesTableQuery = graphql`
	query GenericProjectTemplatesTable_Query {
		viewer {
			actualPersonId
			component(name: "genericProjectTemplatesTable")
			...GenericProjectTemplatesTable_viewer
		}
	}
`;

export {genericProjectTemplatesTableQuery};

export default createFragmentContainer(genericProjectTemplatesTable, {
	viewer: graphql`
		fragment GenericProjectTemplatesTable_viewer on Viewer {
			id
			projects(first: 10000) {
				edges {
					node {
						id
						name
						companyProjectId
						useManualAllocations
						useBaseline
						projectColor
						...DeprecatedProjectIndicatorJS_project
					}
				}
			}
			company {
				pipedriveCompanyDomain
				hubspotAccountId
			}
		}
	`,
});
