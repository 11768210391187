import {RadioButton, Switch} from 'web-components';
import React from 'react';
import {useIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import Util from '../../../shared/util/util';
import UpdateProjectMutation from '../../../../mutations/update_project_mutation.modern';
import {
	InputLineWrapper,
	ProgressTimeRegWarning,
	RadioWithDescriptionWrapper,
	SectionDescription,
	SelectionElementLabel,
	SelectionElementLineWrapper,
} from './ProjectSettings_styled';
import {HIDDEN_FEATURES} from '../../../../constants';

const ManualProgressOptions = {
	DISABLED: 'disabled',
	PROJECT: 'project',
	PHASE: 'phase',
	TASK: 'task',
};

const ProjectManualProgressConfiguration = ({project, sageIntacctTimeRegsFromForecast}) => {
	const {formatMessage} = useIntl();

	// If time regs come from Sage, it should always be possible to toggle Manual Progress even if Time Registrations is hidden
	const lockFeature = Util.isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS) && sageIntacctTimeRegsFromForecast;

	const changeManualProgressConfiguration = manualProgressOption => {
		Util.CommitMutation(UpdateProjectMutation, {
			project: project,
			manualProgressOnProjectEnabled: manualProgressOption === ManualProgressOptions.PROJECT,
			manualProgressOnPhasesEnabled: manualProgressOption === ManualProgressOptions.PHASE,
			manualProgressOnTasksEnabled: manualProgressOption === ManualProgressOptions.TASK,
		});
	};

	const isManualProgressEnabled = () => {
		return (
			project.manualProgressOnProjectEnabled ||
			project.manualProgressOnPhasesEnabled ||
			project.manualProgressOnTasksEnabled
		);
	};

	const toggleManualProgressEnabled = () => {
		changeManualProgressConfiguration(
			isManualProgressEnabled() ? ManualProgressOptions.DISABLED : ManualProgressOptions.TASK
		);
	};

	return (
		<>
			<SectionDescription>
				{formatMessage(
					{id: 'project_settings.progress.description'},
					{
						p: msg => <p>{msg}</p>,
						ul: (...chunks) => <ul>{React.Children.toArray(chunks)}</ul>,
						li: msg => <li>{msg}</li>,
					}
				)}
			</SectionDescription>

			{Util.isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS) && (
				<ProgressTimeRegWarning>
					{formatMessage({id: 'project_settings.progress.timereg_hidden_message'})}
				</ProgressTimeRegWarning>
			)}

			<SelectionElementLineWrapper style={{marginTop: 30, marginBottom: 30}}>
				<Switch
					checked={isManualProgressEnabled()}
					locked={isManualProgressEnabled() && lockFeature}
					onChange={() => toggleManualProgressEnabled()}
				/>

				<SelectionElementLabel locked={isManualProgressEnabled() && lockFeature}>
					{formatMessage({id: 'common.enable'})}
				</SelectionElementLabel>
			</SelectionElementLineWrapper>

			{isManualProgressEnabled() && (
				<>
					<InputLineWrapper>
						{formatMessage({id: 'project_settings.manual_entry_of_progress_select_level'})}
					</InputLineWrapper>
					<RadioWithDescriptionWrapper>
						<SelectionElementLineWrapper>
							<RadioButton
								checked={project.manualProgressOnProjectEnabled}
								onClick={() => changeManualProgressConfiguration(ManualProgressOptions.PROJECT)}
								disabled={false}
							/>
							<SelectionElementLabel>{formatMessage({id: 'common.project'})}</SelectionElementLabel>
						</SelectionElementLineWrapper>
					</RadioWithDescriptionWrapper>
					<RadioWithDescriptionWrapper>
						<SelectionElementLineWrapper>
							<RadioButton
								checked={project.manualProgressOnPhasesEnabled}
								onClick={() => changeManualProgressConfiguration(ManualProgressOptions.PHASE)}
								disabled={false}
							/>
							<SelectionElementLabel>{formatMessage({id: 'common.phase'})}</SelectionElementLabel>
						</SelectionElementLineWrapper>
					</RadioWithDescriptionWrapper>
					<RadioWithDescriptionWrapper>
						<SelectionElementLineWrapper>
							<RadioButton
								checked={project.manualProgressOnTasksEnabled}
								onClick={() => changeManualProgressConfiguration(ManualProgressOptions.TASK)}
								disabled={false}
							/>
							<SelectionElementLabel>{formatMessage({id: 'common.tasks'})}</SelectionElementLabel>
						</SelectionElementLineWrapper>
					</RadioWithDescriptionWrapper>
				</>
			)}
		</>
	);
};

export default createFragmentContainer(ProjectManualProgressConfiguration, {
	project: graphql`
		fragment ProjectManualProgressConfiguration_project on ProjectType {
			id
			manualProgressOnProjectEnabled
			manualProgressOnPhasesEnabled
			manualProgressOnTasksEnabled
			timeRegistrations(first: 1) @connection(key: "Task_timeRegistrations") {
				edges {
					node {
						id
					}
				}
			}
		}
	`,
});
