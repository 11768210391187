/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type TaskRow_task$ref = any;
type genericTaskContextMenu_company$ref = any;
export type PROJECT_STATUS = "DONE" | "HALTED" | "OPPORTUNITY" | "PLANNING" | "RUNNING" | "%future added value";
export type STATUS_CATEGORY = "DONE" | "INPROGRESS" | "TODO" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type MyWorkTasksSection_viewer$ref: FragmentReference;
declare export opaque type MyWorkTasksSection_viewer$fragmentType: MyWorkTasksSection_viewer$ref;
export type MyWorkTasksSection_viewer = {|
  +id: string,
  +actualPersonId: ?string,
  +harvestUser: ?boolean,
  +unit4User: ?boolean,
  +excludeFromCompanyLockedPeriod: ?boolean,
  +submitLockedDateYear: ?number,
  +submitLockedDateMonth: ?number,
  +submitLockedDateDay: ?number,
  +availableFeatureFlags: ?$ReadOnlyArray<?{|
    +key: ?string
  |}>,
  +startDate: ?string,
  +endDate: ?string,
  +createdAt: ?string,
  +monday: ?number,
  +tuesday: ?number,
  +wednesday: ?number,
  +thursday: ?number,
  +friday: ?number,
  +saturday: ?number,
  +sunday: ?number,
  +email: ?string,
  +timerStartDate: ?string,
  +timerTask: ?{|
    +id: string
  |},
  +company: ?{|
    +id: string,
    +harvestEnabled: ?boolean,
    +unit4Enabled: ?boolean,
    +lockedPeriodYear: ?number,
    +lockedPeriodMonth: ?number,
    +lockedPeriodDay: ?number,
    +modules: ?$ReadOnlyArray<?{|
      +moduleType: ?string
    |}>,
    +roles: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |}
      |}>
    |},
    +teams: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +teamPersons: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +person: ?{|
                  +id: string
                |},
              |}
            |}>
          |},
        |}
      |}>
    |},
    +allTasks: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +fullAccessToProject: ?boolean,
          +sortOrder: ?number,
          +statusColumnV2: ?{|
            +id: string,
            +name: ?string,
            +category: ?STATUS_CATEGORY,
          |},
          +companyTaskId: ?number,
          +name: ?string,
          +latestUiUpdateAt: ?string,
          +subTaskCount: ?number,
          +startDay: ?number,
          +startMonth: ?number,
          +startYear: ?number,
          +deadlineDay: ?number,
          +deadlineMonth: ?number,
          +deadlineYear: ?number,
          +bug: ?boolean,
          +blocked: ?boolean,
          +highPriority: ?boolean,
          +hasChildren: ?boolean,
          +billable: ?boolean,
          +sageIntacctId: ?string,
          +favoured: ?boolean,
          +hasDependency: ?boolean,
          +project: ?{|
            +id: string,
            +status: ?PROJECT_STATUS,
            +companyProjectId: ?number,
            +projectColor: ?string,
            +projectGroupId: ?string,
            +name: ?string,
            +client: ?{|
              +id: string
            |},
          |},
          +taskLabels: ?$ReadOnlyArray<?{|
            +label: ?{|
              +id: string,
              +name: ?string,
              +color: ?string,
            |}
          |}>,
          +assignedPersons: ?$ReadOnlyArray<?{|
            +id: string
          |}>,
          +phase: ?{|
            +id: string,
            +name: ?string,
            +deadlineDay: ?number,
            +deadlineMonth: ?number,
            +deadlineYear: ?number,
            +startDay: ?number,
            +startMonth: ?number,
            +startYear: ?number,
          |},
          +sprint: ?{|
            +id: string,
            +endDay: ?number,
            +endMonth: ?number,
            +endYear: ?number,
            +startDay: ?number,
            +startMonth: ?number,
            +startYear: ?number,
            +name: ?string,
            +isProjectGroupSprint: ?boolean,
            +projectGroupSprintId: ?string,
          |},
          +owner: ?{|
            +id: string
          |},
          +followers: ?$ReadOnlyArray<?{|
            +id: string
          |}>,
          +$fragmentRefs: TaskRow_task$ref,
        |}
      |}>
    |},
    +noDatesTasks: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +fullAccessToProject: ?boolean,
          +sortOrder: ?number,
          +companyTaskId: ?number,
          +name: ?string,
          +latestUiUpdateAt: ?string,
          +subTaskCount: ?number,
          +startDay: ?number,
          +startMonth: ?number,
          +startYear: ?number,
          +deadlineDay: ?number,
          +deadlineMonth: ?number,
          +deadlineYear: ?number,
          +bug: ?boolean,
          +blocked: ?boolean,
          +highPriority: ?boolean,
          +billable: ?boolean,
          +favoured: ?boolean,
          +hasDependency: ?boolean,
          +project: ?{|
            +id: string,
            +status: ?PROJECT_STATUS,
            +companyProjectId: ?number,
            +name: ?string,
            +client: ?{|
              +id: string,
              +name: ?string,
            |},
          |},
          +statusColumnV2: ?{|
            +id: string,
            +name: ?string,
            +category: ?STATUS_CATEGORY,
          |},
          +taskLabels: ?$ReadOnlyArray<?{|
            +label: ?{|
              +id: string,
              +name: ?string,
              +color: ?string,
            |}
          |}>,
          +assignedPersons: ?$ReadOnlyArray<?{|
            +id: string
          |}>,
          +phase: ?{|
            +id: string,
            +name: ?string,
            +deadlineDay: ?number,
            +deadlineMonth: ?number,
            +deadlineYear: ?number,
            +startDay: ?number,
            +startMonth: ?number,
            +startYear: ?number,
          |},
          +timeRegistrations: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +minutesRegistered: ?number,
              |}
            |}>
          |},
          +sprint: ?{|
            +id: string,
            +endDay: ?number,
            +endMonth: ?number,
            +endYear: ?number,
            +startDay: ?number,
            +startMonth: ?number,
            +startYear: ?number,
          |},
          +owner: ?{|
            +id: string
          |},
          +followers: ?$ReadOnlyArray<?{|
            +id: string
          |}>,
          +$fragmentRefs: TaskRow_task$ref,
        |}
      |}>
    |},
    +doneTasks: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +fullAccessToProject: ?boolean,
          +sortOrder: ?number,
          +companyTaskId: ?number,
          +name: ?string,
          +latestUiUpdateAt: ?string,
          +subTaskCount: ?number,
          +startDay: ?number,
          +startMonth: ?number,
          +startYear: ?number,
          +deadlineDay: ?number,
          +deadlineMonth: ?number,
          +deadlineYear: ?number,
          +bug: ?boolean,
          +blocked: ?boolean,
          +highPriority: ?boolean,
          +billable: ?boolean,
          +favoured: ?boolean,
          +hasDependency: ?boolean,
          +project: ?{|
            +id: string,
            +status: ?PROJECT_STATUS,
            +companyProjectId: ?number,
            +name: ?string,
            +client: ?{|
              +id: string,
              +name: ?string,
            |},
          |},
          +statusColumnV2: ?{|
            +id: string,
            +name: ?string,
            +category: ?STATUS_CATEGORY,
          |},
          +taskLabels: ?$ReadOnlyArray<?{|
            +label: ?{|
              +id: string,
              +name: ?string,
              +color: ?string,
            |}
          |}>,
          +assignedPersons: ?$ReadOnlyArray<?{|
            +id: string
          |}>,
          +phase: ?{|
            +id: string,
            +name: ?string,
            +deadlineDay: ?number,
            +deadlineMonth: ?number,
            +deadlineYear: ?number,
            +startDay: ?number,
            +startMonth: ?number,
            +startYear: ?number,
          |},
          +timeRegistrations: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +minutesRegistered: ?number,
              |}
            |}>
          |},
          +sprint: ?{|
            +id: string,
            +endDay: ?number,
            +endMonth: ?number,
            +endYear: ?number,
            +startDay: ?number,
            +startMonth: ?number,
            +startYear: ?number,
          |},
          +owner: ?{|
            +id: string
          |},
          +followers: ?$ReadOnlyArray<?{|
            +id: string
          |}>,
          +$fragmentRefs: TaskRow_task$ref,
        |}
      |}>
    |},
    +$fragmentRefs: genericTaskContextMenu_company$ref,
  |},
  +$refType: MyWorkTasksSection_viewer$ref,
|};
export type MyWorkTasksSection_viewer$data = MyWorkTasksSection_viewer;
export type MyWorkTasksSection_viewer$key = {
  +$data?: MyWorkTasksSection_viewer$data,
  +$fragmentRefs: MyWorkTasksSection_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v0/*: any*/),
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullAccessToProject",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sortOrder",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "StatusColumnV2",
  "kind": "LinkedField",
  "name": "statusColumnV2",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "category",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyTaskId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latestUiUpdateAt",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subTaskCount",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDay",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startMonth",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startYear",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineDay",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineMonth",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineYear",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bug",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "blocked",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "highPriority",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billable",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "favoured",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasDependency",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyProjectId",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "concreteType": "TaskLabel",
  "kind": "LinkedField",
  "name": "taskLabels",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Label",
      "kind": "LinkedField",
      "name": "label",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "color",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "concreteType": "Person",
  "kind": "LinkedField",
  "name": "assignedPersons",
  "plural": true,
  "selections": (v1/*: any*/),
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "concreteType": "PhaseType",
  "kind": "LinkedField",
  "name": "phase",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v2/*: any*/),
    (v16/*: any*/),
    (v17/*: any*/),
    (v18/*: any*/),
    (v13/*: any*/),
    (v14/*: any*/),
    (v15/*: any*/)
  ],
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDay",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endMonth",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endYear",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "concreteType": "Person",
  "kind": "LinkedField",
  "name": "owner",
  "plural": false,
  "selections": (v1/*: any*/),
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "concreteType": "Person",
  "kind": "LinkedField",
  "name": "followers",
  "plural": true,
  "selections": (v1/*: any*/),
  "storageKey": null
},
v35 = {
  "args": [
    {
      "kind": "Variable",
      "name": "personId",
      "variableName": "personId"
    }
  ],
  "kind": "FragmentSpread",
  "name": "TaskRow_task"
},
v36 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "TaskTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Task",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v10/*: any*/),
          (v2/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v25/*: any*/),
              (v26/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Client",
                "kind": "LinkedField",
                "name": "client",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v9/*: any*/),
          (v27/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 1000000
              }
            ],
            "concreteType": "TimeRegTypeConnection",
            "kind": "LinkedField",
            "name": "timeRegistrations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TimeRegTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TimeRegType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "minutesRegistered",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "timeRegistrations(first:1000000)"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Sprint",
            "kind": "LinkedField",
            "name": "sprint",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v30/*: any*/),
              (v31/*: any*/),
              (v32/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/)
            ],
            "storageKey": null
          },
          (v33/*: any*/),
          (v34/*: any*/),
          (v4/*: any*/),
          (v35/*: any*/)
        ],
        "storageKey": null
      },
      (v5/*: any*/)
    ],
    "storageKey": null
  },
  (v6/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchQuery",
      "type": "TaskSearchQueryType"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "noDatesSearchQuery",
      "type": "TaskSearchQueryType"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "doneTasksSearchQuery",
      "type": "TaskSearchQueryType"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isClientUser",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "personId",
      "type": "ID"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": null
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "company",
          "teams"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "company",
          "allTasks"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "company",
          "noDatesTasks"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "company",
          "doneTasks"
        ]
      }
    ]
  },
  "name": "MyWorkTasksSection_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualPersonId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "harvestUser",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unit4User",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "excludeFromCompanyLockedPeriod",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submitLockedDateYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submitLockedDateMonth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submitLockedDateDay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FeatureFlag",
      "kind": "LinkedField",
      "name": "availableFeatureFlags",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "monday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tuesday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wednesday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thursday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "friday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "saturday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sunday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timerStartDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Task",
      "kind": "LinkedField",
      "name": "timerTask",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "harvestEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unit4Enabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lockedPeriodYear",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lockedPeriodMonth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lockedPeriodDay",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Module",
          "kind": "LinkedField",
          "name": "modules",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "moduleType",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RoleTypeConnection",
          "kind": "LinkedField",
          "name": "roles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RoleTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Role",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": "teams",
          "args": null,
          "concreteType": "TeamTypeConnection",
          "kind": "LinkedField",
          "name": "__Company_teams_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TeamTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Team",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": "teamPersons",
                      "args": null,
                      "concreteType": "TeamPersonTypeConnection",
                      "kind": "LinkedField",
                      "name": "__Team_teamPersons_connection",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "TeamPersonTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "TeamPerson",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Person",
                                  "kind": "LinkedField",
                                  "name": "person",
                                  "plural": false,
                                  "selections": (v1/*: any*/),
                                  "storageKey": null
                                },
                                (v4/*: any*/)
                              ],
                              "storageKey": null
                            },
                            (v5/*: any*/)
                          ],
                          "storageKey": null
                        },
                        (v6/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v4/*: any*/)
                  ],
                  "storageKey": null
                },
                (v5/*: any*/)
              ],
              "storageKey": null
            },
            (v6/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": "allTasks",
          "args": null,
          "concreteType": "TaskTypeConnection",
          "kind": "LinkedField",
          "name": "__Company_allTasks_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TaskTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Task",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v7/*: any*/),
                    (v8/*: any*/),
                    (v9/*: any*/),
                    (v10/*: any*/),
                    (v2/*: any*/),
                    (v11/*: any*/),
                    (v12/*: any*/),
                    (v13/*: any*/),
                    (v14/*: any*/),
                    (v15/*: any*/),
                    (v16/*: any*/),
                    (v17/*: any*/),
                    (v18/*: any*/),
                    (v19/*: any*/),
                    (v20/*: any*/),
                    (v21/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasChildren",
                      "storageKey": null
                    },
                    (v22/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "sageIntacctId",
                      "storageKey": null
                    },
                    (v23/*: any*/),
                    (v24/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProjectType",
                      "kind": "LinkedField",
                      "name": "project",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v25/*: any*/),
                        (v26/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "projectColor",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "projectGroupId",
                          "storageKey": null
                        },
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Client",
                          "kind": "LinkedField",
                          "name": "client",
                          "plural": false,
                          "selections": (v1/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v27/*: any*/),
                    (v28/*: any*/),
                    (v29/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Sprint",
                      "kind": "LinkedField",
                      "name": "sprint",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v30/*: any*/),
                        (v31/*: any*/),
                        (v32/*: any*/),
                        (v13/*: any*/),
                        (v14/*: any*/),
                        (v15/*: any*/),
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isProjectGroupSprint",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "projectGroupSprintId",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v33/*: any*/),
                    (v34/*: any*/),
                    (v4/*: any*/),
                    (v35/*: any*/)
                  ],
                  "storageKey": null
                },
                (v5/*: any*/)
              ],
              "storageKey": null
            },
            (v6/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": "noDatesTasks",
          "args": null,
          "concreteType": "TaskTypeConnection",
          "kind": "LinkedField",
          "name": "__Company_noDatesTasks_connection",
          "plural": false,
          "selections": (v36/*: any*/),
          "storageKey": null
        },
        {
          "alias": "doneTasks",
          "args": null,
          "concreteType": "TaskTypeConnection",
          "kind": "LinkedField",
          "name": "__Company_doneTasks_connection",
          "plural": false,
          "selections": (v36/*: any*/),
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "genericTaskContextMenu_company"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '53dbf1ad0f004235e3d974474909ffd7';

module.exports = node;
