import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedHTMLMessage, FormattedRelativeTime, injectIntl} from 'react-intl';
import {selectUnit} from '@formatjs/intl-utils';
import {INITIALS_SIZE} from '../../../../constants';
import ActionsMenu from '../action-menu/actions_menu';
import Moment from 'moment';
import Util from '../../util/util';
import {Link} from 'react-router-dom';
import UpdatePersonNotificationMutation from '../../../../mutations/update_person_notification_mutation';
import PersonInitials from '../person/person_initials';
import {profilePicSrc, projectUrl} from '../../../../directApi';
import DeprecatedProjectIndicatorJS from '../project-indicator/js/DeprecatedProjectIndicatorJS';
import {DeprecatedProjectIndicatorComponent} from '../project-indicator/DeprecatedProjectIndicator';

function findProject(companyProjectId, projects) {
	if (projects && companyProjectId) {
		return projects.find(project => project.companyProjectId === companyProjectId);
	}
}

class Notification extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showContextMenu: false,
			contextMenuPosition: {},
		};
		this.markAsUnread = this.markAsUnread.bind(this);
		this.markAsRead = this.markAsRead.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		if (!prevProps.shouldTakeFocus && this.props.shouldTakeFocus) {
			if (this._notification) {
				this._notification.focus();
			}
		}
	}

	getNavigationString(project) {
		const notification = this.props.notification;
		const params = JSON.parse(this.props.notification.params);
		const hasSlash = window.location.href.endsWith('/');
		const hasHash = window.location.href.includes('#');

		if (this.props.notification.entityType === 'TIME_OFF' && notification.publisherAction === 'TIME_OFF_SUBMIT') {
			return '/resourcing/timesheet-approval';
		} else if (this.props.notification.entityType === 'TIME_OFF' && notification.publisherAction === 'TIME_OFF_REGISTER') {
			return '/resourcing/timesheet-entries';
		} else if (this.props.notification.entityType === 'TIME_OFF') {
			return '/timesheets';
		} else if (this.props.notification.entityType === 'PROJECT') {
			return projectUrl(params.companyProjectId, project?.customProjectId) + '/workflow';
		} else if (this.props.notification.entityType === 'PERSON') {
			return '/admin/people';
		} else if (this.props.notification.entityType === 'INVOICE') {
			if (params.companyProjectId) {
				if (params.companyInvoiceId) {
					return `${projectUrl(params.companyProjectId, project?.customProjectId)}/financials/I-${
						params.companyInvoiceId
					}#invoicing`;
				} else {
					return `${projectUrl(params.companyProjectId, project?.customProjectId)}/financials#invoicing`;
				}
			} else {
				if (params.companyInvoiceId) {
					return `/finance/invoices/I-${params.companyInvoiceId}`;
				} else {
					return '/finance/invoices';
				}
			}
		}

		let windowLocation = window.location.pathname;

		if (hasHash) {
			let locationArr = windowLocation.split('#');
			windowLocation = locationArr[0];
		}
		return windowLocation + (hasSlash ? 'T' : '/T') + params.companyTaskId;
	}

	handleNav() {
		this.markAsRead();
		if (this.props.closeNotifications) {
			this.props.closeNotifications();
		}
	}

	markAsRead(e) {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		Util.CommitMutation(UpdatePersonNotificationMutation, {
			ids: [this.props.notification.id],
			read: true,
		});
		if (this.state.showContextMenu) {
			this.closeContextMenu();
		}
	}

	markAsUnread(e) {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		Util.CommitMutation(UpdatePersonNotificationMutation, {
			ids: [this.props.notification.id],
			read: false,
		});
		if (this.state.showContextMenu) {
			this.closeContextMenu();
		}
	}

	disableNotificationType(e, type) {
		if (e) {
			e.stopPropagation();
			e.preventDefault();
		}
		this.props.disableNotificationType(type);
		if (this.state.showContextMenu) {
			this.closeContextMenu();
		}
	}

	getActionMenuOptions() {
		const {notification} = this.props;
		const {formatMessage} = this.props.intl;
		const opts = [];
		const notificationFlag = Util.getNotificationFlagFromType(notification.publisherAction);
		const isNotifyEnabled = this.props.viewer[notificationFlag];
		// Mark as read/unread
		if (notification.read) {
			opts.push({text: formatMessage({id: `notifications.mark_as_unread`}), onClick: this.markAsUnread});
		} else {
			opts.push({text: formatMessage({id: `notifications.mark_as_read`}), onClick: this.markAsRead});
		}
		// Disable this type of notification
		opts.push({
			text: isNotifyEnabled
				? formatMessage(
						{id: 'notifications.disable_notification_type'},
						{type: Util.prettifyNotificationType(notification.publisherAction, this.props.intl)}
				  )
				: formatMessage(
						{id: 'notifications.enable_notification_type'},
						{type: Util.prettifyNotificationType(notification.publisherAction, this.props.intl)}
				  ),
			onClick: e => this.disableNotificationType(e, notification.publisherAction),
		});
		return opts;
	}

	closeContextMenu() {
		this.setState({
			showContextMenu: false,
		});
	}

	showContextMenu(e) {
		e.preventDefault();
		const contextMenuPosition = {};
		contextMenuPosition.x = e.clientX;
		//check if there is place for menu underneath cursor
		if (window.innerHeight - e.pageY < 250) {
			contextMenuPosition.y = e.pageY - 250;
		} else {
			contextMenuPosition.y = e.pageY;
		}
		this.setState({showContextMenu: true, contextMenuPosition});
	}

	stripHtml(string) {
		return string.replace(/<b>|<\/b>/g, '');
	}

	render() {
		const {notification, projects} = this.props;
		const publisher = notification.publisher;
		const action = notification.publisherAction;
		const params = JSON.parse(notification.params);
		const actionMenuOptions = this.getActionMenuOptions();
		const suffix = params.estimationUnit ? params.estimationUnit[0].toLowerCase() : '';
		const notificationContent = Util.getNotificationContent(notification, suffix, this.props.intl);
		const profileSrc = profilePicSrc(publisher?.node?.profilePictureId);
		const createdAt = Moment.utc(Number(notification.createdAt));
		const project = findProject(params.companyProjectId, projects);
		const {value, unit} = selectUnit(createdAt);
		return (
			<Link
				//ref={div => this._notification = div}
				onBlur={this.props.onBlur}
				onKeyDown={this.props.onKeyDown}
				onClick={this.handleNav.bind(this)}
				className={'notification wrapper react-router-link' + (this.props.isLast ? ' last' : '')}
				to={this.getNavigationString(project)}
			>
				<div
					//onContextMenu={this.showContextMenu.bind(this)}
					className={'notification' + (!notification.read ? ' unread' : '')}
					title={this.stripHtml(
						this.props.intl.formatMessage({id: notificationContent.id}, notificationContent.values)
					)}
				>
					<div className="notification-icon">
						<div className="hexagon">
							{action.includes('UPCOMING_DEADLINE') ? (
								<div className={'deadline-icon'} />
							) : profileSrc ? (
								<img crossOrigin="use-credentials" alt="" src={profileSrc} height="34" width="34" />
							) : publisher && publisher.node && publisher.node.initials ? (
								<PersonInitials initials={publisher.node.initials} size={INITIALS_SIZE.LARGE} />
							) : null}
						</div>
					</div>
					<div className="notification-text-wrapper">
						<div className={'notification-text' + (this.props.fullscreenView ? ' full' : '')}>
							<FormattedHTMLMessage id={notificationContent.id} values={notificationContent.values} />
						</div>
						<div className={'bottom-row'}>
							{project ? (
								<DeprecatedProjectIndicatorJS project={project} disableLink />
							) : params.companyProjectId ? (
								<DeprecatedProjectIndicatorComponent
									customProjectId={params.customProjectId}
									companyProjectId={params.companyProjectId}
									projectColor={params.projectColor}
								/>
							) : null}
							<div className={'indicators'}>
								{action.includes('SUB') || action.includes('TODO') ? <div className={'sub-task-icon'} /> : null}
								{action.includes('COMMENT') ? <div className={'comment-icon'} /> : null}
								{action.includes('FILE') ? <div className={'file-icon'} /> : null}
								{action.includes('HIGH_PRIORITY') ? <div className={'high-priority-icon'} /> : null}
								{action.includes('BUG') ? <div className={'bug-icon'} /> : null}
								{action.includes('BLOCKED') ? <div className={'blocked-icon'} /> : null}
							</div>
							<div className={'timestamp'} title={createdAt.format('LL')}>
								{unit === 'month' || unit === 'year' ? (
									createdAt.format('LL')
								) : (
									<FormattedRelativeTime
										numeric="auto"
										value={unit === 'second' && value > -10 ? 0 : value}
										unit={unit}
									/>
								)}
							</div>
						</div>
					</div>
					<div className="notification-dot-menu">
						<ActionsMenu useGreyIcon={true} isWhite={true} whiteInner={true} options={actionMenuOptions} />
					</div>
				</div>
				{
					//this.state.showContextMenu ? this.getContextMenu(actionMenuOptions) : null
				}
			</Link>
		);
	}
}

Notification.propTypes = {
	notification: PropTypes.object.isRequired,
	onBlur: PropTypes.func.isRequired,
	onKeyDown: PropTypes.func.isRequired,
};

export default injectIntl(Notification);
