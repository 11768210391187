import React from 'react';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {createFragmentContainer, graphql} from 'react-relay';
import {NameWrapper, ProfilePictureWrapper, ProfileWrapper, RoleWrapper} from '../MyWork.styled';
import {INITIALS_SIZE} from '../../../../constants';
import PersonInitials from '../../../shared/components/person/person_initials';
import TooltipContainer from '../../../shared/components/tooltips/tooltip_container';
import {profilePicSrc} from '../../../../directApi';

const MyWorkProfile = ({intl, history, person, isAdmin, actualPersonId}) => {
	const goToProfile = () => {
		if (person.id === actualPersonId) {
			history.push('/my-profile/account-configuration');
		} else if (isAdmin) {
			history.push('/admin/team/view-user/' + person.id);
		}
	};

	const selfOrAdmin = isAdmin || person.id === actualPersonId;

	return (
		<ProfileWrapper>
			<ProfilePictureWrapper selfOrAdmin={selfOrAdmin} onClick={goToProfile} data-userpilot={'my-work-profile-picture'}>
				<TooltipContainer text={selfOrAdmin ? intl.formatMessage({id: 'settings_people.go_to_profile'}) : null}>
					{person.profilePictureId ? (
						<img
							crossOrigin="use-credentials"
							alt=""
							src={profilePicSrc(person.profilePictureId)}
							width="100"
							height="100"
						/>
					) : (
						<PersonInitials size={INITIALS_SIZE.HUNDRED} initials={person.initials} />
					)}
				</TooltipContainer>
			</ProfilePictureWrapper>
			<NameWrapper>{person.firstName + ' ' + person.lastName}</NameWrapper>
			<RoleWrapper>{person.role ? person.role.name : ''}</RoleWrapper>
		</ProfileWrapper>
	);
};

export default withRouter(
	injectIntl(
		createFragmentContainer(MyWorkProfile, {
			person: graphql`
				fragment MyWorkProfile_person on Person {
					id
					initials
					firstName
					lastName
					role {
						id
						name
					}
					profilePictureId
					profilePictureDefaultId
				}
			`,
		})
	)
);
