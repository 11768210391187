import {BUDGET_TYPE, HIDDEN_FEATURES, MODULE_TYPES, PERIOD_BUDGET_TYPE} from '../../../../constants';
import {hasFeatureFlag} from '../../../shared/util/FeatureUtil';
import {isFeatureHidden} from '../../../shared/util/CompanySetupUtil';
import {hasSomePermission} from '../../../shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../Permissions';
import {hasModule} from '../../../shared/util/ModuleUtil';

export const TABS = {
	GENERAL: {
		key: 'GENERAL',
		translationId: 'settings.general',
		inConnectedProjectParent: true,
		financialsInfo: false,
	},
	STATUS: {
		key: 'STATUS',
		translationId: 'common.status',
		inConnectedProjectParent: false,
		financialsInfo: false,
	},
	PROJECT_PROGRESS: {
		key: 'PROJECT_PROGRESS',
		translationId: 'common.progress',
		inConnectedProjectParent: false,
		financialsInfo: false,
		isVisible: project => {
			return (
				(isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS) && !hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED)) ||
				BUDGET_TYPE.NON_BILLABLE === project.budgetType ||
				(hasFeatureFlag('manual_progress_fixed_price') &&
					(BUDGET_TYPE.FIXED_PRICE_V2 === project.budgetType ||
						BUDGET_TYPE.FIXED_PRICE === project.budgetType ||
						(BUDGET_TYPE.RETAINER === project.budgetType &&
							PERIOD_BUDGET_TYPE.FIXED_PRICE === project.defaultPeriodBudgetType)))
			);
		},
	},
	TASKS: {
		key: 'TASKS',
		translationId: 'common.tasks',
		inConnectedProjectParent: true,
		financialsInfo: false,
	},
	FINANCIALS: {
		key: 'FINANCIALS',
		translationId: 'common.financials',
		inConnectedProjectParent: false,
		financialsInfo: true,
		isVisible: () => {
			return hasSomePermission([
				PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
				PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
			]);
		},
	},
	TEAM: {
		key: 'TEAM',
		translationId: 'common.team',
		inConnectedProjectParent: true,
		financialsInfo: false,
	},
	INTEGRATIONS: {
		key: 'INTEGRATIONS',
		translationId: 'settings_account.section-integrations',
		inConnectedProjectParent: false,
		financialsInfo: false,
	},
};
