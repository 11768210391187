import React, {useEffect, useState} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {ContentWrapper, MainSectionWrapper, PageWrapper} from './ProjectSettings_styled';
import {TABS} from './ProjectSettingsUtil';
import ProjectGroupSettingsGeneral from './ProjectGroupSettingsGeneral';
import ProjectGroupSettingsTasks from './ProjectGroupSettingsTasks';
import moment from 'moment';
import ProjectSettingsTeam from './ProjectSettingsTeam';
import * as tracking from '../../../../tracking';
import {useTrackPage} from '../../../../tracking/amplitude/hooks/useTrackPage';
import ProjectHeader from '../shared/ProjectHeader';
import CustomScrollDiv from '../../../shared/components/scroll-bars/custom_scroll_div';

const ProjectGroupSettingsPage = ({viewer, history, retry}) => {
	const [selectedTab, setSelectedTab] = useState(TABS.GENERAL.key);
	const {projectGroup, company} = viewer;
	const intl = useIntl();
	const projectsInfo = projectGroup.projects.edges[0].node;

	useTrackPage('Project Group Settings');

	useEffect(() => {
		tracking.trackPage('project-settings', null, null, {newSettings: true, projectGroup: true});
		if (history.location.hash) {
			const tab = history.location.hash.replace('#', '').toUpperCase();
			setSelectedTab(tab);
		}
	}, [history.location.hash]);

	const getSettingsSection = () => {
		switch (selectedTab) {
			case TABS.GENERAL.key:
				const transitionDay = moment('2021-03-24'); //from this day people can choose a custom color in the project settings
				const createdAt = moment(company.createdAt, 'x');
				return (
					<ProjectGroupSettingsGeneral
						intl={intl}
						projectGroupId={projectGroup.id}
						projecGrouptName={projectGroup.name}
						projectGroupColor={projectGroup.color}
						childProjects={projectGroup.projects.edges}
						isOldCompany={createdAt.isBefore(transitionDay)}
					/>
				);

			case TABS.TASKS.key:
				return (
					<ProjectGroupSettingsTasks
						intl={intl}
						projectGroupId={projectGroup.id}
						useSprint={projectsInfo.sprintTimeBox}
						sprintLength={projectsInfo.sprintLengthInDays}
						estimationUnit={projectsInfo.estimationUnit}
						hoursPrStoryPoint={projectsInfo.minutesPerEstimationPoint / 60 || ''}
					/>
				);
			case TABS.TEAM.key:
				return <ProjectSettingsTeam viewer={viewer} intl={intl} history={history} retry={retry} />;
			default:
				//this can happen if you add a wrong parameter to the url
				setSelectedTab(TABS.GENERAL.key);
		}
	};

	const getHeaderName = () => {
		switch (selectedTab) {
			case TABS.GENERAL.key:
				return intl.formatMessage({id: 'project_settings.page_header_general'});
			case TABS.TASKS.key:
				return intl.formatMessage({id: 'project_settings.page_header_tasks'});
			case TABS.TEAM.key:
				return intl.formatMessage({id: 'project_settings.page_header_team'});
			default:
				return intl.formatMessage({id: 'project_settings.page_title'});
		}
	};

	return (
		<PageWrapper>
			<ProjectHeader title={getHeaderName()} projectGroup={viewer.projectGroup} />
			<ContentWrapper>
				<CustomScrollDiv>
					<MainSectionWrapper noWidth={selectedTab === TABS.TEAM.key}>{getSettingsSection()}</MainSectionWrapper>
				</CustomScrollDiv>
			</ContentWrapper>
		</PageWrapper>
	);
};

const ProjectGroupSettingsPageQuery = graphql`
	query ProjectGroupSettingsPage_Query($groupId: String) {
		viewer {
			actualPersonId
			component(name: "project_group_settings")
			projectGroup(id: $groupId) {
				projects(first: 1000000) {
					edges {
						node {
							id
						}
					}
				}
			}
			...ProjectGroupSettingsPage_viewer @arguments(groupId: $groupId)
		}
	}
`;

export {ProjectGroupSettingsPageQuery};

export default withRouter(
	createFragmentContainer(ProjectGroupSettingsPage, {
		viewer: graphql`
			fragment ProjectGroupSettingsPage_viewer on Viewer @argumentDefinitions(groupId: {type: "String"}) {
				id
				availableFeatureFlags {
					key
				}
				projectGroup(id: $groupId) {
					id
					name
					color
					companyProjectGroupId ## team section only
					...ProjectHeader_projectGroup
					...SecondaryNavigation_projectGroup
					projects(first: 1000000) @connection(key: "ProjectGroup_projects", filters: []) {
						edges {
							node {
								id
								estimationUnit
								sprintTimeBox
								sprintLengthInDays
								minutesPerEstimationPoint
								useManualAllocations
								projectStartDay
								projectStartMonth
								projectStartYear
								projectEndDay
								projectEndMonth
								projectEndYear
								## team section start ##
								teamsId
								projectColor
								companyProjectId
								name
								projectPersons(first: 10000, includeInactive: true, excludeClientUsers: true)
									@connection(key: "Project_projectPersons", filters: []) {
									edges {
										node {
											id
											isContactPerson
											relationType
											role {
												id
												name
											}
											person {
												id
												firstName
												lastName
												fullName
												initials
												profiles(first: 10000) {
													edges {
														node {
															id
															name
														}
													}
												}
												permissions
												email
												profilePictureId
												profilePictureDefaultId
												active
												isViewer
												harvestUser
												role {
													id
													name
												}
												personLabels(first: 1000) {
													edges {
														node {
															id
															label {
																id
																name
															}
														}
													}
												}
											}
										}
									}
								}
								## team section end ##
							}
						}
					}
				}
				## team section start and company createdAt needed for the project color picker##
				company {
					isUsingProjectAllocation
					harvestEnabled
					createdAt ##new companies should not see the hex code input
					profiles(first: 100000) {
						edges {
							node {
								id
								name
							}
						}
					}
					roles(first: 10000) {
						edges {
							node {
								id
								name
							}
						}
					}
					labels(first: 10000) {
						edges {
							node {
								id
								name
								color
							}
						}
					}
					teams(first: 1000) {
						edges {
							node {
								id
								name
								teamPersons(first: 1000) {
									edges {
										node {
											id
											person {
												id
												active
												firstName
												lastName
												fullName
												initials
												profilePictureId
												profilePictureDefaultId
												email
												monday
												tuesday
												wednesday
												thursday
												friday
												saturday
												sunday
												harvestUser
												role {
													id
													name
												}
											}
										}
									}
								}
							}
						}
					}
					allPersons(first: 10000, onlyActive: true, excludeClientUsers: true)
						@connection(key: "Company_allPersons", filters: []) {
						edges {
							node {
								id
								firstName
								lastName
								fullName
								initials
								active
								profilePictureId
								profilePictureDefaultId
								email
								monday
								tuesday
								wednesday
								thursday
								friday
								saturday
								sunday
								harvestUser
								role {
									id
									name
								}
							}
						}
					}
				}
				## team section end ##
			}
		`,
	})
);
