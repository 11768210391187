export const CARRIAGE_RETURN = '\u000D';

export const LINE_FEED = '\u000A';

export const NO_BREAK_SPACE = '\u00A0';

export const SPACE = '\u0020';

export const TAB = '\u0009';

export const ZERO_WIDTH_SPACE = '\u200B';
