import React, {Component} from 'react';
import {NativeTypes} from 'react-dnd-html5-backend';
import {DropTarget} from 'react-dnd';
import {FormattedMessage} from 'react-intl';
import ProjectFilesTable from './ProjectFilesTable';
import PropTypes from 'prop-types';
import ProjectFilesPathLinkItem from './ProjectFilesPathLinkItem';
import {MODAL_TYPE, showModal} from '../../../shared/components/modals/generic_modal_conductor';
import {BUTTON_STYLE, BUTTON_COLOR, EMPTY_STATE} from '../../../../constants';
import EmptyState from '../../../shared/components/empty-states/empty_state';

const fileTarget = {
	canDrop(props, monitor) {
		return !props.locked;
	},
	drop(props, monitor) {
		const files = monitor.getItem().files;
		let checkFiles = () => {
			let p = new Promise((resolve, reject) => {
				let fileslength = files.length;
				monitor.getItem().files.forEach((file, index) => {
					let reader = new FileReader();
					reader.onload = result => {
						if (index === fileslength - 1) {
							return resolve(true);
						}
					};
					reader.onerror = result => {
						return reject(result);
					};
					reader.readAsText(file);
				});
				//resolve(canUpload)
			})
				.then(data => {
					if (data === true) {
						props.uploadFiles(files);
					}
				})
				.catch(err => {
					showModal({
						type: MODAL_TYPE.WARNING,
						warningMessageId: 'common.invalid_action_modal_title',
						warningInformation: [props.folderExceptionMsg],
						buttons: [
							{
								text: props.commonOkMsg,
								style: BUTTON_STYLE.FILLED,
								color: BUTTON_COLOR.WHITE,
							},
						],
					});
				});

			return p;
		};
		checkFiles();
	},
};

class ProjectFilesDropZone extends Component {
	moveToFolder(selectedPath) {
		let folder = null;
		if (selectedPath.value !== null) {
			folder = this.props.folders.find(f => f.node.id === selectedPath.value);
		}
		this.props.selectFolder(folder);
	}
	uploadFiles() {
		this.props.uploadFiles(this._file.files.item(0));
	}

	addFolderToPath(folder) {
		this.pathFolders.push({value: folder.id, label: folder.name});
		if (folder.folder) {
			//find folder in projects folders array and call addFolderToPath
			const parentFolder = this.props.folders.find(el => el.node.id === folder.folder.id);
			if (parentFolder) {
				this.addFolderToPath(parentFolder.node);
			}
		}
	}

	render() {
		this.pathFolders = [];
		const {connectDropTarget, isOver} = this.props;
		if (this.props.selectedFolder) {
			this.addFolderToPath(this.props.selectedFolder.node);
		}
		this.pathFolders.push({value: null, label: this.props.projectName});
		this.pathFolders.reverse();
		return connectDropTarget(
			<div className={isOver ? 'file-tables-over' : 'file-tables'}>
				{!this.props.isEmpty ? (
					<div className="new-file-controls">
						<h3 className="new-file-info">
							<FormattedMessage id="project_files.upload-info" />
						</h3>
					</div>
				) : (
					<EmptyState pageName={EMPTY_STATE.FILES}></EmptyState>
				)}
				<div className="inner">
					{this.props.isEmpty ? null : (
						<div className="navigation-bar">
							{this.pathFolders.map((folder, index) => (
								<ProjectFilesPathLinkItem
									key={index}
									folder={folder}
									moveToFolder={this.moveToFolder.bind(this)}
									showActionDropdown={index !== 0 && index + 1 === this.pathFolders.length}
									projectId={this.props.projectId}
									removeAllFromBulkUpdate={this.props.removeAllFromBulkUpdate}
									uploadFiles={this.props.uploadFiles}
									uploadFilesGoogleDrive={this.props.uploadFilesGoogleDrive}
									gdriveEnabled={this.props.gdriveEnabled}
								/>
							))}
						</div>
					)}
					{!this.props.isEmpty ? (
						<ProjectFilesTable
							files={this.props.files}
							folders={this.props.folders}
							selectFile={this.props.selectFile}
							deleteFile={this.props.deleteFile}
							selectedFileId={this.props.selectedFileId}
							unlinkFile={this.props.unlinkFile}
							selectFolder={this.props.selectFolder}
							deleteFolder={this.props.deleteFolder}
							selectedFolder={this.props.selectedFolder}
							showFileLocationModal={this.props.showFileLocationModal}
							cards={this.props.cards}
							handleFileMoveToCard={this.props.handleFileMoveToCard}
							handleFileMoveToRoot={this.props.handleFileMoveToRoot}
							filterSelected={this.props.filterSelected}
							addFileToBulkUpdate={this.props.addFileToBulkUpdate.bind(this)}
							removeFileFromBulkUpdate={this.props.removeFileFromBulkUpdate.bind(this)}
							bulkUpdateFiles={this.props.bulkUpdateFiles}
							removeAllFromBulkUpdate={this.props.removeAllFromBulkUpdate}
							toggleSelectAllFiles={this.props.toggleSelectAllFiles.bind(this)}
							renameFolder={this.props.renameFolder.bind(this)}
							elements={this.props.elements}
							sortBy={this.props.sortBy}
							setSortBy={this.props.setSortBy}
						/>
					) : null}
				</div>
			</div>
		);
	}
}

ProjectFilesDropZone.propTypes = {
	selectedFileId: PropTypes.string,
	selectFile: PropTypes.func.isRequired,
	deleteFile: PropTypes.func.isRequired,
	files: PropTypes.array.isRequired,
	uploadFiles: PropTypes.func.isRequired,
	commonOkMsg: PropTypes.string.isRequired,
	folderExceptionMsg: PropTypes.string.isRequired,
	isEmpty: PropTypes.bool.isRequired,
	locked: PropTypes.bool,
	folders: PropTypes.array,
	selectFolder: PropTypes.func.isRequired,
	selectedFolder: PropTypes.object,
	cards: PropTypes.array.isRequired,
	handleFileMoveToCard: PropTypes.func.isRequired,
	showFileLocationModal: PropTypes.func.isRequired,
	projectId: PropTypes.string.isRequired,
};

export default DropTarget(NativeTypes.FILE, fileTarget, (connect, monitor) => ({
	connectDropTarget: connect.dropTarget(),
	isOver: monitor.isOver(),
	canDrop: monitor.canDrop(),
}))(ProjectFilesDropZone);
