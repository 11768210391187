import {
	DeprecatedContextMenuItem as ContextMenuItem,
	DeprecatedText as Text,
	deprecatedTheme as theme,
	FlexColumn,
	Icon,
	DeprecatedInput as Input,
	DeprecatedList as List,
	DeprecatedListCell as ListCell,
	DeprecatedListRow as ListRow,
	DeprecatedMultiColorIndicator as MultiColorIndicator,
	DeprecatedScrollbar as Scrollbar,
} from '@forecast-it/design-system';
import React, {useContext, useState} from 'react';
import {ProgramContext} from '../../ProgramContextProvider';
import Util from '../../../../../shared/util/util';
import DetachProjectFromProgramMutation from '../../../../../../mutations/project-service/detach_project_from_program_mutation';

import AttachProjectToProgramMutation from '../../../../../../mutations/project-service/attach_project_to_program_mutation';
import {createToast} from '../../../../../shared/components/toasts/toast';
import {trackEvent} from '../../../../../../tracking/amplitude/TrackingV2';
import {useIntl} from 'react-intl';
import ProjectUtil from '../../../../../shared/util/project_util';
import {PROGRAM_BUDGET_TYPE} from '../../../../../../constants';
import ForecastTooltip from '../../../../../shared/components/tooltips/ForecastTooltip';
import styled from 'styled-components';
import {hasPermission} from '../../../../../shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../../../Permissions';
import ProgramUtil from '../../../../../shared/util/ProgramUtil';
import {createFragmentContainer, graphql} from 'react-relay';

const ContextMenuHeader = styled.div`
	// TODO use the one from design system when available
	padding: ${theme.spacing.xs} ${theme.spacing.m} ${theme.spacing.xs} ${theme.spacing.m};
`;

const MoveProjectSection = ({project, closeContextMenu, viewer}) => {
	const {formatMessage} = useIntl();
	const isAdmin = hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS);

	const {program, actualPersonId} = useContext(ProgramContext);
	const allPrograms = viewer.company.programs.edges.map(program => program.node);
	const allProgramsWithoutCurrent = allPrograms.filter(p => p.id !== program.id);
	const allProgramsTheUserIsOwnerOfOrAdmin = allProgramsWithoutCurrent.filter(
		p => !!p.members.edges.filter(m => (m.node.person.id === actualPersonId && m.node.role === 'OWNER') || isAdmin).length
	);
	let allProgramsOfCorrectBudgetType = allProgramsTheUserIsOwnerOfOrAdmin;
	let unavailableProgramsWithWrongBudgetType = [];
	if (!ProjectUtil.isAnyKindOfFixedPrice(project)) {
		allProgramsOfCorrectBudgetType = allProgramsTheUserIsOwnerOfOrAdmin.filter(
			p => p.budgetType !== PROGRAM_BUDGET_TYPE.FIXED_PRICE
		);
		unavailableProgramsWithWrongBudgetType = allProgramsTheUserIsOwnerOfOrAdmin.filter(
			p => p.budgetType === PROGRAM_BUDGET_TYPE.FIXED_PRICE
		);
	}

	const [filteredPrograms, setFilteredPrograms] = useState(allProgramsOfCorrectBudgetType);
	const [loading, setLoading] = useState(false);
	const [filteredUnavailablePrograms, setFilteredUnavailablePrograms] = useState(unavailableProgramsWithWrongBudgetType);

	function filterByName(input, programsList) {
		const lowerCaseInput = input.toLowerCase();
		return programsList.filter(
			program =>
				program.name.toLowerCase().includes(lowerCaseInput) || program.prefix.toLowerCase().includes(lowerCaseInput)
		);
	}

	const searchPrograms = event => {
		const input = event.target.value;
		setFilteredPrograms(filterByName(input, allProgramsOfCorrectBudgetType));
		setFilteredUnavailablePrograms(filterByName(input, unavailableProgramsWithWrongBudgetType));
	};

	const moveProjectToProgram = newProgram => {
		if (loading) {
			return;
		}
		setLoading(true);
		Util.CommitMutation(
			AttachProjectToProgramMutation,
			{
				programId: newProgram.id,
				projectIds: project.id,
			},
			() =>
				Util.CommitSchedulingModalUpdate(
					DetachProjectFromProgramMutation,
					{
						programId: program.id,
						projectIds: project.id,
					},
					() => {
						setLoading(false);
						trackEvent('Project', 'Moved Between Programs', {
							fromProgram: program.name,
							toExistingProgram: newProgram.name,
						});
						closeContextMenu();
						createToast({
							duration: 5000,
							message: formatMessage(
								{id: 'program.project.move.moved_project'},
								{program: ProgramUtil.programText(formatMessage)}
							),
						});
					}
				)
		);
	};

	return (
		<>
			<ContextMenuItem>
				<Input
					icon={'search'}
					placeholder={formatMessage(
						{id: 'search.search_entity'},
						{entity: ProgramUtil.programText(formatMessage, true).toLowerCase()}
					)}
					onChange={e => searchPrograms(e)}
				/>
			</ContextMenuItem>
			<Scrollbar maxHeight={'250px'}>
				<List>
					{filteredPrograms.map(program => (
						<ContextMenuItem onClick={() => moveProjectToProgram(program)} key={program.id}>
							<ListRow>
								<ListCell size={'s'}>
									<MultiColorIndicator color={program.color} size={'s'} />
								</ListCell>
								<ListCell size={'m'}>
									<Text variant={'bold'}>{program.prefix}</Text>
								</ListCell>
								<ListCell>
									<Text>{program.name}</Text>
								</ListCell>
							</ListRow>
						</ContextMenuItem>
					))}
				</List>

				{filteredUnavailablePrograms && filteredUnavailablePrograms.length > 0 && (
					<>
						<List>
							<ContextMenuHeader>
								<Text variant={'bold'}>{formatMessage({id: 'move_project.unavailable_programs'})}</Text>
								<ForecastTooltip
									maxWidth={'320px'}
									spanStyle={{position: 'relative', top: '6px', left: '2px'}}
									placement={'bottom'}
									content={
										<FlexColumn gap={'s'}>
											<Text type={'heading'} variant={'s'}>
												{Util.upperCaseFirst(
													formatMessage(
														{id: 'move_project.unavailable_programs'},
														{programs: ProgramUtil.programText(formatMessage)}
													)
												)}
											</Text>
											<Text>{formatMessage({id: 'import_project.unavailable_projects_info_text'})}</Text>
										</FlexColumn>
									}
								>
									<Icon icon={'help'} size={'m'} />
								</ForecastTooltip>
							</ContextMenuHeader>
							{filteredUnavailablePrograms.map(program => (
								<ContextMenuItem disabled={true} onClick={() => {}} key={program.id}>
									<ListRow>
										<ListCell size={'s'}>
											<MultiColorIndicator color={program.color} size={'s'} />
										</ListCell>
										<ListCell size={'m'}>
											<Text variant={'bold'} color={'disabled'}>
												{program.prefix}
											</Text>
										</ListCell>
										<ListCell>
											<Text color={'disabled'}>{program.name}</Text>
										</ListCell>
									</ListRow>
								</ContextMenuItem>
							))}
						</List>
					</>
				)}
			</Scrollbar>
		</>
	);
};

//export default MoveProjectSection;
const MoveProjectSectionQuery = graphql`
	query MoveProjectSection_Query {
		viewer {
			actualPersonId
			component(name: "move_project_section")
			...MoveProjectSection_viewer
		}
	}
`;

export {MoveProjectSectionQuery};

export default createFragmentContainer(MoveProjectSection, {
	viewer: graphql`
		fragment MoveProjectSection_viewer on Viewer {
			id
			company {
				id
				currency
				programs(first: 10000) @connection(key: "Company_programs", filters: []) {
					edges {
						node {
							id
							name
							description
							prefix
							color
							startDate
							endDate
							budgetType
							members(first: 10000) @connection(key: "Program_members", filters: []) {
								edges {
									node {
										id
										role
										person {
											id
										}
									}
								}
							}
						}
					}
				}
			}
		}
	`,
});
