import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import ProjectFilesLineItem from './ProjectFilesLineItem';
import ProjectFilesFolderLineItem from './ProjectFilesFolderLineItem';
import Checkbox from '../../../../components/inputs/checkbox';
import EmptyState from '../../../shared/components/empty-states/empty_state';
import {EMPTY_STATE} from '../../../../constants';

class ProjectFilesTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sortBy: {column: null, ascending: true},
		};
	}

	setSortBy(column) {
		this.props.setSortBy(column);
	}

	selectFile(file) {
		this.props.selectFile(file);
	}

	addFileToBulkUpdate(file, e) {
		this.props.addFileToBulkUpdate(file, e, this.state.sortBy, this.props.elements);
	}

	removeFileFromBulkUpdate(file, shiftKey) {
		this.props.removeFileFromBulkUpdate(file, shiftKey, this.state.sortBy);
	}

	render() {
		const {formatMessage} = this.props.intl;
		const files = this.props.elements.filter(ele => !ele.isFolder);
		return (
			<div className="table-wrapper">
				{this.props.elements.length !== 0 ? (
					<table className="files-table" id="table">
						<thead>
							<tr>
								<th className="selector">
									<div className="bulk-update-checkbox">
										{files.length ? (
											<Checkbox
												onChange={this.props.toggleSelectAllFiles.bind(this)}
												checked={this.props.bulkUpdateFiles.length !== 0}
												hasEntitiesChecked={
													this.props.bulkUpdateFiles.length !== 0 &&
													this.props.bulkUpdateFiles.length !== files.length
												}
											/>
										) : null}
									</div>
								</th>
								<th className="drag-column" />
								<th
									className={
										'row-format sortable ' +
										(this.props.sortBy.column === 'format'
											? this.props.sortBy.ascending
												? 'ascending'
												: 'descending'
											: '')
									}
									onClick={this.setSortBy.bind(this, 'format')}
								>
									{formatMessage({id: 'common.type'})}
									<span>&nbsp;&nbsp;</span>
								</th>
								<th
									className={
										'row-name sortable ' +
										(this.props.sortBy.column === 'name'
											? this.props.sortBy.ascending
												? 'ascending'
												: 'descending'
											: '')
									}
									onClick={this.setSortBy.bind(this, 'name')}
								>
									{formatMessage({id: 'card_modal.file-name'})}
									<span>&nbsp;&nbsp;</span>
								</th>
								<th
									className={
										'row-date sortable ' +
										(this.props.sortBy.column === 'date'
											? this.props.sortBy.ascending
												? 'ascending'
												: 'descending'
											: '')
									}
									onClick={this.setSortBy.bind(this, 'date')}
								>
									{formatMessage({id: 'project_files.th-date'})}
									<span>&nbsp;&nbsp;</span>
								</th>
								<th
									className={
										'row-task sortable ' +
										(this.props.sortBy.column === 'task'
											? this.props.sortBy.ascending
												? 'ascending'
												: 'descending'
											: '')
									}
									onClick={this.setSortBy.bind(this, 'task')}
								>
									{formatMessage({id: 'common.card'})}
									<span>&nbsp;&nbsp;</span>
								</th>
								<th className="row-action-menu sortable" />
							</tr>
						</thead>
						<tbody>
							{this.props.elements.map((element, index) =>
								element.isFolder ? (
									<ProjectFilesFolderLineItem
										key={index}
										folder={element.object}
										selectFolder={this.props.selectFolder}
										deleteFolder={this.props.deleteFolder}
										removeAllFromBulkUpdate={this.props.removeAllFromBulkUpdate}
										renameFolder={this.props.renameFolder.bind(this)}
									/>
								) : (
									<ProjectFilesLineItem
										key={index}
										file={element.object}
										selectFile={this.selectFile.bind(this)}
										deleteFile={this.props.deleteFile.bind(this)}
										showFileLocationModal={this.props.showFileLocationModal}
										unlinkFile={this.props.unlinkFile.bind(this)}
										handleFileMoveToCard={this.props.handleFileMoveToCard}
										handleFileMoveToRoot={this.props.handleFileMoveToRoot}
										addFileToBulkUpdate={this.addFileToBulkUpdate.bind(this)}
										removeFileFromBulkUpdate={this.removeFileFromBulkUpdate.bind(this)}
										marked={this.props.bulkUpdateFiles.includes(element.object.node.id)}
										bulkUpdateFiles={this.props.bulkUpdateFiles}
										removeAllFromBulkUpdate={this.props.removeAllFromBulkUpdate}
									/>
								)
							)}
						</tbody>
					</table>
				) : (
					<EmptyState pageName={EMPTY_STATE.FILES}></EmptyState>
				)}
			</div>
		);
	}
}

ProjectFilesTable.propTypes = {
	selectedFileId: PropTypes.string,
	selectFile: PropTypes.func.isRequired,
	deleteFile: PropTypes.func.isRequired,
	files: PropTypes.array.isRequired,
	folders: PropTypes.array,
	selectFolder: PropTypes.func.isRequired,
	selectedFolder: PropTypes.object,
	handleFileMoveToCard: PropTypes.func.isRequired,
	handleFileMoveToRoot: PropTypes.func.isRequired,
	showFileLocationModal: PropTypes.func.isRequired,
};

export default injectIntl(ProjectFilesTable);
