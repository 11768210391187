import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import Util from '../../../shared/util/util';
import {Line} from 'react-chartjs-2';
import RadialProgressBar from '../../../../components/new-ui/radial_progress_bar';
import {MODAL_TYPE, showModal} from '../../../shared/components/modals/generic_modal_conductor';
import {BUTTON_COLOR, BUTTON_STYLE, PERIOD_BUDGET_TYPE} from '../../../../constants';
import WarningIcon from '../../../../images/warning_icon';
import Moment from 'moment';
import Button from '../../../shared/components/buttons/button/button';
import * as tracking from '../../../../tracking';
import {getGradients} from '../../../../components/new-ui/project/project-budget-v3/util/ChartsUtil.js';
import {hasPermission} from '../../../shared/util/PermissionsUtil';
import ForecastTooltip from '../../../shared/components/tooltips/ForecastTooltip';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import {hasFeatureFlag} from '../../../shared/util/FeatureUtil';

class ProjectRetainerPeriodDataVisuals extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showPeriodHover: false,
			tooltipPosition: {},
		};
	}

	toggleShowConflictModal() {
		tracking.trackElementClicked('Time entry conflicts');
		trackEvent('Time Entry Conflicts Modal', 'Shown');
		showModal({
			type: MODAL_TYPE.RETAINER_TIME_ENTRY_CONFLICT_HANDLING,
			period: this.props.period.node,
			conflictedTimeEntries: this.props.conflictedTimeEntries,
			inHours: this.props.inHours,
			currency: this.props.currency,
			viewer: this.props.viewer,
			useTimeApproval: this.props.useTimeApproval,
		});
	}
	showPeriodHover(e) {
		if (this._hover_tile) {
			const tileRect = this._hover_tile.getBoundingClientRect();
			const x = tileRect.right;
			const y = tileRect.top;
			const windowHeight = window.innerHeight;
			const shouldFlipUp = windowHeight - y <= 260;
			const tooltipPosition = {x: x - 15, y: y + (shouldFlipUp ? -185 : 15)};
			if (!this.state.showPeriodHover) {
				this.setState({showPeriodHover: true, tooltipPosition});
			}
		}
	}
	hidePeriodHover(e) {
		this.setState({showPeriodHover: false, tooltipPosition: {}});
	}
	render() {
		const {formatMessage, formatNumber} = this.props.intl;
		const {gradientStrokeFixedPrice} = getGradients();
		const data = {
			labels: [],
			datasets: [],
		};
		const {withSuffix, inHours, isFixedPrice} = this.props;
		const period = this.props.period.node;
		const hasFinancialServiceFlag = hasFeatureFlag('planned_values_on_retainer_periods');
		const hasRolloverFlag = hasFeatureFlag('rollover_values_on_retainer_periods');
		const financialNumbers = period.financialNumbers;
		const periodStart = Util.CreateMomentDate(period.startYear, period.startMonth, period.startDay);
		const periodEnd = Util.CreateMomentDate(period.endYear, period.endMonth, period.endDay);
		const timeRegData = new Map();
		const sortByDate = (a, b) => {
			const aDate = Util.CreateMomentDate(a.node.year, a.node.month, a.node.day);
			const bDate = Util.CreateMomentDate(b.node.year, b.node.month, b.node.day);
			return aDate.isBefore(bDate) ? -1 : 1;
		};
		//clone the array to not override it
		let graphTimeRegs = this.props.timeRegs.slice(0);

		// Add start & end date, with 0 minutes registered
		if (periodStart) {
			graphTimeRegs.push({
				node: {
					id: 'PeriodStart',
					year: periodStart.year(),
					month: periodStart.month() + 1,
					day: periodStart.date(),
					price: 0,
					billableMinutesRegistered: 0,
				},
			});
		}
		if (periodEnd) {
			graphTimeRegs.push({
				node: {
					id: 'PeriodEnd',
					year: periodEnd.year(),
					month: periodEnd.month() + 1,
					day: periodEnd.date(),
					price: 0,
					billableMinutesRegistered: 0,
				},
			});
		}
		graphTimeRegs.sort(sortByDate).forEach(timeReg => {
			const tDate = Util.CreateMomentDate(timeReg.node.year, timeReg.node.month, timeReg.node.day).format('DD MMM');
			if (timeRegData.get(tDate)) {
				timeRegData.set(
					tDate,
					timeRegData.get(tDate) + (inHours ? timeReg.node.billableMinutesRegistered / 60 : timeReg.node.price)
				);
			} else {
				timeRegData.set(tDate, inHours ? timeReg.node.billableMinutesRegistered / 60 : timeReg.node.price);
			}
		});
		const dataSetData = [];
		const dataLabels = [];
		let runningTotal = 0; // Accumulated data
		/*const conflictData = this.props.conflictedTimeEntries.map(entry => {
			const tDate = Util.CreateMomentDate(entry.node.year, entry.node.month, entry.node.day).format('DD MMM YYYY');
			return {x: tDate, y: entry.node.price};
		});*/
		timeRegData.forEach((value, key) => {
			dataSetData.push(value + runningTotal);
			runningTotal += value;
			dataLabels.push(key);
		});
		if (dataSetData.length === 1) {
			dataSetData.push(dataSetData[0]);
			dataLabels.push(dataLabels[0]);
		}

		if (!this.props.isTimeMaterials || !!period.periodHoursAmount) {
			data.datasets.push({
				type: 'line',
				label: formatMessage({id: 'retainer.target'}),
				borderColor: gradientStrokeFixedPrice,
				pointHoverRadius: 0,
				pointHoverBorderWidth: 2,
				pointBackgroundColor: 'transparent',
				pointHoverBorderColor: gradientStrokeFixedPrice,
				pointRadius: 0,
				fill: false,
				backgroundColor: '#fff',
				borderWidth: 2,
				data: Array(Math.max(2, dataSetData.length)).fill(
					inHours
						? period.periodHoursAmount +
								period.periodDifferenceHoursAmount +
								period.sharedPeriodDifferenceHoursAmount
						: period.periodPriceAmount +
								period.periodDifferencePriceAmount +
								period.sharedPeriodDifferencePriceAmount
				),
			});
		}
		data.datasets.push({
			label: inHours
				? formatMessage({id: 'common.total_time_entries'})
				: formatMessage({id: 'retainer.total_price_of_time'}),
			pointHoverRadius: 2,
			pointHoverBorderWidth: 2,
			pointBackgroundColor: '#b7ffbe',
			pointRadius: 1,
			lineTension: 0,
			fill: true,
			borderColor: '#49d156',
			backgroundColor: '#b7ffbe',
			borderWidth: 1,
			data: dataSetData,
		});
		/*data.datasets.push({
			label: 'Conflicts',
			borderColor: 'red',
			lineTension: 0,
			fill: false,
			data: conflictData
		});*/
		data.labels = dataLabels;
		const options = {
			font: {
				family: 'neue-haas-grotesk-text',
			},
			plugins: {
				legend: {
					display: false,
				},
				title: {
					display: false,
				},
				tooltip: {
					mode: 'index',
					backgroundColor: '#fff',
					borderColor: '#e6e6e6',
					borderWidth: 1,
					titleColor: '#535353',
					bodyColor: '#535353',
					padding: 12,
					intersect: false,
					callbacks: {
						label: ctx => {
							const dataName = ctx.dataset.label;
							if (dataName === formatMessage({id: 'retainer.target'})) {
								return '';
							}
							return dataName + ': ' + withSuffix(parseFloat(ctx.parsed.y).toFixed(2), true);
						},
					},
				},
				datalabels: {
					display: false,
				},
			},
			responsive: true,
			maintainAspectRatio: false,
			scales: {
				y: {
					title: {
						display: true,
						text: inHours ? formatMessage({id: 'common.hours'}) : formatMessage({id: 'common.price'}),
					},
					beginAtZero: true,
					min: 0,
				},
				x: {
					ticks: {
						maxTicksLimit: data.labels.length,
					},
				},
			},
		};

		const textColor = Util.getTextColorV2(this.props.projectColor);
		const actualHours = hasFinancialServiceFlag
			? financialNumbers.billableActualMinutes / 60
			: this.props.timeRegs.reduce((acc, timeReg) => acc + timeReg.node.billableMinutesRegistered / 60, 0);
		const actualPrice = hasFinancialServiceFlag
			? financialNumbers.billableActualTimeAndExpenses
			: this.props.timeRegs.reduce((acc, timeReg) => acc + timeReg.node.price, 0);
		const totalConflicted = inHours
			? this.props.conflictedTimeEntries.reduce((acc, tReg) => acc + tReg.node.billableMinutesRegistered / 60, 0)
			: this.props.conflictedTimeEntries.reduce((acc, tReg) => acc + tReg.node.price, 0);
		const periodTarget =
			hasFinancialServiceFlag && hasRolloverFlag
				? inHours
					? financialNumbers.retainerPeriodTargetMinutes / 60
					: financialNumbers.retainerPeriodTargetPrice
				: inHours
				? period.periodHoursAmount
				: period.periodPriceAmount;
		const periodDifference =
			hasFinancialServiceFlag && hasRolloverFlag
				? inHours
					? financialNumbers.retainerPeriodRolloverMinutes / 60
					: financialNumbers.retainerPeriodRolloverPrice
				: inHours
				? period.periodDifferenceHoursAmount
				: period.periodDifferencePriceAmount;
		const sharedDifference =
			hasFinancialServiceFlag && hasRolloverFlag
				? 0
				: inHours
				? period.sharedPeriodDifferenceHoursAmount
				: period.sharedPeriodDifferencePriceAmount;
		const periodTotalDifference = periodDifference + sharedDifference;
		const periodTargetTotal = periodTarget + periodDifference + sharedDifference;
		const progress =
			periodTargetTotal <= 0
				? 100
				: inHours
				? Math.round((actualHours / periodTargetTotal) * 100 * 10) / 10
				: Math.round((actualPrice / periodTargetTotal) * 100 * 10) / 10;
		const periodTargetTitle = formatMessage({id: 'retainer.period_target'});
		const differenceIsSubtraction = periodDifference < 0;
		const sharedIsSubtraction = sharedDifference < 0;
		const periodRollOverTitle = differenceIsSubtraction =>
			differenceIsSubtraction
				? inHours
					? formatMessage({id: 'retainer.subtracted_hours'})
					: formatMessage({id: 'retainer.subtracted_price'})
				: inHours
				? formatMessage({id: 'period.rolled_over_hours'})
				: formatMessage({id: 'period.rolled_over_price'});

		const actualWorkTitle = inHours
			? formatMessage({id: 'common.time_entries'})
			: formatMessage({id: isFixedPrice ? 'project_budget.actual_billable_time' : 'project_budget.actual_revenue'});
		const remainingTitle = formatMessage({id: isFixedPrice ? 'retainer.tm_remaining' : 'common.remaining'});
		const isOverdue = Moment().isAfter(periodEnd) && !period.periodLocked;
		return (
			<div className={'period-data-visuals-wrapper'}>
				<div
					className={'time-reg-chart-container'}
					data-userpilot={'retainer-period-chart-' + this.props.userpilotIndex}
				>
					<Line options={options} data={data} />
				</div>

				{period.periodBudgetType === PERIOD_BUDGET_TYPE.TIME_AND_MATERIALS ? null : (
					<div className={'time-reg-pie-wrapper'}>
						<RadialProgressBar
							progress={isNaN(progress) ? 0 : progress}
							textColor={'#535353'}
							trailColor={'#f3f3f3'}
							pathColor={progress > 100 ? '#ec4b4b' : this.props.projectColor}
						/>
					</div>
				)}
				<div className={'period-tiles'} data-cy={'period-tiles'}>
					{period.periodBudgetType === PERIOD_BUDGET_TYPE.TIME_AND_MATERIALS ? (
						<div className={'tile white'}>
							<div className={'tile-header'}>{formatMessage({id: 'common.time_entries'})}</div>
							{this.props.timeRegs.length}
						</div>
					) : (
						<div
							className={'tile white'}
							data-cy={'period-target-tile'}
							ref={div => (this._hover_tile = div)}
							onMouseEnter={this.showPeriodHover.bind(this)}
							onMouseLeave={this.hidePeriodHover.bind(this)}
						>
							<div className={'tile-header'}>{periodTargetTitle}</div>
							{periodTotalDifference != null && periodTotalDifference !== 0 ? (
								<div className={'combined-number'}>
									<div className={'info-icon'}>
										<WarningIcon />
									</div>
									<div className={'period-text'}>
										{this.props.withSuffix(periodTarget + periodTotalDifference)}
									</div>
									{this.state.showPeriodHover ? (
										<div
											className={'hover-container'}
											style={{top: this.state.tooltipPosition.y, left: this.state.tooltipPosition.x}}
										>
											<div className={'hover-line'}>
												<div className={'hover-line-header'}>{periodTargetTitle}</div>
												<div className={'hover-line-number'}>{this.props.withSuffix(periodTarget)}</div>
											</div>
											<div className={'hover-line'}>
												<div className={'hover-line-header'}>
													{periodRollOverTitle(differenceIsSubtraction)}
												</div>
												<div className={'hover-line-number'}>
													{this.props.withSuffix(periodDifference)}
												</div>
											</div>
											{sharedDifference != null && sharedDifference !== 0 ? (
												<div className={'hover-line'}>
													<div className={'hover-line-header'}>
														{periodRollOverTitle(sharedIsSubtraction) + ' (split)'}
													</div>
													<div className={'hover-line-number'}>
														{this.props.withSuffix(sharedDifference)}
													</div>
												</div>
											) : null}
											<div className={'hover-line total'}>
												<div className={'hover-line-header'}>{formatMessage({id: 'common.total'})}</div>
												<div className={'hover-line-number'}>
													{this.props.withSuffix(periodTargetTotal)}
												</div>
											</div>
										</div>
									) : null}
								</div>
							) : (
								this.props.withSuffix(inHours ? period.periodHoursAmount : period.periodPriceAmount)
							)}
						</div>
					)}
					<div style={{backgroundColor: this.props.projectColor, color: textColor}} className={'tile white-border'}>
						<div className={'tile-header'}>{actualWorkTitle}</div>
						{this.props.withSuffix(this.props.inHours ? actualHours : actualPrice)}
					</div>
					{this.props.conflictedTimeEntries.length > 0 ? (
						<div tabIndex="0" className={'tile handle conflicts-tile'}>
							<div className={'tile-header'}>{formatMessage({id: 'retainer.time_entry_conflicts_short'})}</div>
							<div className={'button-value-wrapper'}>
								<ForecastTooltip
									content={formatMessage({id: 'permission.no_time_reg_update_all_permission'})}
									disabled={hasPermission('TIME_REGISTRATION_UPDATE_ALL')}
								>
									<Button
										cy={'fix-conflict-button'}
										className={'fix-button'}
										buttonStyle={BUTTON_STYLE.OUTLINE}
										colorTheme={BUTTON_COLOR.RED}
										text={'fix'}
										onClick={this.toggleShowConflictModal.bind(this)}
										isDisabled={!hasPermission('TIME_REGISTRATION_UPDATE_ALL')}
									/>
								</ForecastTooltip>
								{this.props.withSuffix(totalConflicted)}
							</div>
						</div>
					) : isOverdue ? (
						<div className={'tile overdue'}>
							<Button
								className={'lock-button'}
								buttonStyle={BUTTON_STYLE.FILLED}
								colorTheme={BUTTON_COLOR.GREEN}
								text={formatMessage({
									id: this.props.useTimeApproval ? 'retainer.approve_lock_period' : 'retainer.lock_period',
								})}
								onClick={this.props.lockPeriod}
							/>
						</div>
					) : (
						<div tabIndex="0" className={'tile white'}></div>
					)}
					{period.periodBudgetType !== PERIOD_BUDGET_TYPE.TIME_AND_MATERIALS ? (
						<div style={{backgroundColor: this.props.projectColor, color: textColor}} className={'tile '}>
							<div className={'tile-header'}>{remainingTitle}</div>
							{this.props.withSuffix(Math.max(0, periodTargetTotal - (inHours ? actualHours : actualPrice)))}
						</div>
					) : (
						<div style={{backgroundColor: this.props.projectColor, color: textColor}} className={'tile'}>
							<div className={'tile-header'}>{formatMessage({id: 'retainer.invoiceable'})}</div>
							{Util.getFormattedCurrencyValue(
								Util.GetCurrencySymbol(this.props.currency),
								formatNumber(actualPrice, {format: 'always_two_decimal'})
							)}
						</div>
					)}
				</div>
			</div>
		);
	}
}
ProjectRetainerPeriodDataVisuals.propTypes = {
	period: PropTypes.object.isRequired,
	timeRegs: PropTypes.array.isRequired,
};
export default injectIntl(ProjectRetainerPeriodDataVisuals);
