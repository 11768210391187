import React from 'react';
import Styled from 'styled-components/macro';
import FinancialLockIcon from '../../../../components/new-ui/FinancialLockIcon';
import GenericTooltip from '../../../shared/components/tooltips/generic_tooltip';
import {CSS_CONSTANTS} from '../../../../css_variables';
import {Pill} from '@forecast-it/design-system';

export const PageWrapper = Styled.div`
   margin-bottom: 24px;
   font-size: 13px;
   color: ${CSS_CONSTANTS.v2_text_gray};
   display: flex;
   flex-direction: column;
   flex-grow: 1;
`;

export const ContentWrapper = Styled(PageWrapper)``;

export const MainSectionWrapper = Styled.div`
	display: flex;
	flex-grow: 1;
    flex-direction: column;
    ${props => !props.noWidth && 'width: 684px'};
    ${props => props.width && `width: ${props.width}px`};
    ${props => props.fullHeight && `height:100%`};
    margin: 16px 32px 0 32px;
    .project-team {
        overflow:unset;
        .section-body .member-table{
        	width: 100%;
            padding: 30px 20px 30px 20px; //to remove when Team page is updated, here for the last row context menu
        }
    }
    
    .project-settings{
        flex:1;
    }
    
    .project-role {
    	min-width: 220px;
    }
    
    .section-body {
    	box-sizing: border-box;    
    	display: flex;
    }
`;

export const InputDescription = Styled.div`
    margin: 24px 0 8px 0;
`;

export const InputLineWrapper = Styled.div`
    display: flex;
    flex-direction: row;    
    align-items: center;
    margin:${props => (props.noMargin ? '0 0 0 0' : '16px 0 0 0')};
`;

export const SelectionElementLineWrapper = Styled(InputLineWrapper)`
    align-items: center;
`;

export const InputLineLabel = Styled.div`
    flex:1;
    text-transform:capitalize;
   
`;

export const InputLineLabelRequired = Styled(InputLineLabel)`
    &:after{
        content: " *";
        color: #D0021B;
    }
`;

export const InputLineInput = Styled.div`
    width:424px;
    .project-status-color:not([disabled]) {
	    cursor: pointer;
	}
`;

export const DatesWrapper = Styled.div`
    display:flex;
    flex-direction:row;
    .date-picker-button-container-v3{
        margin-right:8px;
    }
`;

export const SelectionElementLabel = Styled.div`
    ${props => props.locked && 'color:' + CSS_CONSTANTS.v2_text_light_gray + ';'}
    margin:0 0 0 8px;
`;

export const SprintLenghtWrapper = Styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;

`;

export const SprintInputWrapper = Styled.div`
    margin:0 8px;
`;

export const ExtraElementWrapper = Styled.div`
     display:flex;
    flex-direction:column;
    margin:24px 0 0 0;
`;

export const CopyLinkWrapper = Styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    margin: 8px 0 0 0;
`;

export const CopyLinkDropdownWrapper = Styled(CopyLinkWrapper)`
    width:280px;
`;

export const LinkBox = Styled.div`
    display:flex;
    background:#F7F7FE;
    padding:6px 8px 6px 16px;
    flex:1;
    line-height:18px;
    border-radius: 4px;
    margin:0 16px 0 0;
    height:30px;
    >span{
        margin: 0 0 0 8px;
        align-self: center; 
    }
`;
export const PasswordWrapper = Styled.div`
    display:flex;
    flex-direction:row;
    flex:1;
    align-items: center;
`;
export const PasswordBullet = Styled.div`
    height:6px;
    width:6px;
    background:#000000;
    border-radius:50%;
    margin: 0 3px 0 0;
`;

export const Separator = Styled.div`
    height: 0px;
    margin: 24px 0 24px 0;
    border-bottom:solid 1px ${CSS_CONSTANTS.app_border_color};
`;

export const RadioWithDescriptionWrapper = Styled.div`
`;

export const CheckBoxDescription = Styled.div` 
    margin: 4px 0 0 30px;
    color: ${CSS_CONSTANTS.v2_text_light_gray};
`;

export const StoryPointLable = Styled.div`
    margin: 0 0 8px 0;
`;

export const HoursInputWrapper = Styled.div`
    width:80px;
`;

export const InformationWrapper = Styled.div`
    margin:8px 0 0 0;
    font-style: italic;
    color: ${CSS_CONSTANTS.v2_text_light_gray};
`;

export const PictureWrapper = Styled.div`
    margin: 0 8px 0 6px;
`;

export const ProjectStatusWrapper = Styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
`;

export const ProjectStatusFirstLine = Styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    margin:0 0 24px 0;

`;

export const StatusDateWrapper = Styled.div`
    display:flex;
    flex:1;
    margin:0 0 0 8px;
`;

export const PersonStatusWrapper = Styled.div`
    display:flex;
    align-items:center;
`;

export const PersonNameStatusWrapper = Styled.div`
    max-width:300px;
    white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const GreyText = Styled.div`
    color: ${CSS_CONSTANTS.v2_text_light_gray};
    &:after{
        /*Use to show the space after the text*/
        content: " ";
        white-space: pre;
    }
`;

export const StatusDescriptionTitle = Styled.div`
    font-weight: 500;
    margin: 0 0 8px 0 ; 
`;

export const StatusDescriptionWrapper = Styled.div`
    margin: 0 0 24px 0;

    .DraftEditor-editorContainer{
        border-left:0px; //remove the smart 0.1px from DraftJs
    }
    .editor{
        padding:0;
        margin:0;
    }
    .no-description{
        color: ${CSS_CONSTANTS.v2_text_light_gray};
        font-style: italic;
    }
`;

export const FileUploadSection = Styled.div`
    height:112px;
    display:flex;
    .new-file, .new-file-over{
        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;
        border-radius:4px;
        text-align:center;
        color: #4C4C58;
        .file-link {
            text-decoration: underline;
            color: #6E0FEA;
            input {
                display: none;
            }
        }
    }
`;

export const FilesPreviewWrapper = Styled.div`
    display: flex;
    flex-direction:column;
    font-size: 13px;
    color: #4C4C58;
    margin:16px 0 0 0;
`;

export const FilePreviewWrapper = Styled.div`
    display: flex;
    flex-direction:row;
    align-items: center;
`;

export const FilePreviewNameWrapper = Styled.div`
    flex:1;
`;

export const ConversionTableWrapper = Styled.div`
    table {
        width: 100%;
        margin-top: 8px;
    }
`;

const SubSectionWrapper = Styled.div`
    font-size: 13px;
    color: ${CSS_CONSTANTS.v2_text_gray};
    border: solid 1px ${CSS_CONSTANTS.app_border_color};
    border-radius: 4px;
    margin: 0 0 16px 0;
    padding: 24px;
`;

const SectionTitle = Styled.div`
    display:flex;
    flex-direction:row; 
    ${({showNew}) =>
		showNew
			? `
        justify-content: start;
        gap: 8px;
    `
			: `
        justify-content: space-between;
    `};
    align-items:center;
    text-transform:capitalize;
    font-size: 16px;
    font-weight:500;
`;

export const SectionDescription = Styled.div`
    color: ${CSS_CONSTANTS.v2_text_gray};
    margin: 16px 0 0 0;
`;
export const ProgressTimeRegWarning = Styled.div`
    margin: 24px 0 0 0;
`;

const SectionChildren = Styled.div`
     margin:${props => (props.noMargin ? '0 0 0 0' : '24px 0 0 0')};
`;

export const SubSection = ({title, actionElement, description, children, showCrown, showBeta, showNew}) => {
	return (
		<SubSectionWrapper>
			<SectionTitle showNew={showNew}>
				{title}
				{showNew && <Pill icon="new">New</Pill>}
				{showCrown && <FinancialLockIcon />}
				{showBeta && <GenericTooltip type="beta" />}
				{actionElement}
			</SectionTitle>
			{description && <SectionDescription>{description}</SectionDescription>}
			{children && <SectionChildren noMargin={!title}>{children}</SectionChildren>}
		</SubSectionWrapper>
	);
};

/*Information banner, with the blue background*/

const InformationSectionWrapper = Styled(SubSectionWrapper)`
    color: #4C4C58;
    background: #E2F1FF;
    border: solid 1px #8AC7FF;
    padding: 12px 24px;
`;

const InformationSectionTitle = Styled(SectionTitle)`
    font-size: 13px;
    font-weight:normal;
    a{
        //action element
        color:#008EFF;
        text-decoration: underline;
        cursor: pointer;
    }
`;

export const InformationSection = ({title, actionElement}) => {
	return (
		<InformationSectionWrapper>
			<InformationSectionTitle>
				{title}
				{actionElement}
			</InformationSectionTitle>
		</InformationSectionWrapper>
	);
};

export const FixedCostWrapper = Styled.div`
	display: flex;
`;

export const FixedCostInput = Styled.div`
	&:not(:first-child) {
		margin-left: 10px;
	}
`;

export const Subtitle = Styled.div`
    color: ${CSS_CONSTANTS.v2_text_light_gray};
    margin: 16px 0;
`;

export const RevenueChartWrapper = Styled.div`
    height: 380px;
    border: 1px solid ${CSS_CONSTANTS.v2_border_gray};
    border-radius: 4px;
    padding: 16px;
    margin-top: 16px;
`;
