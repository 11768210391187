import Util from '../../../shared/util/util';

const projectGroupSearchFilter = (projectGroup, searchFilterValue) => {
	const origin = 'X' + projectGroup.node.companyProjectGroupId + projectGroup.node.name;
	return Util.normalizedIncludes(origin, searchFilterValue);
};

const searchFilter = (project, searchFilterValue, calculatedDataMap) => {
	const projectCompletion =
		calculatedDataMap && calculatedDataMap.has(project.node.id) ? calculatedDataMap.get(project.node.id).completion : '';
	const origin =
		'P' +
		project.node.companyProjectId +
		project.node.name +
		(project.node.client ? project.node.client.name : '') +
		projectCompletion +
		project.node.forecast +
		project.node.reported +
		project.node.remaining +
		project.node.budget +
		project.node.customProjectId;

	return Util.normalizedIncludes(origin, searchFilterValue);
};

const projectsFilter = (project, filters, filterFunctions, calculatedDataMap) => {
	if (filterFunctions && filterFunctions.projectFilter) {
		const options = {
			completion: calculatedDataMap.has(project.node.id) ? calculatedDataMap.get(project.node.id).completion : null,
		};
		return filterFunctions.projectFilter(project.node, options);
	}
	return true;
};

const hasAccessToProject = p => !p.node.readOnlyAccess;

export const filterProjects = (projects, searchFilterValue, filters, filterFunctions, calculatedDataMap) => {
	return projects.filter(project => {
		const isProjectGroup = project.node.projects;
		let showProject;

		if (isProjectGroup) {
			let hasMatchingProjects = false;
			const groupSearchMatch =
				searchFilterValue && projectGroupSearchFilter(project, searchFilterValue, calculatedDataMap);
			project.node.projects.edges.forEach(p => {
				const projectMatchesFilter = projectsFilter(p, filters, filterFunctions, calculatedDataMap);
				const projectMatchesSearch = searchFilter(p, searchFilterValue, calculatedDataMap);
				// show child project if it matches the filter and either the project or the group matches the search
				p.node.showProject = projectMatchesFilter && (projectMatchesSearch || groupSearchMatch);

				if (p.node.showProject && hasAccessToProject(p)) {
					hasMatchingProjects = true;
				}
			});
			showProject = hasMatchingProjects;
		} else {
			showProject =
				hasAccessToProject(project) &&
				projectsFilter(project, filters, filterFunctions, calculatedDataMap) &&
				searchFilter(project, searchFilterValue, calculatedDataMap);
		}
		return showProject;
	});
};

export const getProjectGroupIdsByStatus = (projects, status) => {
	return projects
		.filter(project => project.node.projects)
		.filter(projectGroup => projectGroup.node.projects.edges.some(project => project.node.status === status))
		.map(projectGroup => projectGroup.node.id);
};
