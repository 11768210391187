import React, {useEffect, useState} from 'react';
import {partition} from 'lodash';
import ForecastQueryRenderer from '../../../../ForecastQueryRenderer';
import {ListHeaderTaskAmount, ListHeaderText, ListHeaderWrapper, MyWorkTasksListWrapper} from '../MyWork.styled';
import {WorkflowCategories} from '../../../../constants';
import MyWorkOverdueTasksSection, {MyWorkOverdueTasksSectionQuery} from './MyWorkOverdueTasksSection';
import {getMyWorkOverdueSearchQuery} from '../MyWorkQueryUtils';
import Util from '../../../shared/util/util';
import {ArrowIcon} from '../../../../images/arrow_icon';
import {TaskList} from '../../../shared/components/task-tables/my-work-task-table/Task_list';
import EmptyState from '../../../shared/components/empty-states/empty_state';
import TooltipContainer from '../../../shared/components/tooltips/tooltip_container';
import CustomScrollToNativeScroll from '../../../shared/components/scroll-bars/CustomScrollToNativeScroll';
import {taskAssignedToSelectedPersonPredicate} from './MyWorkTasksUtil';

export const MyWorkMyTasks = ({
	viewer,
	intl,
	history,
	showThisWeek,
	tasks,
	selectedPersonId,
	listHeaderWidth,
	enabledColumns,
	searchFilterValue,
	filterFunctions,
	hasFillterApplied,
	filterOptions,
	isInPast,
}) => {
	const [overdueTasksAmount, setOverdueTasksAmount] = useState(-1); // Allow overdue tasks section to populate overdueTasksAmount despite the header living outside the queryrenderer
	const [showOverdue, setShowOverdue] = useState(false);
	const [showThisWeekSection, setShowThisWeekeSection] = useState(true);
	const [showDone, setShowDone] = useState(false);
	const [showNoDates, setShowNoDates] = useState(false);

	const tasksAssignedToSelectedPerson = tasks.filter(task => taskAssignedToSelectedPersonPredicate(task, selectedPersonId));

	const statusPartition = partition(tasksAssignedToSelectedPerson, task => {
		return task.node?.statusColumnV2?.category !== WorkflowCategories.DONE;
	});
	const runningTasks = statusPartition[0];
	const thisWeekDoneTasks = statusPartition[1];

	const thisWeekPartition = partition(runningTasks, task => {
		return task.node.deadlineYear !== null;
	});
	const thisWeekRunningTasks = thisWeekPartition[0];
	const noDatesTasks = thisWeekPartition[1];
	const overdueTasksSearchQuery = getMyWorkOverdueSearchQuery(selectedPersonId);

	useEffect(() => {
		setShowOverdue(
			Util.localStorageGetItem('my-work-overdue-section')
				? JSON.parse(Util.localStorageGetItem('my-work-overdue-section'))
				: false
		);
		setShowThisWeekeSection(
			Util.localStorageGetItem('my-work-this-week-section')
				? JSON.parse(Util.localStorageGetItem('my-work-this-week-section'))
				: true
		);
		setShowNoDates(
			Util.localStorageGetItem('my-work-no-dates-section')
				? JSON.parse(Util.localStorageGetItem('my-work-no-dates-section'))
				: false
		);
		setShowDone(
			Util.localStorageGetItem('my-work-done-section')
				? JSON.parse(Util.localStorageGetItem('my-work-done-section'))
				: false
		);
	}, []);

	const setOverdueAmount = val => {
		setOverdueTasksAmount(val);
	};

	const toggleOverdue = () => {
		Util.localStorageSetItem('my-work-overdue-section', !showOverdue);
		setShowOverdue(!showOverdue);
	};

	const toggleNoDates = () => {
		Util.localStorageSetItem('my-work-no-dates-section', !showNoDates);
		setShowNoDates(!showNoDates);
	};

	const toggleThisWeekSection = () => {
		Util.localStorageSetItem('my-work-this-week-section', !showThisWeekSection);
		setShowThisWeekeSection(!showThisWeekSection);
	};

	const toggleDone = () => {
		Util.localStorageSetItem('my-work-done-section', !showDone);
		setShowDone(!showDone);
	};

	const renderEmptyState = () => (
		<EmptyState
			pageName={
				hasFillterApplied || searchFilterValue !== ''
					? EmptyState.EMPTY_STATE.NO_RESULT_FOUND
					: isInPast
					? EmptyState.EMPTY_STATE.MY_WORK_TASKS_PAST
					: EmptyState.EMPTY_STATE.MY_WORK_TASKS
			}
		/>
	);

	const shouldRenderEmptyState = () => {
		if (
			showThisWeek &&
			overdueTasksAmount === 0 &&
			thisWeekRunningTasks.length === 0 &&
			thisWeekDoneTasks.length === 0 &&
			noDatesTasks.length === 0
		) {
			return true;
		}
		return (
			!showThisWeek && thisWeekRunningTasks.length === 0 && thisWeekDoneTasks.length === 0 && noDatesTasks.length === 0
		);
	};

	const stickyTopPosition = 52;

	const renderContent = () => (
		<>
			{showThisWeek ? (
				<>
					<ListHeaderWrapper className={'overdue'} style={{width: listHeaderWidth, paddingTop: '16px'}}>
						<TooltipContainer
							triangleLocation={'topRight'}
							infoText={intl.formatMessage({id: 'my_work.tooltip.overdue'})}
						>
							<ListHeaderText
								overdue={true}
								onClick={overdueTasksAmount > 0 ? toggleOverdue : null}
								disabled={overdueTasksAmount === 0}
							>
								{intl.formatMessage({id: 'common.overdue'})}
								{overdueTasksAmount !== -1 && (
									<ListHeaderTaskAmount>{'(' + overdueTasksAmount + ')'}</ListHeaderTaskAmount>
								)}
								{overdueTasksAmount > 0 && (
									<ArrowIcon direction={showOverdue ? ArrowIcon.DIRECTION.UP : ArrowIcon.DIRECTION.DOWN} />
								)}
							</ListHeaderText>
						</TooltipContainer>
					</ListHeaderWrapper>

					<ForecastQueryRenderer
						key="query-render-myWorkOverdueTasksQueryRenderer"
						query={MyWorkOverdueTasksSectionQuery}
						showLoader={false}
						variables={{searchQuery: overdueTasksSearchQuery, personId: viewer.actualPersonId}}
						render={(relayProps, retry) => {
							return (
								<MyWorkOverdueTasksSection
									{...relayProps}
									retry={retry}
									intl={intl}
									selectedPersonId={selectedPersonId}
									showOverdue={showOverdue}
									enabledColumns={enabledColumns}
									setOverdueTasksAmount={setOverdueAmount}
									searchFilterValue={searchFilterValue}
									filterFunctions={filterFunctions}
									filterOptions={filterOptions}
									history={history}
								/>
							);
						}}
					/>
				</>
			) : null}
			<ListHeaderWrapper
				className={'this-week'}
				style={{width: listHeaderWidth, paddingTop: '16px'}}
				data-userpilot={'this-week'}
			>
				<TooltipContainer
					triangleLocation={'topRight'}
					infoText={intl.formatMessage({id: 'my_work.tooltip.week_tasks'})}
				>
					<ListHeaderText
						onClick={thisWeekRunningTasks.length > 0 ? toggleThisWeekSection : null}
						disabled={thisWeekRunningTasks.length === 0}
					>
						{showThisWeek
							? intl.formatMessage({id: 'overview_upcomming.this-week'})
							: intl.formatMessage({id: 'common.tasks'})}
						<ListHeaderTaskAmount>{'(' + thisWeekRunningTasks.length + ')'}</ListHeaderTaskAmount>
						{thisWeekRunningTasks.length > 0 ? (
							<ArrowIcon
								cy={showThisWeekSection ? 'this-week-expanded' : 'this-week-collapsed'}
								direction={showThisWeekSection ? ArrowIcon.DIRECTION.UP : ArrowIcon.DIRECTION.DOWN}
							/>
						) : null}
					</ListHeaderText>
				</TooltipContainer>
			</ListHeaderWrapper>
			{showThisWeekSection && thisWeekRunningTasks.length > 0 ? (
				<TaskList
					intl={intl}
					enabledColumns={enabledColumns}
					tasks={thisWeekRunningTasks}
					history={history}
					viewer={viewer}
					enableSort
					stickyTopPosition={stickyTopPosition}
					cy={'this-week-task-row'}
					localSortKey={'my-work-week-sort-value'}
				/>
			) : null}
			{noDatesTasks.length === 0 ? null : (
				<>
					<ListHeaderWrapper className={'no-dates'} style={{width: listHeaderWidth}}>
						<TooltipContainer
							triangleLocation={'topRight'}
							infoText={intl.formatMessage({id: 'my_work.tooltip.no_deadline'})}
						>
							<ListHeaderText
								onClick={noDatesTasks.length > 0 ? toggleNoDates : null}
								disabled={noDatesTasks.length === 0}
							>
								{intl.formatMessage({id: 'common.no_deadline'})}
								{noDatesTasks.length !== -1 && (
									<ListHeaderTaskAmount>{'(' + noDatesTasks.length + ')'}</ListHeaderTaskAmount>
								)}
								{noDatesTasks.length > 0 && (
									<ArrowIcon direction={showNoDates ? ArrowIcon.DIRECTION.UP : ArrowIcon.DIRECTION.DOWN} />
								)}
							</ListHeaderText>
						</TooltipContainer>
					</ListHeaderWrapper>

					{showNoDates && noDatesTasks.length > 0 ? (
						<TaskList
							intl={intl}
							enabledColumns={enabledColumns}
							tasks={noDatesTasks}
							history={history}
							viewer={viewer}
							enableSort
							stickyTopPosition={stickyTopPosition}
							localSortKey={'my-work-no-dates-sort-value'}
						/>
					) : null}
				</>
			)}

			<ListHeaderWrapper
				className={'done'}
				done={true}
				style={{width: listHeaderWidth, paddingTop: '16px'}}
				disabled={thisWeekDoneTasks.length === 0}
			>
				<TooltipContainer triangleLocation={'topRight'} infoText={intl.formatMessage({id: 'my_work.tooltip.done'})}>
					<ListHeaderText
						onClick={thisWeekDoneTasks.length > 0 ? toggleDone : null}
						disabled={thisWeekRunningTasks.length === 0}
					>
						{intl.formatMessage({id: 'common.done'})}
						<ListHeaderTaskAmount>{'(' + thisWeekDoneTasks.length + ')'}</ListHeaderTaskAmount>
						{thisWeekDoneTasks.length > 0 ? (
							<ArrowIcon
								cy={showDone ? 'done-expanded' : 'done-collapsed'}
								direction={showDone ? ArrowIcon.DIRECTION.UP : ArrowIcon.DIRECTION.DOWN}
							/>
						) : null}
					</ListHeaderText>
				</TooltipContainer>
			</ListHeaderWrapper>
			{showDone && thisWeekDoneTasks.length > 0 ? (
				<TaskList
					intl={intl}
					enabledColumns={enabledColumns}
					tasks={thisWeekDoneTasks}
					history={history}
					viewer={viewer}
					enableSort
					stickyTopPosition={stickyTopPosition}
					cy={'done-task-row'}
					localSortKey={'my-work-done-sort-value'}
				/>
			) : null}
		</>
	);

	return (
		<CustomScrollToNativeScroll className="custom-scrollbar-div" hasFocusableContent>
			<MyWorkTasksListWrapper>{shouldRenderEmptyState() ? renderEmptyState() : renderContent()}</MyWorkTasksListWrapper>
		</CustomScrollToNativeScroll>
	);
};
