import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {EditorState, convertFromRaw, convertToRaw, Modifier} from 'draft-js';
import RichTextField from '../components/new-ui/rich_text_field';
import {DATE_PICKER_STYLE, BUTTON_STYLE, BUTTON_COLOR} from '../constants';
import {createToast} from '../forecast-app/shared/components/toasts/another-toast/toaster';
import {MODAL_TYPE, showModal} from '../forecast-app/shared/components/modals/generic_modal_conductor';
import * as tracking from '../tracking';
import {useIntl, FormattedHTMLMessage} from 'react-intl';
import {
	ForecastLogoWrapper,
	PageWrapper,
	PasswordDescriptionWrapper,
	PasswordFormWrapper,
	PasswordErrorMessageWrapper,
	PasswordInputWrapper,
	PasswordTitleWrapper,
	SubmitPasswordWrapper,
	MainSectionWrapper,
	SubmitTaskWrapper,
} from './PublicCreateTaskPage_styled';
import {BasicTextInput, FullForecastLogo, LockIcon, Button, CrossIcon} from 'web-components';
import InlineLoader from '../forecast-app/shared/components/inline-loader/inline_loader';
import {
	DatesWrapper,
	FilesPreviewWrapper,
	FilePreviewWrapper,
	FilePreviewNameWrapper,
	FileUploadSection,
	InputLineInput,
	InputLineLabel,
	InputLineLabelRequired,
	InputLineWrapper,
	Separator,
	SubSection,
} from '../forecast-app/project-tab/projects/project-settings/ProjectSettings_styled';
import DateRangePicker from '../forecast-app/shared/components/date-picker/date_picker_v3';
import FileDropZone from '../components/file_drop_zone';
import ForecastTooltip from '../forecast-app/shared/components/tooltips/ForecastTooltip';
import DirectApi from '../directApi';
import {useTrackPage} from '../tracking/amplitude/hooks/useTrackPage';
import {trackComplexEvent} from '../tracking/amplitude/TrackingV2';

const PublicCreateTaskPage = ({setLocaleFromBrowser, match, history}) => {
	const intl = useIntl();

	const [_taskName, setTaskName] = useState('');
	const [_taskDescription, setTaskDescription] = useState(null);
	const [_taskDeadline, setTaskDeadline] = useState(null);
	const [_creatorsCompanyName, setCreatorsCompanyName] = useState('');
	const [_creatorsCompanyMail, setCreatorsCompanyMail] = useState('');
	const [_creatorsName, setCreatorsName] = useState('');

	const [_uploadFiles, setUploadFiles] = useState([]);

	const [_data, setData] = useState({
		companyName: '',
		companyLogoId: '',
		projectId: '',
		projectName: '',
		statusColumnId: '',
		passwordValid: null,
	});

	const [_password, setPassword] = useState('');
	const [_passwordError, setPasswordError] = useState(false);

	const getData = async () => {
		const headers = new Headers();
		headers.append('Content-Type', 'application/json');
		const init = {headers, method: 'GET'};
		const linkToPublicCreateTaskPage =
			match.params && match.params.linkToPublicCreateTaskPage ? match.params.linkToPublicCreateTaskPage : null;
		let requestUrl = DirectApi.graphqlServerEndpoint(
			`public_create_task_page/${linkToPublicCreateTaskPage}/${_password || 'no_password'}`
		);

		const result = await fetch(requestUrl, init)
			.then(response => {
				return response.json();
			})
			.then(data => {
				if (!data.projectId) {
					history.push('/login');
				}
				return data;
			})
			.catch(error => {
				if (!('' + error).includes('abort')) {
					throw error;
				}
				this.props.history.push('/login');
			});
		setPasswordError(!result.passwordValid && _password !== '');
		setData(result);
		return result;
	};

	useTrackPage('Public Create Task');

	useEffect(() => {
		setLocaleFromBrowser();
		getData();
		tracking.trackPage('public-create-task');
	}, []);

	const handleTaskNameChange = name => {
		setTaskName(name);
	};

	const handleTaskDescriptionChange = editorState => {
		setTaskDescription(editorState);
	};

	const handleCreatorsCompanyNameChange = companyName => {
		setCreatorsCompanyName(companyName);
	};

	const handleCreatorsNameChange = name => {
		setCreatorsName(name);
	};

	const handleCreatorsCompanyMailChange = companyMail => {
		setCreatorsCompanyMail(companyMail);
	};

	const handleTaskDeadlineChange = date => {
		setTaskDeadline(date);
	};

	const getFreshStateObject = () => {
		setTaskName('');
		setTaskDescription(null);
		setTaskDeadline(null);
		setUploadFiles([]);
		setPassword('');
	};

	const handleTypePassword = value => {
		setPassword(value);
		setPasswordError(false);
	};

	const prepareDescription = () => {
		const content = _taskDescription ? convertFromRaw(JSON.parse(_taskDescription)) : null;
		const editorState = content ? EditorState.createWithContent(content) : EditorState.createEmpty();
		const contentState = editorState.getCurrentContent();
		const selection = editorState.getSelection();

		let text = `Company: ${_creatorsCompanyName}\nClient Name: ${_creatorsName}\nClient Email: ${_creatorsCompanyMail}\n`;
		if (_taskDeadline) {
			text += `Requested Task End Date: ${_taskDeadline.format('DD/MMM/YYYY')}\n`;
		}
		let newContentState = Modifier.insertText(contentState, selection, text);
		let newEditorState = EditorState.push(editorState, newContentState, 'insert-fragment');
		return JSON.stringify(convertToRaw(newEditorState.getCurrentContent()));
	};

	const addFile = file => {
		const reader = new FileReader();
		setUploadFiles([..._uploadFiles, file]);
		reader.readAsDataURL(file);
	};

	const withdrawFile = fileIndex => {
		const cpy = [..._uploadFiles];
		cpy.splice(fileIndex, 1);
		setUploadFiles([...cpy]);
	};

	const handleFileSelect = files => {
		if (files.constructor === Array) {
			files.forEach(file => {
				addFile(file);
			});
		} else {
			addFile(files);
		}
	};

	const showValidationErrorModal = warning => {
		showModal({
			type: MODAL_TYPE.GENERIC,
			content: warning,
			className: 'default-warning-modal',
			buttons: [
				{
					text: 'OK',
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
			],
		});
	};

	const handleCreateTask = async () => {
		getData().then(result => {
			//we need to make sure the password hasn't been changed
			if (!result.passwordValid) {
				getFreshStateObject();
				return;
			}
			const requiredFieldsProvided =
				_data.projectId &&
				_data.statusColumnId &&
				_taskName &&
				_creatorsCompanyName &&
				_creatorsCompanyMail &&
				_creatorsName;
			const emailValid = _creatorsCompanyMail && /(.+)@(.+)\.(.+)/.test(_creatorsCompanyMail);
			if (!requiredFieldsProvided || !emailValid) {
				return;
			}
			const formData = new FormData();
			const description = prepareDescription();
			formData.append('name', _taskName);
			formData.append('projectId', _data.projectId);
			formData.append('statusColumnId', _data.statusColumnId);
			formData.append('description', description);
			if (_taskDeadline) {
				formData.append('deadlineYear', _taskDeadline.year());
				formData.append('deadlineMonth', _taskDeadline.month() + 1);
				formData.append('deadlineDay', _taskDeadline.date());
			}
			if (_uploadFiles) {
				for (let i = 0; i < _uploadFiles.length; i++) {
					formData.append('uploadFiles', _uploadFiles[i]);
				}
			}
			const headers = new Headers();
			const init = {headers, method: 'POST', body: formData};
			const linkToPublicCreateTaskPage =
				match.params && match.params.linkToPublicCreateTaskPage ? match.params.linkToPublicCreateTaskPage : null;
			let requestUrl = DirectApi.graphqlServerEndpoint(
				`public_create_task_page/${linkToPublicCreateTaskPage}/${_password || 'no_password'}`
			);
			return fetch(requestUrl, init)
				.then(response => {
					return response.json();
				})
				.then(data => {
					if (data.fileValidationError) {
						getFreshStateObject();
						showValidationErrorModal(
							intl.formatMessage({id: `public_crete_task_form.${data.fileValidationError}_error`})
						);
					} else if (data.message === 'RATE_LIMIT') {
						getFreshStateObject();
						showValidationErrorModal(<FormattedHTMLMessage id="login.rate_limit" />);
					} else {
						tracking.trackPageAction('Public task created');
						trackComplexEvent('Task', 'Created', {triggerLocation: 'From Public Create Task Page'});
						createToast({
							duration: 5000,
							message: intl.formatMessage({id: 'public_crete_task_form.submit_form_success_toast_text'}),
						});
						getFreshStateObject();
						return data;
					}
				})
				.catch(error => {
					if (!('' + error).includes('abort')) {
						throw error;
					}
				});
		});
	};

	const readuForSubmition = _taskName && _creatorsCompanyMail && _creatorsCompanyName && _creatorsName;
	return (
		<PageWrapper>
			<ForecastLogoWrapper>
				<FullForecastLogo />
			</ForecastLogoWrapper>
			{_data.passwordValid === null ? (
				<PasswordFormWrapper>
					<InlineLoader />
				</PasswordFormWrapper>
			) : _data.passwordValid ? (
				<MainSectionWrapper>
					<PasswordTitleWrapper>
						{intl.formatMessage({id: 'public_create_task_form.create_a_new_task'})}
					</PasswordTitleWrapper>
					<PasswordDescriptionWrapper>
						{intl.formatMessage({id: 'public_create_task_form.create_a_new_task_description'})}
					</PasswordDescriptionWrapper>
					<SubSection>
						<InputLineWrapper noMargin={true}>
							<InputLineLabel>{intl.formatMessage({id: 'public_create_task_form.vendor'})}</InputLineLabel>
							<InputLineInput>
								<BasicTextInput value={_data.companyName} disabled={true} />
							</InputLineInput>
						</InputLineWrapper>
						<InputLineWrapper>
							<InputLineLabel>{intl.formatMessage({id: 'common.project'})}</InputLineLabel>
							<InputLineInput>
								<BasicTextInput value={_data.projectName} disabled={true} />
							</InputLineInput>
						</InputLineWrapper>
						<Separator />
						<InputLineWrapper>
							<InputLineLabelRequired>
								{intl.formatMessage({id: 'public_create_task_form.your_company_name'})}
							</InputLineLabelRequired>
							<InputLineInput>
								<BasicTextInput
									placeholder={intl.formatMessage({id: 'public_create_task_form.enter_your_company_name'})}
									value={_creatorsCompanyName}
									onChange={handleCreatorsCompanyNameChange}
									onBlur={() => setCreatorsCompanyName(_creatorsCompanyName.trim())}
								/>
							</InputLineInput>
						</InputLineWrapper>
						<InputLineWrapper>
							<InputLineLabelRequired>
								{intl.formatMessage({id: 'public_create_task_form.your_name'})}
							</InputLineLabelRequired>
							<InputLineInput>
								<BasicTextInput
									placeholder={intl.formatMessage({id: 'public_create_task_form.enter_your_name'})}
									value={_creatorsName}
									onChange={handleCreatorsNameChange}
									onBlur={() => setCreatorsName(_creatorsName.trim())}
								/>
							</InputLineInput>
						</InputLineWrapper>
						<InputLineWrapper>
							<InputLineLabelRequired>
								{intl.formatMessage({id: 'public_create_task_form.your_email'})}
							</InputLineLabelRequired>
							<InputLineInput>
								<BasicTextInput
									placeholder={intl.formatMessage({id: 'public_create_task_form.your_email_placeholder'})}
									value={_creatorsCompanyMail}
									onChange={handleCreatorsCompanyMailChange}
									onBlur={() => setCreatorsCompanyMail(_creatorsCompanyMail.trim())}
								/>
							</InputLineInput>
						</InputLineWrapper>
						<Separator />
						<InputLineWrapper>
							<InputLineLabelRequired>{intl.formatMessage({id: 'common.task_name'})}</InputLineLabelRequired>
							<InputLineInput>
								{/*maxLength={191} for an obsucre reason*/}
								<BasicTextInput
									placeholder={intl.formatMessage({id: 'public_create_task_form.name_your_task'})}
									value={_taskName}
									onChange={handleTaskNameChange}
									onBlur={() => setTaskName(_taskName.trim())}
									maxLength={191}
								/>
							</InputLineInput>
						</InputLineWrapper>
						<InputLineWrapper>
							<InputLineLabel>{intl.formatMessage({id: 'common.description'})}</InputLineLabel>
							<InputLineInput>
								<RichTextField
									textCy={'project-description-text'}
									cy={'project-description-container'}
									alwaysShowControls={false}
									text={_taskDescription}
									hideEmojiPicker={true}
									//	characterLimit={this.props.viewer.company.characterLimit}
									placeholder={intl.formatMessage({
										id: 'public_create_task_form.task_description_placeholder',
									})}
									handleTextChange={handleTaskDescriptionChange}
								/>
							</InputLineInput>
						</InputLineWrapper>
						<InputLineWrapper>
							<InputLineLabel>{intl.formatMessage({id: 'common.deadline'})}</InputLineLabel>
							<InputLineInput>
								<DatesWrapper>
									<DateRangePicker
										isSingleDatePicker={true}
										startDate={_taskDeadline}
										handleDateRangeChange={handleTaskDeadlineChange}
										isNewDateRange={true}
										datePickerStyle={DATE_PICKER_STYLE.SLIM}
										highlighted={true}
										clearable={true}
										clearBothDates={() => setTaskDeadline(null)}
										hideLimitMessage
									/>
								</DatesWrapper>
							</InputLineInput>
						</InputLineWrapper>
						<InputLineWrapper>
							<InputLineLabel>{intl.formatMessage({id: 'common.files'})}</InputLineLabel>
							<InputLineInput>
								<ForecastTooltip
									placement={'right'}
									content={
										_uploadFiles.length >= 5 &&
										intl.formatMessage({id: 'public_create_task_form.max_file_reached'})
									}
								>
									<FileUploadSection>
										<FileDropZone
											isExpenseItemDropZone={true}
											isUploading={false}
											uploadFiles={handleFileSelect}
											folderExceptionMsg={intl.formatMessage({id: 'file_upload.folder-message'})}
											commonOkMsg={intl.formatMessage({id: 'common.ok'})}
											locked={_uploadFiles.length >= 5}
										/>
									</FileUploadSection>
								</ForecastTooltip>
								{_uploadFiles.length > 0 && (
									<FilesPreviewWrapper>
										{_uploadFiles.map((file, index) => {
											return (
												<FilePreviewWrapper>
													<FilePreviewNameWrapper>{file.name}</FilePreviewNameWrapper>
													<CrossIcon
														size={CrossIcon.SIZE.SMALL}
														onClick={() => withdrawFile(index)}
													/>
												</FilePreviewWrapper>
											);
										})}
									</FilesPreviewWrapper>
								)}
							</InputLineInput>
						</InputLineWrapper>

						<SubmitTaskWrapper>
							<Button
								size={Button.SIZE.TALL}
								variant={Button.VARIANT.GREEN_FILLED}
								onClick={handleCreateTask}
								disabled={!readuForSubmition}
							>
								{intl.formatMessage({id: 'public_create_task_form.submit_task'})}
							</Button>
						</SubmitTaskWrapper>
					</SubSection>
				</MainSectionWrapper>
			) : (
				<PasswordFormWrapper>
					<LockIcon color={'#4C4C58'} size={LockIcon.SIZE.LARGE} />
					<PasswordTitleWrapper>
						{intl.formatMessage({id: 'public_create_task_form.password_protected_page'})}
					</PasswordTitleWrapper>
					<PasswordDescriptionWrapper>
						<FormattedHTMLMessage
							id={'public_create_task_form.password_protected_page_description'}
							values={{companyName: _data.companyName}}
						/>
					</PasswordDescriptionWrapper>
					<PasswordInputWrapper>
						<BasicTextInput
							value={_password}
							onChange={handleTypePassword}
							hasError={_passwordError}
						></BasicTextInput>
						{_passwordError && (
							<PasswordErrorMessageWrapper>
								{intl.formatMessage({id: 'public_create_task_form.incorrect_password'})}
							</PasswordErrorMessageWrapper>
						)}
					</PasswordInputWrapper>
					<SubmitPasswordWrapper>
						<Button size={Button.SIZE.TALL} variant={Button.VARIANT.GREEN_FILLED} onClick={getData} fullWidth>
							{intl.formatMessage({id: 'public_create_task_form.submit_password'})}
						</Button>
					</SubmitPasswordWrapper>
				</PasswordFormWrapper>
			)}
		</PageWrapper>
	);
};
export default withRouter(PublicCreateTaskPage);
