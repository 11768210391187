import React from 'react';
import {FormattedHTMLMessage, useIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import Util from '../../../shared/util/util';
import UpdateProjectMutation from '../../../../mutations/update_project_mutation.modern';
import ForecastTooltip from '../../../shared/components/tooltips/ForecastTooltip';
import {hasPermission} from '../../../shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../Permissions';
import {ButtonDropdownField, FlexRow, DropdownList, Text} from '@forecast-it/design-system';
import {DesignSystemDropdownOldLookAdapter} from '../../../shared/components/dropdowns/DesignSystemDropdownOldLookAdapter';

const ProjectPriorityDropdown = ({company, project}) => {
	const {formatMessage} = useIntl();
	const projectPriority = project.priorityLevel.id;
	const priorityLevels = company.priorityLevels.edges
		.map(p => p.node)
		.filter(priorityLevel => !priorityLevel.disabled || projectPriority === priorityLevel.id);
	let doPriorityLevelsExist = true;
	let isAdmin = hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS);

	priorityLevels.push({id: btoa('PriorityLevel:'), name: formatMessage({id: 'common.no_priority'})});

	if (company.priorityLevels.edges.length === 0) {
		doPriorityLevelsExist = false;
	}

	const selectedPriorotyLevelName = priorityLevels.find(priorityLevel => priorityLevel.id === projectPriority)?.name;

	const handleChangePriority = newPriorityId => {
		Util.CommitMutation(UpdateProjectMutation, {
			project: project,
			priorityLevelId: newPriorityId,
		});
	};
	return (
		<ForecastTooltip
			delay={[0, 500]}
			interactive={true}
			content={
				doPriorityLevelsExist ? null : isAdmin ? (
					<FormattedHTMLMessage
						id="project_settings.admin_no_priority_level"
						values={{link: window.location.origin + '/admin/project-priority'}}
					/>
				) : (
					formatMessage({id: 'project_settings.no_priority_level'})
				)
			}
		>
			<DesignSystemDropdownOldLookAdapter>
				<DropdownList>
					<DropdownList.Trigger data-cy={'project-priority-level-dropdown'} disabled={!doPriorityLevelsExist}>
						<ButtonDropdownField>
							{!!selectedPriorotyLevelName ? (
								<Text>{selectedPriorotyLevelName}</Text>
							) : (
								<Text color={Text.Color.auxiliary}>
									{doPriorityLevelsExist
										? formatMessage({id: 'project_settings.priority_level'})
										: formatMessage({id: 'common.no_priority'})}
								</Text>
							)}
						</ButtonDropdownField>
					</DropdownList.Trigger>
					<DropdownList.Content align={'start'}>
						{priorityLevels.map(priorityLevel => (
							<DropdownList.Item
								key={priorityLevel.id}
								data-cy={'project-priority-level-dropdown-option-' + priorityLevel.name}
								onClick={() => handleChangePriority(priorityLevel.id)}
							>
								<FlexRow>
									<Text>{priorityLevel.name}</Text>
								</FlexRow>
							</DropdownList.Item>
						))}
					</DropdownList.Content>
				</DropdownList>
			</DesignSystemDropdownOldLookAdapter>
		</ForecastTooltip>
	);
};

export default createFragmentContainer(ProjectPriorityDropdown, {
	project: graphql`
		fragment ProjectPriorityDropdown_project on ProjectType {
			id
			priorityLevel {
				id
				name
			}
		}
	`,
	company: graphql`
		fragment ProjectPriorityDropdown_company on Company {
			id
			priorityLevels {
				edges {
					node {
						id
						name
						weight
						disabled
					}
				}
			}
		}
	`,
});
