import {FlexColumn, DeprecatedText as Text} from '@forecast-it/design-system';
import Util from '../../../shared/util/util';
import React from 'react';
import {useIntl} from 'react-intl';

export const ProgramTotalAllocation = ({
	totalProgramAllocation,
	showOverAllocatedError,
	currencySymbol,
	paddingTop,
	isInFixedPriceProgram,
	showRevenueExceedInfo,
}) => {
	const {formatMessage} = useIntl();
	return (
		<FlexColumn gap={'s'}>
			<div style={{height: paddingTop}} />
			<Text variant={'bold'}>
				{formatMessage({
					id: isInFixedPriceProgram ? 'program.total_allocated_revenue' : 'program.program_confirmed_revenue',
				})}
			</Text>
			{showRevenueExceedInfo && (
				<Text color="secondary">
					{formatMessage({
						id: isInFixedPriceProgram
							? 'retainer.total_allocated_revenue_description.fixed_price'
							: 'retainer.total_allocated_revenue_description.capped',
					})}
				</Text>
			)}
			<Text variant={'bold'} color={showOverAllocatedError ? 'error' : 'primary'}>
				{Util.getFormattedCurrencyValue(currencySymbol, Util.roundToNDecimals(totalProgramAllocation, 2))}
			</Text>
		</FlexColumn>
	);
};
