import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import util from '../../../shared/util/util';
import {BUDGET_TYPE, ESTIMATION_UNIT} from '../../../../constants';
import TdLink from '../../../../components/td_link';
import Person from '../../../shared/components/person/person';
import {hasPermission} from '../../../shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../Permissions';
import ProjectUtil from '../../../shared/util/project_util';
import StatusWrapper, {convertStatusColorToRagStatus} from '../../../shared/components/status/StatusWrapper';
import {profilePicSrc, projectUrl} from '../../../../directApi';

class ProjectListProjectLineItem extends Component {
	getProjectProgress() {
		const calculatedData = this.props.calculatedDataMap.get(this.props.project.node.id);

		return ProjectUtil.projectUsesManualProgress(this.props.project.node)
			? this.props.project.node.progress
			: calculatedData
			? calculatedData.completion
			: undefined;
	}

	getLinkPath(project) {
		let lastViewedPage = localStorage.getItem('project-section-last-viewed') || 'workflow';
		if (
			(lastViewedPage === 'sprints' && !project.node.sprintTimeBox) ||
			lastViewedPage === 'connected-projects' ||
			(lastViewedPage.startsWith('financials') && !this.props.hasFinancialAccess)
		) {
			lastViewedPage = 'workflow';
		}
		return projectUrl(project.node.companyProjectId, project.node.customProjectId) + '/' + lastViewedPage;
	}

	getIdTdHTML() {
		if (this.props.project.node.customProjectId) {
			return this.props.project.node.customProjectId;
		}
		return 'P' + this.props.project.node.companyProjectId;
	}

	calculateVarianceToForecast() {
		const projectData = this.props.calculatedDataMap.get(this.props.project.node.id);
		projectData.minutesPerEstimationPoint = this.props.project.node.minutesPerEstimationPoint;
		return util.calculateVarianceToForecast(projectData, this.props.project.node.estimationUnit === ESTIMATION_UNIT.HOURS);
	}

	render() {
		const {formatMessage, formatNumber} = this.props.intl;
		const project = this.props.project;
		const hasFinancialAccess = this.props.hasFinancialAccess;
		const hasInvoiceReadPermission = hasPermission(PERMISSION_TYPE.INVOICE_READ);

		const financialNumbers = project.node.financialNumbersProjectCurrency;
		const financialData = financialNumbers
			? {
					forecastBillablePrice: financialNumbers.billablePlannedTimeAndExpenses,
					reportedBillablePrice: financialNumbers.actualRevenue,
					remainingBillablePrice: financialNumbers.remainingRevenue,
					forecastCost: financialNumbers.plannedCost,
					currentCost: financialNumbers.actualCost,
					remainingCost: financialNumbers.remainingCost,
					recognitionLockedRevenue: financialNumbers.recognitionLockedRevenue,
					recognitionOpenRevenue: financialNumbers.recognitionOpenRevenue,
					recognitionForecastRevenue: financialNumbers.recognitionForecastRevenue,
			  }
			: null;

		const calculatedData = this.props.calculatedDataMap?.get(project.node.id);

		const projectProgress = this.getProjectProgress();

		//adds white border around status color if too similar to project color
		const addBorderAroundStatusColor =
			project.node.currentProjectStatus &&
			util.calculateColorsDistance(project.node?.currentProjectStatus?.color, project.node?.projectColor) < 150;
		//use white ok sign for completed projects with project color too similar to green ok sign
		const useWhiteCompletedIndicator =
			projectProgress === 100 && util.calculateColorsDistance('#05e298', project.node?.projectColor) < 150;
		const rgbProjectColor = util.hexToRgb(project.node?.projectColor);
		rgbProjectColor.a = 0.5;
		let remainingColor = `rgba(${rgbProjectColor.r}, ${rgbProjectColor.g}, ${rgbProjectColor.b}, ${rgbProjectColor.a})`;
		const completedColorRgba = rgbProjectColor;
		completedColorRgba.a = 1;
		const completedColor = `rgba(${completedColorRgba.r}, ${completedColorRgba.g}, ${completedColorRgba.b}, ${completedColorRgba.a})`;

		const useProgressColorFeature = false;
		if (!useProgressColorFeature) {
			remainingColor = completedColor;
		}
		const projectStartDate = util.CreateNonUtcMomentDate(
			project.node.projectStartYear,
			project.node.projectStartMonth,
			project.node.projectStartDay
		);
		const projectEndDate = util.CreateNonUtcMomentDate(
			project.node.projectEndYear,
			project.node.projectEndMonth,
			project.node.projectEndDay
		);
		return (
			<tr
				data-cy="project-nested-row"
				key={project.node.id}
				ref={el => (this['table_row_' + project.node.id] = el)}
				className={
					(this.props.isInProjectGroup ? 'project-group-nested-row' : '') +
					(project.node?.projectColor && project.node?.projectColor?.toLowerCase() === '#ffffff' ? ' white-row' : '')
				}
				style={{color: util.getTextColorV2(project.node?.projectColor), backgroundColor: remainingColor}}
			>
				{this.props.columns.map((col, index) => {
					const path = this.getLinkPath(project);
					if (col.checked || col.name === 'id') {
						if (col.name === 'id') {
							if (this.props.isInProjectGroup) {
								return (
									<TdLink
										key={index}
										path={path}
										content={
											<div className={'nested-project-id-wrapper v2'}>
												<div className="spacer" />
												<div
													className="id-wrapper id-link"
													style={{
														backgroundColor: completedColor,
													}}
												>
													{col.checked ? this.getIdTdHTML() : null}
												</div>
											</div>
										}
										className={'nested-id v2'}
									/>
								);
							} else {
								return (
									<TdLink
										key={index}
										path={path}
										content={col.checked ? this.getIdTdHTML() : null}
										className="id-link"
										overflowHidden
									/>
								);
							}
						} else if (col.name === 'name') {
							return (
								<TdLink
									key={index}
									path={path}
									content={project.node.name ? project.node.name : formatMessage({id: 'common.untitled'})}
									width={this.props.projectNameColumnWidth}
								/>
							);
						} else if (col.name === 'client') {
							return (
								<TdLink
									key={index}
									path={path}
									content={project.node.client ? project.node.client.name : ''}
									width={'140px'}
								/>
							);
						} else if (col.name === 'contact') {
							return (
								<TdLink
									key={index}
									path={path}
									width={'140px'}
									content={
										<div className={'contacts-wrapper v2'}>
											{project.node.projectPersons
												? project.node.projectPersons.edges
														.filter(pp => pp.node.isContactPerson)
														.slice(0, 2)
														.map((projectPerson, index) => (
															<Person
																key={index}
																name={
																	projectPerson.node.person.firstName +
																	' ' +
																	projectPerson.node.person.lastName
																}
																role={''}
																showName={
																	project.node.projectPersons.edges.filter(
																		pp => pp.node.isContactPerson
																	).length === 1
																}
																showRole={false}
																imageSrc={profilePicSrc(
																	projectPerson.node.person.profilePictureId
																)}
																imageSize="medium"
															/>
														))
												: null}
											{project.node.projectPersons &&
											project.node.projectPersons.edges.filter(pp => pp.node.isContactPerson).length > 3
												? '+' +
												  (project.node.projectPersons.edges.filter(pp => pp.node.isContactPerson)
														.length -
														3)
												: null}
										</div>
									}
								/>
							);
						} else if (col.name === 'completion') {
							return (
								<TdLink
									key={index}
									path={path}
									className="center-text"
									content={
										projectProgress ? (
											projectProgress === 100 ? (
												<div className="completed-indicator-container">
													<div
														className={
															'completed-indicator' + (useWhiteCompletedIndicator ? ' white' : '')
														}
													/>
												</div>
											) : (
												projectProgress + '%'
											)
										) : (
											'-'
										)
									}
								/>
							);
						} else if (col.name === 'priorityLevel') {
							return (
								<TdLink
									minWidth={'110px'}
									key={index}
									path={path}
									content={project.node.priorityLevel?.name}
									className="text"
								/>
							);
						} else if (col.name === 'forecast') {
							//Approved scope
							return (
								<TdLink
									key={index}
									path={path}
									content={
										calculatedData
											? project.node.estimationUnit === 'POINTS'
												? formatMessage({id: 'common.x_points'}, {points: calculatedData.forecast})
												: util.convertMinutesToFullHour(calculatedData.forecast, this.props.intl)
											: '-'
									}
									className="numeric"
								/>
							);
						} else if (col.name === 'reported') {
							return (
								<TdLink
									key={index}
									path={path}
									content={
										calculatedData
											? util.convertMinutesToFullHour(calculatedData.reported, this.props.intl)
											: '-'
									}
									className="numeric"
								/>
							);
						} else if (col.name === 'remaining') {
							return (
								<TdLink
									key={index}
									path={path}
									content={
										calculatedData
											? project.node.estimationUnit === 'POINTS'
												? formatMessage({id: 'common.x_points'}, {points: calculatedData.remaining})
												: util.convertMinutesToFullHour(calculatedData.remaining, this.props.intl)
											: '-'
									}
									className="numeric"
								/>
							);
						} else if (col.name === 'startDate') {
							return (
								<TdLink
									key={index}
									path={path}
									content={
										projectStartDate !== null ? (
											<span>
												<span className="month-day">{projectStartDate.format('DD MMM')}</span>
												<span className="year">{projectStartDate.format('YYYY')}</span>
											</span>
										) : null
									}
									width={'110px'}
								/>
							);
						} else if (col.name === 'endDate') {
							return (
								<TdLink
									key={index}
									path={path}
									content={
										projectEndDate !== null ? (
											<span>
												<span className="month-day">{projectEndDate.format('DD MMM')}</span>
												<span className="year">{projectEndDate.format('YYYY')}</span>
											</span>
										) : null
									}
									width={'110px'}
								/>
							);
						} else if (col.name === 'budget' && hasFinancialAccess) {
							return (
								<TdLink
									key={index}
									path={path}
									content={
										project.node.budget > 0
											? util.getFormattedCurrencyValue(
													this.props.currencySymbol,
													formatNumber(project.node.budget, {maximumFractionDigits: 0})
											  )
											: null
									}
									className="numeric"
								/>
							);
						} else if (col.name === 'win-chance' && hasFinancialAccess) {
							return (
								<TdLink
									minWidth={'110px'}
									key={index}
									path={path}
									content={util.getFormattedPercentage(project.node.baselineWinChance, this.props.intl, 0)}
									className="numeric"
								/>
							);
						} else if (col.name === 'forecast-money' && hasFinancialAccess) {
							return (
								<TdLink
									key={index}
									path={path}
									content={
										financialData
											? util.getFormattedCurrencyValue(
													this.props.currencySymbol,
													formatNumber(
														this.props.project.node.budgetType !== BUDGET_TYPE.NON_BILLABLE
															? financialData.forecastBillablePrice
															: 0,
														{format: 'always_two_decimal'}
													)
											  )
											: '-'
									}
									className="numeric"
								/>
							);
						} else if (col.name === 'reported-money' && hasFinancialAccess) {
							return (
								<TdLink
									key={index}
									path={path}
									content={
										financialData
											? util.getFormattedCurrencyValue(
													this.props.currencySymbol,
													formatNumber(
														this.props.project.node.budgetType !== BUDGET_TYPE.NON_BILLABLE
															? financialData.reportedBillablePrice
															: 0,
														{format: 'always_two_decimal'}
													)
											  )
											: '-'
									}
									className="numeric"
								/>
							);
						} else if (col.name === 'remaining-money' && hasFinancialAccess) {
							return (
								<TdLink
									key={index}
									path={path}
									content={
										financialData
											? util.getFormattedCurrencyValue(
													this.props.currencySymbol,
													formatNumber(
														this.props.project.node.budgetType !== BUDGET_TYPE.NON_BILLABLE
															? financialData.remainingBillablePrice
															: 0,
														{format: 'always_two_decimal'}
													)
											  )
											: '-'
									}
									className="numeric"
								/>
							);
						} else if (col.name === 'projected-money' && hasFinancialAccess) {
							return (
								<TdLink
									key={index}
									path={path}
									content={
										financialData
											? util.getFormattedCurrencyValue(
													this.props.currencySymbol,
													formatNumber(
														this.props.project.node.budgetType !== BUDGET_TYPE.NON_BILLABLE
															? financialData.remainingBillablePrice +
																	financialData.reportedBillablePrice
															: 0,
														{
															format: 'always_two_decimal',
														}
													)
											  )
											: '-'
									}
									className="numeric"
								/>
							);
						} else if (col.name === 'recognition-locked-revenue' && hasFinancialAccess) {
							return (
								<TdLink
									key={index}
									path={path}
									content={
										financialData
											? util.getFormattedCurrencyValue(
													this.props.currencySymbol,
													formatNumber(financialData.recognitionLockedRevenue, {
														format: 'always_two_decimal',
													})
											  )
											: '-'
									}
									className="numeric"
								/>
							);
						} else if (col.name === 'recognition-remaining-revenue' && hasFinancialAccess) {
							return (
								<TdLink
									key={index}
									path={path}
									content={
										financialData
											? util.getFormattedCurrencyValue(
													this.props.currencySymbol,
													formatNumber(financialData.recognitionOpenRevenue, {
														format: 'always_two_decimal',
													})
											  )
											: '-'
									}
									className="numeric"
								/>
							);
						} else if (col.name === 'recognition-forecast-revenue' && hasFinancialAccess) {
							return (
								<TdLink
									key={index}
									path={path}
									content={
										financialData
											? util.getFormattedCurrencyValue(
													this.props.currencySymbol,
													formatNumber(financialData.recognitionForecastRevenue, {
														format: 'always_two_decimal',
													})
											  )
											: '-'
									}
									className="numeric"
								/>
							);
						} else if (col.name === 'forecast-profit' && hasFinancialAccess) {
							return (
								<TdLink
									key={index}
									path={path}
									className="numeric"
									content={
										financialData
											? util.getFormattedCurrencyValue(
													this.props.currencySymbol,
													formatNumber(
														this.props.project.node.budgetType !== BUDGET_TYPE.NON_BILLABLE
															? financialData.forecastBillablePrice - financialData.forecastCost
															: 0,
														{format: 'always_two_decimal'}
													)
											  )
											: '-'
									}
								/>
							);
						} else if (col.name === 'projected-profit' && hasFinancialAccess) {
							return (
								<TdLink
									key={index}
									path={path}
									className="numeric"
									content={
										financialData
											? util.getFormattedCurrencyValue(
													this.props.currencySymbol,
													formatNumber(
														this.props.project.node.budgetType !== BUDGET_TYPE.NON_BILLABLE
															? financialData.reportedBillablePrice +
																	financialData.remainingBillablePrice -
																	(financialData.currentCost + financialData.remainingCost)
															: 0,
														{format: 'always_two_decimal'}
													)
											  )
											: '-'
									}
								/>
							);
						} else if (col.name === 'cost' && hasFinancialAccess) {
							return (
								<TdLink
									key={index}
									path={path}
									className="numeric"
									content={
										financialData
											? util.getFormattedCurrencyValue(
													this.props.currencySymbol,
													formatNumber(financialData.forecastCost, {format: 'always_two_decimal'})
											  )
											: '-'
									}
								/>
							);
						} else if (col.name === 'reported-cost' && hasFinancialAccess) {
							return (
								<TdLink
									key={index}
									path={path}
									className="numeric"
									content={
										financialData
											? util.getFormattedCurrencyValue(
													this.props.currencySymbol,
													formatNumber(financialData.currentCost, {format: 'always_two_decimal'})
											  )
											: '-'
									}
								/>
							);
						} else if (col.name === 'remaining-cost' && hasFinancialAccess) {
							return (
								<TdLink
									key={index}
									path={path}
									className="numeric"
									content={
										financialData
											? util.getFormattedCurrencyValue(
													this.props.currencySymbol,
													formatNumber(financialData.remainingCost, {format: 'always_two_decimal'})
											  )
											: '-'
									}
								/>
							);
						} else if (col.name === 'projected-cost' && hasFinancialAccess) {
							return (
								<TdLink
									key={index}
									path={path}
									className="numeric"
									content={
										financialData
											? util.getFormattedCurrencyValue(
													this.props.currencySymbol,
													formatNumber(financialData.currentCost + financialData.remainingCost, {
														format: 'always_two_decimal',
													})
											  )
											: '-'
									}
								/>
							);
						} else if (col.name === 'xero-amount-total' && hasInvoiceReadPermission && this.props.xeroEnabled) {
							const xeroAmountTotal = project.node.xeroInvoices
								? project.node.xeroInvoices.reduce((total, invoice) => total + invoice.amountTotal, 0)
								: 0;
							return (
								<TdLink
									key={index}
									path={path}
									className="numeric"
									content={formatNumber(xeroAmountTotal, {format: 'always_two_decimal'})}
								/>
							);
						} else if (col.name === 'xero-paid' && hasInvoiceReadPermission && this.props.xeroEnabled) {
							const xeroPaid = project.node.xeroInvoices
								? project.node.xeroInvoices.reduce((total, invoice) => total + invoice.amountPaid, 0)
								: 0;
							return (
								<TdLink
									key={index}
									path={path}
									className="numeric"
									content={formatNumber(xeroPaid, {format: 'always_two_decimal'})}
								/>
							);
						} else if (col.name === 'xero-due' && hasInvoiceReadPermission && this.props.xeroEnabled) {
							const xeroAmountTotal = project.node.xeroInvoices
								? project.node.xeroInvoices.reduce((total, invoice) => total + invoice.amountTotal, 0)
								: 0;
							const xeroPaid = project.node.xeroInvoices
								? project.node.xeroInvoices.reduce((total, invoice) => total + invoice.amountPaid, 0)
								: 0;
							return (
								<TdLink
									key={index}
									path={path}
									className="numeric"
									content={formatNumber(xeroAmountTotal - xeroPaid, {format: 'always_two_decimal'})}
								/>
							);
						} else if (col.name === 'invoice-amount-total' && hasInvoiceReadPermission) {
							let invoiceAmountTotal = 0;
							if (project.node.financialNumbersProjectCurrency) {
								invoiceAmountTotal = project.node.financialNumbersProjectCurrency.invoiced;
							} else if (calculatedData) {
								invoiceAmountTotal = calculatedData.invoiced;
							}
							return (
								<TdLink
									key={index}
									path={path}
									className="numeric"
									content={util.getFormattedCurrencyValue(
										this.props.currencySymbol,
										formatNumber(invoiceAmountTotal, {format: 'always_two_decimal'})
									)}
								/>
							);
						} else if (col.name === 'invoice-paid' && hasInvoiceReadPermission) {
							let invoicePaid = 0;
							if (project.node.financialNumbersProjectCurrency) {
								invoicePaid = project.node.financialNumbersProjectCurrency.paid;
							} else if (calculatedData) {
								invoicePaid = calculatedData.invoicedPaid;
							}
							return (
								<TdLink
									key={index}
									path={path}
									className="numeric"
									content={util.getFormattedCurrencyValue(
										this.props.currencySymbol,
										formatNumber(invoicePaid, {format: 'always_two_decimal'})
									)}
								/>
							);
						} else if (col.name === 'invoice-due' && hasInvoiceReadPermission) {
							let invoiceAmountTotal = 0;
							if (project.node.financialNumbersProjectCurrency) {
								invoiceAmountTotal = project.node.financialNumbersProjectCurrency.invoiced;
							} else if (project.node.invoices) {
								invoiceAmountTotal = project.node.invoices.edges
									.filter(invoiceEdge => invoiceEdge.node.status !== 'DRAFT')
									.reduce(
										(total, curr) =>
											total +
											curr.node.entries.edges.reduce(
												(t, c) =>
													t +
													util.roundToDecimals(
														c.node.quantity *
															c.node.unitPrice *
															(1 + c.node.tax / 100) *
															(1 - c.node.discount / 100)
													),
												0
											),
										0
									);
							}
							let invoicePaid = 0;
							if (project.node.financialNumbersProjectCurrency) {
								invoicePaid = project.node.financialNumbersProjectCurrency.paid;
							} else if (project.node.invoices) {
								invoicePaid = project.node.invoices.edges
									.filter(invoiceEdge => invoiceEdge.node.status !== 'DRAFT')
									.reduce(
										(total, curr) =>
											total +
											util.roundToDecimals(
												curr.node.payments.edges.reduce((t, c) => t + c.node.amount, 0)
											),
										0
									);
							}
							return (
								<TdLink
									key={index}
									path={path}
									className="numeric"
									content={util.getFormattedCurrencyValue(
										this.props.currencySymbol,
										formatNumber(invoiceAmountTotal - invoicePaid, {format: 'always_two_decimal'})
									)}
								/>
							);
						} else if (col.name === 'statusColor') {
							return (
								<TdLink
									key={index}
									path={path}
									content={
										<div
											className="status-color-wrapper"
											title={formatMessage(
												{id: 'projects_overview.project_status_info'},
												{
													color: project.node.currentProjectStatus
														? util.getProjectStatusColorName(
																project.node.currentProjectStatus.color
														  )
														: '',
													person: project.node.currentProjectStatus?.person
														? project.node.currentProjectStatus.person.firstName +
														  ' ' +
														  project.node.currentProjectStatus.person.lastName
														: '',
												}
											)}
										>
											<div
												className={
													'status-color v2' + (addBorderAroundStatusColor ? ' add-border' : '')
												}
												style={{
													backgroundColor: project.node.currentProjectStatus
														? project.node.currentProjectStatus.color
														: 'transparent',
												}}
											/>
										</div>
									}
								/>
							);
						} else if (col.name === 'statusColorV2') {
							return (
								<td key={index} style={{position: 'relative', zIndex: 2, width: '105px'}}>
									<StatusWrapper
										projectId={project.node.id}
										currentStatusRag={convertStatusColorToRagStatus(
											project.node.currentProjectStatus?.color
										)}
										noHeader
									/>
								</td>
							);
						} else if (col.name === 'forecastTotal') {
							return (
								<TdLink
									key={index}
									path={path}
									content={
										calculatedData
											? project.node.estimationUnit === 'POINTS'
												? formatMessage({id: 'common.x_points'}, {points: calculatedData.forecastTotal})
												: util.convertMinutesToFullHour(calculatedData.forecast, this.props.intl)
											: '-'
									}
									className="numeric"
								/>
							);
						} else if (col.name === 'doneTasksCount') {
							return (
								<TdLink
									key={index}
									path={path}
									className="numeric"
									content={calculatedData ? calculatedData.doneTasksCount : '-'}
								/>
							);
						} else if (col.name === 'tasksCount') {
							return (
								<TdLink
									key={index}
									path={path}
									className="numeric"
									content={calculatedData ? calculatedData.tasksCount : '-'}
								/>
							);
						} else if (col.name === 'rateCard' && hasFinancialAccess) {
							return (
								<TdLink
									key={index}
									path={path}
									content={project.node.rateCard ? project.node.rateCard.name : ''}
									width={'140px'}
								/>
							);
						} else if (col.name === 'labels') {
							return (
								<TdLink
									key={index}
									path={path}
									content={
										<div className="labels-wrapper">
											{project.node.projectLabels
												? project.node.projectLabels.edges
														.slice(0, 3)
														.map(projectLabel => (
															<div
																key={projectLabel.node.id}
																style={{backgroundColor: projectLabel.node.label.color}}
																className={'skill-label v2'}
																title={projectLabel.node.label.name}
															/>
														))
												: null}
											{project.node.projectLabels && project.node.projectLabels.edges.length > 3 ? (
												<span>+{project.node.projectLabels.edges.length - 3}</span>
											) : null}
										</div>
									}
								/>
							);
						} else if (col.name === 'projectPersonsCount') {
							return (
								<TdLink
									key={index}
									cy={'project-persons-count'}
									path={path}
									className="numeric"
									content={calculatedData ? calculatedData.projectPersonsCount : '-'}
								/>
							);
						} else if (col.name === 'variance_to_forecast') {
							return (
								<TdLink
									key={index}
									path={path}
									className="numeric"
									content={
										calculatedData
											? project.node.estimationUnit === 'POINTS'
												? formatMessage(
														{id: 'common.x_points'},
														{points: this.calculateVarianceToForecast()}
												  )
												: util.convertMinutesToFullHour(
														this.calculateVarianceToForecast() * 60,
														this.props.intl
												  )
											: '-'
									}
								/>
							);
						} else return null;
					} else {
						return null;
					}
				})}
			</tr>
		);
	}
}

ProjectListProjectLineItem.propTypes = {
	nameStyle: PropTypes.object.isRequired,
	currencySymbol: PropTypes.string.isRequired,
	project: PropTypes.object.isRequired,
};
export default injectIntl(ProjectListProjectLineItem);
