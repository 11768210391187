import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {cloneDeep} from 'lodash';
import Util from '../../forecast-app/shared/util/util';
import {ELEMENT_TYPE, HIDDEN_FEATURES} from '../../constants';
import GenericEmptyState from './empty_states/generic_empty_state';
import * as tracking from '../../tracking';
import ClientSelector from '../../components/client_selector';
import ClientPage from '../../forecast-app/admin-tab/clients-page/client-js/client_page';
import HeaderBar from '../../forecast-app/shared/components/headers/header-bar/header_bar';
import {MODAL_TYPE, showModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../forecast-app/shared/components/headers/top-header-bar/TopHeaderBar';
import {hasPermission} from '../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../Permissions';
import {trackPage, unregisterPageInfo} from '../../tracking/amplitude/TrackingV2';
import {isFeatureHidden} from '../../forecast-app/shared/util/CompanySetupUtil';
import ProjectHeader from '../../forecast-app/project-tab/projects/shared/ProjectHeader';
import {changeClient} from '../../forecast-app/project-tab/projects/project-settings/ProjectSettingsMutations';
import {projectUrl} from '../../directApi';
import {getProjectIndicatorString} from '../../forecast-app/shared/components/project-indicator/support/ProjectIndicatorLogic';
import {getTaskUrl, pathIncludesTask} from '../../forecast-app/shared/util/UrlUtil';

class projectClient extends Component {
	UNSAFE_componentWillMount() {
		const path = this.props.history.location.pathname;
		if (isFeatureHidden(HIDDEN_FEATURES.CLIENT)) {
			if (pathIncludesTask(path)) {
				this.props.history.push(
					projectUrl(this.props.viewer.project.companyProjectId, this.props.viewer.project.customProjectId) +
						'/workflow' +
						getTaskUrl(path)
				);
			} else {
				// if user doesnt have access rights to view this page redirect to no access page
				this.props.history.push(
					projectUrl(this.props.viewer.project.companyProjectId, this.props.viewer.project.customProjectId) +
						'/workflow'
				);
			}
		}
		if (!Util.AuthorizeViewerAccess('project-client')) {
			if (pathIncludesTask(path)) {
				this.props.history.push(
					projectUrl(this.props.viewer.project.companyProjectId, this.props.viewer.project.customProjectId) +
						'/workflow' +
						getTaskUrl(path)
				);
			} else {
				// if user doesnt have access rights to view this page redirect to no access page
				this.props.history.push('/not-authorized');
				Util.localStorageSetItem('project-section-last-viewed', 'workflow');
			}
		}

		this.superPropertyChecksum = trackPage('Project Client');
	}

	componentDidMount() {
		const name =
			this.props.viewer.project.name !== null && this.props.viewer.project.name !== ''
				? this.props.viewer.project.name
				: getProjectIndicatorString(
						this.props.viewer.project.companyProjectId,
						this.props.viewer.project.customProjectId
				  );
		document.title = 'Client - ' + name + ' - Forecast';
		// Segment
		tracking.trackPage('project-client');
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	showAddClientModal() {
		showModal({type: MODAL_TYPE.ADD_CLIENT});
	}

	handleSelectClient(selected) {
		let projectId = this.props.viewer.project?.id;
		let clientId = selected ? (selected.value ? selected.value : selected) : null;
		changeClient(projectId, clientId);
	}

	getHeaderTitle() {
		const content = [];

		return (
			<TopHeaderBarWrapper>
				<TopHeaderBar title={this.props.intl.formatMessage({id: 'common.client'})} content={content} />
			</TopHeaderBarWrapper>
		);
	}

	getHeader(hasProjectUpdatePermission) {
		const clientOptions = this.props.viewer.company.clients.edges
			.map(client => ({value: client.node.id, label: client.node.name}))
			.sort((a, b) => Util.sortAlphabetically(a.label, b.label));
		const rightContent = [
			{
				type: ELEMENT_TYPE.LEFT_DROPDOWN,
				dropdownOptions: clientOptions,
				value: this.props.viewer.project.client ? this.props.viewer.project.client.id : null,
				callback: this.handleSelectClient.bind(this),
				clearable: true,
				clearText: this.props.intl.formatMessage({id: 'client_selector.no_client_text'}),
				placeholder: this.props.intl.formatMessage({id: 'client_selector.placeholder'}),
				disabled:
					!hasProjectUpdatePermission ||
					(this.props.viewer.project &&
						(this.props.viewer.project.status === 'DONE' || this.props.viewer.project.status === 'HALTED')),
			},
		];
		const leftContent = [
			{
				type: ELEMENT_TYPE.INDICATOR,
				status: this.props.viewer.project.status,
			},
		];

		return (
			<HeaderBar
				innerRef={div => (this.header_bar = div)}
				parentGroup={null}
				leftContent={leftContent}
				rightContent={rightContent}
			/>
		);
	}

	render() {
		const projectLocked = this.props.viewer.project.status === 'DONE' || this.props.viewer.project.status === 'HALTED';

		const {formatMessage} = this.props.intl;
		const hasClientUpdatePermission = hasPermission(PERMISSION_TYPE.CLIENT_UPDATE);
		const hasProjectUpdatePermission = hasPermission(PERMISSION_TYPE.PROJECTS_UPDATE);

		const selectedClient = this.props.viewer.project.client;
		let selectorClients = cloneDeep(this.props.viewer.company.clients.edges);
		if (this.props.viewer.project.client) {
			selectorClients = selectorClients.filter(client => {
				return client.node.id !== this.props.viewer.project.client.id;
			});
		}
		return (
			<div className="section-content project-client" data-cy={'project-client-page'}>
				{this.props.children}
				<ProjectHeader
					title={formatMessage({id: 'common.client'})}
					project={this.props.viewer.project}
					psProject={this.props.viewer.psProject}
					titleContent={[]}
					buttons={this.getHeader(hasProjectUpdatePermission)}
				/>
				<div className="section-body">
					{selectedClient ? (
						<ClientPage
							client={cloneDeep(this.props.viewer.project.client)}
							allPersons={this.props.viewer.company.allPersons}
							isProjectClient={true}
							characterLimit={this.props.viewer.company.characterLimit}
							hasClientUpdatePermission={hasClientUpdatePermission}
							hasProjectUpdatePermission={hasProjectUpdatePermission}
							isProjectLocked={projectLocked}
							project={this.props.viewer.project}
							companyId={this.props.viewer.company.id}
							viewer={this.props.viewer}
						/>
					) : (
						<GenericEmptyState
							iconClass={'client'}
							headerText={formatMessage({id: 'empty_state.project_client.header_text'})}
							text={formatMessage({id: 'empty_state.project_client.text'})}
							extraContent={
								!projectLocked && hasProjectUpdatePermission ? (
									<ClientSelector
										canChangeClient={hasProjectUpdatePermission}
										clients={selectorClients}
										showAddClientModal={this.showAddClientModal.bind(this)}
										handleSelectItem={this.handleSelectClient.bind(this)}
										showNoClient={false}
									/>
								) : null
							}
						/>
					)}
				</div>
			</div>
		);
	}
}

const projectClientQuery = graphql`
	query projectClient_Query($projectId: String) {
		viewer {
			actualPersonId
			component(name: "project_client")
			project(id: $projectId) {
				id
			}
			...projectClient_viewer @arguments(projectId: $projectId)
		}
	}
`;

export {projectClientQuery};

export default withRouter(
	injectIntl(
		createFragmentContainer(projectClient, {
			viewer: graphql`
				fragment projectClient_viewer on Viewer @argumentDefinitions(projectId: {type: "String"}) {
					id
					availableFeatureFlags {
						key
					}
					company {
						id
						characterLimit
						allPersons(first: 10000) @connection(key: "Company_allPersons", filters: []) {
							edges {
								node {
									id
									firstName
									lastName
									profilePictureId
									profilePictureDefaultId
									active
								}
							}
						}
						clients(first: 10000) @connection(key: "Company_clients", filters: []) {
							edges {
								node {
									id
									name
									street
									zip
									vat
									city
									country
									notes
									logoId
									logoDefaultId
									clientUsers(first: 1000) @connection(key: "Client_clientUsers", filters: []) {
										edges {
											node {
												id
												firstName
												lastName
												email
												phone
											}
										}
									}
								}
							}
						}
					}
					project(id: $projectId) {
						id
						name
						projectGroupId
						isInProjectGroup
						status
						projectColor
						companyProjectId
						customProjectId
						client {
							id
							name
							street
							vat
							zip
							city
							country
							notes
							logoId
							clientUsers(first: 1000) @connection(key: "Client_clientUsers", filters: []) {
								edges {
									node {
										id
										firstName
										lastName
										email
										phone
									}
								}
							}
						}
						projectPersons(first: 10000) @connection(key: "Project_projectPersons", filters: []) {
							edges {
								node {
									id
									person {
										id
										firstName
										lastName
										profilePictureId
										profilePictureDefaultId
									}
								}
							}
						}
						...ProjectHeader_project
						...SecondaryNavigation_project
					}
					psProject(companyProjectId: $projectId) {
						...ProjectHeader_psProject
					}
				}
			`,
		})
	)
);
