/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ProjectHeader_project$ref = any;
type ProjectHeader_psProject$ref = any;
type genericTaskContextMenu_company$ref = any;
export type BUDGET_TYPE = "FIXED_PRICE" | "FIXED_PRICE_V2" | "NON_BILLABLE" | "RETAINER" | "TIME_AND_MATERIALS" | "%future added value";
export type PERIODICITY = "DAILY" | "MONTHLY" | "WEEKLY" | "%future added value";
export type PERIOD_BUDGET_TYPE = "FIXED_HOURS" | "FIXED_PRICE" | "TIME_AND_MATERIALS" | "%future added value";
export type PROGRAM_BUDGET_TYPE = "CAPPED" | "FIXED_PRICE" | "UNCAPPED" | "%future added value";
export type PROJECT_STATUS = "DONE" | "HALTED" | "OPPORTUNITY" | "PLANNING" | "RUNNING" | "%future added value";
export type STATUS_CATEGORY = "DONE" | "INPROGRESS" | "TODO" | "%future added value";
export type TierType = "CORE" | "ENTERPRISE" | "FREE_FOREVER" | "LITE" | "PRO" | "TRIAL" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectRetainerPeriodsPage_viewer$ref: FragmentReference;
declare export opaque type ProjectRetainerPeriodsPage_viewer$fragmentType: ProjectRetainerPeriodsPage_viewer$ref;
export type ProjectRetainerPeriodsPage_viewer = {|
  +id: string,
  +actualPersonId: ?string,
  +availableFeatureFlags: ?$ReadOnlyArray<?{|
    +key: ?string
  |}>,
  +startDate: ?string,
  +endDate: ?string,
  +createdAt: ?string,
  +monday: ?number,
  +tuesday: ?number,
  +wednesday: ?number,
  +thursday: ?number,
  +friday: ?number,
  +saturday: ?number,
  +sunday: ?number,
  +company: ?{|
    +id: string,
    +currency: ?string,
    +userSeats: ?number,
    +virtualSeats: ?number,
    +tier: ?TierType,
    +isChargebeeCustomer: ?boolean,
    +useTimeApproval: ?boolean,
    +isUsingProjectAllocation: ?boolean,
    +isUsingMixedAllocation: ?boolean,
    +modules: ?$ReadOnlyArray<?{|
      +moduleType: ?string
    |}>,
    +$fragmentRefs: genericTaskContextMenu_company$ref,
  |},
  +psProject: ?{|
    +program: ?string,
    +programBudgetType: ?PROGRAM_BUDGET_TYPE,
    +psProgram: ?{|
      +name: ?string,
      +createdBy: ?{|
        +person: ?{|
          +id: string
        |}
      |},
      +budgetType: ?PROGRAM_BUDGET_TYPE,
      +budgetValue: ?number,
      +projectsFinancialsTotals: ?{|
        +totalRevenueRecognition: ?number
      |},
    |},
    +$fragmentRefs: ProjectHeader_psProject$ref,
  |},
  +project: ?{|
    +id: string,
    +name: ?string,
    +isInProjectGroup: ?boolean,
    +harvestProject: ?{|
      +id: string
    |},
    +projectGroupId: ?string,
    +companyProjectId: ?number,
    +customProjectId: ?string,
    +status: ?PROJECT_STATUS,
    +projectStartYear: ?number,
    +projectStartMonth: ?number,
    +projectStartDay: ?number,
    +projectEndYear: ?number,
    +projectEndMonth: ?number,
    +projectEndDay: ?number,
    +programBudgetType: ?PROGRAM_BUDGET_TYPE,
    +isProgramRevenueLocked: ?boolean,
    +programRemainingBudget: ?number,
    +programRevenueSetting: ?string,
    +manualProgressOnProjectEnabled: ?boolean,
    +manualProgressOnPhasesEnabled: ?boolean,
    +manualProgressOnTasksEnabled: ?boolean,
    +budgetType: ?BUDGET_TYPE,
    +rateCard: ?{|
      +currency: ?string
    |},
    +statusColumnsV2: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +category: ?STATUS_CATEGORY,
          +order: ?number,
          +jiraStatusId: ?string,
        |}
      |}>
    |},
    +phases: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +startYear: ?number,
          +startMonth: ?number,
          +startDay: ?number,
          +deadlineDay: ?number,
          +deadlineMonth: ?number,
          +deadlineYear: ?number,
          +forecast: ?number,
          +estimateForecastPrice: ?number,
          +remaining: ?number,
          +remainingPrice: ?number,
          +phasePersons: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +availableMinutes: ?number,
                +scheduledMinutes: ?number,
                +person: ?{|
                  +id: string
                |},
              |}
            |}>
          |},
        |}
      |}>
    |},
    +timeRegistrations: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +day: ?number,
          +month: ?number,
          +year: ?number,
          +createdAt: ?string,
          +updatedAt: ?string,
          +minutesRegistered: ?number,
          +billableMinutesRegistered: ?number,
          +xeroInvoiceId: ?string,
          +lockedInPeriod: ?boolean,
          +harvestTimeId: ?number,
          +harvestTask: ?{|
            +id: string,
            +name: ?string,
          |},
          +invoiced: ?boolean,
          +price: ?number,
          +retainerConflictHandled: ?boolean,
          +person: ?{|
            +id: string,
            +firstName: ?string,
            +lastName: ?string,
            +profilePictureId: ?string,
            +profilePictureDefaultId: ?number,
          |},
          +task: ?{|
            +id: string,
            +name: ?string,
            +companyTaskId: ?number,
            +deadlineYear: ?number,
            +billable: ?boolean,
            +approved: ?boolean,
            +deadlineMonth: ?number,
            +deadlineDay: ?number,
            +startDay: ?number,
            +startYear: ?number,
            +hasChildren: ?boolean,
            +startMonth: ?number,
            +blocked: ?boolean,
            +sageIntacctId: ?string,
            +bug: ?boolean,
            +highPriority: ?boolean,
            +canStart: ?boolean,
            +canBeSetToDone: ?boolean,
            +statusColumnV2: ?{|
              +id: string,
              +name: ?string,
            |},
          |},
          +project: ?{|
            +id: string,
            +name: ?string,
          |},
        |}
      |}>
    |},
    +estimationUnit: ?string,
    +minutesPerEstimationPoint: ?number,
    +projectColor: ?string,
    +defaultPeriodPeriodicity: ?PERIODICITY,
    +defaultPeriodLength: ?number,
    +defaultPeriodBudgetType: ?PERIOD_BUDGET_TYPE,
    +defaultPeriodHoursAmount: ?number,
    +defaultPeriodPriceAmount: ?number,
    +defaultPeriodSettingSubtractValue: ?boolean,
    +defaultPeriodSettingRollValue: ?boolean,
    +retainerPeriods: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +startYear: ?number,
          +startMonth: ?number,
          +startDay: ?number,
          +endYear: ?number,
          +endMonth: ?number,
          +endDay: ?number,
          +available: ?number,
          +periodLength: ?number,
          +periodPeriodicity: ?PERIODICITY,
          +periodPriceAmount: ?number,
          +periodHoursAmount: ?number,
          +periodBudgetType: ?PERIOD_BUDGET_TYPE,
          +periodSettingIgnoreForBilling: ?boolean,
          +periodSettingSubtractValue: ?boolean,
          +periodSettingRollValue: ?boolean,
          +periodSettingAddExpenses: ?boolean,
          +periodDifferencePriceAmount: ?number,
          +periodDifferenceHoursAmount: ?number,
          +sharedPeriodDifferenceHoursAmount: ?number,
          +sharedPeriodDifferencePriceAmount: ?number,
          +ignoredRolloverHours: ?number,
          +ignoredRolloverPrice: ?number,
          +periodLocked: ?boolean,
          +periodLockedTime: ?string,
          +periodDifferenceSplit: ?boolean,
          +invoiced: ?boolean,
          +financialNumbers?: ?{|
            +retainerPeriodTargetPrice: ?number,
            +retainerPeriodTargetMinutes: ?number,
            +billablePlannedTimeAndExpenses: ?number,
            +billableForecastTimeAndExpensesToComplete: ?number,
            +billableActualTimeAndExpenses: ?number,
            +billableTotalTimeAndExpensesAtCompletion: ?number,
            +scopeApprovedMinutes: ?number,
            +remainingMinutes: ?number,
            +billableActualMinutes: ?number,
            +totalActualRevenueRecognition: ?number,
            +retainerPeriodRolloverMinutes: ?number,
            +retainerPeriodRolloverPrice: ?number,
          |},
          +futureFinancialNumbers?: ?{|
            +allocationMinutes: ?number
          |},
          +retainerPeriodRollovers: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +periodFrom: ?{|
                  +id: string,
                  +name: ?string,
                |},
                +periodTo: ?{|
                  +id: string,
                  +name: ?string,
                  +periodLocked: ?boolean,
                |},
                +amount: ?number,
              |}
            |}>
          |},
        |}
      |}>
    |},
    +financialNumbers?: ?{|
      +retainerPeriodTargetPrice: ?number,
      +retainerPeriodTargetMinutes: ?number,
      +retainerPeriodRolloverPrice: ?number,
      +retainerPeriodRolloverMinutes: ?number,
      +totalActualRevenueRecognition: ?number,
      +billableActualMinutes: ?number,
    |},
    +$fragmentRefs: ProjectHeader_project$ref,
  |},
  +$refType: ProjectRetainerPeriodsPage_viewer$ref,
|};
export type ProjectRetainerPeriodsPage_viewer$data = ProjectRetainerPeriodsPage_viewer;
export type ProjectRetainerPeriodsPage_viewer$key = {
  +$data?: ProjectRetainerPeriodsPage_viewer$data,
  +$fragmentRefs: ProjectRetainerPeriodsPage_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "programBudgetType",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  (v0/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Person",
  "kind": "LinkedField",
  "name": "person",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "budgetType",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startYear",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startMonth",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDay",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineDay",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineMonth",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineYear",
  "storageKey": null
},
v14 = [
  (v0/*: any*/),
  (v4/*: any*/)
],
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invoiced",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "periodLocked",
  "storageKey": null
},
v20 = {
  "kind": "Literal",
  "name": "convertToProjectCurrency",
  "value": true
},
v21 = [
  (v20/*: any*/)
],
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "retainerPeriodTargetPrice",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "retainerPeriodTargetMinutes",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billableActualMinutes",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalActualRevenueRecognition",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "retainerPeriodRolloverMinutes",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "retainerPeriodRolloverPrice",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startYear",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startMonth",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startDay",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "hasFinancialServiceFlag",
      "type": "Boolean!"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "project",
          "timeRegistrations"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "project",
          "retainerPeriods"
        ]
      }
    ]
  },
  "name": "ProjectRetainerPeriodsPage_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualPersonId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FeatureFlag",
      "kind": "LinkedField",
      "name": "availableFeatureFlags",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "monday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tuesday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wednesday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thursday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "friday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "saturday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sunday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userSeats",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "virtualSeats",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tier",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isChargebeeCustomer",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useTimeApproval",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isUsingProjectAllocation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isUsingMixedAllocation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Module",
          "kind": "LinkedField",
          "name": "modules",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "moduleType",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "genericTaskContextMenu_company"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "companyProjectId",
          "variableName": "projectId"
        }
      ],
      "concreteType": "ProjectServiceProjectType",
      "kind": "LinkedField",
      "name": "psProject",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "program",
          "storageKey": null
        },
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ProgramType",
          "kind": "LinkedField",
          "name": "psProgram",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "UserType",
              "kind": "LinkedField",
              "name": "createdBy",
              "plural": false,
              "selections": [
                (v6/*: any*/)
              ],
              "storageKey": null
            },
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "budgetValue",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "FinancialNumbers",
              "kind": "LinkedField",
              "name": "projectsFinancialsTotals",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "totalRevenueRecognition",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectHeader_psProject"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "projectId"
        }
      ],
      "concreteType": "ProjectType",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isInProjectGroup",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HarvestProjectType",
          "kind": "LinkedField",
          "name": "harvestProject",
          "plural": false,
          "selections": (v5/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectGroupId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "companyProjectId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customProjectId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectStartYear",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectStartMonth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectStartDay",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectEndYear",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectEndMonth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectEndDay",
          "storageKey": null
        },
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isProgramRevenueLocked",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "programRemainingBudget",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "programRevenueSetting",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manualProgressOnProjectEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manualProgressOnPhasesEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "manualProgressOnTasksEnabled",
          "storageKey": null
        },
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "RateCard",
          "kind": "LinkedField",
          "name": "rateCard",
          "plural": false,
          "selections": [
            (v2/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 1000000
            }
          ],
          "concreteType": "StatusColumnV2TypeConnection",
          "kind": "LinkedField",
          "name": "statusColumnsV2",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "StatusColumnV2TypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "StatusColumnV2",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "category",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "order",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "jiraStatusId",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "statusColumnsV2(first:1000000)"
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 1000
            }
          ],
          "concreteType": "PhaseTypeConnection",
          "kind": "LinkedField",
          "name": "phases",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PhaseTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PhaseType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v4/*: any*/),
                    (v8/*: any*/),
                    (v9/*: any*/),
                    (v10/*: any*/),
                    (v11/*: any*/),
                    (v12/*: any*/),
                    (v13/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "forecast",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "estimateForecastPrice",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "remaining",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "remainingPrice",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "first",
                          "value": 10000
                        }
                      ],
                      "concreteType": "PhasePersonTypeConnection",
                      "kind": "LinkedField",
                      "name": "phasePersons",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "PhasePersonTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "PhasePerson",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "availableMinutes",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "scheduledMinutes",
                                  "storageKey": null
                                },
                                (v6/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "phasePersons(first:10000)"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "phases(first:1000)"
        },
        {
          "alias": "timeRegistrations",
          "args": null,
          "concreteType": "TimeRegTypeConnection",
          "kind": "LinkedField",
          "name": "__Project_timeRegistrations_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TimeRegTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TimeRegType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "day",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "month",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "year",
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "updatedAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "minutesRegistered",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "billableMinutesRegistered",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "xeroInvoiceId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lockedInPeriod",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "harvestTimeId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "HarvestTaskType",
                      "kind": "LinkedField",
                      "name": "harvestTask",
                      "plural": false,
                      "selections": (v14/*: any*/),
                      "storageKey": null
                    },
                    (v15/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "price",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "retainerConflictHandled",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Person",
                      "kind": "LinkedField",
                      "name": "person",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "firstName",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "lastName",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "profilePictureId",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "profilePictureDefaultId",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Task",
                      "kind": "LinkedField",
                      "name": "task",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v4/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "companyTaskId",
                          "storageKey": null
                        },
                        (v13/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "billable",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "approved",
                          "storageKey": null
                        },
                        (v12/*: any*/),
                        (v11/*: any*/),
                        (v10/*: any*/),
                        (v8/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "hasChildren",
                          "storageKey": null
                        },
                        (v9/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "blocked",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "sageIntacctId",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "bug",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "highPriority",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "canStart",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "canBeSetToDone",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "StatusColumnV2",
                          "kind": "LinkedField",
                          "name": "statusColumnV2",
                          "plural": false,
                          "selections": (v14/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProjectType",
                      "kind": "LinkedField",
                      "name": "project",
                      "plural": false,
                      "selections": (v14/*: any*/),
                      "storageKey": null
                    },
                    (v16/*: any*/)
                  ],
                  "storageKey": null
                },
                (v17/*: any*/)
              ],
              "storageKey": null
            },
            (v18/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "estimationUnit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minutesPerEstimationPoint",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectColor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "defaultPeriodPeriodicity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "defaultPeriodLength",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "defaultPeriodBudgetType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "defaultPeriodHoursAmount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "defaultPeriodPriceAmount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "defaultPeriodSettingSubtractValue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "defaultPeriodSettingRollValue",
          "storageKey": null
        },
        {
          "alias": "retainerPeriods",
          "args": null,
          "concreteType": "RetainerPeriodTypeConnection",
          "kind": "LinkedField",
          "name": "__Project_retainerPeriods_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RetainerPeriodTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "RetainerPeriodType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v4/*: any*/),
                    (v8/*: any*/),
                    (v9/*: any*/),
                    (v10/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endYear",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endMonth",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endDay",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "available",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "periodLength",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "periodPeriodicity",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "periodPriceAmount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "periodHoursAmount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "periodBudgetType",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "periodSettingIgnoreForBilling",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "periodSettingSubtractValue",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "periodSettingRollValue",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "periodSettingAddExpenses",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "periodDifferencePriceAmount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "periodDifferenceHoursAmount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "sharedPeriodDifferenceHoursAmount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "sharedPeriodDifferencePriceAmount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "ignoredRolloverHours",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "ignoredRolloverPrice",
                      "storageKey": null
                    },
                    (v19/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "periodLockedTime",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "periodDifferenceSplit",
                      "storageKey": null
                    },
                    (v15/*: any*/),
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "first",
                          "value": 100000
                        }
                      ],
                      "concreteType": "RetainerPeriodRolloverTypeConnection",
                      "kind": "LinkedField",
                      "name": "retainerPeriodRollovers",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "RetainerPeriodRolloverTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "RetainerPeriodRolloverType",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "RetainerPeriodType",
                                  "kind": "LinkedField",
                                  "name": "periodFrom",
                                  "plural": false,
                                  "selections": (v14/*: any*/),
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "RetainerPeriodType",
                                  "kind": "LinkedField",
                                  "name": "periodTo",
                                  "plural": false,
                                  "selections": [
                                    (v0/*: any*/),
                                    (v4/*: any*/),
                                    (v19/*: any*/)
                                  ],
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "amount",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "retainerPeriodRollovers(first:100000)"
                    },
                    (v16/*: any*/),
                    {
                      "condition": "hasFinancialServiceFlag",
                      "kind": "Condition",
                      "passingValue": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": (v21/*: any*/),
                          "concreteType": "FinancialNumbers",
                          "kind": "LinkedField",
                          "name": "financialNumbers",
                          "plural": false,
                          "selections": [
                            (v22/*: any*/),
                            (v23/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "billablePlannedTimeAndExpenses",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "billableForecastTimeAndExpensesToComplete",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "billableActualTimeAndExpenses",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "billableTotalTimeAndExpensesAtCompletion",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "scopeApprovedMinutes",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "remainingMinutes",
                              "storageKey": null
                            },
                            (v24/*: any*/),
                            (v25/*: any*/),
                            (v26/*: any*/),
                            (v27/*: any*/)
                          ],
                          "storageKey": "financialNumbers(convertToProjectCurrency:true)"
                        },
                        {
                          "alias": "futureFinancialNumbers",
                          "args": [
                            (v20/*: any*/),
                            {
                              "kind": "Variable",
                              "name": "startDay",
                              "variableName": "startDay"
                            },
                            {
                              "kind": "Variable",
                              "name": "startMonth",
                              "variableName": "startMonth"
                            },
                            {
                              "kind": "Variable",
                              "name": "startYear",
                              "variableName": "startYear"
                            }
                          ],
                          "concreteType": "FinancialNumbers",
                          "kind": "LinkedField",
                          "name": "financialNumbers",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "allocationMinutes",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ]
                    }
                  ],
                  "storageKey": null
                },
                (v17/*: any*/)
              ],
              "storageKey": null
            },
            (v18/*: any*/)
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectHeader_project"
        },
        {
          "condition": "hasFinancialServiceFlag",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": null,
              "args": (v21/*: any*/),
              "concreteType": "FinancialNumbers",
              "kind": "LinkedField",
              "name": "financialNumbers",
              "plural": false,
              "selections": [
                (v22/*: any*/),
                (v23/*: any*/),
                (v27/*: any*/),
                (v26/*: any*/),
                (v25/*: any*/),
                (v24/*: any*/)
              ],
              "storageKey": "financialNumbers(convertToProjectCurrency:true)"
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e2f31a3c5910735a3224ac470f277fe4';

module.exports = node;
