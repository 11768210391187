import React, {useEffect} from 'react';
import {withSocketHandling} from '../../../../socket/withSocketHandling';
import {createRefetchContainer, graphql} from 'react-relay';
import ProjectSprintsSprint from './ProjectSprintsSprint';
import ProjectSprintsSideBacklog from './ProjectSprintsSideBacklog';

const ProjectSprintBacklogWrapper = ({viewer, setSocketConfig, socketData, reloadBacklog, backlogWrapperVars, ...props}) => {
	let backlogSprint = props.sprint;
	const filterTasks = backlogTasks => {
		if (props.filterFunctions && props.filterFunctions.taskFilter) {
			const options = {
				isProjectGroup: props.mainViewer.projectGroup !== null && props.mainViewer.projectGroup !== undefined,
				teams: props.mainViewer.company.teams.edges,
			};
			return backlogTasks.filter(task => props.filterFunctions.taskFilter(task.node, options));
		}
	};

	let backlogTasks = props.isConnectedParent
		? viewer.projectGroup.projects.edges.reduce(
				(acc, project) => acc.concat(project.node.tasks ? project.node.tasks.edges : []),
				[]
		  )
		: viewer.project.tasks.edges;

	props.addTasks(backlogTasks);

	// Filtered task for the bottom backlog
	const filteredTasks = filterTasks(backlogTasks);
	backlogSprint.tasks = filteredTasks.map(task => task.node);

	// unfiltered task for the side backlog
	const sideBacklogTask = backlogTasks.map(task => task.node);

	useEffect(() => {
		if (socketData?.socketEvents) {
			setSocketConfig(socketData.socketEvents);
		}
	}, [socketData]);

	useEffect(() => {
		if (reloadBacklog) {
			props.relay.refetch(backlogWrapperVars, null);
		}
	}, [reloadBacklog]);

	return !props.sideBacklog ? (
		<ProjectSprintsSprint
			onSelectAllTasks={props.onSelectAllTasks}
			onDeselectAllTasks={props.onDeselectAllTasks}
			draggingTask={props.draggingTask}
			showSprintNumbers={true}
			minutesPerEstimationPoint={props.minutesPerEstimationPoint}
			projectPersons={props.projectPersons}
			sprintPersons={[]}
			showEmptyPersons={props.showEmptyPersons}
			groupByPerson={false}
			groupByRole={false}
			tableMinWidth={props.tableMinWidth}
			cy={''}
			isConnectedParent={props.isConnectedParent}
			isConnectedChild={props.isConnectedChild}
			phaseHeader={props.phaseHeader}
			selectionMode={props.selectionMode}
			containerWidth={props.containerWidth}
			contextHeight={props.contextHeight}
			dragAndDropGroup={props.dragAndDropGroup}
			showTaskModal={props.showTaskModal}
			projectLocked={props.projectLocked}
			isJiraProject={props.isJiraProject}
			sprint={backlogSprint}
			sortedSprints={props.sortedSprints}
			project={props.project}
			projectId={props.projectId}
			isBacklog={true}
			emptyPhase={true}
			roles={props.roles}
			isEstimatedInHours={props.isEstimatedInHours}
			isEstimatedInNewPoints={props.isEstimatedInNewPoints}
			hasRateCard={props.hasRateCard}
			currencySymbol={props.currencySymbol}
			placeUnitBeforeValue={props.placeUnitBeforeValue}
			hasFinancialAccess={props.hasFinancialAccess}
			language={props.language}
			//calculateTablesSize={this.calculateTablesSize.bind(this)}
			projectTasks={props.projectTasks}
			viewer={viewer}
			availableColumns={props.availableColumns}
			handleRowSelected={props.handleRowSelected}
			selectedRows={props.selectedRows}
			taskCellMaxWidth={props.taskCellMaxWidth}
			columnWidths={props.columnWidths}
			useManualAllocations={props.useManualAllocations}
			unselectTasks={props.unselectTasks}
			lazyDataFetched={props.lazyDataFetched}
			lowHighModelScore={props.lowHighModelScore}
		/>
	) : (
		<ProjectSprintsSideBacklog
			innerRef={props.innerRef}
			tableMinWidth={props.tableMinWidth}
			cy={''}
			isConnectedParent={props.isConnectedParent}
			isConnectedChild={props.isConnectedChild}
			onExpansionChange={props.onExpansionChange}
			phase={props.phase}
			phaseHeader={props.phaseHeader}
			selectionMode={props.selectionMode}
			containerWidth={props.containerWidth}
			dragAndDropGroup={props.dragAndDropGroup}
			showTaskModal={props.showTaskModal}
			projectLocked={props.projectLocked}
			isJiraProject={props.isJiraProject}
			tasks={sideBacklogTask}
			project={props.project}
			projectId={props.projectId}
			emptyPhase={true}
			roles={props.roles}
			isEstimatedInHours={props.isEstimatedInHours}
			hasRateCard={props.hasRateCard}
			currencySymbol={props.currencySymbol}
			placeUnitBeforeValue={props.placeUnitBeforeValue}
			hasFinancialAccess={props.hasFinancialAccess}
			language={props.language}
			projectTasks={props.projectTasks}
			viewer={props.mainViewer}
			availableColumns={props.availableColumns}
			handleRowSelected={props.handleRowSelected}
			selectedRows={props.selectedRows}
			onContextMenu={props.onContextMenu}
			moveBacklog={props.moveBacklog}
			draggingTask={props.draggingTask}
			lazyDataFetched={props.lazyDataFetched}
			buyNowTime={props.buyNowTime}
		/>
	);
};

const ProjectSprintBacklogWrapperQuery = graphql`
	query ProjectSprintBacklogWrapper_Query($projectId: ID, $groupId: String, $fetchLazyData: Boolean!, $personId: ID!) {
		viewer {
			actualPersonId
			component(name: "project_sprint_backlog_wrapper_v4")
			project(internalId: $projectId) {
				id
			}
			projectGroup(id: $groupId) {
				projects(first: 1000000) {
					edges {
						node {
							id
						}
					}
				}
			}
			...ProjectSprintBacklogWrapper_viewer
				@arguments(projectId: $projectId, groupId: $groupId, fetchLazyData: $fetchLazyData, personId: $personId)
		}
	}
`;

export {ProjectSprintBacklogWrapperQuery};

export default withSocketHandling(
	createRefetchContainer(
		ProjectSprintBacklogWrapper,
		{
			viewer: graphql`
				fragment ProjectSprintBacklogWrapper_viewer on Viewer
				@argumentDefinitions(
					projectId: {type: "ID"}
					groupId: {type: "String"}
					fetchLazyData: {type: "Boolean!"}
					personId: {type: "ID!"}
				) {
					id
					language
					actualPersonId
					availableFeatureFlags {
						key
					}
					projectGroup(id: $groupId) {
						id
						companyProjectGroupId
						name
						color
						projects(first: 1000000) {
							edges {
								node {
									id
									name
									demo
									status
									companyProjectId
									...DeprecatedProjectIndicatorJS_project
									taskLevels
									sprintTimeBox
									sprintLengthInDays
									useManualAllocations
									remainingAutoCalculated
									projectColor
									estimationUnit
									minutesPerEstimationPoint
									manualProgressOnProjectEnabled
									manualProgressOnPhasesEnabled
									manualProgressOnTasksEnabled
									budgetWork
									budgetType
									tasks(
										first: 100000
										approvedOnly: true
										simpleResponse: true
										backlog: true
										context: "sprint"
									) @connection(key: "Project_tasks", filters: ["backlog"]) {
										edges {
											node {
												id
												readOnly {
													isReadOnly
												}
												userCanDeleteTask
												userCantDeleteTaskReason
												sageIntacctId
												hasChildren
												name
												description
												highPriority
												companyTaskId
												estimateForecast
												timeLeft @include(if: $fetchLazyData)
												approved
												sortOrder
												blocked
												bug
												estimateForecastPrice
												currentPrice @include(if: $fetchLazyData)
												deadlineYear
												deadlineMonth
												deadlineDay
												startYear
												startMonth
												startDay
												startFrom
												deadlineFrom
												hasDependency
												canStart
												canBeSetToDone
												subTaskCount
												latestUiUpdateAt
												hasLockedTime
												hasInvoicedTime
												favoured
												billable
												parentTaskId
												project {
													id
													demo
													estimationUnit
													minutesPerEstimationPoint
													companyProjectId
													...DeprecatedProjectIndicatorJS_project
													projectEndYear
													projectEndMonth
													projectEndDay
													projectColor
													remainingAutoCalculated
													name
													status
													projectGroupId
													projectPerson(personId: $personId) {
														role {
															id
															name
														}
													}
													harvestProject {
														id
													}
													client {
														id
													}
													rateCard {
														id
														currency
														disabledRoles {
															id
														}
													}
													useTaskHierarchy
												}
												taskLabels {
													id
													label {
														id
														name
														color
													}
												}
												sprint {
													id
													endYear
													endMonth
													endDay
													savedPerformance
													name
													projectGroupSprintId
													isProjectGroupSprint
												}
												statusColumnV2 {
													id
													name
													category
													projectGroupStatusColumnId
												}
												phase {
													id
													name
													deadlineYear
													deadlineMonth
													deadlineDay
												}
												role {
													id
													name
												}
												assignedPersons {
													id
													active
													firstName
													lastName
													profilePictureId
													profilePictureDefaultId
													permissions
													client {
														id
													}
												}
												taskDismissedNotifications {
													taskOverrunPredictionDismissed
												}
												timeRegistrations(first: 10000)
													@connection(key: "Task_timeRegistrations", filters: [])
													@include(if: $fetchLazyData) {
													edges {
														node {
															id
															minutesRegistered
															price
															invoiced
															person {
																id
																role {
																	id
																}
															}
														}
													}
												}
												thisTaskDependsOn(first: 1000) @connection(key: "Task_thisTaskDependsOn") {
													edges {
														node {
															id
															type
															thisDependsOnTask {
																id
																name
																deadlineYear
																deadlineMonth
																deadlineDay
																startYear
																startMonth
																startDay
															}
														}
													}
												}
												owner {
													id
													permissions
												}
												followers {
													id
													permissions
												}
												parentTask {
													id
													name
												}
												progress @include(if: $fetchLazyData)
												progressDetails {
													progress
												}
											}
										}
									}
									projectPersons(first: 1000000) {
										edges {
											node {
												person {
													id
													active
													permissions
													firstName
													lastName
													fullName
													initials
													profilePictureId
													profilePictureDefaultId
													role {
														id
														name
													}
													client {
														id
													}
												}
												role {
													id
													name
												}
											}
										}
									}
									sprints(first: 1000, onlyProjectGroupSprints: true)
										@connection(key: "Project_sprints", filters: []) {
										edges {
											node {
												id
												name
												projectGroupSprintId
												isProjectGroupSprint
												startDay
												startMonth
												startYear
												endDay
												endMonth
												endYear
												description @include(if: $fetchLazyData)
												savedPerformance
												hasUnfinishedTask
												integrations {
													id
													jiraId
													adoId
												}
											}
										}
									}
									phases(first: 1000) {
										edges {
											node {
												id
												name
											}
										}
									}
									statusColumnsV2(first: 1000000) {
										edges {
											node {
												id
												order
												name
												projectGroupStatusColumnId
												jiraStatusId
												category
											}
										}
									}
								}
							}
						}
					}
					project(internalId: $projectId) {
						id
						name
						remainingAutoCalculated
						tasks(first: 100000, approvedOnly: true, simpleResponse: true, backlog: true, context: "sprint")
							@connection(key: "Project_tasks", filters: ["backlog"]) {
							edges {
								node {
									id
									readOnly {
										isReadOnly
									}
									userCanDeleteTask
									userCantDeleteTaskReason
									hasChildren
									sageIntacctId
									name
									description
									highPriority
									companyTaskId
									estimateForecast
									timeLeft @include(if: $fetchLazyData)
									approved
									sortOrder
									currentPrice @include(if: $fetchLazyData)
									estimateForecastPrice
									blocked
									bug
									deadlineYear
									deadlineMonth
									deadlineDay
									startYear
									startMonth
									startDay
									startFrom
									deadlineFrom
									hasDependency
									canStart
									canBeSetToDone
									subTaskCount
									latestUiUpdateAt
									hasLockedTime
									hasInvoicedTime
									favoured
									billable
									parentTaskId
									taskLabels {
										id
										label {
											id
											name
											color
										}
									}
									sprint {
										id
										endYear
										endMonth
										endDay
										name
									}
									statusColumnV2 {
										id
										name
										category
									}
									phase {
										id
										name
										deadlineYear
										deadlineMonth
										deadlineDay
									}
									role {
										id
										name
									}
									assignedPersons {
										id
										active
										firstName
										lastName
										email
										profilePictureId
										profilePictureDefaultId
										permissions
										client {
											id
										}
									}
									taskDismissedNotifications {
										taskOverrunPredictionDismissed
									}
									timeRegistrations(first: 10000)
										@connection(key: "Task_timeRegistrations", filters: [])
										@include(if: $fetchLazyData) {
										edges {
											node {
												id
												minutesRegistered
												price
												invoiced
												person {
													id
													role {
														id
													}
												}
											}
										}
									}
									project {
										id
										name
										demo
										client {
											id
											name
										}
										harvestProject {
											id
										}
										projectPerson(personId: $personId) {
											role {
												id
												name
											}
										}
										remainingAutoCalculated
										companyProjectId
										estimationUnit
										minutesPerEstimationPoint
										projectStartYear
										projectStartMonth
										projectStartDay
										projectEndYear
										projectEndMonth
										projectEndDay
										projectColor
										status
										useTaskHierarchy
									}
									thisTaskDependsOn(first: 1000) @connection(key: "Task_thisTaskDependsOn") {
										edges {
											node {
												id
												type
												thisDependsOnTask {
													id
													name
													deadlineYear
													deadlineMonth
													deadlineDay
													startYear
													startMonth
													startDay
												}
											}
										}
									}
									owner {
										id
										permissions
									}
									followers {
										id
										permissions
									}
									parentTask {
										id
										name
									}
									progress @include(if: $fetchLazyData)
									progressDetails {
										progress
									}
								}
							}
						}
						projectPersons(first: 1000000) {
							edges {
								node {
									person {
										id
										active
										permissions
										firstName
										lastName
										fullName
										initials
										profilePictureId
										profilePictureDefaultId
										role {
											id
											name
										}
										client {
											id
										}
									}
									role {
										id
										name
									}
								}
							}
						}
						sprints(first: 1000000) @connection(key: "Project_sprints", filters: []) {
							edges {
								node {
									id
									name
									timeLeft
									forecast
									startDay
									startMonth
									startYear
									endDay
									endMonth
									endYear
									description @include(if: $fetchLazyData)
									savedPerformance
									hasUnfinishedTask
									sprintPersons(first: 1000000) {
										edges {
											node {
												id
												availableMinutes @include(if: $fetchLazyData)
												scheduledMinutes @include(if: $fetchLazyData)
												taskAvailableMinutes @include(if: $fetchLazyData)
												person {
													id
													firstName
													lastName
													profilePictureId
													profilePictureDefaultId
													role {
														id
														name
													}
												}
											}
										}
									}
									integrations {
										id
										jiraId
										adoId
									}
								}
							}
						}
						phases(first: 1000) {
							edges {
								node {
									id
									name
								}
							}
						}
						statusColumnsV2(first: 1000000) {
							edges {
								node {
									id
									order
									name
									projectGroupStatusColumnId
									jiraStatusId
									category
								}
							}
						}
					}
					company {
						...genericTaskContextMenu_company
						id
						backendId
						name
						currency
						harvestEnabled
						lockedPeriodYear
						lockedPeriodMonth
						lockedPeriodDay
						isUsingProjectAllocation
						modules {
							moduleType
						}
						roles(first: 1000000) {
							edges {
								node {
									id
									name
								}
							}
						}
						labels(first: 1000000, labelType: TASK) {
							edges {
								node {
									id
									name
									color
									category {
										id
										name
									}
									...LabelDropdown_labels
								}
							}
						}
						clients(first: 1000000) {
							edges {
								node {
									id
									name
									logoId
									logoDefaultId
								}
							}
						}
						teams(first: 1000000) {
							edges {
								...TeamDropdown_teams
								node {
									id
									name
									teamPersons(first: 1000000) {
										edges {
											node {
												id
												person {
													id
												}
											}
										}
									}
								}
							}
						}
						monday
						tuesday
						wednesday
						thursday
						friday
						saturday
						sunday
					}
				}
			`,
		},
		graphql`
			query ProjectSprintBacklogWrapperRefetchQuery(
				$projectId: ID
				$groupId: String
				$fetchLazyData: Boolean!
				$personId: ID!
			) {
				viewer {
					component(name: "project_sprints_backlog_refetch_v4")
					...ProjectSprintBacklogWrapper_viewer
						@arguments(projectId: $projectId, groupId: $groupId, fetchLazyData: $fetchLazyData, personId: $personId)
				}
			}
		`
	)
);
