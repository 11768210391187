import React, {useContext} from 'react';
import {
	DeprecatedComponentWithNestedContextMenu as ComponentWithNestedContextMenu,
	DeprecatedContextMenu as ContextMenu,
	DeprecatedContextMenuArrowItem as ContextMenuArrowItem,
	DeprecatedContextMenuGroup as ContextMenuGroup,
	DeprecatedContextMenuItem as ContextMenuItem,
	DeprecatedText as Text,
	DeprecatedNestedContextMenu as NestedContextMenu,
} from '@forecast-it/design-system';
import {useHistory} from 'react-router-dom';
import {MODAL_TYPE, showModal} from '../../../../../shared/components/modals/generic_modal_conductor';
import ReactDOM from 'react-dom';

import {useIntl} from 'react-intl';
import {hasPermission} from '../../../../../shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../../../Permissions';
import MoveProjectSection, {MoveProjectSectionQuery} from './MoveProjectSection';
import {ProgramContext} from '../../ProgramContextProvider';
import Util from '../../../../../shared/util/util';
import AttachProjectToProgramMutation from '../../../../../../mutations/project-service/attach_project_to_program_mutation';
import {useProgram} from '../../../hooks/useProgram';
import ProgramUtil from '../../../../../shared/util/ProgramUtil';
import ForecastQueryRenderer from '../../../../../../ForecastQueryRenderer';
import InlineLoader from '../../../../../shared/components/inline-loader/inline_loader';

const ProgramProjectContextMenu = ({project, bounds, closeContextMenu}) => {
	const {formatMessage} = useIntl();

	const {program, company, actualPersonId} = useContext(ProgramContext);
	const _program = useProgram(program);
	const canManage = _program.canManage(actualPersonId);

	const history = useHistory();

	const goToProjectSettings = () => history.push(`/project/P-${project.companyProjectId}/settings`);

	const openRemoveProjectModal = () =>
		showModal({
			type: MODAL_TYPE.DETACH_PROJECT_MODAL,
			programId: program.id,
			projectId: project.id,
		});

	const attachProjectToNewProgram = program => {
		Util.CommitMutation(
			AttachProjectToProgramMutation,
			{
				programId: program.id,
				projectIds: project.id,
			},
			() => history.push(`${ProgramUtil.programLink()}/${program.prefix}/overview`)
		);
	};

	const openCreateProgramModal = () => {
		showModal({
			type: MODAL_TYPE.CREATE_PROGRAM,
			onCreateCallback: attachProjectToNewProgram,
			companyId: company.id,
		});
	};

	const ProgramMenu = () => (
		<NestedContextMenu verticalPos={'below'} bounds={bounds}>
			{hasPermission(PERMISSION_TYPE.PROGRAMS_CREATE) && (
				<ContextMenuGroup>
					<ContextMenuItem onClick={openCreateProgramModal}>
						<Text>
							{Util.upperCaseFirst(
								formatMessage(
									{id: 'program.project.move.move_to_new_program'},
									{program: ProgramUtil.programText(formatMessage)}
								)
							)}
						</Text>
					</ContextMenuItem>
				</ContextMenuGroup>
			)}
			<ContextMenuGroup>
				<ForecastQueryRenderer
					key="query-render-MoveProjectSection"
					query={MoveProjectSectionQuery}
					customLoader={() => <InlineLoader />}
					render={(relayProps, retry) => {
						return <MoveProjectSection {...relayProps} project={project} closeContextMenu={closeContextMenu} />;
					}}
				/>
			</ContextMenuGroup>
		</NestedContextMenu>
	);

	return ReactDOM.createPortal(
		<ContextMenu verticalPos={'below'} horizontalOrientation={'left'} bounds={bounds} closeContextMenu={closeContextMenu}>
			<ContextMenuGroup>
				<ContextMenuItem onClick={goToProjectSettings}>
					<Text>{formatMessage({id: 'settings.text.entity'}, {entity: formatMessage({id: 'common.project'})})}</Text>
				</ContextMenuItem>
			</ContextMenuGroup>
			{hasPermission(PERMISSION_TYPE.PROGRAMS_UPDATE) && canManage && (
				<>
					<ContextMenuGroup>
						<ComponentWithNestedContextMenu NestedContextMenu={ProgramMenu}>
							<ContextMenuArrowItem>
								<Text>
									{formatMessage(
										{id: 'program.project.move.move_to_another_program'},
										{program: ProgramUtil.programText(formatMessage)}
									)}
								</Text>
							</ContextMenuArrowItem>
						</ComponentWithNestedContextMenu>
					</ContextMenuGroup>
					<ContextMenuGroup>
						<ContextMenuItem onClick={openRemoveProjectModal}>
							<Text color={'destructive'}>
								{formatMessage(
									{id: 'program.project.remove.remove_from_program'},
									{program: ProgramUtil.programText(formatMessage)}
								)}
							</Text>
						</ContextMenuItem>
					</ContextMenuGroup>
				</>
			)}
		</ContextMenu>,
		document.querySelector('#root-portal-container')
	);
};

export default ProgramProjectContextMenu;
