/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type MyWorkProfile_person$ref = any;
export type STATUS_CATEGORY = "DONE" | "INPROGRESS" | "TODO" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type MyWorkStatsSection_viewer$ref: FragmentReference;
declare export opaque type MyWorkStatsSection_viewer$fragmentType: MyWorkStatsSection_viewer$ref;
export type MyWorkStatsSection_viewer = {|
  +id: string,
  +actualPersonId: ?string,
  +availableFeatureFlags: ?$ReadOnlyArray<?{|
    +key: ?string
  |}>,
  +company: ?{|
    +id: string,
    +idleTimes: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |}
      |}>
    |},
    +isUsingProjectAllocation: ?boolean,
    +person: ?{|
      +monday: ?number,
      +tuesday: ?number,
      +wednesday: ?number,
      +thursday: ?number,
      +friday: ?number,
      +saturday: ?number,
      +sunday: ?number,
      +createdAt: ?string,
      +holidayCalendar: ?{|
        +id: string,
        +holidayCalendarEntries: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +day: ?number,
              +month: ?number,
              +year: ?number,
              +name: ?string,
            |}
          |}>
        |},
        +name: ?string,
      |},
      +$fragmentRefs: MyWorkProfile_person$ref,
    |},
    +deadlineThisWeekTasks: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +fullAccessToProject: ?boolean,
          +estimateForecast: ?number,
          +timeLeft: ?number,
          +statusColumnV2: ?{|
            +id: string,
            +name: ?string,
            +category: ?STATUS_CATEGORY,
          |},
          +timeRegistrations: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +minutesRegistered: ?number,
                +day: ?number,
                +month: ?number,
                +year: ?number,
              |}
            |}>
          |},
        |}
      |}>
    |},
  |},
  +timeRegistrations: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +day: ?number,
        +month: ?number,
        +year: ?number,
        +minutesRegistered: ?number,
        +task: ?{|
          +id: string,
          +deadlineDay: ?number,
          +deadlineMonth: ?number,
          +deadlineYear: ?number,
        |},
        +project: ?{|
          +id: string
        |},
      |}
    |}>
  |},
  +projects: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +companyProjectId: ?number,
        +name: ?string,
        +projectColor: ?string,
      |}
    |}>
  |},
  +projectGroups: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +companyProjectGroupId: ?number,
        +name: ?string,
        +color: ?string,
      |}
    |}>
  |},
  +$refType: MyWorkStatsSection_viewer$ref,
|};
export type MyWorkStatsSection_viewer$data = MyWorkStatsSection_viewer;
export type MyWorkStatsSection_viewer$key = {
  +$data?: MyWorkStatsSection_viewer$data,
  +$fragmentRefs: MyWorkStatsSection_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "day",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "month",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "year",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minutesRegistered",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "personId",
      "type": "ID"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchQuery",
      "type": "TaskSearchQueryType"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startDateString",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endDateString",
      "type": "String"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": null
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "company",
          "deadlineThisWeekTasks"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "timeRegistrations"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "projects"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "projectGroups"
        ]
      }
    ]
  },
  "name": "MyWorkStatsSection_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualPersonId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FeatureFlag",
      "kind": "LinkedField",
      "name": "availableFeatureFlags",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "IdleTimeTypeConnection",
          "kind": "LinkedField",
          "name": "idleTimes",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "IdleTimeTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "IdleTime",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isUsingProjectAllocation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "id",
              "variableName": "personId"
            }
          ],
          "concreteType": "Person",
          "kind": "LinkedField",
          "name": "person",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "monday",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tuesday",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "wednesday",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "thursday",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "friday",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "saturday",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sunday",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "createdAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "HolidayCalendar",
              "kind": "LinkedField",
              "name": "holidayCalendar",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "first",
                      "value": 10000
                    }
                  ],
                  "concreteType": "HolidayCalendarEntryTypeConnection",
                  "kind": "LinkedField",
                  "name": "holidayCalendarEntries",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "HolidayCalendarEntryTypeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "HolidayCalendarEntry",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v2/*: any*/),
                            (v3/*: any*/),
                            (v4/*: any*/),
                            (v1/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "holidayCalendarEntries(first:10000)"
                },
                (v1/*: any*/)
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "MyWorkProfile_person"
            }
          ],
          "storageKey": null
        },
        {
          "alias": "deadlineThisWeekTasks",
          "args": null,
          "concreteType": "TaskTypeConnection",
          "kind": "LinkedField",
          "name": "__Company_deadlineThisWeekTasks_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TaskTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Task",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "fullAccessToProject",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "estimateForecast",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "timeLeft",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "StatusColumnV2",
                      "kind": "LinkedField",
                      "name": "statusColumnV2",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "category",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": "timeRegistrations",
                      "args": null,
                      "concreteType": "TimeRegTypeConnection",
                      "kind": "LinkedField",
                      "name": "__Task_timeRegistrations_connection",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "TimeRegTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "TimeRegType",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                (v5/*: any*/),
                                (v2/*: any*/),
                                (v3/*: any*/),
                                (v4/*: any*/),
                                (v6/*: any*/)
                              ],
                              "storageKey": null
                            },
                            (v7/*: any*/)
                          ],
                          "storageKey": null
                        },
                        (v8/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v6/*: any*/)
                  ],
                  "storageKey": null
                },
                (v7/*: any*/)
              ],
              "storageKey": null
            },
            (v8/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "timeRegistrations",
      "args": null,
      "concreteType": "TimeRegTypeConnection",
      "kind": "LinkedField",
      "name": "__Viewer_timeRegistrations_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TimeRegTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TimeRegType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Task",
                  "kind": "LinkedField",
                  "name": "task",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "deadlineDay",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "deadlineMonth",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "deadlineYear",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectType",
                  "kind": "LinkedField",
                  "name": "project",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                },
                (v6/*: any*/)
              ],
              "storageKey": null
            },
            (v7/*: any*/)
          ],
          "storageKey": null
        },
        (v8/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": "projects",
      "args": null,
      "concreteType": "ProjectTypeConnection",
      "kind": "LinkedField",
      "name": "__Viewer_projects_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "companyProjectId",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "projectColor",
                  "storageKey": null
                },
                (v6/*: any*/)
              ],
              "storageKey": null
            },
            (v7/*: any*/)
          ],
          "storageKey": null
        },
        (v8/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": "projectGroups",
      "args": null,
      "concreteType": "ProjectGroupTypeConnection",
      "kind": "LinkedField",
      "name": "__Viewer_projectGroups_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectGroupTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectGroupType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "companyProjectGroupId",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "color",
                  "storageKey": null
                },
                (v6/*: any*/)
              ],
              "storageKey": null
            },
            (v7/*: any*/)
          ],
          "storageKey": null
        },
        (v8/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '6554f00e0554481e8db2ac93621f58da';

module.exports = node;
