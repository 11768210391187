import React, {Component} from 'react';
import {cloneDeep} from 'lodash';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import NotificationContainer from '../../shared/components/notifications/NotificationContainer';
import UpdateViewerMutation from '../../../mutations/update_viewer_mutation.modern';
import {createToast} from '../../shared/components/toasts/another-toast/toaster';
import Util from '../../shared/util/util';
import * as tracking from '../../../tracking';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../shared/components/headers/top-header-bar/TopHeaderBar';
import {trackPage, unregisterPageInfo} from '../../../tracking/amplitude/TrackingV2';

class MyNotifications extends Component {
	constructor(props) {
		super(props);

		this.state = {
			teammemberFilterValue: (JSON.parse(localStorage.getItem('all-notifications-filter-value-teammember')) || []).filter(
				val => val !== null
			),
			actionFilterValue: (JSON.parse(localStorage.getItem('all-notifications-filter-value-action')) || []).filter(
				val => val !== null
			),
			readStatusFilterValue: (
				JSON.parse(localStorage.getItem('all-notifications-filter-value-read-status')) || []
			).filter(val => val !== null),
			projectFilterValue: (JSON.parse(localStorage.getItem('all-notifications-filter-value-project')) || []).filter(
				val => val !== null
			),
		};

		this.superPropertyChecksum = trackPage('Notifications');
	}

	componentDidMount() {
		tracking.trackPage('profile-all-notifications');
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);
	}

	handleBlur(e) {
		const newTarget = e ? e.relatedTarget || e.explicitOriginalTarget || document.activeElement : null; // IE11
		if (
			newTarget &&
			newTarget.className &&
			((this.notification_container && this.notification_container.contains(newTarget)) ||
				newTarget.className.includes('notification') ||
				newTarget.className.includes('actions'))
		)
			return;
		this.setState({expanded: false});
	}

	handleKeyPress(e) {
		if (e.keyCode === 27 && this.state.expanded) {
			// Escape
			this.buttonRef.focus();
			this.setState({expanded: false});
		} else if (e.keyCode === 13 || e.keyCode === 32) {
			// Enter / Space
			const focusedElement = document.activeElement;
			if (focusedElement !== this.buttonRef) {
				e.preventDefault();
				focusedElement.click();
			} else {
				this.toggleNotifications();
			}
		}
	}

	handleFilterChange(filterType, value) {
		this.setState({[filterType + 'FilterValue']: value, selectedTasks: []});
		if (typeof value === 'string') {
			Util.localStorageSetItem('all-notifications-filter-value-' + filterType, value);
		} else {
			Util.localStorageSetItem(
				'all-notifications-filter-value-' + filterType,
				JSON.stringify(value.map(v => ({...v, label: null})))
			);
		}
	}

	disableNotificationType(type) {
		const params = {
			id: this.props.viewer.id,
		};
		const notification = Util.getNotificationFlagFromType(type);
		const shouldNotify = !this.props.viewer[notification];
		const onSuccess = () =>
			createToast({
				duration: 5000,
				message: shouldNotify
					? this.props.intl.formatMessage(
							{id: 'notification.notification_enabled'},
							{type: Util.prettifyNotificationType(type, this.props.intl)}
					  )
					: this.props.intl.formatMessage(
							{id: 'notification.notification_disabled'},
							{type: Util.prettifyNotificationType(type, this.props.intl)}
					  ),
			});

		params[notification] = shouldNotify;
		Util.CommitMutation(UpdateViewerMutation, params, onSuccess, true);
	}

	findPerson(id) {
		return this.props.viewer.company.allPersons.edges.find(p => p.node.id === id);
	}

	populateNotificationData(notifications) {
		const tmpNotifications = cloneDeep(notifications);
		if (this.props.viewer.company.allPersons) {
			tmpNotifications.forEach(notification => {
				notification.publisher = this.findPerson(notification.publisherPersonId);
			});
		}
		return tmpNotifications;
	}

	render() {
		const {formatMessage} = this.props.intl;
		let teammemberFilterOptions = [];
		let actionFilterOptions = [];
		let readStatusFilterOptions = [
			{value: 'read', label: formatMessage({id: 'notifications.read'})},
			{value: 'unread', label: formatMessage({id: 'notifications.unread'})},
		];
		let projectFilterOptions = [];
		const allPersons = this.props.viewer.company.allPersons.edges;
		const actions = Util.getAllNotificationOptions();
		const projects = this.props.viewer.projects.edges;
		const projectNodes = projects.map(project => project.node);
		allPersons.map(p => {
			return teammemberFilterOptions.push({
				value: p.node.id,
				label: p.node.fullName,
			});
		});
		teammemberFilterOptions.sort((a, b) => {
			const nameA = a.label.toLowerCase();
			const nameB = b.label.toLowerCase();
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
			return 0;
		});
		actions.map(action => {
			return actionFilterOptions.push({
				value: Util.getNotificationFlagFromType(action),
				label: Util.upperCaseFirst(Util.prettifyNotificationType(action, this.props.intl)),
			});
		});
		projects.map(p => {
			return projectFilterOptions.push({
				value: p.node.companyProjectId,
				label: p.node.name,
			});
		});

		const filters = [
			{
				options: teammemberFilterOptions,
				onChange: this.handleFilterChange.bind(this, 'teammember'),
				value: this.state.teammemberFilterValue,
				placeholder: formatMessage({id: 'common.filter-by-persons'}),
				label: formatMessage({id: 'common.persons'}),
			},
			{
				options: actionFilterOptions,
				onChange: this.handleFilterChange.bind(this, 'action'),
				value: this.state.actionFilterValue,
				placeholder: formatMessage({id: 'common.filter_by_action'}),
				label: formatMessage({id: 'common.notification_actions'}),
			},
			{
				options: readStatusFilterOptions,
				onChange: this.handleFilterChange.bind(this, 'readStatus'),
				value: this.state.readStatusFilterValue,
				placeholder: formatMessage({id: 'notifications.filter_read'}),
				label: formatMessage({id: 'notifications.read_unread'}),
			},
			{
				options: projectFilterOptions,
				onChange: this.handleFilterChange.bind(this, 'project'),
				value: this.state.projectFilterValue,
				placeholder: formatMessage({id: 'common.filter-by-project'}),
				label: formatMessage({id: 'common.projects'}),
			},
		];
		const notifications = this.populateNotificationData(this.props.viewer.recentNotifications);
		return (
			<div className="settings-all-notifications" data-cy={'notifications-page'}>
				{this.props.children}
				<TopHeaderBarWrapper>
					<TopHeaderBar title="My Notifications" />
				</TopHeaderBarWrapper>
				<NotificationContainer
					showAll={true}
					viewer={this.props.viewer}
					projects={projectNodes}
					notifications={notifications}
					disableNotificationType={this.disableNotificationType.bind(this)}
					onBlur={this.handleBlur.bind(this)}
					onKeyDown={this.handleKeyPress.bind(this)}
					fullscreenView={true}
					filters={filters}
					teammemberFilterValue={this.state.teammemberFilterValue}
					actionFilterValue={this.state.actionFilterValue}
					readStatusFilterValue={this.state.readStatusFilterValue}
					projectFilterValue={this.state.projectFilterValue}
				/>
			</div>
		);
	}
}

const MyNotificationsQuery = graphql`
	query MyNotifications_Query {
		viewer {
			actualPersonId
			component(name: "profile_all_notifications")
			...MyNotifications_viewer
		}
	}
`;

export {MyNotificationsQuery};

export default injectIntl(
	createFragmentContainer(MyNotifications, {
		viewer: graphql`
			fragment MyNotifications_viewer on Viewer {
				id
				availableFeatureFlags {
					key
				}
				unseenNotifications
				profilePictureId
				profilePictureDefaultId
				recentNotifications {
					id
					countId
					publisherPersonId
					subscriberPersonId
					publisherAction
					entityType
					entityId
					params
					createdAt
					read
				}
				notifyInAppOnPersonJoin
				notifyInAppOnProjectStatusChange
				notifyInAppOnAssignedProject
				notifyInAppOnProjectDeadline
				notifyInAppOnAssignedTask
				notifyInAppOnAssignedTaskUpdate
				notifyInAppOnTaskDeadline
				notifyInAppOnMention
				notifyInAppOnTaskDescriptionChange
				notifyInAppOnTaskTitleChange
				notifyInAppOnTaskEstimateChange
				notifyInAppOnTaskStartDateChange
				notifyInAppOnTaskEndDateChange
				notifyInAppOnTaskSprintChange
				notifyInAppOnTaskPhaseChange
				notifyInAppOnTaskStatusColumnChange
				notifyInAppOnTaskProjectChange
				notifyInAppOnTaskSubtaskChange
				notifyInAppOnTaskSubtaskEstimateChange
				notifyInAppOnTaskCommentChange
				notifyInAppOnTaskFileChange
				notifyInAppOnTaskBugChange
				notifyInAppOnTaskBlockedChange
				notifyInAppOnTaskRepeatingChange
				notifyInAppOnTimeOffManager
				notifyInAppOnTimeOffOwner
				notifyInAppOnInvoiceDueDate
				notifyInAppOnInvoiceOverdue
				notifyInAppOnInvoiceDaysOverdue
				notifyInAppOnInvoiceCreatedOrDeleted
				notifyInAppOnInvoiceStatusChange
				notifyInAppOnInvoiceDateReached
				notifyInAppOnInvoicePayment
				notifyInAppOnInvoiceDateChanged
				notifyInAppOnInvoiceDueDateChanged
				projects(first: 10000) {
					edges {
						node {
							id
							name
							companyProjectId
							...DeprecatedProjectIndicatorJS_project
						}
					}
				}
				company {
					allPersons(first: 48714, includeSystem: true) {
						edges {
							node {
								id
								fullName
								active
								profilePictureId
								profilePictureDefaultId
								initials
							}
						}
					}
				}
			}
		`,
	})
);
