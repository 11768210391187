import React, {useEffect, useMemo, useState} from 'react';
import {createRefetchContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import Moment from 'moment';
import ForecastQueryRenderer from '../../../ForecastQueryRenderer';
import {ELEMENT_TYPE, FILTER_SECTION, FILTER_TYPE} from '../../../constants';
import {
	HeaderBarStyled,
	MyWorkContainer,
	MyWorkPageWrapper,
	MyWorkStatsSectionWrapper,
	MyWorkTasksSectionWrapper,
	MyWorkWrapper,
} from './MyWork.styled';
import HeaderBar from '../../shared/components/headers/header-bar/header_bar';
import MyWorkStatsSection, {MyWorkStatsSectionQuery} from './stats-section/MyWorkStatsSection';
import MyWorkTasksSectionV2, {MyWorkTasksSectionQuery} from './task-section/MyWorkTasksSection';
import MyWorkFavouredTasksSection from './favoured-section/MyWorkFavouredTasksSection';
import InlineLoader from '../../shared/components/inline-loader/inline_loader';
import {
	getMyWorkDoneTasksSearchQuery,
	getMyWorkNoDatesTasksSearchQuery,
	getMyWorkStatsSearchQuery,
	getMyWorkTasksSearchQuery,
} from './MyWorkQueryUtils';
import * as tracking from '../../../tracking';
import {fetchData} from '../../../components/canvas-scheduling/modals/standalone_utilization_modal_fetch';
import {handlePersonSelected} from './MyWorkPersonData';
import Util from '../../shared/util/util';
import usePrevious from '../../shared/hooks/usePrevious';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../shared/components/headers/top-header-bar/TopHeaderBar';
import {getInitialOptions, handleChangedOptions} from '../../shared/util/the-eye/task_section_they_eye_util';
import {FILTER_SECTIONS} from '../../shared/components/filters/FilterWrapper';
import {getFiltersAlphabetically} from '../../shared/components/filters/filter_util';
import {getFilterFunctions} from '../../shared/components/filters/filter_logic';
import {hasPermission, isClientUser} from '../../shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import {useTrackPage} from '../../../tracking/amplitude/hooks/useTrackPage';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import {hasFeatureFlag} from '../../shared/util/FeatureUtil';

const MyWorkPage = ({intl, viewer, relay, history}) => {
	const localFilters = Util.localStorageGetItem('my-work-filters')
		? JSON.parse(Util.localStorageGetItem('my-work-filters'))
		: null;

	const [selectedPersonId, setSelectedPersonId] = useState(viewer.actualPersonId);
	const [isInThisWeek, setIsInThisWeek] = useState(true);
	const [selectedDate, setSelectedDate] = useState(Moment());
	const [allocationData, setData] = useState({loading: true});
	const [isStaredSectionCollapsed, setIsStaredSectionCollapsed] = useState(
		localStorage.getItem('mywork-favoured-is-collapsed') === 'true' || viewer.favouredTasksCount === 0
	);
	const [searchFilterValue, setSearchFilterValue] = useState('');
	const [theEyeOptions, setTheEyeOptions] = useState(getInitialOptions());
	const [filterFunctions, setFilterFunctions] = useState(localFilters ? getFilterFunctions(localFilters) : () => true);

	const previousFavoredTaskCount = usePrevious(viewer.favouredTasksCount);

	useTrackPage('My Work', {starredSectionExpanded: !isStaredSectionCollapsed});

	useEffect(() => {
		document.title = 'My Work - Forecast';
		tracking.trackPage('My-work');
	}, []);

	useEffect(() => {
		localStorage.setItem('mywork-favoured-is-collapsed', isStaredSectionCollapsed ? 'true' : 'false');
	}, [isStaredSectionCollapsed]);

	useEffect(() => {
		if (previousFavoredTaskCount === 0 && viewer.favouredTasksCount > 0) {
			setIsStaredSectionCollapsed(false);
		}
	}, [viewer.favouredTasksCount]);

	const handleArrowClick = () => {
		setIsStaredSectionCollapsed(!isStaredSectionCollapsed);
		tracking.trackPageAction('Starred section expanded', {expanded: isStaredSectionCollapsed});
		trackEvent('Starred Section', 'Expanded', {expanded: isStaredSectionCollapsed});
	};

	const showPersonDropDown = hasPermission(PERMISSION_TYPE.TIME_REGISTRATION_CREATE_ALL); // Could be split into its own permission, tied to time registrations for others for now

	const startOfWeekDate = useMemo(() => selectedDate.clone().startOf('week').startOf('day'), [selectedDate]);
	const startOfWeekDateFormatted = selectedDate.clone().startOf('week').startOf('day').format('YYYY-MM-DD');

	const endOfWeekDate = useMemo(() => selectedDate.clone().endOf('week').endOf('day'), [selectedDate]);
	const endOfWeekDateFormatted = selectedDate.clone().endOf('week').endOf('day').format('YYYY-MM-DD');

	const isInPast = useMemo(() => endOfWeekDate.isBefore(Moment()), [selectedDate]);

	useEffect(() => {
		if (isClientUser()) {
			return;
		}
		fetchData(startOfWeekDate, endOfWeekDate, selectedPersonId).then(allocationData => {
			setData(allocationData);
		});
		setIsInThisWeek(startOfWeekDate.week() === Moment().week());
	}, [selectedPersonId, startOfWeekDate, endOfWeekDate]);

	const handlePersonChange = person => {
		tracking.trackPageAction('Person Changed');
		trackEvent('Person', 'Changed');
		setSelectedPersonId(handlePersonSelected(person, relay.refetch));
	};

	const changetDate = val => {
		let selectedDateCpy = selectedDate.clone();
		if (val === true) {
			setSelectedDate(selectedDateCpy.add(1, 'w'));
		} else if (val === false) {
			setSelectedDate(selectedDateCpy.add(-1, 'w'));
		} else {
			setSelectedDate(val);
		}
	};

	const goToThisWeek = () => {
		setSelectedDate(Moment().startOf('week'));
	};

	const handleTheEyeOptionSelect = (_, __, ___, newOptions) => {
		setTheEyeOptions(handleChangedOptions(newOptions));
	};

	const onFilterChange = (filters, filterFunctions) => {
		Util.localStorageSetItem(`my-work-filters`, JSON.stringify(filters));
		setFilterFunctions(filterFunctions);
	};

	const getHeader = () => {
		const leftContent = [];
		const rightContent = [];
		if (showPersonDropDown) {
			const personDropdown = {
				type: ELEMENT_TYPE.DROPDOWN_PERSON,
				customWidth: 172,
				selectedPerson: selectedPersonId,
				callback: handlePersonChange,
				userpilot: 'my-work-select-person',
			};
			leftContent.push(personDropdown);
		}

		const dateChanger = {
			type: ELEMENT_TYPE.DATE_CHANGER_V2,
			useThisWeekButton: true,
			timePeriod: 'week',
			id: 'time-date-select',
			currentViewingDate: selectedDate,
			handleThisWeekButtonClick: goToThisWeek,
			handleAdjacentDateButtonClick: val => changetDate(val),
			updateDateRange: val => changetDate(val),
			boldLongDayMonth: true,
			selectorTooltipEnabled: true,
			//startDateLimite: Moment().startOf('week'),
			userpilot: 'week-selector',
			selectorTooltipProps: {
				autoPlace: true,
				grey: true,
				infoText: intl.formatMessage({id: 'common.select_date'}),
			},
			tooltipEnabled: true,
		};

		leftContent.push(dateChanger);

		rightContent.push({
			type: ELEMENT_TYPE.SEARCH_LAZY,
			value: searchFilterValue,
			onChange: search => {
				setSearchFilterValue(search);
			},
		});

		const taskFilters = [
			FILTER_TYPE.PROJECT,
			FILTER_TYPE.STATUS_CATEGORY,
			FILTER_TYPE.DEADLINE,
			FILTER_TYPE.DEPENDENCIES,
			FILTER_TYPE.FOLLOWER,
			FILTER_TYPE.INDICATOR_FILTERED,
			FILTER_TYPE.LABEL,
			FILTER_TYPE.PHASE_CATEGORY,
			FILTER_TYPE.OWNER,
			FILTER_TYPE.RECENT_ACTIVITY,
			FILTER_TYPE.SPRINT_CATEGORY,
			FILTER_TYPE.PROJECT_STAGE,
			FILTER_TYPE.SUB_TASKS,
		];
		if (!isClientUser()) {
			taskFilters.push(FILTER_TYPE.TEAM);
			taskFilters.push(FILTER_TYPE.CLIENT);
		}
		const projectFilters = [];
		const peopleFilters = [];
		rightContent.push({
			type: ELEMENT_TYPE.FILTER_V4,
			defaultSection: FILTER_SECTIONS.TASKS,
			projectFilters,
			peopleFilters,
			taskFilters: getFiltersAlphabetically(taskFilters, intl.formatMessage),
			primaryFilters: {
				[FILTER_SECTIONS.TASKS]: [FILTER_TYPE.CLIENT, FILTER_TYPE.DEADLINE, FILTER_TYPE.PROJECT],
			},
			viewer,
			filterSection: FILTER_SECTION.MY_WORK,
			appliedFiltersName: 'my-work-filters',
			onFiltersChange: onFilterChange,
		});

		const onboardingFlows = [
			{
				id: 'my-work-introduction',
				title: 'Introduction to this page',
				description: null,
				contentId: '1681984560wBlt0609',
			},
			{
				id: 'my-work-register-time-update-status',
				title: 'How to register time quickly and update statuses',
				description: null,
				contentId: '1681988458uIes8303',
			},
		];

		const onboardingComponent = {
			id: 'onboarding-component',
			intl: intl,
			type: ELEMENT_TYPE.ONBOARDING_POPUP,
			title: intl.formatMessage({id: 'onboarding.my_task_onboarding_title'}),
			options: onboardingFlows,
			helpCenterLink: 'https://support.forecast.app/hc/en-us/sections/4419414222865-Tasks',
			subLink: 'https://support.forecast.app/hc/en-us/articles/5112201831569',
		};
		rightContent.push(onboardingComponent);

		rightContent.push({
			type: ELEMENT_TYPE.THE_EYE,
			options: theEyeOptions,
			expandLeft: true,
			onSelect: handleTheEyeOptionSelect,
		});

		return <HeaderBar leftContent={leftContent} rightContent={rightContent} />;
	};

	const getHeaderTitle = () => {
		return (
			<TopHeaderBarWrapper>
				<TopHeaderBar title={intl.formatMessage({id: 'header.my_tasks'})} />
			</TopHeaderBarWrapper>
		);
	};

	const statsSearchQuery = getMyWorkStatsSearchQuery(selectedPersonId, isInThisWeek);
	const tasksSearchQuery = getMyWorkTasksSearchQuery(selectedPersonId, startOfWeekDateFormatted, endOfWeekDateFormatted);
	const noDatesSearchQuery = getMyWorkNoDatesTasksSearchQuery(selectedPersonId, endOfWeekDateFormatted);
	const doneTasksSearchQuery = getMyWorkDoneTasksSearchQuery(
		selectedPersonId,
		startOfWeekDateFormatted,
		endOfWeekDateFormatted
	);
	const hasSprint = false;
	return (
		<MyWorkPageWrapper data-cy={'my-work-page'} overflowHidden>
			<MyWorkContainer>
				{getHeaderTitle()}
				<HeaderBarStyled>{getHeader()}</HeaderBarStyled>
				<MyWorkWrapper overflowHidden>
					<MyWorkStatsSectionWrapper data-userpilot={'stats-section-wrapper'}>
						<ForecastQueryRenderer
							key="query-render-myWorkStatsSectionQ"
							query={MyWorkStatsSectionQuery}
							customLoader={() => <InlineLoader />}
							variables={{
								personId: selectedPersonId,
								searchQuery: statsSearchQuery,
								startDateString: startOfWeekDateFormatted,
								endDateString: endOfWeekDateFormatted,
							}}
							render={(relayProps, retry) => {
								return (
									<MyWorkStatsSection
										{...relayProps}
										retry={retry}
										intl={intl}
										selectedPersonId={selectedPersonId}
										startDate={startOfWeekDate}
										endDate={endOfWeekDate}
										allocationData={allocationData}
									/>
								);
							}}
						/>
					</MyWorkStatsSectionWrapper>
					<MyWorkTasksSectionWrapper data-userpilot={'tasks-section-wrapper'} overflowHidden>
						{!hasFeatureFlag('marigold_suppress_assignees') && (
							<ForecastQueryRenderer
								key="query-render-myWorkTasksSection"
								query={MyWorkTasksSectionQuery}
								customLoader={() => <InlineLoader />}
								variables={{
									isClientUser: isClientUser(),
									personId: selectedPersonId,
									searchQuery: tasksSearchQuery,
									noDatesSearchQuery: noDatesSearchQuery,
									doneTasksSearchQuery: doneTasksSearchQuery,
								}}
								render={(relayProps, retry) => {
									return (
										<MyWorkTasksSectionV2
											{...relayProps}
											history={history}
											retry={retry}
											intl={intl}
											isInPast={isInPast}
											selectedPersonId={selectedPersonId}
											showThisWeek={isInThisWeek}
											theEyeOptions={theEyeOptions}
											searchFilterValue={searchFilterValue}
											hasSprint={hasSprint}
											isStaredSectionCollapsed={isStaredSectionCollapsed} //needed only for the resizing of the section headers
											filterFunctions={filterFunctions}
											localFilters={localFilters}
										/>
									);
								}}
							/>
						)}
					</MyWorkTasksSectionWrapper>
					{selectedPersonId === viewer.actualPersonId && (
						<MyWorkFavouredTasksSection
							selectedPersonId={selectedPersonId}
							handleArrowClick={() => handleArrowClick()}
							isCollapsed={isStaredSectionCollapsed}
							intl={intl}
						/>
					)}
				</MyWorkWrapper>
			</MyWorkContainer>
		</MyWorkPageWrapper>
	);
};

// RELAY SECTION

const MyWorkPageQuery = graphql`
	query MyWorkPage_Query($personId: ID) {
		viewer {
			actualPersonId
			component(name: "my_work")
			...MyWorkPage_viewer @arguments(personId: $personId)
		}
	}
`;

export {MyWorkPageQuery};

export default withRouter(
	injectIntl(
		createRefetchContainer(
			MyWorkPage,
			{
				viewer: graphql`
					fragment MyWorkPage_viewer on Viewer @argumentDefinitions(personId: {type: "ID"}) {
						id
						actualPersonId
						favouredTasksCount
						email
						availableFeatureFlags {
							key
						}
						company {
							harvestEnabled
						}
						#end sprint section
						filters(first: 1000000, filterSection: MY_WORK) @connection(key: "Viewer_filters", filters: []) {
							edges {
								node {
									id
									name
									section
									value
									updatedAt
								}
							}
						}
					}
				`,
			},
			graphql`
				query MyWorkPageRefetchQuery($personId: ID) {
					viewer {
						component(name: "my_work")
						...MyWorkPage_viewer @arguments(personId: $personId)
					}
				}
			`
		)
	)
);
