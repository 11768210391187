import React from 'react';
import {
	DeprecatedContentContainer as ContentContainer,
	FlexColumn,
	FlexItem,
	FlexRow,
	DeprecatedText as Text,
	deprecatedTheme as theme,
} from '@forecast-it/design-system';
import Styled from 'styled-components/macro';
import {Checkbox, CurrencyInput, InputLabel, PercentageInput} from 'web-components';
import Util from '../../../forecast-app/shared/util/util';
import {useIntl} from 'react-intl';
import RadioButton from '../../../components/new-ui/radio_button';
import {useProjectProgramFinancials} from '../../../forecast-app/project-tab/programs/hooks/useProjectProgramFinancials';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import {ProgramBudgetInfo} from '../../../forecast-app/project-tab/programs/ProgramBudgetInfo';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';

const SectionWrapper = Styled.div`
	margin-top: 14px;
`;

const TopMargin = Styled.div`
	margin-top: 3px;
`;

const FieldLabel = Styled.div`
    font-size: 9px;
    font-weight: 400;
    line-height: 1.22;
    -webkit-letter-spacing: 1px;
    -moz-letter-spacing: 1px;
    -ms-letter-spacing: 1px;
    letter-spacing: 1px;
    color: #a1a1a1;
    text-transform: uppercase;
    position: relative;
    top: 8px;
`;

export const BillableSection = ({
	isCreate,
	billable,
	setBillable,
	partOfFixedPrice,
	setPartOfFixedPrice,
	planned,
	updatePlanned,
	approved,
	updateApproved,
	price,
	updatePrice,
	quantity,
	initialExpenseAmount,
	markupPercentage,
	updateMarkupPercentage,
	handleBudgetOverflowError,
	budgetOverflowError,
	handleShowLockedRevenueError,
	showLockedRevenueError,
	currencySymbol,
	isSelectedProjectBillable,
	isSelectedProjectFixedPrice,
	isSelectedProjectHaltedOrDone,
	locked,
	setLocked,
	actualPersonId,
	companyProjectId,
	setDisableSave,
	initialApprovedState,
	setInitialApproved,
	initialOnTopOfFixedPrice,
}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const setBillableOnTopOfFixedPrice = billable => {
		setBillable(billable);
		if (hasFeatureFlag('Expense_Modal_Update_23Q1')) {
			setPartOfFixedPrice(false);
		}
	};

	const setBillablePartOfFixedPrice = () => {
		if (hasFeatureFlag('Expense_Modal_Update_23Q1')) {
			if (showLockedRevenueError) {
				handleShowLockedRevenueError(false);
			}
			setBillable(true);
			setPartOfFixedPrice(true);
		}
	};
	const totalExpenseAmount = Math.round(price * quantity * 100) / 100;
	let expenseAmountDifference = totalExpenseAmount;

	if (!isCreate) {
		expenseAmountDifference = totalExpenseAmount - initialExpenseAmount;
	}

	const {getProgramBudgetInfo, getExpenseProgramLogic} = useProjectProgramFinancials(companyProjectId, isReady => {
		setDisableSave(!isReady);
	});
	const {programBudget, programAllocation, isInFixedPriceProgram, canManageProgram} = getProgramBudgetInfo(actualPersonId);

	let expenseLogic = getExpenseProgramLogic(
		actualPersonId,
		approved,
		billable,
		isCreate,
		expenseAmountDifference,
		partOfFixedPrice,
		initialOnTopOfFixedPrice
	);

	// some compromises here: we are updating the state in the parent component based on logic in this child component
	// this is to avoid refactoring the entire expense modal right now - when parent is a functional component the logic
	// should be moved there.
	if (!expenseLogic.canSetBillable() && billable && expenseLogic.canEdit()) {
		setBillableOnTopOfFixedPrice(false);
	}

	if (!expenseLogic.canApprove() && isCreate && approved) {
		updateApproved({target: {checked: false}});
		expenseLogic = getExpenseProgramLogic(
			actualPersonId,
			false,
			billable,
			isCreate,
			expenseAmountDifference,
			partOfFixedPrice,
			initialOnTopOfFixedPrice
		);
	}

	if (!expenseLogic.canEdit() && !locked) {
		setLocked(true);
	}

	if (
		expenseLogic.cannotAddBillableExpenses() &&
		billable &&
		(!initialApprovedState || !initialOnTopOfFixedPrice) &&
		!partOfFixedPrice &&
		approved
	) {
		setInitialApproved();
	}

	if (!expenseLogic.canSetBillable()) {
		trackEvent('Expense Modal', 'Shown', {
			error: 'Fixed price program permission warning - billable expense cannot be added',
			operation: isCreate ? 'NEW' : 'EDIT',
			isOwner: canManageProgram,
		});
	}

	if (
		expenseLogic.needsToValidateAgainstProgramBudget() &&
		((expenseLogic.cannotAddBillableExpenses() &&
			expenseLogic.checkIfUnitPriceIsIncreased() &&
			approved &&
			!partOfFixedPrice) ||
			expenseLogic.checkForProgramBudgetOverflow())
	) {
		if (
			expenseLogic.cannotAddBillableExpenses() &&
			expenseLogic.checkIfUnitPriceIsIncreased() &&
			approved &&
			!showLockedRevenueError &&
			!partOfFixedPrice
		) {
			handleShowLockedRevenueError(true);
			handleBudgetOverflowError(false);
		} else if (!budgetOverflowError && !showLockedRevenueError) {
			handleBudgetOverflowError(true);

			trackEvent('Expense Billable Option', 'Selected', {
				error: isInFixedPriceProgram
					? 'Fixed price program allocation overflow'
					: 'Capped revenue program allocation overflow',
				operation: isCreate ? 'NEW' : 'EDIT',
				canManageProgram: canManageProgram,
			});
		}
	} else if (budgetOverflowError || showLockedRevenueError) {
		handleBudgetOverflowError(false);
		handleShowLockedRevenueError(false);
	}

	const ExpenseMarkupSection = () => {
		return (
			<FlexRow>
				<div className={'expense-modal-row'}>
					<div className="input-control-v2 grow">
						<InputLabel
							text={formatMessage({id: 'common.markup'})}
							child={
								<PercentageInput
									cy={'markup-input-modal'}
									disabled={isSelectedProjectHaltedOrDone || locked}
									placeholder={'-'}
									value={markupPercentage !== null ? Math.round(markupPercentage * 100) / 100 : null}
									hasError={budgetOverflowError || showLockedRevenueError}
									callback={updateMarkupPercentage}
									modalView={true}
									alignLeft={true}
									showDecimals={true}
								/>
							}
						/>
					</div>
					<div className="input-control-v2 grow">
						<InputLabel
							text={formatMessage({id: 'expense_item_modal.unit_price'})}
							required
							extension={
								price !== null && price <= 0
									? formatMessage({
											id: 'common.must_be_greater_than_zero',
									  })
									: null
							}
							activeError={price !== null && price <= 0}
							child={
								<CurrencyInput
									cy={'unit-price-input-modal'}
									disabled={isSelectedProjectHaltedOrDone || locked}
									currencySymbole={currencySymbol}
									placeholder="-"
									value={price !== null ? Math.round(price * 100) / 100 : null}
									callback={updatePrice}
									modalView={true}
									alignLeft={true}
									hasError={budgetOverflowError || showLockedRevenueError}
									intl={intl}
								/>
							}
						/>
					</div>
					<div className="input-control-v2 grow total">
						<InputLabel
							text={formatMessage({id: 'expense_item_modal.total_price'})}
							alignRight
							child={
								<div className="total-amount" data-cy={'total-price-input-modal'}>
									{Util.getFormattedCurrencyValue(currencySymbol, totalExpenseAmount, false)}
								</div>
							}
						/>
					</div>
				</div>
			</FlexRow>
		);
	};

	return (
		<SectionWrapper>
			<FlexColumn gap={'l'}>
				{isSelectedProjectBillable ? (
					<>
						<FieldLabel>{formatMessage({id: 'expense_item_modal.billing_options'})}</FieldLabel>

						{isSelectedProjectFixedPrice && hasFeatureFlag('Expense_Modal_Update_23Q1') && (
							<ContentContainer padding={'none'}>
								<div
									style={{
										width: '100%',
										padding: theme.spacing.l,
										backgroundColor:
											!expenseLogic.canSetBillable() || locked
												? theme.colors.background.disabled
												: 'white',
									}}
								>
									<FlexColumn gap={'s'}>
										<FlexRow alignItems={'start'} gap={'s'}>
											<FlexItem>
												<RadioButton
													cy={'expense-billable-part-of-fixed-price-selector'}
													stateValue={billable && partOfFixedPrice}
													value={true}
													onSelected={setBillablePartOfFixedPrice}
													locked={!expenseLogic.canSetBillable() || locked}
												/>
											</FlexItem>
											<TopMargin>
												<FlexColumn gap={'s'}>
													<Text style={'label'}>
														{formatMessage({id: 'expense_item_modal.billable_part_of_fixed_price'})}
													</Text>
													<Text type={'small'}>
														{formatMessage({
															id: 'expense_item_modal.billable_part_of_fixed_price_description',
														})}
													</Text>
												</FlexColumn>
											</TopMargin>
										</FlexRow>

										{billable && partOfFixedPrice ? (
											<div style={{paddingTop: '12px'}}>
												<ExpenseMarkupSection />
											</div>
										) : null}
									</FlexColumn>
								</div>
							</ContentContainer>
						)}

						<ContentContainer padding={'none'}>
							<div
								style={{
									width: '100%',
									padding: theme.spacing.l,
									backgroundColor:
										!expenseLogic.canSetBillable() || locked ? theme.colors.background.disabled : 'white',
								}}
							>
								<FlexColumn gap={'s'}>
									<FlexRow alignItems={'start'} gap={'s'}>
										<FlexItem>
											<RadioButton
												cy={'expense-billable-on-top-selector'}
												stateValue={billable && !partOfFixedPrice}
												value={true}
												onSelected={setBillableOnTopOfFixedPrice}
												locked={!expenseLogic.canSetBillable() || locked}
											/>
										</FlexItem>
										<TopMargin>
											<FlexColumn gap={'s'}>
												<Text style={'label'}>
													{formatMessage({
														id: isSelectedProjectFixedPrice
															? 'expense_item_modal.billable_fixed_price'
															: 'expense_item_modal.billable',
													})}
												</Text>
												<Text type={'small'}>
													{formatMessage({
														id: isSelectedProjectFixedPrice
															? 'expense_item_modal.billable_fixed_price_description'
															: 'expense_item_modal.billable_description',
													})}
												</Text>
											</FlexColumn>
										</TopMargin>
									</FlexRow>

									{billable && !partOfFixedPrice ? (
										<div style={{paddingTop: '12px'}}>
											{expenseLogic.showProgramBudgetInfo() && (
												<FlexRow>
													<ProgramBudgetInfo
														{...{
															programBudget,
															programAllocation,
															currencySymbol,
															isInFixedPriceProgram,
														}}
														width={'100%'}
													/>
												</FlexRow>
											)}
											<ExpenseMarkupSection />
										</div>
									) : null}
									{budgetOverflowError && (
										<Text color={'destructive'}>
											{formatMessage({
												id: isInFixedPriceProgram
													? 'project_budget.expense.program_fixed_price_exceeded'
													: 'project_budget.expense.program_cap_exceeded',
											})}
										</Text>
									)}
									{showLockedRevenueError && (
										<Text color={'destructive'}>
											{formatMessage({
												id: 'project_budget.expense.increased_unit_price',
											})}
										</Text>
									)}
									{billable && !partOfFixedPrice && expenseLogic.showProgramBudgetInfo() && (
										<>
											<FieldLabel style={{marginBottom: '4px'}}>
												{formatMessage({id: 'program.program_confirmed_revenue'})}
											</FieldLabel>
											<Text type={'small'}>
												{formatMessage({
													id: isInFixedPriceProgram
														? 'project_budget.expense.confirmed_revenue_exceeded.fixed_price_program'
														: 'project_budget.expense.confirmed_revenue_exceeded.capped_program',
												})}
											</Text>

											<Text color={budgetOverflowError ? 'destructive' : 'primary'}>
												{Util.getFormattedCurrencyValue(
													currencySymbol,
													Util.roundToNDecimals(programAllocation + expenseAmountDifference, 2),
													false
												)}
											</Text>
										</>
									)}
								</FlexColumn>
							</div>
						</ContentContainer>

						<ContentContainer padding={'none'}>
							<div
								style={{
									width: '100%',
									padding: theme.spacing.l,
									backgroundColor: locked ? theme.colors.background.disabled : 'white',
								}}
							>
								<FlexRow alignItems={'start'} gap={'s'}>
									<FlexItem>
										<RadioButton
											cy={'expense-non-billable-selector'}
											stateValue={billable}
											value={false}
											locked={!expenseLogic.canSetBillable() || locked}
											onSelected={setBillableOnTopOfFixedPrice}
										/>
									</FlexItem>
									<TopMargin>
										<FlexColumn gap={'s'}>
											<Text tyle={'label'}>
												{formatMessage({
													id:
														isSelectedProjectFixedPrice &&
														!hasFeatureFlag('Expense_Modal_Update_23Q1')
															? 'expense_item_modal.non_billable_fixed_price'
															: 'expense_item_modal.non_billable',
												})}
											</Text>
											<Text type={'small'}>
												{formatMessage({
													id:
														isSelectedProjectFixedPrice &&
														!hasFeatureFlag('Expense_Modal_Update_23Q1')
															? 'expense_item_modal.non_billable_fixed_price_description'
															: 'expense_item_modal.non_billable_description',
												})}
											</Text>
										</FlexColumn>
									</TopMargin>
								</FlexRow>
							</div>
						</ContentContainer>
					</>
				) : (
					<></>
				)}
				<FieldLabel>{formatMessage({id: 'expense_item_modal.other_expense_options'})}</FieldLabel>
				<FlexRow gap={'s'}>
					<Checkbox checked={planned} disabled={locked} onChange={updatePlanned} cy={'expense-planned-checkbox'} />
					<Text>{formatMessage({id: 'expenses.this_is_planned'})}</Text>
				</FlexRow>
				<FlexRow gap={'s'}>
					<Checkbox
						checked={approved}
						disabled={
							locked ||
							!expenseLogic.canApprove() ||
							(expenseLogic.cannotAddBillableExpenses() &&
								!approved &&
								billable &&
								!partOfFixedPrice &&
								(!initialApprovedState || !initialOnTopOfFixedPrice))
						}
						onChange={updateApproved}
						cy={'expense-approved-checkbox'}
					/>
					<Text>{formatMessage({id: 'expenses.approved'})}</Text>
				</FlexRow>{' '}
				{expenseLogic.cannotAddBillableExpenses() &&
					!approved &&
					billable &&
					!partOfFixedPrice &&
					(!initialApprovedState || !initialOnTopOfFixedPrice) && (
						<Text type={'small'} color={'destructive'}>
							{formatMessage({
								id: 'project_budget.expense.cannot_approve_billable_expenses_description',
							})}
						</Text>
					)}
			</FlexColumn>
		</SectionWrapper>
	);
};
