import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import DatePicker from '../../../shared/components/date-picker/date_picker_v3';
import Util from '../../../shared/util/util';
import UpdateSprintMutation from '../../../../mutations/update_sprint_mutation';
import DeleteSprintMutation from '../../../../mutations/delete_sprint_mutation';
import {BUTTON_COLOR, BUTTON_STYLE, DATE_PICKER_STYLE, PROJECT_STATUS, WorkflowCategories} from '../../../../constants';
import {injectIntl} from 'react-intl';
import {createToast} from '../../../shared/components/toasts/another-toast/toaster';
import ActionsMenu from '../../../shared/components/action-menu/actions_menu';
import {MODAL_TYPE, showModal} from '../../../shared/components/modals/generic_modal_conductor';
import ContextMenu from '../../../shared/components/context-menus/context_menu';
import TooltipIcon from '../../../shared/components/tooltips/tooltip_icon';
import TooltipContainer from '../../../shared/components/tooltips/tooltip_container';
import ProjectSprintsHeaderTile from './ProjectSprintsHeaderTile';
import Button from '../../../shared/components/buttons/button/button';
import {fetchData} from '../../../../components/canvas-scheduling/modals/standalone_utilization_modal_fetch';
import {AdoIcon} from 'web-components';
import {PERMISSION_TYPE} from '../../../../Permissions';
import {hasPermission} from '../../../shared/util/PermissionsUtil';
import {hasFeatureFlag} from '../../../shared/util/FeatureUtil';
import {SprintNotesFoldout} from '../../../shared/components/popups/SprintNotesFoldout';
import {isDateDisabled} from '../../../shared/util/DateUtil';
import {toggleSprintGoalsSlideout} from './slideouts/sprint-goals/SprintGoalSlideoutWrapper';
import {Bullseye} from '../../../../images/svg-collection/Bullseye';

class ProjectSprintsSprintHeader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			inputSprintTitle: props.sprint.name || '',
			showUnfinishedWorkModal: false,
			selectedRadioButton: null,
			data: null,
		};
		this.emptyCols = [];
		this.sprintGoalRef = React.createRef();
	}

	componentDidMount() {
		this.updateWhiteSpace();
		const endOfWeekDate = Util.CreateNonUtcMomentDate(
			this.props.sprint.endYear,
			this.props.sprint.endMonth,
			this.props.sprint.endDay
		);
		const startOfWeekDate = Util.CreateNonUtcMomentDate(
			this.props.sprint.startYear,
			this.props.sprint.startMonth,
			this.props.sprint.startDay
		);
		if (startOfWeekDate && endOfWeekDate && !this.props.useManualAllocations) {
			// Only fetch if less than 5 sprints visible
			if (this.props.sprintIndex < 4) {
				if (this.props.viewer.company.backendId !== 8622 && this.props.viewer.company.backendId !== 13436) {
					fetchData(startOfWeekDate, endOfWeekDate, this.props.viewer.actualPersonId).then(allocationData => {
						this.setState({data: allocationData});
					});
				}
			}
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.state.inputSprintTitle !== nextProps.sprint.name) {
			this.setState({inputSprintTitle: nextProps.sprint.name});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		this.updateWhiteSpace();
	}

	updateWhiteSpace() {
		if (this.nameContainer && this.nameInput) {
			const nameWhiteSpace = this.nameContainer.clientWidth - this.nameInput.clientWidth;
			const dateWhiteSpace = this.emptyCols.reduce((acc, col) => col.minWidth + acc, 0);
			if (this.state.nameWhiteSpace !== nameWhiteSpace || this.state.dateWhiteSpace !== dateWhiteSpace) {
				this.setState({nameWhiteSpace: nameWhiteSpace, dateWhiteSpace: dateWhiteSpace});
			}
		}
	}

	handleSprintTitleChange(e) {
		this.setState({
			inputSprintTitle: e.target.value,
		});
	}

	handleSaveSprintTitle() {
		const mutationObject = {
			id: this.props.sprint.id,
			name:
				this.state.inputSprintTitle === ''
					? this.props.intl.formatMessage({id: 'project_sprints.new-sprint'})
					: this.state.inputSprintTitle,
		};
		if (this.props.isConnectedParent) {
			mutationObject.projectGroupId = this.props.viewer.projectGroup.id;
		} else {
			mutationObject.projectId = this.props.projectId;
		}
		Util.CommitMutation(UpdateSprintMutation, mutationObject);
	}

	handleSaveSprintDescription(description) {
		if (description !== this.props.sprint.description) {
			const mutationObject = {
				id: this.props.sprint.id,
				description: description || '',
			};

			if (this.props.isConnectedParent) {
				mutationObject.projectGroupId = this.props.viewer.projectGroup.id;
			} else {
				mutationObject.projectId = this.props.projectId;
			}

			Util.CommitMutation(UpdateSprintMutation, mutationObject);
		}
	}

	handleKeyPressName(e) {
		if (e.key === 'Enter') {
			this.nameInput.blur();
		}
	}

	handleExpandClick(e) {
		e.stopPropagation();
		e.preventDefault();
		const newTarget = e.relatedTarget || document.activeElement;
		if (
			(newTarget &&
				newTarget.className &&
				(newTarget.className.toLowerCase().includes('calendar') ||
					newTarget.className.toLowerCase().includes('foldout') ||
					newTarget.className.toLowerCase().includes('-control'))) ||
			newTarget.tagName === 'INPUT' ||
			newTarget.className.toLowerCase().includes('clear-dates')
		) {
			return;
		}
		this.props.onExpansionChange(this.props.sprint.id);
	}
	onContextMenu(e) {
		e.preventDefault();
		const el = e.target;
		const contextMenuPosition = {};

		contextMenuPosition.x = e.pageX;
		//check if there is place for menu underneath cursor
		if (window.innerHeight - e.pageY < 220) {
			contextMenuPosition.y = e.pageY - 150;
		} else {
			contextMenuPosition.y = e.pageY;
		}
		this.setState({
			showContextMenu: true,
			contextMenuElement: el,
			contextMenuPosition,
		});
	}

	onSprintGoalsClick(slideoutId, e) {
		e.preventDefault();
		e.stopPropagation();
		toggleSprintGoalsSlideout({
			slideoutId,
			projectGroupSprintId: this.props.isConnectedParent ? this.props.sprint.projectGroupSprintId : null,
			sprintId: this.props.isConnectedParent ? null : this.props.sprint.id,
			projectColor: this.props.projectColor,
			invokerRef: this.sprintGoalRef,
		});
	}

	closeContextMenu() {
		this.setState({
			showContextMenu: false,
			contextMenuElement: null,
			contextMenuPosition: null,
		});
	}

	handleDateRangeChange(startDate, endDate) {
		const mutationObject = {
			id: this.props.sprint ? this.props.sprint.id : null,
			startYear: startDate.year(),
			startMonth: startDate.month() + 1,
			startDay: startDate.date(),
			endYear: endDate.year(),
			endMonth: endDate.month() + 1,
			endDay: endDate.date(),
		};
		if (this.props.isConnectedParent) {
			mutationObject.projectGroupId = this.props.viewer.projectGroup.id;
		} else {
			mutationObject.projectId = this.props.projectId;
		}
		Util.CommitMutation(UpdateSprintMutation, mutationObject);
	}

	getActionMenuOptions() {
		const {formatMessage} = this.props.intl;

		return this.props.emptyPhase
			? [
					{
						text: formatMessage({id: 'common.move-to-left-side'}),
						onClick: this.props.moveBacklog.bind(this),
						callback: this.props.moveBacklog.bind(this),
					},
			  ]
			: [
					{
						text: formatMessage({id: 'common.delete'}),
						onClick: this.showModalDeletePhase.bind(this),
						callback: this.showModalDeletePhase.bind(this),
						cy: 'delete-btn',
					},
			  ];
	}

	closeSprint() {
		if (this.notDoneTasksUnfiltered.length > 0) {
			this.toggleUnfinishedWorkModal();
		} else {
			this.props.calculatePerformance();
		}
	}

	showModalDeletePhase() {
		const {formatMessage} = this.props.intl;

		const onSuccess = () => {
			createToast({duration: 5000, message: formatMessage({id: 'sprint.has-been-deleted'})});
			if (this.props.onSprintDelete) {
				this.props.onSprintDelete(this.props.sprint.id);
			}
		};
		const callbackPositive = params => {
			Util.CommitMutation(DeleteSprintMutation, {id: this.props.sprint.id, projectId: this.props.projectId}, onSuccess);
		};

		showModal({
			type: MODAL_TYPE.WARNING,
			warningMessageId: 'project_sprints.delete-confirmation-title',
			warningInformation: [
				formatMessage({id: 'project_sprints.delete-confirmation-info'}),
				formatMessage({id: 'common.warning.this_action_can_not_be_undone'}),
			],
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.delete'}),
					callback: callbackPositive,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
					cy: 'modal-btn-confirm-delete',
				},
			],
		});
	}

	toggleUnfinishedWorkModal() {
		let doneColumns = [];
		const columns = this.props.viewer.project
			? this.props.viewer.project.statusColumnsV2.edges
			: this.props.viewer.projectGroup.projects.edges[0].node.statusColumnsV2.edges;
		doneColumns = columns
			.filter(col => col.node.category === WorkflowCategories.DONE)
			.sort((a, b) => a.node.order - b.node.order)
			.map(col => ({
				value: col.node.projectGroupStatusColumnId || col.node.id,
				label: col.node.name,
				logo: col.node.jiraStatusId ? 'jira-logo' : undefined,
			}));
		showModal({
			type: MODAL_TYPE.CLOSE_SPRINT,
			tasksList: this.notDoneTasksUnfiltered,
			doneColumns: doneColumns,
			callback: this.props.handleUnfinishedWork.bind(this, this.props.sprint ? this.props.sprint.id : null),
		});
	}

	getIntegrationIcons(sprint) {
		const integrationIcons = [];
		if (sprint?.integrations?.jiraId) {
		}
		if (sprint?.integrations?.adoId) {
			integrationIcons.push(<AdoIcon size={AdoIcon.SIZE.MEDIUM} />);
		}
		if (integrationIcons.length) {
			return <div style={{marginRight: '8px'}}>{integrationIcons}</div>;
		}
		return null;
	}

	render() {
		const {formatMessage, formatNumber} = this.props.intl;
		const sprint = this.props.sprint;
		const isEstimatedInHours = this.props.isEstimatedInHours;
		const canUpdateSprint = hasPermission(PERMISSION_TYPE.SPRINT_UPDATE);

		const empty = this.props.sprint.id == null;
		const today = Moment().startOf('day');
		const forecastUnfiltered = sprint.forecastUnfiltered;
		const remainingUnfiltered = sprint.totalRemainingUnfiltered;

		const slideoutId = `sprint-goals-${sprint.id}`;

		let totalMinutesAvailable, remaingMinutesAllocated, remainingMinutesAvailable;
		if (this.props.projectGroupSprint) {
			totalMinutesAvailable =
				this.props.projectGroupSprint.node.sprintPersons &&
				this.props.projectGroupSprint.node.sprintPersons.edges.reduce(
					(acc, sp) => (sp.node.taskAvailableMinutes || sp.node.scheduledMinutes) + acc,
					0
				);
			remaingMinutesAllocated =
				this.props.projectGroupSprint.node.sprintPersons &&
				this.props.projectGroupSprint.node.sprintPersons.edges.reduce(
					(acc, sp) => (sp.node.taskAvailableMinutes || sp.node.availableMinutes) + acc,
					0
				);
		} else {
			totalMinutesAvailable =
				sprint.sprintPersons &&
				sprint.sprintPersons.edges.reduce(
					(acc, sp) => (sp.node.taskAvailableMinutes || sp.node.scheduledMinutes) + acc,
					0
				);
			remaingMinutesAllocated =
				sprint.sprintPersons &&
				sprint.sprintPersons.edges.reduce(
					(acc, sp) => (sp.node.taskAvailableMinutes || sp.node.availableMinutes) + acc,
					0
				);
		}

		remainingMinutesAvailable =
			remaingMinutesAllocated -
			remainingUnfiltered * (this.props.isEstimatedInHours ? 1 : this.props.minutesPerEstimationPoint);
		if (!this.props.useManualAllocations) {
			const minutesAllocated = this.state.data ? this.state.data.minutesAllocated : 0;
			remainingMinutesAvailable -= minutesAllocated;
		}

		this.notDoneTasksUnfiltered = sprint.allTasks.filter(
			task =>
				task.project &&
				task.project.status !== PROJECT_STATUS.HALTED &&
				task.project.status !== PROJECT_STATUS.DONE &&
				task.statusColumnV2 &&
				task.statusColumnV2.category !== WorkflowCategories.DONE
		);
		const notDoneTasksUnfilteredNumber = this.notDoneTasksUnfiltered.length;

		// Had to split the projectColor into red green and blue values due a sass rgba() function limitation
		// rgba() can receive a hex according to the documentation, but misbehaved when I passed a hex to it, therefore we pass r g and b
		const isWhite = Util.getTextColorV2(this.props.projectColor) === '#ffffff';
		this.emptyCols = [];

		const sprintEndDate = empty ? null : Util.CreateNonUtcMomentDate(sprint.endYear, sprint.endMonth, sprint.endDay);
		const isDone = sprint.isDone;
		const showUnfinishedWorkInfo =
			!empty &&
			sprintEndDate.isSameOrBefore(today, 'd') &&
			notDoneTasksUnfilteredNumber !== 0 &&
			canUpdateSprint &&
			!this.props.projectLocked;
		const estimationUnit = isEstimatedInHours
			? formatMessage({id: 'common.hours.short'})
			: formatMessage({id: 'common.points.short'});
		const maxNameWidth = this.props.taskCellMaxWidth
			? empty
				? this.props.taskCellMaxWidth
				: Math.min(this.props.taskCellMaxWidth, this.props.minWidth / 2)
			: 200;
		const showCloseSprintButton =
			!this.props.isConnectedChild && ((this.props.differentPerformance && isDone) || showUnfinishedWorkInfo);
		const sprintNameElem = (
			<div key={sprint.id} className={'elem-wrapper growable'} style={{minWidth: 150, maxWidth: maxNameWidth}}>
				<div className="title" title={this.state.inputSprintTitle} style={{maxWidth: maxNameWidth}}>
					<div className="editable" ref={div => (this.nameContainer = div)}>
						{empty ? (
							<div className={'backlog-action-menu'}>
								<p className="phase-none">{formatMessage({id: 'project_sprints.backlog'})} </p>
							</div>
						) : (
							<input
								data-cy={
									this.props.cy +
									'-input' +
									(this.props.isConnectedChild || this.props.projectLocked || !canUpdateSprint
										? ' disabled'
										: '')
								}
								//style={{color: Util.getTextColor(this.props.project.projectColor)}}
								type="text"
								ref={input => {
									this.nameInput = input;
								}}
								value={this.state.inputSprintTitle}
								onChange={this.handleSprintTitleChange.bind(this)}
								onBlur={this.handleSaveSprintTitle.bind(this)}
								disabled={this.props.isConnectedChild || this.props.projectLocked || !canUpdateSprint}
								maxLength={191}
								spellCheck={false}
								onKeyPress={this.handleKeyPressName.bind(this)}
							/>
						)}
					</div>
				</div>
			</div>
		);
		const sprintDateElem = (
			<div className={'elem-wrapper sprint-date'}>
				<div className="date-picker">
					{empty ? (
						<div />
					) : (
						<div className="phase-deadline-select">
							{this.props.isConnectedChild ? (
								<TooltipContainer
									infoText={formatMessage(
										{id: 'common.connected_project_no_change'},
										{functionality: 'change sprint date'}
									)}
								>
									<DatePicker
										buttonCy={this.props.cy + '-date-button'}
										disabled={this.props.isConnectedChild || this.props.projectLocked || !canUpdateSprint}
										id={sprint.id}
										isNewDateRange={true}
										startDate={Util.CreateNonUtcMomentDate(
											sprint.startYear,
											sprint.startMonth,
											sprint.startDay
										)}
										endDate={Util.CreateNonUtcMomentDate(sprint.endYear, sprint.endMonth, sprint.endDay)}
										handleDateRangeChange={this.handleDateRangeChange.bind(this)}
										clearable={false}
										//startFrom={phase && phase.name ? phase.name : formatMessage({id: 'common.untitled'})}
										//endFrom={phase && phase.name ? phase.name : formatMessage({id: 'common.untitled'})}
										datePickerStyle={DATE_PICKER_STYLE.SCOPING_PHASE_DATE}
										colorInherited={true}
										disabledDates={isDateDisabled}
									/>
								</TooltipContainer>
							) : (
								<DatePicker
									buttonCy={this.props.cy + '-date-button'}
									disabled={
										!this.props.lazyDataFetched ||
										this.props.isConnectedChild ||
										this.props.projectLocked ||
										!canUpdateSprint
									}
									id={sprint.id}
									isNewDateRange={true}
									startDate={Util.CreateNonUtcMomentDate(
										sprint.startYear,
										sprint.startMonth,
										sprint.startDay
									)}
									endDate={Util.CreateNonUtcMomentDate(sprint.endYear, sprint.endMonth, sprint.endDay)}
									handleDateRangeChange={this.handleDateRangeChange.bind(this)}
									clearable={false}
									//startFrom={phase && phase.name ? phase.name : formatMessage({id: 'common.untitled'})}
									//endFrom={phase && phase.name ? phase.name : formatMessage({id: 'common.untitled'})}
									datePickerStyle={DATE_PICKER_STYLE.SCOPING_PHASE_DATE}
									colorInherited={true}
									disabledDates={isDateDisabled}
								/>
							)}
						</div>
					)}
				</div>
			</div>
		);

		const lazyDataFetched = this.props.lazyDataFetched;

		const sprintValues = this.props.isEstimatedInNewPoints ? (
			<>
				<ProjectSprintsHeaderTile
					label={formatMessage({id: 'common.total_availability'})}
					value={
						lazyDataFetched
							? formatNumber(totalMinutesAvailable / this.props.minutesPerEstimationPoint, {
									format: 'rounded_two_decimal',
							  })
							: null
					}
					valueUnit={estimationUnit}
				/>
				{!isDone && (
					<ProjectSprintsHeaderTile
						label={formatMessage({id: 'common.remaining_work'})}
						value={lazyDataFetched ? formatNumber(remainingUnfiltered, {format: 'rounded_two_decimal'}) : null}
						valueUnit={estimationUnit}
					/>
				)}
				{!isDone && (
					<ProjectSprintsHeaderTile
						label={formatMessage({id: 'common.remaining_availability'})}
						value={
							lazyDataFetched
								? formatNumber(remainingMinutesAvailable / this.props.minutesPerEstimationPoint, {
										format: 'rounded_two_decimal',
								  })
								: null
						}
						valueUnit={estimationUnit}
					/>
				)}
				<ProjectSprintsHeaderTile
					label={formatMessage({id: 'common.progress'})}
					value={
						lazyDataFetched
							? (sprint.progress !== ''
									? formatNumber(sprint.progress, {format: 'rounded_zero_decimal'})
									: sprint.progress) + '%'
							: null
					}
				/>
				{isDone && canUpdateSprint && (
					<ProjectSprintsHeaderTile
						label={formatMessage({id: 'common.performance'})}
						value={Util.convertMinutesToFullHour(sprint.performance || 0, this.props.intl)}
					/>
				)}
				{isDone && (
					<ProjectSprintsHeaderTile
						label={'Done work'}
						value={
							lazyDataFetched
								? formatNumber((forecastUnfiltered * this.props.minutesPerEstimationPoint) / 60, {
										format: 'rounded_two_decimal',
								  })
								: null
						}
						valueUnit={estimationUnit}
					/>
				)}
			</>
		) : (
			<>
				<ProjectSprintsHeaderTile
					label={formatMessage({id: 'common.total_availability'})}
					value={lazyDataFetched ? Util.convertMinutesToFullHour(totalMinutesAvailable, this.props.intl) : null}
				/>
				{!isDone && (
					<ProjectSprintsHeaderTile
						label={formatMessage({id: 'common.remaining_work'})}
						value={lazyDataFetched ? Util.convertMinutesToFullHour(remainingUnfiltered, this.props.intl) : null}
					/>
				)}
				{!isDone && (
					<ProjectSprintsHeaderTile
						label={formatMessage({id: 'common.remaining_availability'})}
						value={
							lazyDataFetched
								? Util.convertMinutesToFullHour(remainingMinutesAvailable || 0, this.props.intl)
								: null
						}
					/>
				)}
				<ProjectSprintsHeaderTile
					label={formatMessage({id: 'common.progress'})}
					value={
						lazyDataFetched
							? (sprint.progress !== ''
									? formatNumber(sprint.progress, {format: 'rounded_zero_decimal'})
									: sprint.progress) + '%'
							: null
					}
				/>
				{isDone && (
					<ProjectSprintsHeaderTile
						label={'Done work'}
						value={lazyDataFetched ? Util.convertMinutesToFullHour(forecastUnfiltered, this.props.intl) : null}
					/>
				)}
			</>
		);
		const sprintsDataHeader = empty ? (
			<div className={'sprints-info-container'}>
				<div className={'data-tiles'}>{sprintNameElem}</div>
			</div>
		) : (
			<div className={'sprints-info-container'}>
				<div className="data-tiles">
					{sprintNameElem}
					{sprintDateElem}
					{sprintValues}
					<div className={'sprints-header-elem'}>
						<div className={'sprint-header-controls'}>
							<div className="progress-controls-wrapper">
								<TooltipIcon
									infoText={
										this.props.expanded
											? formatMessage({id: 'workflow.collapse'})
											: formatMessage({id: 'workflow.expand'})
									}
									cy={this.props.cy + '-expand-control'}
									className={'expand-control ' + (this.props.expanded === true ? 'collapse' : 'expand')}
									onClick={this.handleExpandClick.bind(this)}
								/>
							</div>
							{!canUpdateSprint ? null : (
								<ActionsMenu
									useBlackIcon={!isWhite}
									isWhite={isWhite}
									whiteInner={true}
									options={this.getActionMenuOptions()}
									cy={this.props.cy}
									disabled={this.props.projectLocked || !this.props.lazyDataFetched}
								/>
							)}
							{!this.props.isConnectedChild ? (
								<button
									ref={this.sprintGoalRef}
									onClick={this.onSprintGoalsClick.bind(this, slideoutId)}
									tabIndex={0}
									className={'sprint-goals-wrapper' + (isWhite ? ' white' : '')}
									title={'Sprint Goals'}
								>
									<Bullseye color={isWhite ? 'white' : 'black'} />
								</button>
							) : hasFeatureFlag('sprint_description', this.props.viewer.availableFeatureFlags) &&
							  !this.props.isConnectedChild ? (
								<div className={'description-button-wrapper'}>
									<SprintNotesFoldout
										value={sprint.description || ''}
										onSubmit={this.handleSaveSprintDescription.bind(this)}
										disabled={
											this.props.isConnectedChild ||
											this.props.projectLocked ||
											!canUpdateSprint ||
											!this.props.lazyDataFetched
										}
										maxChars={5000}
										isWhite={isWhite}
									/>
								</div>
							) : null}
						</div>
					</div>
				</div>
			</div>
		);

		return (
			<>
				<div
					data-cy="phases-header-v2 sprints"
					className={
						'phases-header-v2 sprints' +
						(empty ? ' no-phase' : '') +
						(isWhite ? ' white' : ' black') +
						(isDone ? ' done' : '')
					}
					style={
						!empty
							? {
									backgroundColor: this.props.projectColor,
									minWidth: this.props.minWidth ? this.props.minWidth : '',
							  }
							: {minWidth: this.props.minWidth ? this.props.minWidth : ''}
					}
					onClick={!empty ? this.handleExpandClick.bind(this) : null}
					data-userpilot={this.props.sprintIndex === 0 ? 'sprint-header-latest' : null}
				>
					{<div className="overlay"></div>}
					<div className="chip-container">
						<div className="chip-div left" />
						<div className="chip left" />
					</div>
					{this.getIntegrationIcons(this.props.sprint)}
					{sprintsDataHeader}
					<div className="chip-container">
						<div className="chip-div right" />
						<div className="chip right" />
					</div>
					{this.state.showContextMenu ? (
						<ContextMenu
							options={this.getActionMenuOptions()}
							contextMenuPosition={this.state.contextMenuPosition}
							closeContextMenu={this.closeContextMenu.bind(this)}
						/>
					) : null}
				</div>
				{showCloseSprintButton && (
					<div className={'sprint-extra-section'}>
						<Button
							text={this.props.intl.formatMessage({
								id: showUnfinishedWorkInfo
									? 'sprint.close-sprint'
									: 'story_point_performance.update_performance',
							})}
							cy={showUnfinishedWorkInfo ? 'close-sprint' : 'update_performance'}
							buttonStyle={BUTTON_STYLE.OUTLINE}
							colorTheme={BUTTON_COLOR.PURPLE}
							onClick={() => this.closeSprint()}
							isDisabled={!this.props.lazyDataFetched}
						></Button>
					</div>
				)}
			</>
		);
	}
}

ProjectSprintsSprintHeader.propTypes = {
	sprint: PropTypes.object.isRequired,
	emptyPhase: PropTypes.bool.isRequired,
	locale: PropTypes.string,
	hasRateCard: PropTypes.bool.isRequired,
	projectLocked: PropTypes.bool.isRequired,
	currencySymbol: PropTypes.string.isRequired,
	placeUnitBeforeValue: PropTypes.bool.isRequired,
	hasFinancialAccess: PropTypes.bool.isRequired,
};

export default injectIntl(ProjectSprintsSprintHeader);
