import Moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { createRefetchContainer, graphql } from 'react-relay';
import styled from 'styled-components';
import * as OsUtil from '../../../../../util/OsUtil';
import { Alert, BetaTag, DeprecatedTimeRegCreativeModal as TimeRegCreativeModal, FlexColumn, FlexRow, Label, Text, TextArea, } from '@forecast-it/design-system';
import { AlertType } from '@forecast-it/design-system/dist/forecast-v2/components/data-display/alert/types';
import { CommitMutation } from '../../../../../../../mutations/ts/CommitMutation';
import OptOutOfFeatureFlagMutation from '../../../../../../../mutations/ts/OptOutOfFeatureFlagMutation';
import { asFragment } from '../../../../../../../relay-helpers/TSRelayConverter';
import { TRACKING_OBJECTS } from '../../../../../../../tracking/amplitude/constants/TrackingObjects';
import { useTrackModal } from '../../../../../../../tracking/amplitude/hooks/useTrackModal';
import { trackEvent } from '../../../../../../../tracking/amplitude/TrackingV2';
import { workingHourForTheDay } from '../../../../../../my-work-tab/my-timesheets-page/timesheets_person_data';
import { getAllowedEntitiesText } from '../../../../../../navigation/header/global-search/formatters/FormatterUtil';
import { SEARCH_ENTITIES } from '../../../../../../navigation/header/global-search/GlobalSearchModal';
import { usePrevious } from '../../../../../hooks/ts/usePreviousPersistent';
import { useWindowSize } from '../../../../../hooks/useWindowSize';
import { isBillableSplitAllowed, isInternalTimeRegistrationAllowed, isProjectTimeRegistrationAllowed, isRolesAllowed, isTimeRegistrationNoteRequired, } from '../../../../../util/cache/TimeRegistrationSettingsUtil';
import { hasFeatureFlag } from '../../../../../util/FeatureUtil';
import { isTimeRegistrationTimeInvalidWithReason } from '../../../../../util/time-registration/time-registration-settings/TimeRegistrationTimeFilter';
import Util from '../../../../../util/util';
import TimerTaskInvalidInfo from '../../../../add-time-entry/TimerTaskInvalidInfo';
import { TimeRegGranularityInfoIcon } from '../../../../add-time-entry/validation-info/TimeRegGranularityInfo';
import { TimeRegistrationSettingsInfoMessage } from '../../../../add-time-entry/validation-info/TimeRegistrationSettingsInfoMessage';
import { showTimeRegistrationSettingsInfoMessage } from '../../../../add-time-entry/validation-info/TimeRegistrationSettingsInfoMessageContent';
import SkylineRoleDropdownRelayWrapper from '../../../../dropdowns/role-dropdown/skyline/SkylineRoleDropdownRelayWrapper';
import { convertIntoFloatHoursFormatWithGranularity } from '../../../../inputs/hours-input/hours_input_logic';
import HoursInput from '../../../../inputs/hours-input/hours_input_view';
import TimeRegWSuggestionsInput from '../../../../inputs/time-registration-input/time_reg_w_sugestions_input';
import { MODAL_TYPE, showModal } from '../../../../modals/generic_modal_conductor';
import DeprecatedScrollToNativeScroll from '../../../../scroll-bars/DeprecatedScrollToNativeScroll';
import { isModalInputInvalid, ModalInvalidInputReason, validateTargetEntity } from '../logic/TimeRegistrationModalValidation';
import { TimeRegistrationEntity } from '../types/TimeRegistrationEntityId';
import { getInitialOrFirstNonLockValidDateForTargetEntity, getLockDateFromViewer } from '../util/TimeRegistrationModalDateUtil';
import { getDefaultRole, updateRoleOnProjectChange } from '../util/TimeRegistrationModalRoleUtil';
import { doesTargetEntityAllowRoles, getEntityProject, isProjectEntity, isTargetEntityBillable, isTaskEntity, searchResultToBackendEntityId, } from '../util/TimeRegistrationModalUtil';
import TimeRegistrationModalDates from './TimeRegistrationModalDates';
import TimeRegistrationModalIntegrations from './TimeRegistrationModalIntegrations';
import TimeRegistrationModalSearch from './TimeRegistrationModalSearch';
import TimeRegistrationModalTaskInfo from './TimeRegistrationModalTaskInfo';
import { idFromGlobalId } from '../../../../../util/GlobalIdUtil';
import { useSpinner } from '../../../../spinner/useSpinner';
import { EVENT_ID } from '../../../../../../../containers/event_manager';
import useSubscription from '../../../../../hooks/useSubscription';
function optOutOfFeatureFlag(viewer, closeModal) {
    const onSuccess = () => {
        trackEvent(TRACKING_OBJECTS.TIME_REGISTRATION_MODAL, 'Opted Out New Time Registration Modal');
        closeModal();
        showModal({
            type: MODAL_TYPE.TIMER_V3,
            personId: viewer.actualPersonId,
        });
    };
    CommitMutation(OptOutOfFeatureFlagMutation, { featureFlag: 'new_time_registration_modal' }, onSuccess);
}
function getPrimaryTargetEntityFromViewer(viewer) {
    // The primary target entity is only a task if no project is also selected/loaded
    if (viewer.task && !viewer.project)
        return Object.assign({ entity: TimeRegistrationEntity.Task }, viewer.task);
    if (viewer.project)
        return Object.assign({ entity: TimeRegistrationEntity.Project }, viewer.project);
    if (viewer.idleTime)
        return Object.assign({ entity: TimeRegistrationEntity.IdleTime }, viewer.idleTime);
    return null;
}
function getSecondaryTargetEntityFromViewer(viewer) {
    // The secondary target entity is only a task if there is also a project selected/loaded
    if (viewer.project && viewer.task)
        return Object.assign({ entity: TimeRegistrationEntity.Task }, viewer.task);
    return null;
}
const TextAreaWrapper = styled.div `
	margin-bottom: ${props => props.theme.sys.spacing[400]};
`;
const TaskSelectionWrapper = styled.div `
	display: flex;
	flex-direction: column;
	row-gap: ${props => props.theme.sys.spacing[100]};
	margin-top: ${props => props.theme.sys.spacing[300]};
	margin-bottom: ${props => props.theme.sys.spacing[100]};
`;
const CallAttentionWrapper = styled.div `
	textarea,
	[role='spinbutton'] {
		animation: ${props => (props.callAttention ? 'callAttention 0.5s ease-in-out' : '')};
		animation-delay: 250ms;
		@keyframes callAttention {
			0% {
				background-color: ${({ theme }) => theme.comp.calendar.color.background.calendarevent.hover};
			}
			100% {
				background-color: white;
			}
		}
	}
`;
function getTargetEntity(targetEntityPrimary, targetEntitySecondary) {
    return targetEntitySecondary ? targetEntitySecondary : targetEntityPrimary;
}
function getBillableTimeInputValue_timeRegInputConversion(targetEntityBillable, billableTime, time) {
    if (!targetEntityBillable) {
        return 0;
    }
    if (billableTime != null) {
        return `${billableTime}m`;
    }
    return `${time}m`;
}
function TimeRegistrationModal({ viewer, relay, infoText, initialMinutes = 0, addInitialTimeToRemaining = false, initialBillableTime = null, initialDate = new Date(), initialNote = '', initialHarvestTaskId, jiraTaskId, initialRole, onlyValidateFormElementsOnChange = false, shouldValidateEntity = false, showValidationError = false, callbackPositive, callbackNegative, closeModal, showAsModal = true, fromTaskModal = false, initialCallAttention = false, }) {
    var _a, _b, _c, _d, _e, _f;
    const hasSecondaryButton = !!infoText.secondaryButtonText && showAsModal;
    const lockDate = getLockDateFromViewer(viewer);
    const canOptOut = hasFeatureFlag('new_time_registration_modal') && hasFeatureFlag('new_time_registration_modal_opt_in');
    // The target entities are being fetch using refetch, so we don't have any states for the target entities other than the viewer object. So we have these helper functions to extract and validate the entities
    // from the viewer objects. Information about whether or not an entity belongs to the primary or secondary target entity, is inferred from the type of the entity.
    const { targetEntity: targetEntityPrimary, targetEntityError: targetEntityPrimaryError } = validateTargetEntity({
        targetEntity: getPrimaryTargetEntityFromViewer(viewer),
        personId: viewer.actualPersonId,
        shouldValidateEntity,
        showError: showValidationError,
    });
    const { targetEntity: targetEntitySecondary, targetEntityError: targetEntitySecondaryError } = validateTargetEntity({
        targetEntity: getSecondaryTargetEntityFromViewer(viewer),
        personId: viewer.actualPersonId,
        shouldValidateEntity,
        showError: showValidationError,
    });
    const [callAttention, setCallAttention] = useState(initialCallAttention);
    const [targetEntityFetching, setTargetEntityFetching] = useState(false);
    const [secondaryTargetEntityFetching, setSecondaryTargetEntityFetching] = useState(false);
    const targetEntity = getTargetEntity(targetEntityPrimary, targetEntitySecondary);
    const [time, setTime] = useState(initialMinutes);
    const [timeError, setTimeError] = useState(false);
    const [billableTime, setBillableTime] = useState(initialBillableTime);
    const [selectedDate, setSelectedDate] = useState(getInitialOrFirstNonLockValidDateForTargetEntity(initialDate, lockDate, targetEntity));
    const [dateError, setDateError] = useState(false);
    const [formValidationFailed, setFormValidationFailed] = useState(false);
    const [note, setNote] = useState(initialNote);
    const [noteError, setNoteError] = useState(false);
    const [showMovedToProjectRoleWarning, setShowMovedToProjectRoleWarning] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const { spinning } = useSpinner({ waiting });
    const [selectedHarvestTask, setSelectedHarvestTask] = useState(initialHarvestTaskId ? { value: initialHarvestTaskId } : null);
    const [selectedUnit4Activity, setSelectedUnit4Activity] = useState(null);
    const defaultRole = useMemo(() => {
        var _a, _b;
        return initialRole !== undefined ? initialRole : getDefaultRole(targetEntity, (_b = (_a = viewer.company) === null || _a === void 0 ? void 0 : _a.person) === null || _b === void 0 ? void 0 : _b.role);
    }, [targetEntity, (_b = (_a = viewer.company) === null || _a === void 0 ? void 0 : _a.person) === null || _b === void 0 ? void 0 : _b.role, initialRole]);
    const [selectedRole, setSelectedRole] = useState(defaultRole);
    const intl = useIntl();
    const allowedEntities = [SEARCH_ENTITIES.TASK, SEARCH_ENTITIES.PROJECT];
    if (isInternalTimeRegistrationAllowed()) {
        allowedEntities.push(SEARCH_ENTITIES.INTERNAL_TIME);
    }
    function handleEventTracking(value) {
        trackEvent('Shortcut', 'Pressed', { value });
    }
    useEffect(() => {
        if (defaultRole === null || defaultRole === void 0 ? void 0 : defaultRole.id) {
            setSelectedRole(defaultRole);
        }
    }, [defaultRole === null || defaultRole === void 0 ? void 0 : defaultRole.id]);
    useTrackModal('Time Registration Modal');
    const initialTargetEntityId = useRef(targetEntity === null || targetEntity === void 0 ? void 0 : targetEntity.id);
    const billableMinutesUpdated = useRef(false);
    const isCommittingData = useRef(false);
    const searchButtonRefPrimary = useRef(null);
    const searchButtonRefSecondary = useRef(null);
    const hoursInputRef = useRef(null);
    const notesInputRef = useRef(null);
    const handleHarvestTaskChange = harvestTask => {
        setSelectedHarvestTask(harvestTask);
    };
    const handleUnit4ActivityChange = unit4Activity => {
        setSelectedUnit4Activity(unit4Activity);
    };
    const clearPrimarySelected = (selectedItem) => {
        if (selectedItem) {
            // When clearing the primary selector, we do a refetch without any entity ids, since that will reset
            // all entities, clearing both the primary and secondary selector.
            setTargetEntityFetching(true);
            relay.refetch({ taskId: null, projectId: null }, null, () => setTargetEntityFetching(false));
        }
    };
    const clearSecondarySelected = (selectedItem) => {
        var _a;
        if ((_a = selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.project) === null || _a === void 0 ? void 0 : _a.id) {
            // We are clearing the secondary selector(task), by doing a refretch with only the tasks project,
            // since the tasks project is the entity in the primary selector
            const backendEntityId = { taskId: null, projectId: idFromGlobalId(selectedItem.project.id) };
            setSecondaryTargetEntityFetching(true);
            relay.refetch(backendEntityId, null, () => setSecondaryTargetEntityFetching(false));
        }
    };
    const onSearchSelection = useCallback(searchResult => {
        const id = parseInt(searchResult.id);
        const projectId = parseInt(searchResult.searchResult.projectId);
        if (id && !isNaN(id)) {
            setTargetEntityFetching(true);
            const variant = searchResult.variant;
            const backendEntityId = searchResultToBackendEntityId({ id, projectId, variant });
            relay.refetch(backendEntityId, null, () => setTargetEntityFetching(false));
        }
    }, []);
    const onSecondarySearchSelection = useCallback(searchResult => {
        const id = parseInt(searchResult.id);
        const projectId = parseInt(searchResult.searchResult.projectId);
        if (id && !isNaN(id)) {
            setSecondaryTargetEntityFetching(true);
            const variant = searchResult.variant;
            const backendEntityId = searchResultToBackendEntityId({ id, projectId, variant });
            relay.refetch(backendEntityId, null, () => setSecondaryTargetEntityFetching(false));
        }
    }, []);
    const updateTime = useCallback(value => {
        setTime(Util.convertTimeInputToMinutes(value, true) || 0);
    }, [setTime]);
    const onHoursInputFormat = useCallback(value => {
        updateTime(value != null ? `${value}h` : undefined);
    }, [updateTime]);
    const onHoursInputChange = useCallback(value => {
        trackEvent(TRACKING_OBJECTS.TIME_REGISTRATION_MODAL, 'Time Input Changed', { from: time, to: value });
        updateTime(value);
        if (!billableMinutesUpdated.current) {
            setBillableTime(null);
        }
    }, [updateTime, setBillableTime]);
    const onBillableHoursInputChange = value => {
        const formattedValue = Util.convertTimeInputToMinutes(value, true);
        trackEvent(TRACKING_OBJECTS.TIME_REGISTRATION_MODAL, 'Billable Time Input Changed', {
            from: billableTime,
            to: formattedValue,
        });
        setBillableTime(formattedValue);
        billableMinutesUpdated.current = true;
    };
    function onNoteChange(value) {
        setNoteError(false);
        setNote(value);
    }
    function handleModalInputInvalid(modalInputInvalidReasons) {
        let elementFocused = false; // store if error handling has already focused an element to prevent trying to focus multiple elements and giving unpredictable behavior
        modalInputInvalidReasons.forEach(modalInputInvalidReason => {
            var _a, _b;
            if (modalInputInvalidReason === ModalInvalidInputReason.InvalidTime) {
                (_a = hoursInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
                elementFocused = true;
            }
            if (modalInputInvalidReason === ModalInvalidInputReason.InvalidNote) {
                setNoteError(true);
                if (!elementFocused) {
                    (_b = notesInputRef.current) === null || _b === void 0 ? void 0 : _b.focus();
                    elementFocused = true;
                }
            }
        });
    }
    function validate() {
        if (!targetEntity) {
            return false;
        }
        if (dateError || timeError) {
            setFormValidationFailed(true);
            return false;
        }
        else {
            setFormValidationFailed(false);
        }
        const modalInputInvalidReasons = isModalInputInvalid({
            targetEntity,
            actualPersonId: viewer.actualPersonId,
            time,
            billableTime,
            note,
        });
        if (modalInputInvalidReasons.length > 0) {
            handleModalInputInvalid(modalInputInvalidReasons);
            return false;
        }
        return true;
    }
    function getBillableMinutesRegistered() {
        return isTargetEntityBillable(targetEntity) ? billableTime : undefined;
    }
    function trackSaveEvent(isCreateNew) {
        var _a, _b, _c, _d;
        if (!targetEntity) {
            return;
        }
        trackEvent(TRACKING_OBJECTS.TIME_REGISTRATION_MODAL, 'Submitted', {
            timeRegistrationPersonId: (_b = (_a = viewer === null || viewer === void 0 ? void 0 : viewer.company) === null || _a === void 0 ? void 0 : _a.person) === null || _b === void 0 ? void 0 : _b.id,
            timeRegistrationPersonEmail: (_d = (_c = viewer === null || viewer === void 0 ? void 0 : viewer.company) === null || _c === void 0 ? void 0 : _c.person) === null || _d === void 0 ? void 0 : _d.email,
            timeRegistrationTargetId: targetEntity === null || targetEntity === void 0 ? void 0 : targetEntity.id,
            timeRegistrationTargetType: TimeRegistrationEntity[targetEntity.entity],
            timeRegistrationTargetName: targetEntity === null || targetEntity === void 0 ? void 0 : targetEntity.name,
            minutesRegistered: time,
            billableMinutesRegistered: getBillableMinutesRegistered(),
            date: selectedDate.toDateString(),
            note,
            timeRegistrationRoleName: selectedRole === null || selectedRole === void 0 ? void 0 : selectedRole.name,
            usingSaveAndCreateNew: isCreateNew ? true : undefined,
        });
    }
    function commitData(onSuccess) {
        var _a, _b, _c;
        if (!targetEntity || !((_a = viewer.company) === null || _a === void 0 ? void 0 : _a.id) || !((_b = viewer.company.person) === null || _b === void 0 ? void 0 : _b.id))
            return;
        if (isCommittingData.current) {
            return;
        }
        isCommittingData.current = true;
        setWaiting(true);
        callbackPositive({
            companyId: viewer.company.id,
            personId: (_c = viewer.company.person) === null || _c === void 0 ? void 0 : _c.id,
            targetEntity,
            time,
            billableTime: getBillableMinutesRegistered(),
            date: selectedDate,
            note,
            harvestTaskId: selectedHarvestTask ? selectedHarvestTask.value : undefined,
            unit4ActivityId: selectedUnit4Activity ? selectedUnit4Activity.value : undefined,
            unit4ActivityName: selectedUnit4Activity ? selectedUnit4Activity.label : undefined,
            roleId: selectedRole === null || selectedRole === void 0 ? void 0 : selectedRole.id,
            onCreateSuccessCallbacks: [onSuccess],
        });
    }
    function resetForm() {
        setTargetEntityFetching(true);
        relay.refetch({ taskId: null, projectId: null }, null, () => setTargetEntityFetching(false));
        setNote('');
        setTime(0);
        setBillableTime(0);
    }
    const initializeForm = useCallback(params => {
        // clear the search when re-initializing the form (clicking a calendar event)
        // remove if we want to keep the search entity
        setTargetEntityFetching(true);
        relay.refetch({ taskId: null, projectId: null }, null, () => setTargetEntityFetching(false));
        setNote(params.initialNote);
        setTime(params.initialMinutes);
        setBillableTime(params.initialBillableTime);
        setSelectedDate(params.initialDate);
        setCallAttention(params.initialCallAttention);
    }, []);
    useSubscription(EVENT_ID.TIMESHEET_SHOW_SIDE_MODAL, initializeForm);
    function validateAndCallbackPositive() {
        if (validate()) {
            const onSuccess = () => {
                trackSaveEvent(false);
                setWaiting(false);
                if (showAsModal) {
                    closeModal();
                }
                else {
                    resetForm();
                }
            };
            commitData(onSuccess);
        }
    }
    function saveAndCreateNew() {
        if (validate()) {
            const onSuccess = () => {
                trackSaveEvent(true);
                setWaiting(false);
                closeModal();
                showModal({
                    type: MODAL_TYPE.CREATE_TIME_REGISTRATION,
                    personId: viewer.actualPersonId,
                });
            };
            commitData(onSuccess);
        }
    }
    function handleShortcutTimeReg(event) {
        const isMac = OsUtil.isMac();
        const ctrlKey = isMac ? event.metaKey : event.ctrlKey;
        const shiftKey = event.shiftKey;
        switch (event.key) {
            case 'Enter':
                if (ctrlKey) {
                    if (shiftKey) {
                        if (hasSecondaryButton) {
                            handleEventTracking('time-reg-save-and-create-new');
                            saveAndCreateNew();
                        }
                    }
                    else {
                        handleEventTracking('time-reg-save');
                        validateAndCallbackPositive();
                    }
                }
                break;
            default:
                break;
        }
    }
    useEffect(() => {
        window.addEventListener('keydown', handleShortcutTimeReg);
        return () => {
            window.removeEventListener('keydown', handleShortcutTimeReg);
        };
    }, [handleShortcutTimeReg]);
    function onCloseModal() {
        trackEvent(TRACKING_OBJECTS.TIME_REGISTRATION_MODAL, 'Closed');
        closeModal();
    }
    useEffect(() => {
        var _a, _b;
        if (targetEntity && time === 0) {
            (_a = hoursInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
        else {
            (_b = searchButtonRefPrimary.current) === null || _b === void 0 ? void 0 : _b.focus();
        }
    }, []);
    // If the initial targetEntity has not been changed and the modal is in onlyValidateOnChange mode, subtract the initialTime.
    const subtractMinutes = useMemo(() => {
        if (onlyValidateFormElementsOnChange && initialTargetEntityId.current === (targetEntity === null || targetEntity === void 0 ? void 0 : targetEntity.id)) {
            return initialMinutes;
        }
    }, [initialMinutes, initialTargetEntityId.current, targetEntity, onlyValidateFormElementsOnChange]);
    const timeInputError = useMemo(() => {
        function getTaskRemainingMinutes(task) {
            if (!task || task.estimateForecastMinutes === null || task.totalMinutesRegistered === null) {
                return null;
            }
            return task.estimateForecastMinutes - task.totalMinutesRegistered;
        }
        return isTimeRegistrationTimeInvalidWithReason({
            inputMinutes: time,
            remainingMinutes: getTaskRemainingMinutes(viewer.task),
            subtractMinutes,
        });
    }, [time, viewer.task, subtractMinutes]);
    useEffect(() => {
        setTimeError(!!timeInputError);
    }, [timeInputError]);
    const windowSize = useWindowSize();
    const windowHeight = (_c = windowSize === null || windowSize === void 0 ? void 0 : windowSize.height) !== null && _c !== void 0 ? _c : window.innerHeight;
    const company = viewer.company;
    const person = company === null || company === void 0 ? void 0 : company.person;
    const noteRequired = isTimeRegistrationNoteRequired();
    const billableSplitAllowed = isBillableSplitAllowed();
    const rolesAllowed = isRolesAllowed();
    const targetEntityAllowRoles = doesTargetEntityAllowRoles(targetEntity);
    const showRoles = rolesAllowed && targetEntityAllowRoles;
    //const exceedingEstimateAllowed = isExceedingEstimateAllowed();
    const targetEntityBillable = isTargetEntityBillable(targetEntity);
    const entityProject = getEntityProject(targetEntity);
    const previousProjectId = usePrevious(entityProject === null || entityProject === void 0 ? void 0 : entityProject.id);
    useEffect(() => {
        var _a, _b;
        const newDefaultRole = getDefaultRole(targetEntity, (_b = (_a = viewer.company) === null || _a === void 0 ? void 0 : _a.person) === null || _b === void 0 ? void 0 : _b.role) || undefined;
        updateRoleOnProjectChange({
            newDefaultRole: newDefaultRole,
            initialRole: initialRole,
            projectId: entityProject === null || entityProject === void 0 ? void 0 : entityProject.id,
            previousProjectId: previousProjectId || undefined,
            selectedRole: selectedRole,
            setSelectedRole: setSelectedRole,
            setShowMovedToProjectRoleWarning: setShowMovedToProjectRoleWarning,
        });
    }, [initialRole === null || initialRole === void 0 ? void 0 : initialRole.id, entityProject === null || entityProject === void 0 ? void 0 : entityProject.id, (_d = viewer === null || viewer === void 0 ? void 0 : viewer.task) === null || _d === void 0 ? void 0 : _d.role, (_f = (_e = viewer === null || viewer === void 0 ? void 0 : viewer.project) === null || _e === void 0 ? void 0 : _e.projectPerson) === null || _f === void 0 ? void 0 : _f.role]);
    const onRoleManuallySelected = useCallback((role) => {
        setSelectedRole(role);
        setShowMovedToProjectRoleWarning(false);
    }, [setSelectedRole, setShowMovedToProjectRoleWarning]);
    const billableTimeInputValue = getBillableTimeInputValue_timeRegInputConversion(targetEntityBillable, billableTime, time);
    const workingHoursForDay = workingHourForTheDay(person, Moment(selectedDate) || Moment());
    const betaLink = (React.createElement("div", { style: { marginLeft: '16px' } },
        React.createElement(FlexRow, { alignItems: "center", gap: "m" },
            React.createElement(BetaTag, null))));
    const targetIsProjectAndProjectTimeNotAllowed = targetEntity && isProjectEntity(targetEntity.entity) && !isProjectTimeRegistrationAllowed();
    const modalDisabled = !targetEntity ||
        targetIsProjectAndProjectTimeNotAllowed ||
        (dateError && formValidationFailed) ||
        timeError ||
        isCommittingData.current ||
        waiting;
    return (React.createElement(TimeRegCreativeModal, { showAsModal: showAsModal, showHeader: !fromTaskModal, size: 'xl', closeModal: onCloseModal, creativeButtonText: infoText.creativeButtonText, secondaryButtonText: infoText.secondaryButtonText, cancelButtonText: infoText.cancelButtonText, onCreateHandler: validateAndCallbackPositive, onSecondaryHandler: hasSecondaryButton ? saveAndCreateNew : undefined, onCancelHandler: callbackNegative, title: infoText.headerText, tooltip: showTimeRegistrationSettingsInfoMessage() ? React.createElement(TimeRegistrationSettingsInfoMessage, null) : null, disabled: modalDisabled, waiting: spinning, betaLink: showAsModal ? betaLink : undefined, tertiaryButtonText: "Switch to classic look", onTertiaryHandler: canOptOut && showAsModal ? () => optOutOfFeatureFlag(viewer, closeModal) : undefined, quarternaryButtonText: "Give Feedback", onQuarternaryHandler: showAsModal ? () => window.open('https://www.forecast.app/feedback-timereg') : undefined, replicateDesignSystem: true },
        React.createElement(DeprecatedScrollToNativeScroll, { maxHeight: `${windowHeight - (fromTaskModal ? 600 : 300)}px`, nativeMaxHeight: windowHeight - (fromTaskModal ? 600 : 300), hasFocusableContent: true },
            React.createElement(FlexColumn, { gap: 'l' },
                showRoles && showMovedToProjectRoleWarning && (React.createElement(Alert, { text: intl.formatMessage({ id: 'time_reg.changed_project_role_changed' }), type: AlertType.INFORMATION, onDismiss: () => setShowMovedToProjectRoleWarning(false) })),
                targetEntityPrimaryError ? React.createElement(TimerTaskInvalidInfo, { invalidatedTimerTask: targetEntityPrimaryError }) : null,
                targetEntitySecondaryError ? (React.createElement(TimerTaskInvalidInfo, { invalidatedTimerTask: targetEntitySecondaryError })) : null,
                !fromTaskModal && (React.createElement(FlexColumn, null,
                    React.createElement(Label, { "aria-label": "Search  for a task project or internal time" }, infoText.searchFieldText
                        ? infoText.searchFieldText
                        : `Find a ${getAllowedEntitiesText(allowedEntities, intl)}`),
                    React.createElement(TimeRegistrationModalSearch, { ref: searchButtonRefPrimary, targetEntity: targetEntityPrimary, targetEntityFetching: targetEntityFetching, companyId: company ? company.id : null, personId: viewer.backendId, onResultSelected: onSearchSelection, clearSelected: clearPrimarySelected, allowedEntities: allowedEntities, dataCy: 'time-registration-primary-modal-search-button', trackingObject: TRACKING_OBJECTS.TIME_REGISTRATION_MODAL_SEARCH, disabled: !!jiraTaskId, makeWider: !showAsModal }),
                    targetEntityPrimary && isTaskEntity(targetEntityPrimary.entity) ? (React.createElement(TimeRegistrationModalTaskInfo, { task: asFragment(targetEntityPrimary), initialTime: initialMinutes, addInitialTimeToRemaining: addInitialTimeToRemaining })) : null,
                    targetEntityPrimary && isProjectEntity(targetEntityPrimary.entity) ? (React.createElement(TaskSelectionWrapper, null,
                        React.createElement(Label, null, targetIsProjectAndProjectTimeNotAllowed ? 'Task (Required)' : 'Task (Optional)'),
                        React.createElement(TimeRegistrationModalSearch, { ref: searchButtonRefSecondary, targetEntity: targetEntitySecondary, targetEntityFetching: secondaryTargetEntityFetching, showTaskIndicator: true, companyId: company ? company.id : null, personId: viewer.backendId, onResultSelected: onSecondarySearchSelection, clearSelected: clearSecondarySelected, projectId: idFromGlobalId(targetEntityPrimary.id), baseSearch: 'type:task', allowedEntities: [SEARCH_ENTITIES.TASK], dataCy: 'time-registration-secondary-modal-search-button', trackingObject: TRACKING_OBJECTS.TIME_REGISTRATION_MODAL_SEARCH_SECONDARY, disabled: !!jiraTaskId, makeWider: !showAsModal }))) : null,
                    targetEntitySecondary && isTaskEntity(targetEntitySecondary.entity) ? (React.createElement(TimeRegistrationModalTaskInfo, { task: asFragment(targetEntitySecondary), initialTime: initialMinutes, addInitialTimeToRemaining: addInitialTimeToRemaining })) : null)),
                React.createElement(CallAttentionWrapper, { callAttention: callAttention, onAnimationEnd: () => setCallAttention(false) },
                    React.createElement(TimeRegistrationModalDates, { personId: person ? person.id : null, targetEntity: targetEntity, lockDate: lockDate, selectedDate: selectedDate, setSelectedDate: setSelectedDate, setDateError: setDateError, formValidationFailed: formValidationFailed, width: '200px' })),
                React.createElement(TimeRegistrationModalIntegrations, { project: asFragment(entityProject), person: asFragment(person), company: asFragment(company), selectedHarvestTask: selectedHarvestTask, handleHarvestTaskChange: handleHarvestTaskChange, handleUnit4ActivityChange: handleUnit4ActivityChange, selectedUnit4Activity: selectedUnit4Activity }),
                React.createElement(FlexColumn, null,
                    React.createElement(FlexRow, { gap: 'l' },
                        React.createElement(FlexColumn, { flex: "0", gap: 'xs' },
                            React.createElement(FlexRow, null,
                                React.createElement(Label, null, intl.formatMessage({ id: 'common.time_entry' })),
                                React.createElement(TimeRegGranularityInfoIcon, null)),
                            React.createElement(TimeRegWSuggestionsInput, { id: 'time-add-hours-input', initialValue: `${time}m`, onInputChange: onHoursInputChange, onHoursInputFormatted: 
                                // FIXME: Investigate if we can get rid of this hacky solution without breaking shit.
                                onHoursInputFormat, date: Moment(selectedDate), task: viewer.task, idleTime: viewer.idleTime, personId: person ? person.id : null, person: person, innerRef: hoursInputRef, userpilot: 'time-registration-modal-hours-input', onlyFormatGranularityOnBlur: onlyValidateFormElementsOnChange, width: '120px', replicateDesignSystem: true, clearable: true })),
                        billableSplitAllowed && (React.createElement(FlexColumn, { flex: "0", gap: 'xs' },
                            React.createElement(FlexRow, null,
                                React.createElement(Label, null, intl.formatMessage({ id: 'common.billable_time' })),
                                React.createElement(TimeRegGranularityInfoIcon, null)),
                            React.createElement(HoursInput, { customClassName: 'hour-input', clearable: true, cy: 'new-billable-time-entry-input', value: billableTimeInputValue, mutation: onBillableHoursInputChange, disabled: !targetEntityBillable, replicateDesignSystem: true, width: '120px', onlyFormatGranularityOnBlur: onlyValidateFormElementsOnChange, granularityFormatter: (val, intl, minuteLimit, showAsterisk) => convertIntoFloatHoursFormatWithGranularity(val, intl, minuteLimit, showAsterisk, workingHoursForDay), isTimeregInput: true }))),
                        showRoles && showAsModal && (React.createElement(FlexColumn, { flex: "1" },
                            React.createElement(SkylineRoleDropdownRelayWrapper, { role: selectedRole, onSelect: onRoleManuallySelected, projectId: entityProject === null || entityProject === void 0 ? void 0 : entityProject.id, controlled: true })))),
                    showRoles && !showAsModal && (React.createElement("div", { style: { marginTop: '12px' } },
                        React.createElement(SkylineRoleDropdownRelayWrapper, { role: selectedRole, onSelect: onRoleManuallySelected, projectId: entityProject === null || entityProject === void 0 ? void 0 : entityProject.id, controlled: true }))),
                    timeInputError ? React.createElement(Text, { color: 'error' }, timeInputError) : null),
                React.createElement(CallAttentionWrapper, { callAttention: callAttention, onAnimationEnd: () => setCallAttention(false) },
                    React.createElement(TextAreaWrapper, null,
                        React.createElement(TextArea, { ref: notesInputRef, label: "Note", height: 100, value: note, isOptional: !noteRequired, placeholder: "Enter a brief description of your activity", "data-cy": 'time-registration-modal-notes', onChange: onNoteChange, onBlur: () => trackEvent(TRACKING_OBJECTS.TIME_REGISTRATION_MODAL, 'Note Input Updated'), errorText: noteError
                                ? 'Adding a note is required for time entries. This is a company-wide setting.'
                                : undefined, maxLength: 999 })))))));
}
export const TimeRegistrationModalQuery = graphql `
	query TimeRegistrationModal_Query(
		$personId: ID
		$taskId: Int
		$projectId: Int
		$idleTimeId: Int
		$fetchProgramInfo: Boolean!
	) {
		viewer {
			actualPersonId
			component(name: "time_registration_modal")
			...TimeRegistrationModal_viewer
				@arguments(
					personId: $personId
					taskId: $taskId
					projectId: $projectId
					idleTimeId: $idleTimeId
					fetchProgramInfo: $fetchProgramInfo
				)
		}
	}
`;
export default createRefetchContainer(TimeRegistrationModal, {
    viewer: graphql `
			fragment TimeRegistrationModal_viewer on Viewer
			@argumentDefinitions(
				personId: {type: "ID!"}
				taskId: {type: "Int"}
				projectId: {type: "Int"}
				idleTimeId: {type: "Int"}
				date: {type: "String"}
				fetchProgramInfo: {type: "Boolean!"}
			) {
				backendId
				actualPersonId
				company {
					id
					sageIntacctEnabled
					lockedPeriodYear
					lockedPeriodMonth
					lockedPeriodDay
					roles(includeDisabled: false, backendProjectId: $projectId) {
						edges {
							node {
								id
								name
							}
						}
					}
					...TimeRegistrationModalIntegrations_company
					person(id: $personId) {
						id
						role {
							id
							name
						}
						email
						submitLockedDateYear
						submitLockedDateMonth
						submitLockedDateDay
						excludeFromCompanyLockedPeriod
						startDate # used in HoursInput, should eventually be refactored to fragment
						endDate # used in HoursInput, should eventually be refactored to fragment
						createdAt # used in HoursInput, should eventually be refactored to fragment
						monday # used in HoursInput, should eventually be refactored to fragment
						tuesday # used in HoursInput, should eventually be refactored to fragment
						wednesday # used in HoursInput, should eventually be refactored to fragment
						thursday # used in HoursInput, should eventually be refactored to fragment
						friday # used in HoursInput, should eventually be refactored to fragment
						saturday # used in HoursInput, should eventually be refactored to fragment
						sunday # used in HoursInput, should eventually be refactored to fragment
						...TimeRegistrationModalIntegrations_person
					}
				}
				task(backendId: $taskId) {
					id
					name
					role {
						id
						name
					}
					companyTaskId
					estimateForecastMinutes
					totalMinutesRegistered
					timeLeft
					billable
					startYear
					startMonth
					startDay
					deadlineYear
					deadlineMonth
					deadlineDay
					statusColumnV2 {
						category
					}
					assignedPersons {
						id
					}
					project {
						id
						name
						status
						fullAccessToProject
						companyProjectId
						customProjectId
						projectColor
						billable
						projectStartYear
						projectStartMonth
						projectStartDay
						projectEndYear
						projectEndMonth
						projectEndDay
						projectRoles {
							id
							name
						}
						projectPerson(personId: $personId) {
							role {
								id
								name
							}
						}
						programBudgetType @include(if: $fetchProgramInfo)
						isProgramRevenueLocked @include(if: $fetchProgramInfo)
						program @include(if: $fetchProgramInfo) {
							name
							prefix
							members {
								edges {
									node {
										role
										person {
											id
										}
									}
								}
							}
						}
						...ProjectIndicator_project
						...TimeRegistrationModalIntegrations_project
					}
					...TimeRegistrationModalTaskInfo_task
				}
				project(backendId: $projectId) {
					id
					name
					status
					fullAccessToProject
					companyProjectId
					customProjectId
					projectColor
					billable
					projectStartYear
					projectStartMonth
					projectStartDay
					projectEndYear
					projectEndMonth
					projectEndDay
					client {
						name
					}
					projectRoles {
						id
						name
					}
					projectPerson(personId: $personId) {
						role {
							id
							name
						}
					}
					programBudgetType @include(if: $fetchProgramInfo)
					isProgramRevenueLocked @include(if: $fetchProgramInfo)
					program @include(if: $fetchProgramInfo) {
						name
						prefix
						members {
							edges {
								node {
									role
									person {
										id
									}
								}
							}
						}
					}
					...ProjectIndicator_project
					...TimeRegistrationModalIntegrations_project
				}
				idleTime(backendId: $idleTimeId) {
					id
					isInternalTime
					name
				}
			}
		`,
}, graphql `
		query TimeRegistrationModalRefetchQuery(
			$personId: ID!
			$taskId: Int
			$projectId: Int
			$idleTimeId: Int
			$fetchProgramInfo: Boolean!
		) {
			viewer {
				component(name: "time_registration_modal_refetch")
				...TimeRegistrationModal_viewer
					@arguments(
						personId: $personId
						taskId: $taskId
						projectId: $projectId
						idleTimeId: $idleTimeId
						fetchProgramInfo: $fetchProgramInfo
					)
			}
		}
	`);
