import React from 'react';
import {
	MyWorkFavouredTasksSectionWrapper,
	ArrowSection,
	StarredTitle,
	CollapsedStarredText,
} from './MyWorkFavouredTasksSection.styled';
import {ArrowIcon} from '../../../../images/arrow_icon';
import MyWorkFavouredList, {MyWorkFavouredListQuery} from './MyWorkFavouredList';
import ForecastQueryRenderer from '../../../../ForecastQueryRenderer';
import InlineLoader from '../../../shared/components/inline-loader/inline_loader';

const MyWorkFavouredTasksSection = ({selectedPersonId, handleArrowClick, isCollapsed, intl}) => {
	return (
		<MyWorkFavouredTasksSectionWrapper
			isCollapsed={isCollapsed}
			data-cy={isCollapsed ? 'stared-tasks-section-collapsed' : 'stared-tasks-section-expanded'}
			data-userpilot={'starred-tasks-section-wrapper'}
		>
			<ArrowSection isCollapsed={isCollapsed}>
				<ArrowIcon
					direction={isCollapsed ? ArrowIcon.DIRECTION.LEFT : ArrowIcon.DIRECTION.RIGHT}
					onClick={handleArrowClick}
					cy={isCollapsed ? 'starred-tasks-collapsed' : 'starred-tasks-expanded'}
				/>
				{!isCollapsed && (
					<StarredTitle isCollapsed={isCollapsed}>{intl.formatMessage({id: 'my_work.starred_tasks'})}</StarredTitle>
				)}
			</ArrowSection>
			{isCollapsed ? (
				<CollapsedStarredText>{intl.formatMessage({id: 'my_work.starred_tasks'})}</CollapsedStarredText>
			) : (
				<ForecastQueryRenderer
					key={'query-render-myWorkFavouredTasksSection'}
					query={MyWorkFavouredListQuery}
					customLoader={() => <InlineLoader />}
					render={(relayProps, retry) => (
						<MyWorkFavouredList
							{...relayProps}
							retry={retry}
							selectedPersonId={selectedPersonId}
							cy={'starred-card'}
						/>
					)}
				/>
			)}
		</MyWorkFavouredTasksSectionWrapper>
	);
};

export default MyWorkFavouredTasksSection;
