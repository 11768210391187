import React, {Component} from 'react';
import Moment from 'moment';
import PropTypes from 'prop-types';
import {cloneDeep} from 'lodash';
import TooltipIcon from '../../../shared/components/tooltips/tooltip_icon';
import Util from '../../../shared/util/util';
import {injectIntl} from 'react-intl';
import {MODAL_TYPE, showModal} from '../../../shared/components/modals/generic_modal_conductor';
import DatePicker from '../../../shared/components/date-picker/date_picker_v3';
import {BUTTON_COLOR, BUTTON_STYLE, DATE_PICKER_STYLE} from '../../../../constants';
import UpdateRetainerPeriodMutation from '../../../../mutations/update_retainer_period_mutation';
import ActionsMenu from '../../../shared/components/action-menu/actions_menu';
import LockedIcon from '../../../../images/integrations/locked_icon';
import WarningIcon from '../../../../images/warning_icon';
import Checkbox from '../../../../components/new-ui/check_box';
import {bulkChangePeriodTarget, bulkCreateInvoices} from './RetainerBulkLogic';
import {addPeriodIdToTimeReg, handleCsvDownloadPress, sortByStartDate} from './RetainerPeriodUtil';
import {hasFeatureFlag} from '../../../shared/util/FeatureUtil';
import {dispatch, EVENT_ID} from '../../../../containers/event_manager';

class ProjectRetainerPeriodHeader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			key: 0,
			inputPeriodTitle: props.period.node.name || '',
			hideText: false,
		};
	}

	componentDidUpdate() {
		if (this._period_name_indicator && this._period_name_indicator.clientWidth < 217 && !this.state.hideText) {
			this.setState({
				hideText: true,
			});
		} else if (this._period_name_indicator && this._period_name_indicator.clientWidth >= 217 && this.state.hideText) {
			this.setState({
				hideText: false,
			});
		}
	}

	isLockUnlockDisabled(isPeriodLocked, isPeriodInvoiced, periodDifferenceSplit) {
		if (hasFeatureFlag('invoice_retainers_up_front')) {
			return periodDifferenceSplit;
		} else {
			return periodDifferenceSplit || isPeriodInvoiced;
		}
	}

	getActionMenuOptions() {
		const {formatMessage} = this.props.intl;
		const options = [];
		options.push({
			text: this.props.period.node.periodLocked
				? formatMessage({id: 'retainer.unlock_period'})
				: formatMessage({id: this.props.useTimeApproval ? 'retainer.approve_lock_period' : 'retainer.lock_period'}),
			onClick: this.props.period.node.periodLocked ? this.props.unlockPeriod : this.props.lockPeriod,
			callback: this.props.period.node.periodLocked ? this.props.unlockPeriod : this.props.lockPeriod,
			locked: this.isLockUnlockDisabled(this.props.period.node.invoiced, this.props.period.node.periodDifferenceSplit),
			disabledTitle: this.props.period.node.invoiced
				? formatMessage({id: 'retainer.unlock_warning_invoiced'})
				: formatMessage({id: 'retainer.unlock_warning_split'}),
			cy: 'lock-btn',
		});

		if (hasFeatureFlag('invoice_retainers_up_front')) {
			options.push({
				text: formatMessage({id: 'invoicing.create_invoice'}),
				onClick: () =>
					bulkCreateInvoices({
						periods: [this.props.period.node],
						project: this.props.project,
						currency: this.props.currency,
					}),
				locked: this.props.period.node.invoiced,
				cy: 'create-invoice-btn',
			});
		}

		if (this.props.hasTarget) {
			options.push({
				text: formatMessage({id: 'retainer.change_target'}),
				onClick: () =>
					bulkChangePeriodTarget({
						periods: [this.props.period.node],
						project: this.props.project,
						currency: this.props.currency,
						programBudgetInfo: this.props.programBudgetInfo,
						resetSelectedPeriods: this.props.resetSelectedPeriods,
						intl: this.props.intl,
					}),
				locked: this.props.period.node.periodLocked,
				cy: 'change-target-btn',
			});
		}

		options.push({
			text: formatMessage({id: 'retainer.download_reported_time'}),
			onClick: () =>
				handleCsvDownloadPress(
					this.props.timeRegs.map(timeReg => addPeriodIdToTimeReg(timeReg, this.props.period.node)),
					this.props.currency,
					this.props.intl
				),
			callback: () =>
				handleCsvDownloadPress(
					this.props.timeRegs.map(timeReg => addPeriodIdToTimeReg(timeReg, this.props.period.node)),
					this.props.currency,
					this.props.intl
				),
		});

		options.push({
			text: formatMessage({id: 'common.delete'}),
			onClick: this.props.deletePeriod,
			callback: this.props.deletePeriod,
			cy: 'delete-btn',
			locked: this.props.period.node.invoiced,
			disabledTitle: formatMessage({id: 'retainer.delete_warning_invoiced'}),
		});
		return options;
	}

	toggleShowConflictModal() {
		showModal({
			type: MODAL_TYPE.RETAINER_TIME_ENTRY_CONFLICT_HANDLING,
			period: this.props.period.node,
			conflictedTimeEntries: this.props.conflictedTimeEntries,
			inHours: this.props.inHours,
			currency: this.props.currency,
			viewer: this.props.viewer,
			useTimeApproval: this.props.useTimeApproval,
		});
	}

	handlePeriodTitleChange(e) {
		this.setState({
			inputPeriodTitle: e.target.value,
		});
	}

	handleSavePeriodTitle() {
		Util.CommitMutation(UpdateRetainerPeriodMutation, {
			id: this.props.period.node.id,
			projectId: this.props.project.id,
			name: this.state.inputPeriodTitle === '' ? 'New Period' : this.state.inputPeriodTitle,
		});
	}

	handleKeyPressName(e) {
		if (e.key === 'Enter') {
			this.nameInput.blur();
		}
	}

	handleDateRangeChange(startDate, endDate) {
		const {formatMessage} = this.props.intl;
		const mutationObject = {
			id: this.props.period.node.id,
			projectId: this.props.project.id,
			startYear: startDate.year(),
			startMonth: startDate.month() + 1,
			startDay: startDate.date(),
			endYear: endDate.year(),
			endMonth: endDate.month() + 1,
			endDay: endDate.date(),
			pushPeriods: false,
		};

		let warning = false;

		const onSuccess = () => {
			dispatch(EVENT_ID.RETAINER_UPDATE_PROJECT);
		};

		const callbackPositive = params => {
			mutationObject.pushPeriods = params === 'push';
			Util.CommitMutation(UpdateRetainerPeriodMutation, mutationObject, onSuccess);
		};

		// Kinda disgusting hack which forces the datepicker to reset its state to the one derived from props
		const callbackCancel = () => this.setState({key: Math.random()});

		const sortedRetainerPeriods = cloneDeep(this.props.project.retainerPeriods.edges).sort(sortByStartDate);
		const thisPeriodIndex = sortedRetainerPeriods.findIndex(p => p.node.id === this.props.period.node.id);
		const nextPeriod = sortedRetainerPeriods[thisPeriodIndex + 1];
		const prevPeriods = sortedRetainerPeriods.slice(0, thisPeriodIndex);

		// Checks regarding if changed dates infringe on dates of any previous period
		warning = prevPeriods.some(period => {
			const periodStartDate = Util.CreateNonUtcMomentDate(
				period.node.startYear,
				period.node.startMonth,
				period.node.startDay
			);
			const periodEndDate = Util.CreateNonUtcMomentDate(period.node.endYear, period.node.endMonth, period.node.endDay);
			// Overlaps?
			return startDate.isSameOrBefore(periodEndDate) && endDate.isSameOrAfter(periodStartDate);
		});
		if (warning) {
			showModal({
				type: MODAL_TYPE.WARNING,
				warningMessageId: 'common.invalid_action_modal_title',
				warningInformation: [formatMessage({id: 'retainer.warning.not-allowed-change-date'})],
				buttons: [
					{
						text: 'ok',
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
						callback: callbackCancel,
					},
				],
			});
		}

		// Checks regarding if changed dates infringe on dates of next period
		if (nextPeriod && !warning) {
			const nextPeriodStartDate = Util.CreateNonUtcMomentDate(
				nextPeriod.node.startYear,
				nextPeriod.node.startMonth,
				nextPeriod.node.startDay
			);
			const nextPeriodEndDate = Util.CreateNonUtcMomentDate(
				nextPeriod.node.endYear,
				nextPeriod.node.endMonth,
				nextPeriod.node.endDay
			);
			if (endDate.isSameOrAfter(nextPeriodEndDate)) {
				warning = true;
				showModal({
					type: MODAL_TYPE.WARNING,
					warningMessageId: 'period.push_dates',
					warningInformation: [formatMessage({id: 'warning-modal.edit-warning-title'})],
					buttons: [
						{
							text: formatMessage({id: 'common.cancel'}),
							style: BUTTON_STYLE.FILLED,
							color: BUTTON_COLOR.WHITE,
							callback: callbackCancel,
						},
						{
							text: formatMessage({id: 'common.push'}),
							style: BUTTON_STYLE.FILLED,
							color: BUTTON_COLOR.GREEN,
							callback: callbackPositive.bind(this, 'push'),
						},
					],
				});
			} else if (endDate.isSameOrAfter(nextPeriodStartDate)) {
				warning = true;
				showModal({
					type: MODAL_TYPE.WARNING_RADIO_BUTTON_MODAL,
					warningMessageId: 'retainer.moving_date_warning_message',
					warningInformation: [formatMessage({id: 'warning_modal.edit_warning_title_choice'})],
					callbackCancel: callbackCancel,
					callbackPositive: callbackPositive.bind(this),
					radioOptions: [
						{
							text: formatMessage({id: 'retainer.push_all_periods'}),
							key: 'push',
						},
						{
							text: formatMessage({id: 'retainer.shorten_next_period'}),
							key: 'shorten',
						},
					],
				});
			}
		}

		if (!warning) {
			Util.CommitMutation(UpdateRetainerPeriodMutation, mutationObject, onSuccess);
		}
	}

	render() {
		const useNewFormat = this.props.useNewFormat;
		const project = this.props.project;
		const inHours = this.props.inHours;
		const period = this.props.period.node;
		const {formatMessage, formatNumber} = this.props.intl;
		const hasFinancialServiceFlag = hasFeatureFlag('planned_values_on_retainer_periods');
		const hasRolloverFlag = hasFeatureFlag('rollover_values_on_retainer_periods');
		const financialNumbers = period.financialNumbers;

		const hasWarning = this.props.conflictedTimeEntries.length > 0;
		const textColor = Util.getTextColorV2(this.props.backgroundColor);
		const isWhite = textColor === '#ffffff';
		const available = this.props.availableForPeriod;
		const actualHours =
			Math.round(
				(hasFinancialServiceFlag
					? financialNumbers.billableActualMinutes / 60
					: this.props.timeRegs.reduce((acc, timeReg) => acc + timeReg.node.billableMinutesRegistered / 60, 0)) *
					100.0
			) / 100.0;
		const actualPrice = hasFinancialServiceFlag
			? financialNumbers.billableActualTimeAndExpenses
			: this.props.timeRegs.reduce((acc, timeReg) => acc + timeReg.node.price, 0);
		const periodTarget =
			hasFinancialServiceFlag && hasRolloverFlag
				? inHours
					? financialNumbers.retainerPeriodTargetMinutes / 60
					: financialNumbers.retainerPeriodTargetPrice
				: inHours
				? period.periodHoursAmount
				: period.periodPriceAmount;
		const periodForecast = inHours
			? actualHours + this.props.remainingPlanForPeriod
			: actualPrice + this.props.remainingPlanForPeriod;
		const periodDifference =
			hasFinancialServiceFlag && hasRolloverFlag
				? inHours
					? financialNumbers.retainerPeriodRolloverMinutes / 60
					: financialNumbers.retainerPeriodRolloverPrice
				: inHours
				? period.periodDifferenceHoursAmount + period.sharedPeriodDifferenceHoursAmount
				: period.periodDifferencePriceAmount + period.sharedPeriodDifferencePriceAmount;
		const periodTargetTotal = periodTarget + periodDifference;
		const periodProgress =
			periodTargetTotal <= 0
				? 100
				: inHours
				? formatNumber((actualHours / periodTargetTotal) * 100, {format: 'rounded_one_decimal'})
				: formatNumber((actualPrice / periodTargetTotal) * 100, {format: 'rounded_one_decimal'});
		const currencySymbol = Util.GetCurrencySymbol(
			project.rateCard ? project.rateCard.currency : this.props.viewer.company.currency
		);
		const isEstimatedInPoints = this.props.isEstimatedInPoints;

		return (
			<div
				style={{backgroundColor: this.props.backgroundColor}}
				className={'retainer-period-header-wrapper'}
				onClick={this.props.onExpansionClick.bind(this)}
				data-cy={'retainer-period-header'}
			>
				<div className="period-select-checkbox">
					<Checkbox
						isChecked={this.props.isSelected}
						onClick={selected => this.props.handleSelectedChanged(selected, period.id)}
					/>
				</div>
				<div className="period-header-elem">
					<div className={'period-name-and-indicators'} ref={element => (this._period_name_indicator = element)}>
						{period.periodLocked ? (
							<LockedIcon
								width={12}
								height={16}
								fill={textColor}
								className="status-icon"
								title={formatMessage(
									{id: 'retainer.period_locked'},
									{lockedTime: Moment(period.periodLockedTime).format('LL')}
								)}
							/>
						) : null}
						<div className={'period-name'} title={this.state.inputPeriodTitle} style={{color: textColor}}>
							<div className="editable">
								<input
									type="text"
									ref={input => {
										this.nameInput = input;
									}}
									value={this.state.inputPeriodTitle}
									onChange={this.handlePeriodTitleChange.bind(this)}
									onBlur={this.handleSavePeriodTitle.bind(this)}
									disabled={this.props.projectLocked || period.periodLocked}
									maxLength={191}
									spellCheck={false}
									onKeyPress={this.handleKeyPressName.bind(this)}
								/>
							</div>
						</div>
						{hasWarning ? (
							<div className={'warning-section'} onClick={this.toggleShowConflictModal.bind(this)}>
								<WarningIcon
									className="warning-icon"
									title={formatMessage({id: 'retainer.period.confliting_info'})}
									danger={true}
								/>
								<div className={'warning-text'} style={{color: textColor}}>
									{this.state.hideText ? null : formatMessage({id: 'retainer.period.confliting'})}
								</div>
							</div>
						) : null}
					</div>
				</div>
				<div className="period-header-elem">
					<div style={{color: textColor}} className={'period-date'}>
						<div className="date-picker-wrapper" key={this.state.key}>
							<DatePicker
								buttonCy={this.props.cy + '-date-button'}
								disabled={this.props.projectLocked || period.periodLocked}
								id={period.id}
								isNewDateRange={true}
								startDate={Util.CreateNonUtcMomentDate(period.startYear, period.startMonth, period.startDay)}
								endDate={Util.CreateNonUtcMomentDate(period.endYear, period.endMonth, period.endDay)}
								handleDateRangeChange={this.handleDateRangeChange.bind(this)}
								clearable={false}
								//startFrom={phase && phase.name ? phase.name : formatMessage({id: 'common.untitled'})}
								//endFrom={phase && phase.name ? phase.name : formatMessage({id: 'common.untitled'})}
								datePickerStyle={DATE_PICKER_STYLE.SCOPING_PHASE_DATE}
								colorInherited={true}
								compactShowYear={true}
							/>
						</div>
					</div>
				</div>
				{this.props.hasTarget ? (
					<div className="period-header-elem">
						<div style={{color: textColor}} className={'period-progress'}>
							<div className="period-data-text">{periodProgress + '%'}</div>
						</div>
					</div>
				) : null}

				<div className="period-header-elem">
					<div style={{color: textColor}} className={'period-type'}>
						<div className="period-data-text">
							{Util.getPeriodBudgetTypeTranslation(period.periodBudgetType, this.props.intl)}
						</div>
					</div>
				</div>

				{this.props.hasTarget ? (
					<div className="period-header-elem">
						<div style={{color: textColor}} className={'period-target'}>
							<div data-cy="period-target-text" className="period-data-text">
								{this.props.withSuffix(periodTargetTotal)}
							</div>
						</div>
					</div>
				) : null}
				{!isEstimatedInPoints && (
					<>
						<div className="period-header-elem">
							<div style={{color: textColor}} className={'period-plan'}>
								<div data-cy="period-plan-text" className="period-data-text">
									{this.props.withSuffix(this.props.planForPeriod)}
								</div>
							</div>
						</div>
						<div className="period-header-elem">
							<div style={{color: textColor}} className={'period-remaining-plan'}>
								<div className="period-data-text">
									{this.props.withSuffix(this.props.remainingPlanForPeriod)}
								</div>
							</div>
						</div>
					</>
				)}
				{this.props.showRemainingCapacity && inHours ? (
					<div className="period-header-elem">
						<div style={{color: textColor}} className={'period-allocation'}>
							<div className="period-data-text">
								{useNewFormat
									? Util.convertMinutesToFullHour(available, this.props.intl)
									: (available ? formatNumber(available) : 0) + formatMessage({id: 'common.hours.short'})}
							</div>
						</div>
					</div>
				) : null}
				<div className="period-header-elem">
					<div style={{color: textColor}} className={'period-worked-hours'}>
						{inHours ? (
							<div data-cy="period-time-entries-text" className="period-data-text">
								{useNewFormat
									? Util.convertMinutesToFullHour(actualHours * 60, this.props.intl)
									: (actualHours ? actualHours : 0) + formatMessage({id: 'common.hours.short'})}
							</div>
						) : (
							<div className="period-data-text">
								{Util.getFormattedCurrencyValue(
									currencySymbol,
									formatNumber(actualPrice, {format: 'always_two_decimal'})
								)}
							</div>
						)}
					</div>
				</div>
				<div className="period-header-elem">
					<div style={{color: textColor}} className={'period-forecast'}>
						<div className="period-data-text">
							{inHours
								? useNewFormat
									? Util.convertMinutesToFullHour(periodForecast * 60, this.props.intl)
									: formatNumber(periodForecast) + formatMessage({id: 'common.hours.short'})
								: Util.getFormattedCurrencyValue(
										currencySymbol,
										formatNumber(periodForecast, {format: 'always_two_decimal'})
								  )}
						</div>
					</div>
				</div>
				<div style={{color: textColor}} className={'period-controls'}>
					<TooltipIcon
						infoText={
							this.props.expanded
								? formatMessage({id: 'workflow.collapse'})
								: formatMessage({id: 'workflow.expand'})
						}
						cy={'retainer-expand-control'}
						userpilot={'retainer-expand-control-' + this.props.userpilotIndex}
						className={
							'expand-control ' + (this.props.expanded ? 'collapse' : 'expand') + (isWhite ? '' : ' black-icon')
						}
					/>
					{this.props.projectLocked ? (
						''
					) : (
						<ActionsMenu
							userpilot={'retainer-actions-menu-' + this.props.userpilotIndex}
							useBlackIcon={!isWhite}
							isWhite={isWhite}
							whiteInner={true}
							options={this.getActionMenuOptions()}
							cy={this.props.cy}
						/>
					)}
				</div>
			</div>
		);
	}
}
ProjectRetainerPeriodHeader.propTypes = {
	backgroundColor: PropTypes.string.isRequired,
	period: PropTypes.object.isRequired,
};
export default injectIntl(ProjectRetainerPeriodHeader);
