import React, {useEffect, useState} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {SystemStatusMessage} from '@forecast-it/design-system';
import BudgetPage from '../../../../components/new-ui/project/project-budget-v3/BudgetPage';
import Util from '../../../shared/util/util';
import RatesAndInternalCostPage from '../../../../components/new-ui/project/project-budget-v3/RatesAndInternalCost/rates_and_internal_cost_page';
import ExpensesPage from '../../../../components/new-ui/project/project-budget-v3/expenses_page';
import HelpCenterIcon from '../../../../components/new-ui/help_center_icon';
import {TopHeaderBar} from '../../../shared/components/headers/top-header-bar/TopHeaderBar';
import {useIntl} from 'react-intl';
import {BudgetContentWrapper, BudgetWrapper} from './ProjectFinancialsPage.styled';
import {BUDGET_TYPE, ELEMENT_TYPE, PROJECT_STATUS} from '../../../../constants';
import BudgetWarningTab from '../../../../components/new-ui/project/project-budget-v3/components/budget_warning_tab';
import FinancialCalculationTrigger, {
	financialCalculationTriggerQuery,
} from '../../../../components/new-ui/project/project-budget-v3/loaders/FinancialCalculationTrigger';
import {LoadMore} from '../../../../components/loaders/LoadMore';
import {hasPermission, hasSomePermission} from '../../../shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../Permissions';
import RevenueRecognitionPage from '../../../../components/new-ui/project/project-budget-v3/RevenueRecognitionPage';
import {hasFeatureFlag} from '../../../shared/util/FeatureUtil';
import ProjectTaskReportPage, {
	projectTaskReportPageQuery,
} from '../../../../containers/project/budget/task_report/project_task_report_page';
import ForecastQueryRenderer from '../../../../ForecastQueryRenderer';
import {EVENT_ID, subscribe, unsubscribe} from '../../../../containers/event_manager';
import {createProjectUpdateToast} from '../../../../components/new-ui/project/project-budget-v3/util/BudgetUtils';
import BudgetWarningsLoader, {budgetWarningsLoaderQuery} from '../../../../containers/project/BudgetWarningsLoader';
import * as tracking from '../../../../tracking';
import ProjectUtil from '../../../shared/util/project_util';
import RatesAndInternalCostLitePage from '../../../../components/new-ui/project/project-budget-v3/RatesAndInternalCost/RatesAndInternalCostLitePage';
import CompanySetupUtil from '../../../shared/util/CompanySetupUtil';
import {withRouter} from 'react-router-dom';
import ProjectHeader from '../shared/ProjectHeader';
import {dateFragmentPropsFromIntervalInIsoFormat, dateObjectToIsoDate} from '../../../shared/util/DateUtil';
import moment from 'moment';
import ProjectInvoicingPage, {
	ProjectInvoicingPageQuery,
} from '../../../../containers/project/budget/invoicing/ProjectInvoicingPage';
import ProjectBreakdownPage from '../../../../containers/project/budget/breakdown/ProjectBreakdownPage';
import HeaderBar from '../../../shared/components/headers/header-bar/header_bar';
import {MODAL_TYPE, showModal} from '../../../shared/components/modals/generic_modal_conductor';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import {hasProjectFinancialsBasedOnAllocations} from '../../../navigation/secondary/SecondaryNavigation';

const ProjectFinancialsPage = ({history, updateCurrentPath, ...props}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const {availableFeatureFlags} = props.viewer;
	const [retryCallback, setRetryCallback] = useState();
	const [retryProcessing, setRetryProcessing] = useState(false);
	const [retryProps, setRetryProps] = useState();
	const [financialCalculationTriggerTimestamp, setFinancialCalculationTriggerTimestamp] = useState();

	const hasFinancials = CompanySetupUtil.hasFinance();
	const hasFinancialAccess = hasSomePermission([
		PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
		PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
	]);
	// Prevent loading the page by manually typing in the URL
	if (!hasFinancialAccess || !(hasFinancials || CompanySetupUtil.hasFinanceLight())) {
		// Remove project-section-last-viewed to avoid people getting locked out of projects
		Util.localStorageRemoveItem('project-section-last-viewed');
		history.goBack();
	}
	const hasBaseline = Util.hasBaselineModule() && props.viewer.project.useBaseline;
	const hasRevenueWithoutCostAccess = Util.hasRevenueWithoutCostAccess();
	const hasExpenses = props.viewer.project.expenseItems.edges.length > 0;
	const showCost = hasPermission(PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION) && ProjectUtil.projectTracksCost();
	const tracksRevenue = ProjectUtil.projectTracksRevenue(props.viewer.project);
	const showRevenueRecognition =
		tracksRevenue &&
		(props.viewer.project.budgetType === BUDGET_TYPE.FIXED_PRICE_V2 ||
			hasFeatureFlag('revenue_recognition_with_all_budget_types'));
	const showInvoicing =
		(props.viewer.project.hasInvoices || props.viewer.project.budgetType !== BUDGET_TYPE.NON_BILLABLE) &&
		hasPermission(PERMISSION_TYPE.INVOICE_READ);

	const showProjectBreakdown =
		hasFeatureFlag('project_level_financials_from_allocations') &&
		hasProjectFinancialsBasedOnAllocations(props.viewer.project);

	const getTabKey = prevTab =>
		prevTab === 'TASK_REPORT' || prevTab === 'BREAKDOWN' ? (showProjectBreakdown ? 'BREAKDOWN' : 'TASK_REPORT') : prevTab;

	let retryWarningQuery;

	let tabs = {
		DETAILS: 'project_budget.details',
	};

	if (
		hasPermission(PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION) ||
		hasPermission(PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE)
	) {
		tabs = {
			...tabs,
			RATES_AND_INTERNAL_COST: tracksRevenue
				? showCost
					? 'project_budget.rates_and_internal_cost'
					: 'common.rates'
				: 'project_budget.internal_cost',
		};
	}

	if (showRevenueRecognition) {
		if (CompanySetupUtil.hasRevenueRecognition()) {
			tabs = {
				...tabs,
				REVENUE_RECOGNITION: 'project_budget.revenue_recognition',
			};
		}
		if (hasFinancials) {
			tabs = {
				...tabs,
				TASK_REPORT: 'project_budget.task_breakdown',
				BREAKDOWN: 'project_budget.breakdown',
			};
		}
	}

	if (hasFinancials && hasPermission(PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION)) {
		tabs = {
			...tabs,
			EXPENSES: 'project_budget.expenses',
		};
	}

	if (hasFeatureFlag('invoicing_at_project_level') && hasFinancials && showInvoicing) {
		tabs = {
			...tabs,
			INVOICING: 'common.invoicing',
		};
	}

	const [selectedTab, setSelectedTab] = useState(() => {
		const savedSelectedTab = localStorage.getItem('project-budget-v3-last-selected-tab');
		const savedTabExists = Object.values(tabs).includes(savedSelectedTab);
		if (hasFinancials && savedTabExists) {
			const newTabKey = getTabKey(savedSelectedTab);
			return tabs[newTabKey];
		} else {
			return tabs.DETAILS;
		}
	});

	const getTabName = tab => {
		for (const [key, value] of Object.entries(tabs)) {
			if (value === tab) {
				return key.toLowerCase();
			}
		}
		return null;
	};

	const updateProject = props => {
		if (retryCallback) {
			retryCallback();
			setRetryProcessing(true);
			setRetryProps(props);
		}
		if (retryWarningQuery) {
			retryWarningQuery();
		}
	};

	const handleRetryCompletion = result => {
		if (!result.isSmallProject && !retryProps?.noToast) {
			createProjectUpdateToast(intl, result.duration);
		}
	};

	function handleFinancialCalculationTriggerResponse(retry, result) {
		if (!retryCallback) {
			setRetryCallback(() => retry);
		}
		if (retryProcessing && result.timestamp !== financialCalculationTriggerTimestamp) {
			handleRetryCompletion(result);
			setRetryProcessing(false);
		}
	}

	useEffect(() => {
		subscribe(EVENT_ID.BUDGET_UPDATE_PROJECT, updateProject);
		return () => {
			unsubscribe(EVENT_ID.BUDGET_UPDATE_PROJECT, updateProject);
		};
	});

	useEffect(() => {
		tracking.trackPage('Project-budget');

		if (history.location.hash) {
			const prevTab = history.location.hash.replace('#', '').toUpperCase().split('+')[0];
			const newTabKey = getTabKey(prevTab);
			setSelectedTab(tabs[newTabKey]);
			const newPath = `${location.pathname}#${getTabName(tabs[newTabKey])}`;
			updateCurrentPath(newPath);
		} else {
			const selectedTabName = getTabName(selectedTab);
			if (selectedTabName) {
				const newPath = `${location.pathname}#${selectedTabName}`;
				history.push(newPath);
				updateCurrentPath(newPath);
			}
		}
	}, [history.location.hash, props.viewer.project]);

	const renderSelectedTab = result => {
		Util.localStorageSetItem('project-budget-v3-last-selected-tab', selectedTab);
		switch (selectedTab) {
			case tabs.DETAILS:
				return (
					<BudgetPage
						key={'BudgetPage'}
						timestamp={result.timestamp}
						viewer={props.viewer}
						project={props.viewer.project}
						company={props.viewer.company}
						retry={props.retry}
						hasFinancials={hasFinancials}
						hasBaseline={hasBaseline}
						hasRevenueWithoutCostAccess={hasRevenueWithoutCostAccess}
						hasExpenses={hasExpenses}
					/>
				);

			case tabs.RATES_AND_INTERNAL_COST:
				return CompanySetupUtil.hasFinanceLight() ? (
					<RatesAndInternalCostLitePage
						key={'RatesAndInternalCostPage'}
						timestamp={result.timestamp}
						project={props.viewer.project}
						company={props.viewer.company}
					/>
				) : (
					<RatesAndInternalCostPage
						key={'RatesAndInternalCostPage'}
						timestamp={result.timestamp}
						project={props.viewer.project}
						company={props.viewer.company}
						relay={props.relay}
						retry={props.retry}
						availableFeatureFlags={availableFeatureFlags}
					/>
				);

			case tabs.EXPENSES:
				return (
					<ExpensesPage
						key={'ExpensesPage'}
						timestamp={result.timestamp}
						project={props.viewer.project}
						company={props.viewer.company}
						retry={props.retry}
						availableFeatureFlags={availableFeatureFlags}
						actualPersonId={props.viewer.actualPersonId}
					/>
				);

			case tabs.REVENUE_RECOGNITION:
				return (
					<RevenueRecognitionPage
						key={'RecognitionPage'}
						timestamp={result.timestamp}
						viewer={props.viewer}
						retry={props.retry}
						availableFeatureFlags={availableFeatureFlags}
						project={props.viewer.project}
						company={props.viewer.company}
						hasExpenses={hasExpenses}
					/>
				);

			case tabs.TASK_REPORT:
				return (
					<ForecastQueryRenderer
						key="query-render-project-task-report"
						query={projectTaskReportPageQuery}
						variables={{
							projectId: props.viewer.project.id,
						}}
						render={relayProps => <ProjectTaskReportPage {...relayProps} />}
					/>
				);
			case tabs.BREAKDOWN:
				return (
					<ProjectBreakdownPage projectId={props.viewer.project.id} budgetType={props.viewer.project.budgetType} />
				);
			case tabs.INVOICING:
				const defaultStartDate = dateObjectToIsoDate(moment().startOf('month'));
				const defaultEndDate = dateObjectToIsoDate(moment().endOf('month'));

				const variables = {
					projectId: props.viewer.project.id,
					...dateFragmentPropsFromIntervalInIsoFormat(defaultStartDate, defaultEndDate),
				};
				return (
					<ForecastQueryRenderer
						key="query-render-project-invoicing-page"
						query={ProjectInvoicingPageQuery}
						variables={variables}
						render={relayProps => <ProjectInvoicingPage {...relayProps} />}
					/>
				);
			default:
				return null;
		}
	};

	const getHeaderTitleContent = () => {
		const content = [];

		content.push(
			<HelpCenterIcon key={'help-center-icon'} href={'https://support.forecast.app/hc/en-us/articles/5101281131537'} />
		);

		if (props.viewer.project.status !== PROJECT_STATUS.DONE && props.viewer.project.status !== PROJECT_STATUS.HALTED) {
			content.push(
				<div key={'top_header_warning_button'} className="warning-button">
					<LoadMore
						key="query-render-project-budget-warnings"
						query={budgetWarningsLoaderQuery}
						variables={{
							projectId: props.viewer.project.id,
						}}
						loader={<BudgetWarningsLoader />}
					>
						{({data, retry}) => {
							retryWarningQuery = retry;
							return (
								<BudgetWarningTab
									setSelectedTab={tab => setSelectedTab(tab)}
									hasFinancials={hasFinancials}
									project={data.project}
									tabs={tabs}
									viewer={data.viewer}
									relay={props.relay}
									retry={retry}
								/>
							);
						}}
					</LoadMore>
				</div>
			);
		}

		if (showRevenueRecognition) {
			const email = props.viewer.email;
			content.push({
				type: TopHeaderBar.TYPE.FEEDBACK,
				link:
					'https://www.forecast.app/feedback-revenue-recognition' +
					(email ? '?email=' + encodeURIComponent(email) : ''),
			});
		}

		return content;
	};

	const getPageHeaderName = showCost => {
		switch (selectedTab) {
			case tabs.DETAILS:
				return formatMessage({id: 'financial.page_header_overview'});
			case tabs.RATES_AND_INTERNAL_COST:
				return showCost
					? formatMessage({id: 'financial.page_header_rates_internal_costs'})
					: formatMessage({id: 'common.rates'});
			case tabs.EXPENSES:
				return formatMessage({id: 'financial.page_header_expenses'});
			case tabs.REVENUE_RECOGNITION:
				return formatMessage({id: 'financial.page_header_revenue_recognition'});
			case tabs.TASK_REPORT:
				return formatMessage({id: 'financial.page_header_task_breakdown'});
			case tabs.BREAKDOWN:
				return formatMessage({id: 'financial.page_header_breakdown'});
			case tabs.INVOICING:
				return formatMessage({id: 'financial.page_header_invoicing'});
			default:
				return formatMessage({id: 'financial.page_header_default'});
		}
	};

	const getPageHeaderButtons = () => {
		switch (selectedTab) {
			case tabs.DETAILS:
				const sageEnabled =
					props.viewer.company.sageIntacctConnectionEnabled &&
					hasFeatureFlag('sage_intacct_integration') &&
					hasFeatureFlag('sage_allow_manual_sync');
				const rightContent = [];
				if (props.viewer.project.sageProject.sageProjectId) {
					rightContent.push({
						type: ELEMENT_TYPE.SYSTEM_STATUS_MESSAGE,
						id: 'sage-integration-project-sync-status',
						text: 'Synced with Sage Intacct',
						linkText: 'Update',
						status: SystemStatusMessage.STATUS.GREEN,
						callback: () => {
							showModal({
								type: MODAL_TYPE.SAGE_INTACCT_EXPORT_TASKS,
								forecastProjectId: props.viewer.project.id,
								sageIntacctProjectId: props.viewer.project.sageProject.sageProjectId,
							});
						},
					});
				} else {
					rightContent.push({
						type: ELEMENT_TYPE.SKYLINE_BUTTON,
						id: 'sage-integration-sync-project',
						text: 'Sync Project with Sage Intacct',
						callback: () => {
							tracking.trackEvent('Sync project with Sage Intacct pressed');
							trackEvent('Sync Project With Sage Intacct', 'Clicked');
							showModal({
								type: MODAL_TYPE.SAGE_INTACCT_SYNC_PROJECT,
								forecastProjectId: props.viewer.project.id,
								forecastProjectName: props.viewer.project.name,
							});
						},
						emphasis: 'medium',
					});
				}

				return sageEnabled ? <HeaderBar leftContent={[]} rightContent={rightContent} /> : undefined;
			default:
				return undefined;
		}
	};

	return (
		<LoadMore
			key="financial-calculation-trigger"
			query={financialCalculationTriggerQuery}
			variables={{
				projectId: props.viewer.project.id,
			}}
			loader={<FinancialCalculationTrigger />}
		>
			{(result, retry) => {
				setFinancialCalculationTriggerTimestamp(result.timestamp);
				handleFinancialCalculationTriggerResponse(retry, result);

				return (
					<BudgetWrapper data-cy={'budget-page'}>
						{props.children} {/* renders the task modal if open */}
						<ProjectHeader
							title={getPageHeaderName(showCost)}
							project={props.viewer.project}
							psProject={props.viewer.psProject}
							titleContent={getHeaderTitleContent()}
							buttons={getPageHeaderButtons()}
						/>
						<BudgetContentWrapper>{renderSelectedTab(result)}</BudgetContentWrapper>
					</BudgetWrapper>
				);
			}}
		</LoadMore>
	);
};

const ProjectFinancialsPageQuery = graphql`
	query ProjectFinancialsPage_Query($projectId: String, $currentYear: Int, $currentMonth: Int, $currentMonthLastDay: Int) {
		viewer {
			actualPersonId
			project(id: $projectId) {
				id
			}
			component(name: "project_budget_v3", projectId: $projectId)
			...ProjectFinancialsPage_viewer
				@arguments(
					projectId: $projectId
					currentYear: $currentYear
					currentMonth: $currentMonth
					currentMonthLastDay: $currentMonthLastDay
				)
		}
	}
`;

export {ProjectFinancialsPageQuery};

export default withRouter(
	createFragmentContainer(ProjectFinancialsPage, {
		viewer: graphql`
			fragment ProjectFinancialsPage_viewer on Viewer
			@argumentDefinitions(
				projectId: {type: "String"}
				currentYear: {type: "Int"}
				currentMonth: {type: "Int"}
				currentMonthLastDay: {type: "Int"}
			) {
				id
				actualPersonId
				email
				project(id: $projectId) {
					id
					name
					budgetType
					useBaseline
					projectEndYear
					projectEndMonth
					projectEndDay
					companyProjectId
					customProjectId
					hasInvoices
					sageProject {
						id
						sageProjectId
					}
					expenseItems(first: 10000) @connection(key: "Project_expenseItems") {
						edges {
							node {
								id
								category {
									id
									name
								}
								phase {
									id
									name
								}
								name
								approved
								expenseYear
								expenseMonth
								expenseDay
								billable
								price
								cost
								quantity
								name
								planned
								createdAt
								invoiced
								person {
									id
									fullName
									profilePictureId
									profilePictureDefaultId
								}
							}
						}
					}
					financialSourceSettings {
						plannedCost
						plannedRevenue
						actualCost
						actualRevenue
						forecastCost
						forecastRevenue
					}
					...BudgetPage_project
						@arguments(
							projectId: $projectId
							currentYear: $currentYear
							currentMonth: $currentMonth
							currentMonthLastDay: $currentMonthLastDay
						)
					...expensesPage_project
					...ratesAndInternalCostPage_project
					...RatesAndInternalCostLitePage_project
					...RevenueRecognitionPage_project
					...ProjectHeader_project
					...SecondaryNavigation_project
				}
				psProject(companyProjectId: $projectId) {
					...ProjectHeader_psProject
				}
				company {
					id
					tier
					...BudgetPage_company
					...expensesPage_company
					...ratesAndInternalCostPage_company
					...RatesAndInternalCostLitePage_company
					...RevenueRecognitionPage_company
					modules {
						moduleType
					}
					sageIntacctConnectionEnabled
				}
			}
		`,
	})
);
