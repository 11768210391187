/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type DeprecatedProjectIndicatorJS_project$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type NotificationMenu_viewer$ref: FragmentReference;
declare export opaque type NotificationMenu_viewer$fragmentType: NotificationMenu_viewer$ref;
export type NotificationMenu_viewer = {|
  +backendId: ?number,
  +unseenNotifications: ?number,
  +recentNotifications: ?$ReadOnlyArray<?{|
    +id: string,
    +countId: ?number,
    +publisherPersonId: string,
    +subscriberPersonId: string,
    +publisherAction: ?string,
    +entityType: ?string,
    +entityId: ?number,
    +params: ?string,
    +createdAt: ?string,
    +read: ?boolean,
  |}>,
  +notifyInAppOnProjectStatusChange: ?boolean,
  +notifyInAppOnPersonJoin: ?boolean,
  +notifyInAppOnAssignedProject: ?boolean,
  +notifyInAppOnProjectDeadline: ?boolean,
  +notifyInAppOnAssignedTask: ?boolean,
  +notifyInAppOnAssignedTaskUpdate: ?boolean,
  +notifyInAppOnTaskDeadline: ?boolean,
  +notifyInAppOnMention: ?boolean,
  +notifyInAppOnTaskDescriptionChange: ?boolean,
  +notifyInAppOnTaskTitleChange: ?boolean,
  +notifyInAppOnTaskEstimateChange: ?boolean,
  +notifyInAppOnTaskStartDateChange: ?boolean,
  +notifyInAppOnTaskEndDateChange: ?boolean,
  +notifyInAppOnTaskSprintChange: ?boolean,
  +notifyInAppOnTaskPhaseChange: ?boolean,
  +notifyInAppOnTaskStatusColumnChange: ?boolean,
  +notifyInAppOnTaskProjectChange: ?boolean,
  +notifyInAppOnTaskSubtaskChange: ?boolean,
  +notifyInAppOnTaskSubtaskEstimateChange: ?boolean,
  +notifyInAppOnTaskCommentChange: ?boolean,
  +notifyInAppOnTaskFileChange: ?boolean,
  +notifyInAppOnTaskBugChange: ?boolean,
  +notifyInAppOnTaskBlockedChange: ?boolean,
  +notifyInAppOnTaskRepeatingChange: ?boolean,
  +notifyInAppOnTimeOffManager: ?boolean,
  +notifyInAppOnTimeOffOwner: ?boolean,
  +notifyInAppOnInvoiceDueDate: ?boolean,
  +notifyInAppOnInvoiceOverdue: ?boolean,
  +notifyInAppOnInvoiceDaysOverdue: ?boolean,
  +notifyInAppOnInvoiceCreatedOrDeleted: ?boolean,
  +notifyInAppOnInvoiceStatusChange: ?boolean,
  +notifyInAppOnInvoiceDateReached: ?boolean,
  +notifyInAppOnInvoicePayment: ?boolean,
  +notifyInAppOnInvoiceDateChanged: ?boolean,
  +notifyInAppOnInvoiceDueDateChanged: ?boolean,
  +company: ?{|
    +allPersons: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +fullName: ?string,
          +profilePictureId: ?string,
          +profilePictureDefaultId: ?number,
          +initials: ?string,
        |}
      |}>
    |}
  |},
  +projects: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +companyProjectId: ?number,
        +customProjectId: ?string,
        +$fragmentRefs: DeprecatedProjectIndicatorJS_project$ref,
      |}
    |}>
  |},
  +$refType: NotificationMenu_viewer$ref,
|};
export type NotificationMenu_viewer$data = NotificationMenu_viewer;
export type NotificationMenu_viewer$key = {
  +$data?: NotificationMenu_viewer$data,
  +$fragmentRefs: NotificationMenu_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotificationMenu_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "backendId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unseenNotifications",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PersonNotification",
      "kind": "LinkedField",
      "name": "recentNotifications",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "countId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "publisherPersonId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subscriberPersonId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "publisherAction",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entityType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entityId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "params",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "read",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnProjectStatusChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnPersonJoin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnAssignedProject",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnProjectDeadline",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnAssignedTask",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnAssignedTaskUpdate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnTaskDeadline",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnMention",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnTaskDescriptionChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnTaskTitleChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnTaskEstimateChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnTaskStartDateChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnTaskEndDateChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnTaskSprintChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnTaskPhaseChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnTaskStatusColumnChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnTaskProjectChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnTaskSubtaskChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnTaskSubtaskEstimateChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnTaskCommentChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnTaskFileChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnTaskBugChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnTaskBlockedChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnTaskRepeatingChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnTimeOffManager",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnTimeOffOwner",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnInvoiceDueDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnInvoiceOverdue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnInvoiceDaysOverdue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnInvoiceCreatedOrDeleted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnInvoiceStatusChange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnInvoiceDateReached",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnInvoicePayment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnInvoiceDateChanged",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notifyInAppOnInvoiceDueDateChanged",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 48714
            },
            {
              "kind": "Literal",
              "name": "includeSystem",
              "value": true
            }
          ],
          "concreteType": "PersonTypeConnection",
          "kind": "LinkedField",
          "name": "allPersons",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PersonTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Person",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "fullName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "profilePictureId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "profilePictureDefaultId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "initials",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "allPersons(first:48714,includeSystem:true)"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 10000
        }
      ],
      "concreteType": "ProjectTypeConnection",
      "kind": "LinkedField",
      "name": "projects",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "companyProjectId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "customProjectId",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "DeprecatedProjectIndicatorJS_project"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "projects(first:10000)"
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '4a650d27243bfd36b5387997b1dd97e3';

module.exports = node;
