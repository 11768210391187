import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';
import {showModal, MODAL_TYPE} from '../../../shared/components/modals/generic_modal_conductor';
import Warning from '../../../../components/warning';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../../constants';

class ProjectFilesDeleteButton extends Component {
	delete() {
		const {formatMessage} = this.props.intl;
		const callbackPositive = params => {
			if (this.props.file && this.props.file.node.link) {
				this.props.unlinkFile(this.props.file.node.id, this.props.file.node.task ? this.props.file.node.task.id : null);
			} else if (this.props.file) {
				this.props.deleteFile(this.props.file);
			} else if (this.props.folder) {
				this.props.deleteFolder(this.props.folder);
			} else {
				throw new Error('Neither file nor folder set in delete button props');
			}
		};

		showModal({
			type: MODAL_TYPE.GENERIC,
			content: (
				<div className="default-warning-modal">
					<Warning messageId="common.delete-confirmation-title" />
					<div className="warning-part-2">{formatMessage({id: 'common.warning.this_action_can_not_be_undone'})}</div>
				</div>
			),
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.delete'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
					callback: callbackPositive,
				},
			],
		});
	}

	render() {
		return (
			<div className="delete-link-container" onClick={this.delete.bind(this)}>
				<FormattedMessage
					id={
						this.props.file && this.props.file.node.link
							? 'project_files.google_drive_link_unlink'
							: 'common.delete'
					}
				/>
			</div>
		);
	}
}

ProjectFilesDeleteButton.propTypes = {
	file: PropTypes.object,
	folder: PropTypes.object,
	unlinkFile: PropTypes.func,
	deleteFile: PropTypes.func,
	deleteFolder: PropTypes.func,
	intl: PropTypes.object.isRequired,
};

export default injectIntl(ProjectFilesDeleteButton);
