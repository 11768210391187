var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { RichTextEditor } from '@forecast-it/design-system';
import React from 'react';
import { useDraftJsHtmlConversion } from './useDraftJsConversion';
export const RichTextEditorDraftJsWrapper = (_a) => {
    var { peopleMentions, initialDraftJsContent, onDraftJsChanged, 'data-cy': dataCy, id = 'editor', editorRef, readOnly } = _a, rest = __rest(_a, ["peopleMentions", "initialDraftJsContent", "onDraftJsChanged", 'data-cy', "id", "editorRef", "readOnly"]);
    const { initialHtml, convertHtmlToDraftJs } = useDraftJsHtmlConversion({
        initialDraftJsContent,
        onDraftJsChanged,
    });
    return (React.createElement(RichTextEditor, Object.assign({ id: id, "data-cy": dataCy, peopleMentions: peopleMentions, onBlur: convertHtmlToDraftJs, initialHtml: initialHtml, ref: editorRef, readOnly: readOnly }, rest)));
};
