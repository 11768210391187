import React, {useEffect, useState} from 'react';
import {VerticalNavigationBar} from '@forecast-it/design-system';
import {Link, useHistory} from 'react-router-dom';
import {BUDGET_TYPE, HIDDEN_FEATURES, MODULE_TYPES} from '../../../constants';
import Util from '../../shared/util/util';
import {hasPermission, hasSomePermission, isClientUser} from '../../shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import CompanySetupUtil, {isFeatureHidden} from '../../shared/util/CompanySetupUtil';
import {hasFeatureFlag} from '../../shared/util/FeatureUtil';
import {hasModule} from '../../shared/util/ModuleUtil';
import {useSecondaryNavState} from './SecondaryNavigationStateManager';
import {createFragmentContainer, graphql} from 'react-relay';
import {useIntl} from 'react-intl';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import ProjectUtil from '../../shared/util/project_util';
import {TABS} from '../../project-tab/projects/project-settings/ProjectSettingsUtil';
import {ZenModeable} from '../../shared/components/zen-mode/ZenModeable';
import {ValueSource} from '../../project-tab/projects/project-settings/financials/ValueCalculationsComponent';
import {projectGroupUrl, projectUrl} from '../../../directApi';

const NavigationLink = React.forwardRef(({navigate, target, onClick, ...props}, ref) => {
	const isModifiedEvent = event => !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);

	const handleClick = (event, navigate, target, onClick) => {
		try {
			if (onClick) onClick(event);
		} catch (ex) {
			event.preventDefault();
			throw ex;
		}

		if (
			!event.defaultPrevented && // onClick prevented default
			event.button === 0 && // ignore everything but left clicks
			(!target || target === '_self') && // let browser handle "target=_blank" etc.
			!isModifiedEvent(event) // ignore clicks with modifier keys
		) {
			event.preventDefault();
			// Timeout to make sure the dropdown has time to close
			setTimeout(() => {
				navigate();
			}, 50);
		}
	};

	return (
		<VerticalNavigationBar.Link
			ref={ref}
			{...props}
			onClick={event => {
				handleClick(event, navigate, target, onClick);
			}}
		>
			{props.children}
		</VerticalNavigationBar.Link>
	);
});

const getActiveSubMenu = currentPath => {
	if (currentPath.includes('/financials') || currentPath.includes('/budget')) {
		return 'financials';
	}
	if (currentPath.includes('/settings') || currentPath.includes('/group-settings')) {
		return 'settings';
	}
	return '';
};

export const hasProjectFinancialsBasedOnAllocations = project => {
	const fss = project?.financialSourceSettings;
	if (!fss) {
		return false;
	}
	return [
		fss.plannedCost,
		fss.plannedRevenue,
		fss.actualCost,
		fss.actualRevenue,
		fss.forecastCost,
		fss.forecastRevenue,
	].includes(ValueSource.ALLOCATION);
};

const SecondaryNavigation = ({project, projectGroup, currentPath, updateCurrentPath}) => {
	const {formatMessage} = useIntl();
	const {expanded, locked, toggleLock, open, close} = useSecondaryNavState();
	const [menuHeight, setMenuHeight] = useState(null);
	const history = useHistory();

	if (!project && !projectGroup) {
		return; // if no project or project group, don't render
	}

	const updateMenuHeight = () => {
		const topNav = document.querySelector('[class^="TopNavigation"]');
		if (topNav) {
			setMenuHeight(window.innerHeight - topNav.offsetHeight);
		}
	};
	useEffect(() => {
		window.addEventListener('resize', updateMenuHeight);
		updateMenuHeight();
		return () => window.removeEventListener('resize', updateMenuHeight);
	}, []);

	useEffect(() => {
		const unlisten = history.listen((location, _) => {
			updateCurrentPath(location.pathname + location.hash);
		});
		return () => {
			unlisten();
		};
	}, []);

	useEffect(() => {
		const localStorageKey = projectGroup ? 'project-group-section-last-viewed' : 'project-section-last-viewed';
		const prevPathEnding = localStorage.getItem(localStorageKey);
		let thisPathEnding = history.location.pathname.split('/').pop();
		thisPathEnding += history.location.hash || '';
		if (thisPathEnding !== prevPathEnding) {
			Util.localStorageSetItem(localStorageKey, thisPathEnding);
		}
	});

	const navLinkBase = project
		? projectUrl(project.companyProjectId, project.customProjectId)
		: projectGroupUrl(projectGroup.companyProjectGroupId);
	const clientUser = isClientUser();
	const sprintsEnabled = project?.sprintTimeBox || projectGroup?.projectHeaderProjects?.edges[0]?.node?.sprintTimeBox;
	const hasRetainerAccess = hasPermission(PERMISSION_TYPE.RETAINER_PERIOD_READ);
	const canUpdateProjects = hasPermission(PERMISSION_TYPE.PROJECTS_UPDATE);
	const isRetainerProject = project?.budgetType === BUDGET_TYPE.RETAINER;
	const projectTracksCost = ProjectUtil.projectTracksCost();
	const showRevenueRecognition = ProjectUtil.projectHasRevenueRecognition(project?.budgetType);
	const showProjectBreakdown =
		hasFeatureFlag('project_level_financials_from_allocations') && hasProjectFinancialsBasedOnAllocations(project);
	const showInvoices =
		CompanySetupUtil.hasFinance() &&
		hasFeatureFlag('invoicing_at_project_level') &&
		hasPermission(PERMISSION_TYPE.INVOICE_READ) &&
		(project?.hasInvoices || project?.budgetType !== BUDGET_TYPE.NON_BILLABLE);

	const hasFinancialAccess =
		CompanySetupUtil.hasFinance() &&
		hasSomePermission([PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION, PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE]);

	const genericSubItem = (context, name, subType, subTypeAlias) => (
		<VerticalNavigationBar.Item
			key={`${subType}-${context}`}
			isActive={
				currentPath.includes(`/${subType}#${context}`) ||
				(subTypeAlias && currentPath.includes(`/${subTypeAlias}#${context}`))
			}
		>
			<Link
				to={`${navLinkBase}/${subType}#${context}`}
				component={NavigationLink}
				onClick={() => trackEvent('Sub Level Navigation', 'Clicked')}
				data-cy={`secondary-nav-${subType}-sub-${context}`}
			>
				<div>{name}</div>
			</Link>
		</VerticalNavigationBar.Item>
	);

	const projectFinancialsSubItem = (context, name) => genericSubItem(context, name, 'financials', 'budget');

	const projectSettingSubItem = (context, name) => genericSubItem(context, name, 'settings');

	const projectGroupSettingItem = (context, name) => genericSubItem(context, name, 'group-settings');

	const projectFinancialsSubItems = [
		hasFinancialAccess && projectFinancialsSubItem('details', 'Overview'),
		showRevenueRecognition && projectFinancialsSubItem('revenue_recognition', 'Revenue Recognition'),
		showRevenueRecognition &&
			(showProjectBreakdown
				? projectFinancialsSubItem('breakdown', 'Breakdown')
				: projectFinancialsSubItem('task_report', 'Task Breakdown')),
		projectFinancialsSubItem(
			'rates_and_internal_cost',
			hasPermission(PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION) && projectTracksCost ? 'Rates & Internal Costs' : 'Rates'
		),
		CompanySetupUtil.hasFinance() && projectFinancialsSubItem('expenses', 'Expenses'),
		showInvoices && projectFinancialsSubItem('invoicing', 'Invoicing'),
	].filter(item => item);

	const projectSettingsSubItems = project
		? [
				projectSettingSubItem('general', 'General'),
				projectSettingSubItem('status', 'Status'),
				TABS.PROJECT_PROGRESS.isVisible(project) && projectSettingSubItem('project_progress', 'Progress'),
				projectSettingSubItem('tasks', 'Tasks'),
				hasFinancialAccess && projectSettingSubItem('financials', 'Financials'),
				projectSettingSubItem('team', 'Team'),
				projectSettingSubItem('integrations', 'Integrations'),
		  ].filter(item => item)
		: [
				projectGroupSettingItem('general', 'General'),
				// projectGroupSettingItem('progress', 'Progress'),
				projectGroupSettingItem('tasks', 'Tasks'),
				projectGroupSettingItem('team', 'Team'),
		  ];

	return (
		<ZenModeable>
			<VerticalNavigationBar
				expanded={expanded}
				locked={locked}
				onToggle={toggleLock}
				open={open}
				close={close}
				maxHeight={menuHeight}
				defaultValue={getActiveSubMenu(currentPath)}
			>
				{Util.isBaselineProject(null, project) && (
					<VerticalNavigationBar.Item isActive={currentPath.includes(`${navLinkBase}/baseline`)}>
						<Link
							to={`${navLinkBase}/baseline`}
							component={NavigationLink}
							onClick={() => trackEvent('Secondary Navigation Baseline', 'Clicked')}
							data-cy="secondary-nav-baseline"
						>
							{formatMessage({id: 'project_section.baseline'})}
						</Link>
					</VerticalNavigationBar.Item>
				)}

				{!projectGroup && !clientUser && (
					<VerticalNavigationBar.Item isActive={currentPath.includes('/scoping')}>
						<Link
							to={`${navLinkBase}/scoping`}
							component={NavigationLink}
							onClick={() => trackEvent('Secondary Navigation Scoping', 'Clicked')}
							data-cy="secondary-nav-scoping"
						>
							{formatMessage({id: 'common.scoping'})}
						</Link>
					</VerticalNavigationBar.Item>
				)}

				<VerticalNavigationBar.Item isActive={currentPath.includes('/workflow') || currentPath.includes('/task-board')}>
					<Link
						to={`${navLinkBase}/task-board`}
						component={NavigationLink}
						onClick={() => trackEvent('Secondary Navigation Board', 'Clicked')}
						data-cy="secondary-nav-board"
					>
						{formatMessage({id: 'task_board.title'})}
					</Link>
				</VerticalNavigationBar.Item>

				{!clientUser && sprintsEnabled && (
					<VerticalNavigationBar.Item isActive={currentPath.includes('/sprints')}>
						<Link
							to={`${navLinkBase}/sprints`}
							component={NavigationLink}
							onClick={() => trackEvent('Secondary Navigation Sprints', 'Clicked')}
							data-cy="secondary-nav-sprints"
						>
							{formatMessage({id: 'common.sprints'})}
						</Link>
					</VerticalNavigationBar.Item>
				)}

				{hasRetainerAccess &&
					Util.shouldUseRetainers() &&
					!isFeatureHidden(HIDDEN_FEATURES.REVENUE) &&
					isRetainerProject && (
						<VerticalNavigationBar.Item isActive={currentPath.includes('/retainer-tracking')}>
							<Link
								to={`${navLinkBase}/retainer-tracking`}
								component={NavigationLink}
								onClick={() => trackEvent('Secondary Navigation Retainer Tracking', 'Clicked')}
								data-cy="secondary-nav-retainer-tracking"
							>
								{formatMessage({id: 'common.retainer_tracking'})}
							</Link>
						</VerticalNavigationBar.Item>
					)}

				{!clientUser && (
					<VerticalNavigationBar.Item
						isActive={currentPath.includes('/schedule') || currentPath.includes('/timeline')}
					>
						<Link
							to={`${navLinkBase}/timeline`}
							component={NavigationLink}
							onClick={() => trackEvent('Secondary Navigation Timeline', 'Clicked')}
							data-cy="secondary-nav-timeline"
						>
							{formatMessage({id: 'common.timeline'})}
						</Link>
					</VerticalNavigationBar.Item>
				)}

				{project && hasFinancialAccess && (
					<VerticalNavigationBar.SubMenu
						value="financials"
						name={formatMessage({id: 'common.financials'})}
						isActive={currentPath.includes('/financials#') || currentPath.includes('/budget#')}
						data-cy="secondary-nav-financials-submenu"
					>
						{projectFinancialsSubItems}
					</VerticalNavigationBar.SubMenu>
				)}

				{hasModule(MODULE_TYPES.NOVA_INSIGHTS) &&
					!projectGroup &&
					project &&
					!project.projectGroupId &&
					!isRetainerProject &&
					Util.AuthorizeViewerAccess('project-health') && (
						<VerticalNavigationBar.Item isActive={currentPath.includes('/nova-insights')}>
							<Link
								to={`${navLinkBase}/nova-insights`}
								component={NavigationLink}
								onClick={() => trackEvent('Secondary Navigation Nova Insights', 'Clicked')}
								data-cy="secondary-nav-nova-insights"
							>
								{formatMessage({id: 'common.nova_insights'})}
							</Link>
						</VerticalNavigationBar.Item>
					)}

				{project && !clientUser && (
					<VerticalNavigationBar.Item isActive={currentPath.includes('/client')}>
						<Link
							to={`${navLinkBase}/client`}
							component={NavigationLink}
							onClick={() => trackEvent('Secondary Navigation Client', 'Clicked')}
							data-cy="secondary-nav-client"
						>
							{formatMessage({id: 'common.client'})}
						</Link>
					</VerticalNavigationBar.Item>
				)}

				{project && !clientUser && (
					<VerticalNavigationBar.Item isActive={currentPath.includes('/files')}>
						<Link
							to={`${navLinkBase}/files`}
							component={NavigationLink}
							onClick={() => trackEvent('Secondary Navigation Files', 'Clicked')}
							data-cy="secondary-nav-files"
						>
							{formatMessage({id: 'common.files'})}
						</Link>
					</VerticalNavigationBar.Item>
				)}

				{!project && projectGroup && canUpdateProjects && (
					<VerticalNavigationBar.Item isActive={currentPath.includes('/connected-projects')}>
						<Link
							to={`${navLinkBase}/connected-projects`}
							component={NavigationLink}
							onClick={() => trackEvent('Secondary Navigation Connected Projects', 'Clicked')}
							data-cy="secondary-nav-connected-projects"
						>
							{Util.titleCase(formatMessage({id: 'common.connected_projects'}))}
						</Link>
					</VerticalNavigationBar.Item>
				)}

				{canUpdateProjects && (
					<VerticalNavigationBar.SubMenu
						value="settings"
						name={formatMessage({id: 'settings.title'})}
						isActive={currentPath.includes('/settings#') || currentPath.includes('/group-settings#')}
						data-cy="secondary-nav-settings-submenu"
					>
						{projectSettingsSubItems}
					</VerticalNavigationBar.SubMenu>
				)}
			</VerticalNavigationBar>
		</ZenModeable>
	);
};

export default createFragmentContainer(SecondaryNavigation, {
	project: graphql`
		fragment SecondaryNavigation_project on ProjectType {
			companyProjectId
			customProjectId
			sprintTimeBox
			budgetType
			projectGroupId
			useBaseline
			hasInvoices
			financialSourceSettings {
				plannedCost
				plannedRevenue
				actualCost
				actualRevenue
				forecastCost
				forecastRevenue
			}
		}
	`,
	projectGroup: graphql`
		fragment SecondaryNavigation_projectGroup on ProjectGroupType {
			companyProjectGroupId
			projectHeaderProjects: projects(first: 1) @connection(key: "ProjectGroup_projectHeaderProjects", filters: []) {
				edges {
					node {
						id
						sprintTimeBox
					}
				}
			}
		}
	`,
});
