import React, {useState} from 'react';
import {FormattedHTMLMessage, useIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import {
	BUDGET_TYPE,
	BUTTON_COLOR,
	BUTTON_STYLE,
	ELEMENT_TYPE,
	FILTER_SECTION,
	FILTER_TYPE,
	GLOBAL_FILTER_FIELD,
	GLOBAL_FILTER_OPERATOR,
	PERIOD_BUDGET_TYPE,
	PROJECT_TASK_REPORT_GROUPINGS,
	REPORT_GROUPINGS,
} from '../../../../constants';

import ForecastQueryRenderer from '../../../../ForecastQueryRenderer';
import ProjectTaskReportTable, {ProjectTaskReportTableQuery} from './project_task_report_table';
import {buildHeaderBar} from '../../../../forecast-app/shared/components/headers/header-bar/header_bar';
import {getFiltersAlphabetically} from '../../../../forecast-app/shared/components/filters/filter_util';
import {FILTER_SECTIONS} from '../../../../forecast-app/shared/components/filters/FilterWrapper';
import {buildUpdatedEyeOptions} from '../../../../forecast-app/reports-tab/saved-report/GenericReportContext';
import {createSavedOptions} from '../../../../the_eye_util';
import Util from '../../../../forecast-app/shared/util/util';
import {
	getSearchQueryFiltersValue,
	getSecondDropdownOptionsByGroupings,
	noGroupingFirstDropdownOptions,
} from '../../../../components/insights/task-report/TaskReportUtil';
import {getEnabledColumns, getTheEyeOptionsNoGrouping} from '../../../../components/insights/task-report/TaskReportTheEyeUtil';
import {ValueSource} from '../../../../forecast-app/project-tab/projects/project-settings/financials/ValueCalculationsComponent';
import {FlexRow, SummaryCard} from '@forecast-it/design-system';
import {useTrackPage} from '../../../../tracking/amplitude/hooks/useTrackPage';

function getHiddenFinancialFields(financialSourceSettings, intl) {
	const hiddenFinancialFields = [];

	if (!financialSourceSettings) return hiddenFinancialFields;

	if (financialSourceSettings.plannedRevenue !== ValueSource.TASK) {
		hiddenFinancialFields.push(intl.formatMessage({id: 'project_budget.planned_billable_time'}));
	}
	if (financialSourceSettings.actualRevenue !== ValueSource.TASK) {
		hiddenFinancialFields.push(intl.formatMessage({id: 'project_budget.actual_billable_time'}));
	}
	if (financialSourceSettings.forecastRevenue !== ValueSource.TASK) {
		hiddenFinancialFields.push(intl.formatMessage({id: 'project_budget.forecast_billable_time_and_expenses'}));
	}
	if (
		financialSourceSettings.actualRevenue !== ValueSource.TASK ||
		financialSourceSettings.forecastRevenue !== ValueSource.TASK
	) {
		hiddenFinancialFields.push(intl.formatMessage({id: 'common.revenue_recognition'}));
		hiddenFinancialFields.push(intl.formatMessage({id: 'project_budget.total_billable_time_and_expenses'}));
	}
	return hiddenFinancialFields;
}

const ProjectTaskReportPage = ({viewer}) => {
	useTrackPage('Project Task Breakdown', null, {budgetType: viewer.project.budgetType});
	window.history.replaceState(null, '', `${location.pathname}#task_report`);
	const intl = useIntl();
	const {project} = viewer;
	const hasProjectTimeRegistrations = project.timeRegistrations.edges.some(timeReg => timeReg.node.project);
	const revenueRecognitionNotEqualToBudget =
		hasProjectTimeRegistrations &&
		(project.budgetType === BUDGET_TYPE.FIXED_PRICE_V2 ||
			(project.budgetType === BUDGET_TYPE.RETAINER &&
				project.defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.FIXED_PRICE));

	const localStoragePrefix = 'project-task-report';
	const localStorageEyeOptionsKey = localStoragePrefix + '-eye-option';
	const localStorageGroupingOneKey = localStoragePrefix + '-grouping-one';
	const localStorageGroupingTwoKey = localStoragePrefix + '-grouping-two';
	const localStorageFilteringKey = localStoragePrefix + '-filtering';

	const [theEyeOptionsChecked, setTheEyeOptionsChecked] = useState(
		JSON.parse(Util.localStorageGetItem(localStorageEyeOptionsKey)) || {
			project: false,
			clientName: false,
			approved: false,
			assignees: false,
			financials: true,
			'financials.revenueRecognition': true,
			'financials.revenueRecognition.recognitionLockedRevenue': true,
			'financials.revenueRecognition.recognitionOpenRevenue': true,
			'financials.revenueRecognition.recognitionForecastRevenue': true,
			'financials.revenueRecognition.recognitionSurplus': true,
			'financials.timeMaterial': false,
			'financials.timeMaterial.actualRevenue': false,
			'financials.timeMaterial.remainingRevenue': false,
			'financials.timeMaterial.plannedRevenue': false,
			'financials.timeMaterial.forecastRevenue': false,
			indicators: false,
			labels: false,
			phase: false,
			phaseDates: false,
			'phaseDates.deadline': false,
			'phaseDates.startDate': false,
			progress: true,
			rateCard: false,
			reported: false,
			'reported.projected': false,
			'reported.reportedTime': false,
			'reported.reportedBillableTime': false,
			'reported.reportedNonBillableTime': false,
			roleName: false,
			sprint: false,
			sprintDates: false,
			'sprintDates.deadline': false,
			'sprintDates.startDate': false,
			status: true,
			task: true,
			taskDates: false,
			'taskDates.deadline': false,
			'taskDates.startDate': false,
			work: false,
			'work.difference': false,
			'work.difference.hours': false,
			'work.difference.points': false,
			'work.estimate': false,
			'work.estimate.hours': false,
			'work.estimate.points': false,
			'work.remaining': false,
			'work.remaining.hours': false,
			'work.remaining.points': false,
		}
	);

	const firstDropdownValueStorage = Util.localStorageGetItem(localStorageGroupingOneKey);
	const [firstDropdownValue, setFirstDropdownValue] = useState(
		firstDropdownValueStorage && firstDropdownValueStorage !== 'null' ? firstDropdownValueStorage : null
	);
	const secondDropdownValueStorage = Util.localStorageGetItem(localStorageGroupingTwoKey);
	const [secondDropdownValue, setSecondDropdownValue] = useState(
		secondDropdownValueStorage && secondDropdownValueStorage !== 'null' ? secondDropdownValueStorage : null
	);
	const [filterValue, setFilterValue] = useState(JSON.parse(Util.localStorageGetItem(localStorageFilteringKey)) || {});

	const handleSetFirstDropdownValue = changedValue => {
		setFirstDropdownValue(changedValue);
		Util.localStorageSetItem(localStorageGroupingOneKey, changedValue);
	};

	const handleSetSecondDropdownValue = changedValue => {
		setSecondDropdownValue(changedValue);
		Util.localStorageSetItem(localStorageGroupingTwoKey, changedValue);
	};

	const handleSetFilterValue = changedFilters => {
		setFilterValue(changedFilters);
		Util.localStorageSetItem(localStorageFilteringKey, JSON.stringify(changedFilters));
	};

	const defaultSearchQueryFilters = [
		{
			field: GLOBAL_FILTER_FIELD.WITH_TASKS,
			operator: GLOBAL_FILTER_OPERATOR.IS,
			value: true.toString(),
		},
	];

	const initialSearchQueryFilters = getSearchQueryFiltersValue(defaultSearchQueryFilters, filterValue);
	const [searchQueryFilters, setSearchQueryFilters] = useState(initialSearchQueryFilters);

	const hiddenFinancialFields = getHiddenFinancialFields(project.financialSourceSettings, intl);

	//Handling of showing of columns
	const enabledColumns = getEnabledColumns(theEyeOptionsChecked, project.financialSourceSettings);

	// Options for grouping
	const firstDropdownOptions = noGroupingFirstDropdownOptions(
		intl,
		[REPORT_GROUPINGS.PROJECT, REPORT_GROUPINGS.CLIENT],
		[{value: PROJECT_TASK_REPORT_GROUPINGS.PERSON, label: intl.formatMessage({id: 'common.group_by_person'})}]
	);
	const [secondDropdownOptions, setSecondDropdownOptions] = useState(
		getSecondDropdownOptionsByGroupings(firstDropdownValue, intl, true)
	);

	// Handle of grouping in Task Report
	const groupings = [];
	if (firstDropdownValue !== null) {
		groupings.push(firstDropdownValue);
		if (secondDropdownValue !== null) {
			groupings.push(secondDropdownValue);
		}
	}

	// this is the fundament of all groupings -> keep this
	groupings.push(PROJECT_TASK_REPORT_GROUPINGS.TASK);

	/**
	 * This method is called when the grouping option is changed.
	 * @param option The selected grouping option.
	 */
	const handleFirstDropdownChange = option => {
		handleSetFirstDropdownValue(option.value);
		handleSetSecondDropdownValue(null);
		setSecondDropdownOptions(getSecondDropdownOptionsByGroupings(option.value, intl, true));
	};

	const handleSecondDropdownChange = option => {
		handleSetSecondDropdownValue(option.value);
	};

	/**
	 * Search query used for filtering.
	 */
	const searchQuery = {
		filters: [
			...searchQueryFilters.filter(filter => filter.value),
			{
				field: 'PROJECT',
				operator: 'IS',
				value: [project.id],
			},
		],
	};

	/**
	 * This method is called when the filter is changing.
	 *
	 * @param filters The selected filters.
	 */
	const onFiltersChange = filters => {
		const updatedFilters = getSearchQueryFiltersValue(defaultSearchQueryFilters, filters);
		handleSetFilterValue(filters);
		setSearchQueryFilters(updatedFilters);
	};

	const handleSetTheEyeOptions = (_, __, ___, newOptions) => {
		const updatedEyeOptions = {
			...buildUpdatedEyeOptions(
				createSavedOptions(newOptions),
				JSON.parse(Util.localStorageGetItem(localStorageEyeOptionsKey))
			),
			project: false,
			clientName: false,
		};
		setTheEyeOptionsChecked(updatedEyeOptions);
		Util.localStorageSetItem(localStorageEyeOptionsKey, JSON.stringify(updatedEyeOptions));
	};

	/**
	 * Creates the Navigation bar, with:
	 *
	 * - Date picker
	 * - Groupings
	 * - Eye options
	 * - Filtering
	 */
	const getNavBar = () => {
		const leftContent = [];
		const rightContent = [];

		/**
		 * Add grouping options
		 */
		rightContent.push({
			sameTypeOrder: 1,
			type: ELEMENT_TYPE.DROPDOWN,
			userpilot: 'projecttaskreport-first-grouping',
			dropdownOptions: firstDropdownOptions,
			value: firstDropdownValue,
			callback: option => {
				handleFirstDropdownChange(option);
			},
			style: BUTTON_STYLE.OUTLINE,
			color: BUTTON_COLOR.PURPLE,
		});

		/**
		 * Add second grouping options
		 */
		if (secondDropdownOptions !== null) {
			rightContent.push({
				sameTypeOrder: 2,
				type: ELEMENT_TYPE.DROPDOWN,
				dropdownOptions: secondDropdownOptions,
				value: secondDropdownValue,
				callback: option => {
					handleSecondDropdownChange(option);
				},
				style: BUTTON_STYLE.OUTLINE,
				color: BUTTON_COLOR.PURPLE,
			});
		}

		/**
		 * Add 'eye' options
		 */
		const eyeOptions = Object.values(getTheEyeOptionsNoGrouping(project.financialSourceSettings, null, true));
		const eyeOptionsFilters = ['project', 'projectDeadline', 'projectStage', 'clientName'];
		const filteredEyeOptions = eyeOptions.filter(option => !eyeOptionsFilters.includes(option.name));
		rightContent.push({
			type: ELEMENT_TYPE.THE_EYE,
			defaultOptions: filteredEyeOptions,
			checkedOptions: theEyeOptionsChecked,
			onSelect: handleSetTheEyeOptions,
		});

		/**
		 * Available Filters
		 */
		const taskFilters = [
			FILTER_TYPE.PERSON,
			FILTER_TYPE.ROLE,
			FILTER_TYPE.LABEL,
			FILTER_TYPE.STATUS_CATEGORY,
			FILTER_TYPE.SPRINT_CATEGORY,
			FILTER_TYPE.TEAM,
			FILTER_TYPE.INDICATOR_FILTERED,
			FILTER_TYPE.RECENT_ACTIVITY,
			FILTER_TYPE.PHASE_CATEGORY,
			FILTER_TYPE.DEPENDENCIES,
			FILTER_TYPE.OWNER,
			FILTER_TYPE.DEADLINE,
			FILTER_TYPE.PROJECT_TYPE,
		];

		/**
		 * Add filter selector
		 */
		rightContent.push({
			type: ELEMENT_TYPE.FILTER_V4,
			operatorOptions: {allowExclude: false, allowRequireAll: false},
			defaultSection: FILTER_SECTIONS.TASKS,
			projectFilters: [],
			peopleFilters: [],
			timeRegFilters: [],
			taskFilters: getFiltersAlphabetically(taskFilters, intl.formatMessage),
			primaryFilters: {
				[FILTER_SECTIONS.TASKS]: [
					FILTER_TYPE.PERSON,
					FILTER_TYPE.STATUS_CATEGORY,
					FILTER_TYPE.ROLE,
					FILTER_TYPE.PROJECT,
					FILTER_TYPE.PROJECT_TYPE,
					FILTER_TYPE.INDICATOR_FILTERED,
				],
			},
			viewer: viewer,
			filterSection: FILTER_SECTION.TASK_REPORT,
			appliedFiltersName: `project-task-report-filters`,
			noMenu: false,
			useSavedReport: true,
			preAppliedFilters: filterValue,
			onFiltersChange,
			userpilot: 'project-task-report-filter-button',
		});
		return buildHeaderBar(leftContent, rightContent, {noPadding: true});
	};

	return (
		<>
			<FlexRow flex={'0'} justifyContent={'end'} alignItems={'end'}>
				{hiddenFinancialFields.length > 0 && (
					<SummaryCard.Container>
						<SummaryCard
							title={intl.formatMessage({id: 'financial.task_breakdown.finance_information_na'})}
							flexGrow={'0'}
							flexBasis={'500px'}
						>
							<FormattedHTMLMessage
								id="financial.task_breakdown.finance_information_na_description"
								values={{
									link: `${location.pathname.split('/').slice(0, -1).join('/')}/settings#financials`,
								}}
							></FormattedHTMLMessage>
							<ul>
								{hiddenFinancialFields.map(field => (
									<li>{field}</li>
								))}
							</ul>
						</SummaryCard>
					</SummaryCard.Container>
				)}
				{getNavBar()}
			</FlexRow>
			<ForecastQueryRenderer
				key="query-render-taskReportTable"
				query={ProjectTaskReportTableQuery}
				// This is basically initialVariables
				variables={{
					searchQuery: searchQuery,
					projectId: project.id,
					convertToProjectCurrency: true,
				}}
				render={relayProps => {
					return (
						<ProjectTaskReportTable
							{...relayProps}
							relayProps={relayProps}
							enabledColumns={enabledColumns}
							groupings={groupings}
							searchQuery={searchQuery}
							revenueRecognitionNotEqualToBudget={revenueRecognitionNotEqualToBudget}
						/>
					);
				}}
			/>
		</>
	);
};

export const projectTaskReportPageQuery = graphql`
	query projectTaskReportPage_Query($projectId: ID) {
		viewer {
			actualPersonId
			component(name: "project_task_report_page")
			...projectTaskReportPage_viewer @arguments(projectId: $projectId)
		}
	}
`;

export default createFragmentContainer(ProjectTaskReportPage, {
	viewer: graphql`
		fragment projectTaskReportPage_viewer on Viewer @argumentDefinitions(projectId: {type: "ID"}) {
			actualPersonId
			id
			email
			filters(first: 10000, filterSection: TASK_REPORT) @connection(key: "Viewer_filters", filters: []) {
				edges {
					node {
						id
						name
						section
						value
						updatedAt
					}
				}
			}
			project(internalId: $projectId) {
				id
				projectFirstDateYear
				projectFirstDateMonth
				projectFirstDateDay
				projectLastDateYear
				projectLastDateMonth
				projectLastDateDay
				budgetType
				defaultPeriodBudgetType
				financialSourceSettings {
					plannedCost
					plannedRevenue
					actualCost
					actualRevenue
					forecastCost
					forecastRevenue
				}
				timeRegistrations(first: 10000000) {
					edges {
						node {
							id
							project {
								id
							}
						}
					}
				}
			}
		}
	`,
});
