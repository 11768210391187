import React, {Component} from 'react';
import {FormattedHTMLMessage, injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {cloneDeep} from 'lodash';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../../../constants';
import GenericModal from '../../../../../containers/modal/generic_modal';
import {MODAL_TYPE, showModal} from '../../../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {createToast} from '../../../../../forecast-app/shared/components/toasts/another-toast/toaster';
import UpdateProjectMutation from '../../../../../mutations/update_project_budget_page_mutation';
import Util from '../../../../../forecast-app/shared/util/util';
import TooltipContainer from '../../../../../forecast-app/shared/components/tooltips/tooltip_container';
import * as tracking from '../../../../../tracking';
import {trackEvent} from '../../../../../tracking/amplitude/TrackingV2';
import {WarningMenu} from '../../../../../forecast-app/project-tab/projects/project-financials/ProjectFinancialsPage.styled';
import {projectUrl} from '../../../../../directApi';

class BudgetWarningTab extends Component {
	constructor(props) {
		super(props);

		const {project} = this.props;
		const projectEndDate = Util.CreateNonUtcMomentDate(
			project.projectEndYear,
			project.projectEndMonth,
			project.projectEndDay
		);

		this.state = {
			projectEndDate,
			showProjectPhaseEndDateModal: false,
		};

		this.phaseEndDate = null;

		this.getActionsMenuOptions = this.getActionsMenuOptions.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.updateProjectEndDate = this.updateProjectEndDate.bind(this);
		this.refreshProject = this.refreshProject.bind(this);
		this.onActionMenuToggle = this.onActionMenuToggle.bind(this);
	}

	componentDidUpdate(prevProps) {
		const {project} = this.props;
		const prevProject = prevProps.viewer.project;
		const {formatMessage} = this.props.intl;

		const warnings = project.warnings.reduce((map, warning) => {
			map[warning.id] = warning;
			return map;
		}, {});

		const prevWarnings = prevProject.warnings.reduce((map, warning) => {
			map[warning.id] = warning;
			return map;
		}, {});

		const unestimatedTasksWarning = warnings['unestimated_tasks'];
		const unassignedTasksWarning = warnings['unassigned_tasks'];
		const isPersonMissingCostPeriod = warnings['person_missing_cost_period'];

		if (!isPersonMissingCostPeriod) {
			const wasPersonMissingCostPeriod = prevWarnings['person_missing_cost_period'];

			if (wasPersonMissingCostPeriod) {
				createToast({
					duration: 5000,
					message: formatMessage({id: 'project_budget.cost_periods_updated'}),
				});
			}
		}

		if (!unestimatedTasksWarning) {
			const prevUnestimatedTasksWarning = prevWarnings['unestimated_tasks'];

			if (prevUnestimatedTasksWarning) {
				createToast({
					duration: 5000,
					message: formatMessage({id: 'project_budget.tasks_estimated'}),
				});
			}
		}

		if (!unassignedTasksWarning) {
			const prevUnassignedTasksWarning = prevWarnings['unassigned_tasks'];

			if (prevUnassignedTasksWarning) {
				createToast({
					duration: 5000,
					message: formatMessage({id: 'project_budget.tasks_assigned'}),
				});
			}
		}
	}

	refreshProject() {
		this.props.retry();
	}

	getActionsMenuOptions() {
		const {projectEndDate} = this.state;
		const {project, tabs, viewer, history} = this.props;
		const {formatMessage} = this.props.intl;
		const options = [];

		const dateSortFunc = (dayField, monthField, yearField) => (a, b) => {
			const aDay = a.node[dayField];
			const bDay = b.node[dayField];
			const aMonth = a.node[monthField];
			const bMonth = b.node[monthField];
			const aYear = a.node[yearField];
			const bYear = b.node[yearField];
			if (aYear !== bYear) {
				return aYear > bYear ? 1 : -1;
			}
			if (aMonth !== bMonth) {
				return aMonth > bMonth ? 1 : -1;
			}
			return aDay > bDay ? 1 : -1;
		};
		const warnings = project.warnings.reduce((map, warning) => {
			map[warning.id] = warning;
			return map;
		}, {});

		const isPersonMissingCostPeriod = warnings['person_missing_cost_period'];
		const unassignedTasksWarning = warnings['unassigned_tasks'];
		const unestimatedTasksWarning = warnings['unestimated_tasks'];
		const missingProjectDates = warnings['missing_start_or_end_date'];
		const projectEndsBeforeLastPhase = warnings['project_ends_before_last_phase'];
		const missingProjectRateCard = warnings['missing_rate_card'];
		const timeRegOnProjectLevel = warnings['time_reg_on_project_level'];
		const taskOutsideProjectPeriod = warnings['task_outside_project_period'];
		const timeRegOutsideProjectPeriod = warnings['time_reg_outside_project_period'];
		const expensesOutsideProjectPeriod = warnings['expenses_outside_project_period'];
		const isBaselineExceeded = warnings['baseline_limit_exceeded'];

		const {hasFinancials} = this.props;

		if (missingProjectDates) {
			options.push({
				cy: 'option',
				text: formatMessage({id: 'project_budget.missing_project_dates'}),
				onClick: () => {
					showModal({
						type: MODAL_TYPE.ADD_PROJECT_DATES,
						project,
						onSuccess: this.refreshProject,
					});

					tracking.trackElementClicked('Budget Warning:Missing Project Dates');
					trackEvent('Missing Project Dates Budget Warning', 'Clicked');
				},
			});
		}

		if (projectEndsBeforeLastPhase) {
			this.phaseEndDate = Util.CreateNonUtcMomentDate(
				projectEndsBeforeLastPhase.year,
				projectEndsBeforeLastPhase.month,
				projectEndsBeforeLastPhase.day
			);

			if (this.phaseEndDate && this.phaseEndDate.isAfter(projectEndDate)) {
				options.push({
					cy: 'option',
					text: formatMessage({id: 'project_budget.project_ends_before_last_phase'}),
					onClick: () => {
						this.setState({showProjectPhaseEndDateModal: true});

						tracking.trackElementClicked('Budget Warning:Project ends before last phase');
						trackEvent('Project Ends Before Last Phase Budget Warning', 'Clicked');
					},
				});
			}
		}

		if (isPersonMissingCostPeriod && hasFinancials) {
			options.push({
				cy: 'option',
				text: formatMessage({id: 'project_budget.team_members_missing_internal_cost'}),
				onClick: () => {
					if (this.props.setSelectedTab) {
						this.props.setSelectedTab(tabs.RATES_AND_INTERNAL_COST);
						requestAnimationFrame(() => {
							const heading = document.getElementById('missing-hourly-cost-heading');
							if (heading) {
								if (heading.scrollIntoViewIfNeeded) {
									// This feature only exists in chrome
									heading.scrollIntoViewIfNeeded(true);
								} else {
									// This hides the project header in chrome
									heading.scrollIntoView(true);
								}
							}
						});

						tracking.trackElementClicked('Budget Warning:Team members missing internal cost');
						trackEvent('Team Members Missing Internal Cost Budget Warning', 'Clicked');
					}
				},
			});
		}

		if (isBaselineExceeded) {
			options.push({
				cy: 'option',
				text: formatMessage({id: 'baseline.limit_exceeded'}),
				onClick: () => {
					const path = `${projectUrl(project.companyProjectId, project.customProjectId)}/baseline`;
					history.push(path);

					tracking.trackElementClicked('Budget Warning:Baseline limit exceeded');
					trackEvent('Baseline Limit Exceeded Budget Warning', 'Clicked');
				},
			});
		}

		if (unestimatedTasksWarning) {
			const unestimatedTasks = unestimatedTasksWarning.tasks
				? unestimatedTasksWarning.tasks.edges.map(task => task.node)
				: [];
			options.push({
				cy: 'option',
				text: formatMessage({id: 'project_budget.add_time_estimations'}),
				onClick: () => {
					// Show modal
					showModal({
						type: MODAL_TYPE.FIX_UNESTIMATED_TASKS,
						unestimatedTasks,
						project,
						onSuccess: this.refreshProject,
					});

					tracking.trackElementClicked('Budget Warning:Add time estimations');
					trackEvent('Add Time Estimates Budget Warning', 'Clicked');
				},
			});
		}

		if (unassignedTasksWarning) {
			options.push({
				cy: 'option',
				text: formatMessage({id: 'project_budget.assign_tasks'}),
				onClick: () => {
					const unassignedTasks = unassignedTasksWarning.tasks
						? unassignedTasksWarning.tasks.edges.map(task => task.node)
						: [];

					// Show modal
					showModal({
						type: MODAL_TYPE.FIX_UNASSIGNED_TASKS,
						unassignedTasks,
						project,
						viewer,
						onSuccess: this.refreshProject,
					});

					tracking.trackElementClicked('Budget Warning:Assign tasks');
					trackEvent('Assign Tasks Budget Warning', 'Clicked');
				},
			});
		}

		if (missingProjectRateCard && hasFinancials) {
			options.push({
				cy: 'option',
				text: formatMessage({id: 'project_budget.missing_rate_card'}),
				onClick: () => {
					const path = `${projectUrl(project.companyProjectId, project.customProjectId)}/rates`;
					history.push(path);

					tracking.trackElementClicked('Budget Warning:Missing rate card');
					trackEvent('Missing Rate Card Budget Warning', 'Clicked');
				},
			});
		}

		if (timeRegOnProjectLevel) {
			const {timeRegistrations} = cloneDeep(timeRegOnProjectLevel);
			timeRegistrations.edges.sort(dateSortFunc('day', 'month', 'year'));
			options.push({
				cy: 'option',
				text: formatMessage({id: 'project_budget.time_entries_on_project'}),
				onClick: () => {
					showModal({
						type: MODAL_TYPE.TIME_REGS_ON_PROJECT,
						timeRegistrations: timeRegOnProjectLevel.timeRegistrations,
						project,
						viewer,
						onSuccess: this.refreshProject,
					});

					tracking.trackElementClicked('Budget Warning:Missing rate card');
					trackEvent('Time Entries On Project Budget Warning', 'Clicked');
				},
			});
		}

		if (taskOutsideProjectPeriod) {
			options.push({
				cy: 'option',
				text: formatMessage({id: 'project_budget.task_outside_project_period'}),
				onClick: () => {
					showModal({
						type: MODAL_TYPE.TASK_OUTSIDE_PROJECT_PERIOD,
						tasks: taskOutsideProjectPeriod.tasks,
						project,
						viewer,
						onSuccess: this.refreshProject,
					});

					tracking.trackElementClicked('Budget Warning:Tasks outside project period');
					trackEvent('Tasks Outside Project Period Budget Warning', 'Clicked');
				},
			});
		}

		if (timeRegOutsideProjectPeriod) {
			const {timeRegistrations} = cloneDeep(timeRegOutsideProjectPeriod);
			timeRegistrations.edges.sort(dateSortFunc('day', 'month', 'year'));
			options.push({
				cy: 'option',
				text: formatMessage({id: 'project_budget.time_entries_outside_project_period'}),
				onClick: () => {
					showModal({
						type: MODAL_TYPE.TIME_REGS_OUTSIDE_PROJECT_PERIOD,
						timeRegistrations: timeRegOutsideProjectPeriod.timeRegistrations,
						project,
						viewer,
						onSuccess: this.refreshProject,
					});

					tracking.trackElementClicked('Budget Warning:Time entries outside project period');
					trackEvent('Time Entries Outside Project Period Budget Warning', 'Clicked');
				},
			});
		}

		if (expensesOutsideProjectPeriod && hasFinancials) {
			const {expenseItems} = cloneDeep(expensesOutsideProjectPeriod);
			expenseItems.edges.sort(dateSortFunc('expenseDay', 'expenseMonth', 'expenseYear'));
			options.push({
				cy: 'option',
				text: formatMessage({id: 'project_budget.expenses_outside_project_period'}),
				onClick: () => {
					showModal({
						type: MODAL_TYPE.EXPENSES_OUTSIDE_PROJECT_PERIOD,
						expenseItems: expensesOutsideProjectPeriod.expenseItems,
						project,
						viewer,
						onSuccess: this.refreshProject,
					});

					tracking.trackElementClicked('Budget Warning:Expenses outside project period');
					trackEvent('Expenses Outside Project Period Budget Warning', 'Clicked');
				},
			});
		}

		return options;
	}

	closeModal() {
		this.setState({showProjectPhaseEndDateModal: false});
	}

	updateProjectEndDate() {
		const {project} = this.props;
		const {formatMessage} = this.props.intl;

		// Mutation
		const onSuccess = () => {
			createToast({
				duration: 5000,
				message: formatMessage({id: 'project_budget.project_end_date_updated'}),
			});
			this.closeModal();
		};

		// Mutation
		Util.CommitMutation(
			UpdateProjectMutation,
			{
				project,
				projectEndDay: this.phaseEndDate.date(),
				projectEndMonth: this.phaseEndDate.month() + 1,
				projectEndYear: this.phaseEndDate.year(),
			},
			onSuccess
		);

		this.setState({projectEndDate: this.phaseEndDate});
	}

	onActionMenuToggle(expanded) {
		if (expanded) {
			tracking.trackElementClicked('Budget Warning');
			trackEvent('Budget Warning', 'Clicked');
		}
	}

	render() {
		const {showProjectPhaseEndDateModal, projectEndDate} = this.state;
		const {formatMessage} = this.props.intl;
		const actionsMenuOptions = this.getActionsMenuOptions();

		if (!actionsMenuOptions.length) return null;

		return (
			<>
				<TooltipContainer infoText={formatMessage({id: 'project_budget.click_here'})}>
					<WarningMenu
						className="warning"
						title={formatMessage({id: 'project_budget.budget_warning_title'})}
						description={formatMessage({id: 'project_budget.budget_warning_description'})}
						customWidth={20}
						whiteInner={true}
						isWhite={false}
						showOnRight={true}
						options={actionsMenuOptions}
						label={formatMessage({id: 'common.warning'})}
						onActionMenuToggle={this.onActionMenuToggle}
						cy={'budget-warnings'}
					/>
				</TooltipContainer>
				{showProjectPhaseEndDateModal ? (
					<GenericModal
						className={'modal-outer-div'}
						buttons={[
							{
								text: formatMessage({id: 'common.cancel'}),
								callback: () => {
									this.closeModal();
								},
								style: BUTTON_STYLE.FILLED,
								color: BUTTON_COLOR.WHITE,
							},
							{
								text: formatMessage({id: 'common.ok'}),
								callback: () => {
									this.updateProjectEndDate();
								},
								style: BUTTON_STYLE.FILLED,
								color: BUTTON_COLOR.GREEN,
							},
						]}
						content={
							<div className="update-project-dates-wrapper">
								<p>{formatMessage({id: 'project_budget.project_ends_before_phase_warning'})}</p>
								<p>
									<FormattedHTMLMessage
										id={'project_budget.project_ends_before_phase_action'}
										values={{
											projectEndDate: projectEndDate.format('D MMM'),
											phaseEndDate: this.phaseEndDate.format('D MMM'),
										}}
										tagName="div"
									></FormattedHTMLMessage>
								</p>
							</div>
						}
						headerText={'Update Project Dates'}
						closeModal={() => {
							this.closeModal();
						}}
					/>
				) : null}
			</>
		);
	}
}

export default withRouter(injectIntl(BudgetWarningTab));
