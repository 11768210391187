import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedDate, FormattedMessage, injectIntl} from 'react-intl';
import Moment from 'moment';
import {fileExtensions, ItemTypes} from '../../../../constants';
import {DragSource} from 'react-dnd';
import ProjectFilesDeleteButton from './ProjectFilesDeleteButton';
import ProjectFilesMoveButton from './ProjectFilesMoveButton';
import Checkbox from '../../../../components/inputs/checkbox';
import Util from '../../../shared/util/util';
import DirectApi from '../../../../directApi';

const fileSource = {
	beginDrag(props) {
		if (props.marked) {
			return {files: props.bulkUpdateFiles, parentFolder: props.file.node.folder ? props.file.node.folder : null};
		} else {
			props.removeAllFromBulkUpdate();
			if (props.file.node.link) {
				return {
					file: props.file,
					parentFolder: props.file.node.folder ? props.file.node.folder : null,
					isGoogleDriveFile: true,
				};
			} else {
				return {
					files: [props.file.node.id],
					parentFolder: props.file.node.folder ? props.file.node.folder : null,
					isGoogleDriveFile: false,
				};
			}
		}
	},
	isDragging(props, monitor) {
		if (props.file.node.link) {
			return monitor.getItem().file && monitor.getItem().file.node.id === props.file.node.id;
		} else {
			return monitor.getItem().files && monitor.getItem().files.includes(props.file.node.id);
		}
	},
};

function collect(connect, monitor) {
	return {
		connectDragSource: connect.dragSource(),
		isDragging: monitor.isDragging(),
	};
}

class ProjectFilesLineItem extends Component {
	constructor(props) {
		super(props);

		this.state = {
			actionMenuExpanded: false,
		};
	}

	selectFile() {
		this.props.selectFile(this.props.file);
	}

	unlinkFile(taskId) {
		this.props.unlinkFile(this.props.file.node.id, taskId);
	}

	toggleActionMenu() {
		this.setState({actionMenuExpanded: !this.state.actionMenuExpanded});
	}

	closeActionMenu(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11
		if (newTarget && newTarget.id === 'download-link') {
			return;
		}
		this.setState({actionMenuExpanded: false});
	}

	onCheckedChange(e) {
		if (this.props.marked) {
			this.props.removeFileFromBulkUpdate(this.props.file, e.shiftKey);
		} else {
			this.props.addFileToBulkUpdate(this.props.file, e);
		}
	}

	handleFileMoveToRoot() {
		this.props.handleFileMoveToRoot(
			[this.props.file.node.link ? this.props.file : this.props.file.node.id],
			this.props.file.node.link !== undefined
		);
	}

	render() {
		const file = this.props.file;
		const {connectDragSource, isDragging} = this.props;
		const style = {opacity: isDragging ? 0 : 1};
		const {formatMessage} = this.props.intl;
		const fileName = file.node.name
			? file.node.name.indexOf('.') === -1
				? file.node.name
				: file.node.name.substr(0, file.node.name.lastIndexOf('.'))
			: '';

		return connectDragSource(
			<tr
				className={
					(this.props.selectedFileId === file.node.id ? 'selected-table-row' : 'table-row') +
					(this.props.marked ? ' marked' : '')
				}
				style={style}
			>
				<td>
					{!file.node.link ? (
						<div className="bulk-update-checkbox">
							<Checkbox onChange={this.onCheckedChange.bind(this)} checked={this.props.marked} />
						</div>
					) : null}
				</td>
				<td className="drag-handle" />
				<td
					onClick={this.selectFile.bind(this)}
					className={
						fileExtensions.includes(file.node.name.toLowerCase().split('.').pop())
							? 'file-format ' + file.node.name.toLowerCase().split('.').pop()
							: 'file-format file'
					}
				/>
				<td className="name" onClick={this.selectFile.bind(this)}>
					<div className="name-info">
						<span className={file.node.link ? 'google-drive-file-name' : 'name-span'}>{fileName}</span>
					</div>
				</td>
				<td className="added-date" onClick={this.selectFile.bind(this)}>
					<FormattedDate
						value={
							file.node.link
								? Moment.utc(file.node.createdAt)
								: Util.CreateMomentDate(file.node.yearCreated, file.node.monthCreated, file.node.dayCreated)
						}
					/>
				</td>
				<td className="task" onClick={this.selectFile.bind(this)}>
					{file.node.task ? 'T' + file.node.task.companyTaskId + ' ' + file.node.task.name : ''}
				</td>
				<td
					className="gear-menu"
					onBlur={this.closeActionMenu.bind(this)}
					onClick={this.toggleActionMenu.bind(this)}
					tabIndex="0"
				>
					<span className="gear-icon" />
					<div className={'gear-menu-drop-down ' + (this.state.actionMenuExpanded ? 'expanded' : 'collapsed')}>
						<ul className="settings-drop-down">
							{/* TODO: replace by DownloadButton component */}
							<li className="option download-link-option">
								{file.node.link ? (
									<a
										id="download-link"
										className="download-action"
										href={file.node.link}
										target="_blank"
										rel="noopener noreferrer"
									>
										<div className="download-link-container">
											{formatMessage({id: 'project_files.google_drive_link_open'})}
										</div>
									</a>
								) : (
									<a
										id="download-link"
										className="download-action"
										//for some reason when '?123 is added after file id it downloads file with correct filename otherwise filename is ignored'
										href={DirectApi.fileSrc(file.node.id) + '?123'}
									>
										<div className="download-link-container">{formatMessage({id: 'common.download'})}</div>
									</a>
								)}
							</li>
							<li className="option">
								<ProjectFilesDeleteButton
									file={file}
									deleteFile={this.props.deleteFile.bind(this)}
									unlinkFile={this.props.unlinkFile.bind(this)}
								/>
							</li>
							<li className="option move-button">
								<ProjectFilesMoveButton
									file={this.props.file}
									handleFileMoveToCard={this.props.handleFileMoveToCard}
									intl={this.props.intl}
									showFileLocationModal={this.props.showFileLocationModal}
								/>
							</li>
							{file.node.folder ? (
								<li className="option" onClick={this.handleFileMoveToRoot.bind(this)}>
									<div>
										<FormattedMessage id="project_files.move-to-top-level" />
									</div>
								</li>
							) : null}
						</ul>
					</div>
				</td>
			</tr>
		);
	}
}

ProjectFilesLineItem.propTypes = {
	file: PropTypes.object.isRequired,
	deleteFile: PropTypes.func.isRequired,
	connectDragSource: PropTypes.func.isRequired,
	handleFileMoveToCard: PropTypes.func.isRequired,
	showFileLocationModal: PropTypes.func.isRequired,
};
export default injectIntl(DragSource(ItemTypes.PROJECT_FILE, fileSource, collect)(ProjectFilesLineItem));
