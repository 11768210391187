import draftToHtml from './draftjsToHtml/js';
export const convertFromDraftJsToHtml = (draftJsOrHtml) => {
    if (!draftJsOrHtml) {
        return '';
    }
    try {
        /* if the contents is not valid json, we assume it to the html or text and return it directly*/
        const json = JSON.parse(draftJsOrHtml);
        if (json.blocks.length === 0) {
            return draftJsOrHtml;
        }
        return draftToHtml(json);
    }
    catch (e) {
        return draftJsOrHtml;
    }
};
