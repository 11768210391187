import React, {useEffect, useRef} from 'react';
import {Button, RadioButton, Switch} from 'web-components';
import {useHistory} from 'react-router-dom';
import {BUDGET_TYPE, HIDDEN_FEATURES, MODULE_TYPES, TASK_COST_CALCULATION_TYPE} from '../../../../constants';
import {
	CheckBoxDescription,
	FixedCostInput,
	FixedCostWrapper,
	RadioWithDescriptionWrapper,
	RevenueChartWrapper,
	SelectionElementLabel,
	SelectionElementLineWrapper,
	SubSection,
	Subtitle,
} from './ProjectSettings_styled';
import {changeTaskCostCalculationType, changeUnassignedTaskHourlyCost, changeUseBaseline} from './ProjectSettingsMutations';
import {FormattedHTMLMessage} from 'react-intl';
import {CurrencyInputWrapper} from '../../../../components/inputs/currency_input_wrapper';
import Util from '../../../shared/util/util';
import ForecastTooltip from '../../../shared/components/tooltips/ForecastTooltip';
import RateCardDropdown, {changeRateCard} from '../../../../components/dropdowns/rate-card-dropdown/Rate_card_dropdown';
import {hasPermission} from '../../../shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../Permissions';
import UpdateProjectRevenueMarginMutation from '../../../../mutations/UpdateProjectRevenueMarginMutation';
import {createToast} from '../../../shared/components/toasts/toast';
import {BudgetThresholdsUpdateForm} from '../../../shared/components/BudgetThresholdsUpdateForm';
import {ForecastSurplusModalChartMock} from '../../../../containers/project/project_health/modals/forecast_surplus_modal/ForecastSurplusModalChartMock';
import {hasModule} from '../../../shared/util/ModuleUtil';
import {isFeatureHidden} from '../../../shared/util/CompanySetupUtil';
import {hasFeatureFlag} from '../../../shared/util/FeatureUtil';
import ValueCalculations from './financials/ValueCalculations';
import {Variant} from './financials/ValueCalculationsComponent';
import {Button as DsButton, FlexColumn, FlexItem, FlexRow, Table, Text, theme, Tooltip} from '@forecast-it/design-system';
import styled from 'styled-components';
import {MODAL_TYPE, showModal} from '../../../shared/components/modals/generic_modal_conductor';

import InvoicingIntegrationIcon from '../../../admin-tab/finance-page/ts/InvocingIntegrationIcon';

export const AllBillingEntitiesAddedSectionWrapper = styled.div`
	border: 1px solid ${theme.colors.border.separator};
	border-radius: ${theme.border.radius['2']};
	padding: 15px;
	display: flex;
	margin-top: 20px;
	width: 100%;
	justify-content: space-between;
	background-color: #fcfbfd;
`;

export const EmptyBillingDetailsSectionWrapper = styled.div`
	border: 1px solid ${theme.colors.border.separator};
	border-radius: ${theme.border.radius['2']};
	padding: 20px;
	margin-top: 20px;
	justify-content: center;
	background-color: #fcfbfd;
	align-content: center;
	display: flex;
`;

export const AddBillingOptionSectionWrapper = styled.div`
	margin-top: 15px;
`;

const ProjectSettingsFinancials = ({
	intl,
	projectId,
	useBaseline,
	showChangeBudgetTypeModal,
	hasFinanceAccess,
	hasBaselineAccess,
	taskCostCalculationType,
	unassignedTaskHourlyCost,
	currency,
	projectDoneOrHalted,
	hasLockedPeriod,
	budgetType,
	showUpdateFixedPriceModal,
	rateCard,
	company,
	project,
	projectStartDate,
	projectEndDate,
}) => {
	const isInTaskMode = !Util.isMixedAllocationModeEnabled(company) && !company.isUsingProjectAllocation;

	const {formatMessage} = intl;
	const isDoneOrHalted = project.status === 'DONE' || project.status === 'HALTED';
	const isCompanyCurrency = currency === company.currency;
	const exchangeRate = isCompanyCurrency
		? 1
		: company.exchangeRates.edges.find(exchangeRate => exchangeRate.node.currency === currency).node.rate;

	const history = useHistory();
	const novaInsightsSectionRef = useRef(null);

	const clientId = project.client?.id;
	const filteredProjectBillToEntitiesByClient = clientId
		? project.projectBillTos.edges.map(billTo => billTo.node).filter(billTo => billTo.clientId === clientId)
		: [];

	const formattedProjectBillToEntities = filteredProjectBillToEntitiesByClient.map(projectBillTo => {
		const billFromName = projectBillTo.billFrom.name;
		const billFromIntegration = projectBillTo.billFrom.integration;
		const billToName = projectBillTo.billTo.name;

		return {...projectBillTo, billFromName: billFromName, billToName: billToName, billFromIntegration: billFromIntegration};
	});

	const hasNovaInsights = hasModule(MODULE_TYPES.NOVA_INSIGHTS) && Util.hasNovaInsights(company);
	const isAdmin = hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS);

	useEffect(() => {
		if (novaInsightsSectionRef.current && history.location.hash) {
			const section = history.location.hash.replace('#', '').split('+').pop();
			if (section && section.toLowerCase() === 'budget-tolerance') {
				novaInsightsSectionRef.current.scrollIntoView({block: 'end', inline: 'center'});
			}
		}
	}, []);

	const handleChangeFixedCost = (value, isProjectCurrency) => {
		if (isProjectCurrency) {
			value /= exchangeRate;
		}
		changeUnassignedTaskHourlyCost(project.id, value);
	};

	const updateProjectRevenueMargin = (revenueMarginLow, revenueMarginHigh) => {
		Util.CommitMutation(
			UpdateProjectRevenueMarginMutation,
			{id: projectId, revenueMarginLow: revenueMarginLow, revenueMarginHigh: revenueMarginHigh},
			() => createToast({duration: 3000, message: 'Revenue thresholds updated'})
		);
	};

	const removeBillingOption = row => {
		showModal({
			type: MODAL_TYPE.REMOVE_PROJECT_BILL_TO,
			projectBillToId: row.id,
			projectId: project.id,
		});
	};

	const goToClientPage = () => {
		history.push(`/admin/clients/view-client/${clientId}`);
	};

	const addBillingOption = () => {
		if (!project.client) {
			showModal({
				type: MODAL_TYPE.ASSOCIATE_CLIENT_TO_PROJECT,
				projectId: project.id,
			});
		} else {
			const hasClientBillTos = project.client.clientBillTos.edges.length > 0 || false;
			if (hasClientBillTos) {
				showModal({
					type: MODAL_TYPE.ADD_PROJECT_BILL_TO,
					projectId: project.id,
				});
			} else {
				showModal({
					type: MODAL_TYPE.MISSING_CLIENT_BILL_TO_WARNING,
					clientId,
					fromProjectSettings: true,
				});
			}
		}
	};

	return (
		<>
			{/** Billing Details */}
			{hasFeatureFlag('invoicing_pathways') && (
				<SubSection title={'Billing Details'} description={'Billing details to be used when exporting invoices'}>
					{filteredProjectBillToEntitiesByClient.length === 0 ? (
						<EmptyBillingDetailsSectionWrapper>
							<FlexItem>
								<FlexColumn alignItems={'center'} gap={'s'}>
									<FlexItem>
										<Text size={'2'}>
											<b>{formatMessage({id: 'project_settings.not_specified_billing_details'})}</b>
										</Text>
									</FlexItem>
									<FlexItem>
										<DsButton onClick={addBillingOption}>
											{formatMessage({id: 'project_settings.add_billing_details'})}
										</DsButton>
									</FlexItem>
								</FlexColumn>
							</FlexItem>
						</EmptyBillingDetailsSectionWrapper>
					) : (
						<>
							<Table data={formattedProjectBillToEntities} width={'100%'}>
								<Table.TextColumn
									icon={row => <InvoicingIntegrationIcon integration={row.billFromIntegration} />}
									accessorKey={'billFromName'}
									header={'Bill From Entity'}
								/>
								<Table.TextColumn accessorKey={'billToName'} header={'‘Bill To Entity'} />
								<Table.ButtonColumn
									align={Table.Align.right}
									header={formatMessage({id: 'common.remove'})}
									hideHeader={true}
									button={row => ({
										label: formatMessage({
											id: row.invoiced
												? 'project_settings.used_on_invoices'
												: 'project_settings.remove_billing_option_button_label',
										}),
										onClick: () => removeBillingOption(row),
										disabled: row.invoiced,
										disabledTooltip: (
											<Tooltip.Item
												title={formatMessage({id: 'project_settings.used_on_invoices'})}
												details={formatMessage({id: 'project_settings.used_on_invoices_info'})}
											/>
										),
									})}
								/>
							</Table>
							{filteredProjectBillToEntitiesByClient.length === project?.client.clientBillTos.edges.length ? (
								<FlexRow>
									<AllBillingEntitiesAddedSectionWrapper>
										<FlexItem>
											<FlexColumn gap={'s'}>
												<Text size={'2'}>
													{formatMessage({id: 'project_settings.add_billing_entities_desc_part_1'})}
												</Text>
												<Text size={'2'}>
													{isAdmin
														? formatMessage({
																id: 'project_settings.add_billing_entities_desc_part_2_with_admin_permission',
														  })
														: formatMessage({
																id: 'project_settings.add_billing_entities_desc_part_2_with_no_admin_permission',
														  })}
												</Text>
											</FlexColumn>
										</FlexItem>
										{isAdmin && (
											<FlexItem>
												<DsButton emphasis="medium" onClick={goToClientPage}>
													{formatMessage({id: 'common.go_to_client_page'})}
												</DsButton>
											</FlexItem>
										)}
									</AllBillingEntitiesAddedSectionWrapper>
								</FlexRow>
							) : (
								<AddBillingOptionSectionWrapper>
									<DsButton onClick={addBillingOption}>
										{formatMessage({id: 'project_settings.add_billing_option'})}
									</DsButton>
								</AddBillingOptionSectionWrapper>
							)}
						</>
					)}
				</SubSection>
			)}

			{/** Baseline */}
			{budgetType !== BUDGET_TYPE.RETAINER && hasFinanceAccess && (
				<SubSection
					title={formatMessage({id: 'project_section.baseline'})}
					showCrown={!hasBaselineAccess}
					description={formatMessage({id: 'project_settings.baseline_desc'})}
				>
					<SelectionElementLineWrapper>
						<Switch
							checked={useBaseline}
							onChange={value => changeUseBaseline(projectId, value)}
							locked={projectDoneOrHalted || !hasBaselineAccess}
						/>
						<SelectionElementLabel>{formatMessage({id: 'common.enable'})}</SelectionElementLabel>
					</SelectionElementLineWrapper>
				</SubSection>
			)}
			{/** budget type */}
			{!isFeatureHidden(HIDDEN_FEATURES.BUDGET_TYPES) && (
				<SubSection title={formatMessage({id: 'project_settings.project_budget_type'})}>
					{Util.getBudgetTypeTranslation(budgetType, intl, project.defaultPeriodBudgetType)}
					<SelectionElementLineWrapper>
						<ForecastTooltip content={hasLockedPeriod && formatMessage({id: 'retainer.locked_period_disable'})}>
							<Button
								disabled={hasLockedPeriod}
								size={Button.SIZE.STANDARD}
								variant={Button.VARIANT.VERY_LIGHT_GRAY_OUTLINE}
								onClick={showChangeBudgetTypeModal}
								cy={'change-budget-type-button'}
							>
								{formatMessage({id: 'project_settings.change_budget_type'})}
							</Button>
						</ForecastTooltip>
					</SelectionElementLineWrapper>
				</SubSection>
			)}

			{/** rate card */}
			{!isFeatureHidden(HIDDEN_FEATURES.REVENUE) && hasFinanceAccess && (
				<SubSection title={formatMessage({id: 'settings.rate-card'})}>
					<div style={{width: '250px'}}>
						<RateCardDropdown
							rateCards={company.rateCards.edges}
							company={company}
							project={project}
							selectedItems={rateCard && rateCard.id ? [rateCard.id] : []}
							dropdownAlignment="none"
							onSelect={value => changeRateCard(projectId, value, company.rateCards.edges, project)}
							onRemove={() => false}
							disabled={isDoneOrHalted}
						/>
					</div>
				</SubSection>
			)}
			{/** Fixed Price V2 Upgrade */}
			{budgetType === BUDGET_TYPE.FIXED_PRICE ? (
				<SubSection
					title={formatMessage({id: 'project_budget.new_fixed_price_logic'})}
					actionElement={
						<Button
							size={Button.SIZE.STANDARD}
							variant={Button.VARIANT.PURPLE_OUTLINE}
							onClick={showUpdateFixedPriceModal}
						>
							{formatMessage({id: 'project_budget.update_fixed_price'})}
						</Button>
					}
				/>
			) : null}
			{/** Internal hourly cost calculation */}
			{hasFinanceAccess && (
				<SubSection
					title={formatMessage({id: 'project_settings.internal_hourly_cost_calculation'})}
					description={formatMessage({id: 'project_settings.internal_hourly_cost_calculation_desc'})}
				>
					<RadioWithDescriptionWrapper>
						<SelectionElementLineWrapper>
							<RadioButton
								checked={
									taskCostCalculationType === TASK_COST_CALCULATION_TYPE.COMPANY_AVERAGE_UNASSIGNED_TASKS
								}
								onClick={() =>
									changeTaskCostCalculationType(
										projectId,
										TASK_COST_CALCULATION_TYPE.COMPANY_AVERAGE_UNASSIGNED_TASKS
									)
								}
								disabled={projectDoneOrHalted}
							/>
							<SelectionElementLabel>
								<FormattedHTMLMessage id={'project_settings.company_averages'} />
							</SelectionElementLabel>
						</SelectionElementLineWrapper>
						<CheckBoxDescription>
							{formatMessage({id: 'project_settings.company_averages_desc'})}
						</CheckBoxDescription>
					</RadioWithDescriptionWrapper>
					<RadioWithDescriptionWrapper>
						<SelectionElementLineWrapper>
							<RadioButton
								checked={
									taskCostCalculationType === TASK_COST_CALCULATION_TYPE.PROJECT_TEAM_AVERAGE_UNASSIGNED_TASKS
								}
								onClick={() =>
									changeTaskCostCalculationType(
										projectId,
										TASK_COST_CALCULATION_TYPE.PROJECT_TEAM_AVERAGE_UNASSIGNED_TASKS
									)
								}
								disabled={projectDoneOrHalted}
							/>
							<SelectionElementLabel>
								<FormattedHTMLMessage id={'project_settings.project_team_averages'} />
							</SelectionElementLabel>
						</SelectionElementLineWrapper>
						<CheckBoxDescription>
							{formatMessage({id: 'project_settings.project_team_averages_desc'})}
						</CheckBoxDescription>
					</RadioWithDescriptionWrapper>
					<RadioWithDescriptionWrapper>
						<SelectionElementLineWrapper>
							<RadioButton
								checked={taskCostCalculationType === TASK_COST_CALCULATION_TYPE.FIXED_UNASSIGNED_TASKS}
								onClick={() =>
									changeTaskCostCalculationType(projectId, TASK_COST_CALCULATION_TYPE.FIXED_UNASSIGNED_TASKS)
								}
								disabled={projectDoneOrHalted}
							/>
							<SelectionElementLabel>
								<FormattedHTMLMessage id={'project_settings.set_fixed_internal_cost'} />
							</SelectionElementLabel>
						</SelectionElementLineWrapper>
						<CheckBoxDescription>
							<FixedCostWrapper>
								<FixedCostInput>
									<CurrencyInputWrapper
										intl={intl}
										value={unassignedTaskHourlyCost}
										callback={value => handleChangeFixedCost(value, false)}
										currency={company.currency}
										width={'160px'}
										disabled={
											projectDoneOrHalted ||
											taskCostCalculationType !== TASK_COST_CALCULATION_TYPE.FIXED_UNASSIGNED_TASKS
										}
										label={`${intl.formatMessage({
											id: 'common.company_currency',
										})} (${company.currency})`}
									/>
								</FixedCostInput>
								<FixedCostInput>
									{!isCompanyCurrency && (
										<CurrencyInputWrapper
											intl={intl}
											value={unassignedTaskHourlyCost * exchangeRate}
											callback={value => handleChangeFixedCost(value, true)}
											currency={currency}
											width={'160px'}
											disabled={
												projectDoneOrHalted ||
												taskCostCalculationType !== TASK_COST_CALCULATION_TYPE.FIXED_UNASSIGNED_TASKS
											}
											label={`${intl.formatMessage({
												id: 'common.project_currency',
											})} (${currency})`}
										/>
									)}
								</FixedCostInput>
							</FixedCostWrapper>
						</CheckBoxDescription>
					</RadioWithDescriptionWrapper>
				</SubSection>
			)}
			{(budgetType === BUDGET_TYPE.FIXED_PRICE || budgetType === BUDGET_TYPE.FIXED_PRICE_V2) &&
			hasNovaInsights &&
			hasFinanceAccess ? (
				<SubSection title={'Set Budget Tolerance'} description={'This setting only applies to the current project.'}>
					{hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS) ? (
						<Subtitle>
							{'You can change the company default tolerance in the '}
							<a href={'/admin/finance#budget-tolerance'}>admin settings</a>
						</Subtitle>
					) : null}
					<BudgetThresholdsUpdateForm
						revenueMarginLow={project.revenueMarginLow}
						revenueMarginHigh={project.revenueMarginHigh}
						callback={updateProjectRevenueMargin}
					/>
					<RevenueChartWrapper ref={novaInsightsSectionRef}>
						<ForecastSurplusModalChartMock
							fixedPrice={project.budget}
							startDate={projectStartDate}
							endDate={projectEndDate}
							currencySymbol={Util.GetCurrencySymbol(currency)}
							revenueMarginLow={project.revenueMarginLow}
							revenueMarginHigh={project.revenueMarginHigh}
						></ForecastSurplusModalChartMock>
					</RevenueChartWrapper>
				</SubSection>
			) : null}
			{/** Value Calculations */}
			{hasFinanceAccess && hasFeatureFlag('project_level_financials_from_allocations') && !isInTaskMode ? (
				<SubSection
					title={formatMessage({id: 'project_settings.financials.value_calculations'})}
					description={formatMessage({id: 'project_settings.financials.value_calculations_description'})}
				>
					<FlexColumn gap={'xl'}>
						<ValueCalculations project={project} variant={Variant.PLANNED} />
						<ValueCalculations project={project} variant={Variant.FORECAST} />
						<ValueCalculations project={project} variant={Variant.ACTUAL} />
					</FlexColumn>
				</SubSection>
			) : null}
		</>
	);
};
export default ProjectSettingsFinancials;
