import React, {useEffect, useState} from 'react';
import {CardWrapper, Container, EmptyStateContainer, Spacer} from './FavouredList.styled';
import {DragDropContext, Draggable, Droppable} from '@forecasthq/react-virtualized-dnd';

import DraggableCard from '../../../shared/components/card/Draggable_card';
import {createFragmentContainer, graphql} from 'react-relay';
import Util from '../../../shared/util/util';
import ReorderTaskMutation from '../../../../mutations/reorder_task_mutation.modern';
import {useHistory} from 'react-router-dom';
import EmptyState from '../../../shared/components/empty-states/empty_state';
import {withSocketHandling} from '../../../../socket/withSocketHandling';
import {getStarredSocketConfig} from '../MyWorkSocketHandling';

export const MyWorkFavouredList = ({viewer, selectedPersonId, setSocketConfig, isCollapsed, cy}) => {
	const dragAndDropGroup = 'FavouredList';
	const [height, setHeight] = useState(window.innerHeight - 206);

	const enabledColumns = {bug: true, taskId: true};

	const tasks = viewer.favouredTasks.edges.map(edge => edge.node);
	tasks.sort((a, b) => {
		const aSort = a.favouredSortOrder;
		const bSort = b.favouredSortOrder;
		if (aSort && bSort && aSort.length !== bSort.length) {
			return aSort.length < bSort.length ? 1 : -1;
		} else {
			return aSort < bSort ? 1 : -1;
		}
	});

	useEffect(() => {
		function handleResize() {
			setHeight(window.innerHeight - 206);
		}

		window.addEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		const selectedPersonIdInt = parseInt(atob(selectedPersonId).replace('Person:', ''));
		setSocketConfig(getStarredSocketConfig(selectedPersonIdInt));
	}, [selectedPersonId]);

	const history = useHistory();
	const showTaskModal = taskId => {
		Util.showTaskModal(taskId, history);
	};

	const handleDragEnd = (draggable, droppableId, placeholderId) => {
		const mutation = {};
		mutation.ids = [draggable.draggableId];
		if (placeholderId !== 'END_OF_LIST') {
			const task = tasks.find(t => t.id === placeholderId);
			if (task) {
				mutation.aboveFavouredTaskId = task.id;
				//mutation.aboveFavouredTaskOrder = BigInt(task.node.favouredSortOrder);
				mutation.aboveFavouredTaskOrder = task.favouredSortOrder;
			}
		} else {
			mutation.aboveFavouredTaskId = null;
			//mutation.aboveFavouredTaskOrder = BigInt(1);
			mutation.aboveFavouredTaskOrder = '1';
		}
		mutation.viewerId = viewer.id;
		//this.setState({draggingTask: false});
		Util.CommitMutation(ReorderTaskMutation, mutation);
	};

	if (tasks.length) {
		return (
			<Container>
				<DragDropContext
					scrollYSpeed={25}
					scrollContainerHeight={height}
					scrollContainerMinHeight={height}
					dragAndDropGroup={dragAndDropGroup}
					onDragEnd={handleDragEnd}
					outerScrollBar={true}
				>
					<Droppable
						dragAndDropGroup={dragAndDropGroup}
						droppableId="favouriteListDroppable"
						placeholderStyle={{marginRight: 5, marginLeft: 5}}
						hideList={isCollapsed}
						containerHeight={height}
						containerMinHeight={height}
						dynamicElemHeight={true}
						minElemHeight={40}
					>
						{tasks.map(task => (
							<Draggable
								key={task.id}
								draggableId={task.id}
								dragAndDropGroup={dragAndDropGroup}
								noCancelOnMove={true}
							>
								<CardWrapper>
									<DraggableCard
										viewer={viewer}
										showProjectIndicator={true}
										theEyeOptions={enabledColumns}
										onClick={showTaskModal}
										dragAndDropGroup={dragAndDropGroup}
										task={task}
										cy={cy}
									/>
								</CardWrapper>
							</Draggable>
						))}
						<Spacer />
					</Droppable>
				</DragDropContext>
			</Container>
		);
	}
	return (
		<EmptyStateContainer>
			<EmptyState size={EmptyState.SIZE.SMALL} pageName={EmptyState.EMPTY_STATE.STARRED_TASKS_SMALL} />
		</EmptyStateContainer>
	);
};

const MyWorkFavouredListQuery = graphql`
	query MyWorkFavouredList_Query {
		viewer {
			actualPersonId
			component(name: "my_work_favoured_list")
			...MyWorkFavouredList_viewer
		}
	}
`;

export {MyWorkFavouredListQuery};

export default withSocketHandling(
	createFragmentContainer(MyWorkFavouredList, {
		viewer: graphql`
			fragment MyWorkFavouredList_viewer on Viewer {
				id
				actualPersonId
				availableFeatureFlags {
					key
				}
				startDate
				endDate
				createdAt
				monday
				tuesday
				wednesday
				thursday
				friday
				saturday
				sunday
				company {
					...genericTaskContextMenu_company
				}
				favouredTasks: tasks(first: 100000, favouredOnly: true, includeDone: true, approvedOnly: true)
					@connection(key: "Viewer_favouredTasks", filters: []) {
					edges {
						node {
							id
							favouredSortOrder
							...DraggableCard_task
						}
					}
				}
			}
		`,
	})
);
