import {graphql} from 'react-relay';
import Util from '../forecast-app/shared/util/util';
import * as tracking from '../tracking';
import {trackAndCommitMutation} from '../tracking/amplitude/TrackMutation';

const mutation = graphql`
	mutation createProjectGroupMutation($input: CreateProjectGroupInput!) {
		createProjectGroup(input: $input) {
			projectGroup {
				node {
					companyProjectGroupId
					...ProjectListPage_projectGroup
				}
			}
		}
	}
`;

function getConfigs(input) {
	return [
		{
			type: 'RANGE_ADD',
			parentID: 'Vmlld2VyOnFsZGZqYmUyNDM0UlpSRmVlcmc=',
			connectionInfo: [
				{
					key: 'Viewer_projectGroups',
					rangeBehavior: 'append',
				},
				{
					key: 'Viewer_headerProjectGroups',
					rangeBehavior: 'append',
				},
			],
			edgeName: 'projectGroup',
		},
	];
}

function commit(environment, input, onSuccess, onError) {
	tracking.trackEvent('Connected Project Created', {_NumberOfProjects: input.projects.length});
	const variables = {input: {csrfToken: Util.getCsrfValue(), socketClientId: Util.getClientId(), ...input}};

	return trackAndCommitMutation(environment, {
		mutation,
		variables,
		configs: getConfigs(input),
		onCompleted: onSuccess,
		onError: onError,
	});
}

export default {commit};
