import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import DropdownV2 from '../../shared/components/dropdowns/dropdown';

class Filter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			optionsExpanded: false,
			// Selected filter object, including options
			selectedFilter: null,
			// Selected filter option, from a previously selected filter
			selectedFilterOption: null,
			showTooltip: false,
			tooltipPosition: {},
		};
		//If filtering by invalid values (something that does not exist anymore, or perhaps a user has 2 accounts and all the localstorage values are from another account),
		//force a call to onChange with only proper values
		if (props.availableFilters) {
			for (const filter of props.availableFilters) {
				const actualFilterValues = filter.value;
				const validFilterValues = this.getValidFilterValues(filter);
				if (actualFilterValues.length !== validFilterValues.length) {
					filter.onChange(validFilterValues);
				}
			}
		}
	}

	componentDidMount() {
		if (this.filterDiv) {
			this.setState({tooltipPosition: this.filterDiv.getBoundingClientRect()});
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		//This clears the filter when you change tabs in scheduling while having selected a filter that is not present in the tab you are switching to
		let {selectedFilter, selectedFilterOption} = this.state;
		if (
			selectedFilter &&
			!nextProps.availableFilters.find(filter => {
				return filter.label === selectedFilter.label;
			})
		) {
			selectedFilter = null;
			selectedFilterOption = null;
			this.setState({selectedFilter, selectedFilterOption});
		}
	}

	showTooltip(e) {
		if (!this.state.showTooltip) {
			this.setState({showTooltip: true});
		}
	}
	hideTooltip() {
		if (this.state.showTooltip) {
			this.setState({showTooltip: false});
		}
	}

	toggleFilterOptions(selectedFilterCount) {
		this.setState(
			{optionsExpanded: !this.state.optionsExpanded},
			selectedFilterCount === 0 ? () => this.focusFirstDropdown() : null
		);
	}

	focusFirstDropdown() {
		if (this.firstDropdown && (!this.state.selectedFilter || this.state.selectedFilter.value.length === [])) {
			this.firstDropdown.focusDropdownInput();
			//this.filter_button.focus();
		}
	}

	handleFilterSelected(e) {
		const filterObject = this.getFilterObjectFromSelection(e);
		const options = this.getFilterOptions(filterObject);
		const firstOption = options[0];
		this.setState(
			{
				selectedFilter: filterObject,
				// Automatically change filter selection dropdown to the first value of the appropriate filterType.
				selectedFilterOption: firstOption,
			},
			() => {
				this.optionDropdown.inputRef.focus();
			}
		);
		this.filter.focus();
	}

	handleFilterOptionSelected(selectedOption) {
		const {selectedFilter} = this.state;
		this.addFilterSelection(selectedFilter, selectedOption);
	}

	// Remove selected filter from list of active filters
	removeActiveFilter(filter, selectedFilterOption) {
		let index = -1;
		filter.value.forEach((elem, i) => {
			if (elem.value === selectedFilterOption.value) {
				index = i;
			}
		});
		filter.value.splice(index, 1);
		filter.onChange(filter.value);
		if (this.filter) {
			this.filter.focus();
		}
	}

	//Exclude already selected options from the filter
	getFilterOptions(filter) {
		return filter.options.filter(option => {
			return !filter.value
				.map(filterValue => {
					return filterValue.value;
				})
				.includes(option.value);
		});
	}

	// Add a selected filter option to the list of active filters, including a mapping to the filter it belongs to
	addFilterSelection(filter, selectedFilterOption) {
		if (!selectedFilterOption) return;
		filter.value.push(selectedFilterOption);
		filter.onChange(filter.value);
		const filterOptions = this.getFilterOptions(filter);
		this.setState({
			selectedFilter: filterOptions.length ? this.state.selectedFilter : null,
			selectedFilterOption: filterOptions.length ? filterOptions[0] : undefined,
		});
	}

	// Remove all active filters
	removeAllActiveFilters() {
		this.props.availableFilters.forEach(filter => {
			if (filter.value.length > 0) {
				filter.value = [];
				filter.onChange(filter.value);
			}
		});
		this.setState({
			selectedFilter: null,
			selectedFilterOption: null,
		});
		if (this.props.onResetChangeHandler) {
			this.props.onResetChangeHandler(null, null);
		}
		this.filter.focus();
	}

	// Get labels for all given available filters
	getAvailableFilterLabels() {
		const availableFilters = this.props.availableFilters || [];
		return availableFilters
			.filter(filter => {
				return this.getFilterOptions(filter).length;
			})
			.map(filter => {
				// Format to match expected form in DropdownV2. Could be changed to use placeholders rather than just labels.
				return {label: filter.label, value: filter.label, disabled: false};
			});
	}

	// Given a selection from the filter dropdown, get the entire filter object
	getFilterObjectFromSelection(selection) {
		const availableFilters = this.props.availableFilters || [];
		return availableFilters.find(filter => {
			return filter.label === selection.label;
		});
	}

	//Need to make sure the all the selected values are still legit in case you have something in local storage that is then deleted
	getValidFilterValues(filter) {
		const optionValues = filter.options.map(option => {
			return option.value;
		});

		return filter.value
			.filter(filterValue => {
				return optionValues.includes(filterValue.value);
			})
			.map(filterValue => ({
				...filterValue,
				label: filter.options.find(option => option.value === filterValue.value).label,
			}));
	}

	handleBlur(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11
		if (!this.filter || !this.filter.contains(newTarget)) {
			this.setState({optionsExpanded: false});
		}
	}

	handleDropdownBlur(e) {
		e.stopPropagation();
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11
		if (!this.filter || !this.filter.contains(newTarget)) {
			this.setState({optionsExpanded: false});
		}
	}

	handleKeyPress(e) {
		if (e.key === 'Escape') {
			this.setState({optionsExpanded: false});
		}
	}

	sortAlphabetically(opt1, opt2) {
		return opt1.label < opt2.label ? -1 : 1;
	}

	getActiveFilterString(availableFilters) {
		let filterString = 'Active Filters\n';
		availableFilters.map(filter =>
			this.getValidFilterValues(filter).map(
				(filterValue, index) => (filterString += filter.label + ': ' + filterValue.label + '\n')
			)
		);
		return filterString;
	}

	render() {
		const {formatMessage} = this.props.intl;
		const availableFilters = this.props.availableFilters ? this.props.availableFilters.sort(this.sortAlphabetically) : [];
		const {selectedFilter, selectedFilterOption, optionsExpanded} = this.state;
		//Checking this to hide ugly padding on the bottom when nothing is selected
		const selectedFilterCount = availableFilters.reduce(
			(total, filter) => total + this.getValidFilterValues(filter).length,
			0
		);
		const availableFilterLabels = this.getAvailableFilterLabels();
		return (
			<section
				className="filter-section-v2"
				ref={e => (this.filter = e)}
				onBlur={this.handleBlur.bind(this)}
				tabIndex={0}
				onMouseEnter={this.showTooltip.bind(this)}
				onMouseLeave={this.hideTooltip.bind(this)}
			>
				<div className="filter-open-section" ref={e => (this.filterDiv = e)}>
					<button
						data-cy={this.props.dataCy}
						ref={btn => (this.filter_button = btn)}
						onKeyDown={e => this.handleKeyPress(e)}
						className={
							'filter-button' +
							(this.state.optionsExpanded ? ' expanded' : '') +
							(selectedFilterCount > 0 ? ' active' : '') +
							(this.props.noText ? ' no-text' : '') +
							(this.props.noBorder && !this.state.optionsExpanded ? ' no-border' : '')
						}
						onClick={this.toggleFilterOptions.bind(this, selectedFilterCount)}
					>
						{this.props.noText ? (
							<div className={'filter-button-content-wrapper'}>
								{selectedFilterCount === 0 ? null : (
									<div className="selected-filter-count">{selectedFilterCount}</div>
								)}
								{selectedFilterCount === 0 ? (
									<div className="filter-icon"></div>
								) : (
									<div className="filter-icon purple"></div>
								)}
							</div>
						) : (
							<div className={'filter-button-content-wrapper'}>
								{this.state.optionsExpanded || selectedFilterCount === 0 ? (
									<div className="filter-icon" />
								) : (
									<div className="selected-filter-count">{selectedFilterCount}</div>
								)}
								{formatMessage({id: optionsExpanded ? 'common.filter-close' : 'common.filter-as-verb'})}
							</div>
						)}
					</button>
				</div>
				<div
					className={
						'filter-section-inner' +
						(this.state.optionsExpanded ? '' : ' hidden') +
						(this.props.expandLeft ? ' expand-left' : ' expand-right')
					}
					data-cy={this.props.dataCy + '-options'}
				>
					<div className={'filter-options'}>
						<DropdownV2
							ref={dropdown => (this.firstDropdown = dropdown)}
							stylingTheme={'white'}
							hideLabel={true}
							value={selectedFilter ? selectedFilter.label : undefined}
							placeholder={formatMessage({
								id: 'common.filter-filter-by',
							})}
							onChange={e => this.handleFilterSelected(e)}
							options={availableFilterLabels}
							disabled={!availableFilterLabels.length}
							onBlur={this.handleDropdownBlur.bind(this)}
							customHeight={40}
							filterDropdown={true}
						/>
						<DropdownV2
							ref={e => (this.optionDropdown = e)}
							stylingTheme={'white'}
							hideLabel={true}
							disabled={!selectedFilter}
							value={undefined}
							placeholder={
								selectedFilterOption
									? formatMessage({id: 'common.select'})
									: formatMessage({
											id: 'common.filter-no-selection',
									  })
							}
							onChange={e => this.handleFilterOptionSelected(e)}
							options={selectedFilter ? this.getFilterOptions(selectedFilter) : []}
							stayOpenOnSelect={true}
							onBlur={this.handleDropdownBlur.bind(this)}
							customHeight={40}
							filterDropdown={true}
							isPersonSelector={selectedFilter ? selectedFilter.isPersonSelector : false}
							personImageSize={selectedFilter ? selectedFilter.personImageSize : null}
						/>
					</div>
					{selectedFilterCount ? (
						<div className="active-filters">
							{availableFilters.map(filter =>
								this.getValidFilterValues(filter).map((filterValue, index) => (
									<div key={filterValue.value} className="active-filter">
										<div className="active-filter-text">{filterValue.label}</div>
										<button onClick={() => this.removeActiveFilter(filter, filterValue)} />
									</div>
								))
							)}
						</div>
					) : null}
					{selectedFilterCount ? (
						<div className="clear-filter-button-container">
							<button className="clear-filter-button" onClick={this.removeAllActiveFilters.bind(this)}>
								{formatMessage({id: 'common.clear-filter'})}
							</button>
						</div>
					) : null}
				</div>
			</section>
		);
	}
}

Filter.propTypes = {
	availableFilters: PropTypes.arrayOf(
		PropTypes.shape({
			options: PropTypes.arrayOf(
				PropTypes.shape({
					label: PropTypes.any,
					value: PropTypes.any,
				})
			).isRequired,
			onChange: PropTypes.func.isRequired,
			value: PropTypes.array.isRequired,
			label: PropTypes.string.isRequired,
		})
	).isRequired,
	onResetChangeHandler: PropTypes.func,
};
export default injectIntl(Filter);
