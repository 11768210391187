import Moment from 'moment';
import {PERIOD_BUDGET_TYPE, PERIODICITY} from '../../../../constants';
import Util from '../../../shared/util/util';
import {hasFeatureFlag} from '../../../shared/util/FeatureUtil';
import {CreateMomentDate} from '../../../shared/util/DateUtil';
import {convertPriceToInvoiceCurrency} from '../../../../components/insights/invoicing/line-generation/invoicing_generate_lines_utils';
import RoundingUtility, {RoundingEntities} from '../../../shared/util/RoundingUtil';
import {getTotalPrice} from '../../../../components/insights/invoicing/line-generation/invoicing_generate_lines_fixedhours_retainer';
import {RateCardUtil} from '../../../shared/util/RateCardUtil';

export const timeRegIsInConflict = (timeReg, period) => {
	if (period.periodBudgetType === PERIOD_BUDGET_TYPE.TIME_AND_MATERIALS) return false;

	const timeRegDate = Moment(timeReg.updatedAt);
	const lockedDate = Moment(period.periodLockedTime);
	return period.periodLocked && !timeReg.retainerConflictHandled && timeRegDate.isAfter(lockedDate);
};

export function getLastPeriodEndDateForSelectedPeriodCount(
	latestPeriodEndDate,
	numNewPeriods,
	defaultPeriodLength,
	defaultPeriodPeriodicity
) {
	if (!latestPeriodEndDate || numNewPeriods < 1 || !defaultPeriodLength) {
		return null;
	}
	if (defaultPeriodPeriodicity === PERIODICITY.MONTHLY) {
		const lastPeriodIsEndOfMonth = latestPeriodEndDate.isSame(latestPeriodEndDate.clone().endOf('month'), 'day');
		if (lastPeriodIsEndOfMonth) {
			return latestPeriodEndDate.clone().startOf('month').add(numNewPeriods, 'months').endOf('month');
		} else {
			return latestPeriodEndDate.clone().add(numNewPeriods, 'months');
		}
	} else {
		return latestPeriodEndDate
			.clone()
			.add(defaultPeriodLength * numNewPeriods, defaultPeriodPeriodicity === PERIODICITY.DAILY ? 'days' : 'weeks');
	}
}

export function getTimeRegsForPeriod(timeRegs, period, removeConflicts) {
	const sortByTimeRegDate = (a, b) => {
		const aDate = Util.CreateNonUtcMomentDate(a.node.year, a.node.month, a.node.day);
		const bDate = Util.CreateNonUtcMomentDate(b.node.year, b.node.month, b.node.day);
		if (aDate.isAfter(bDate)) return 1;
		if (bDate.isAfter(aDate)) return -1;
		return 0;
	};

	const startDate = Util.CreateMomentDate(period.startYear, period.startMonth, period.startDay);
	const endDate = Util.CreateMomentDate(period.endYear, period.endMonth, period.endDay);
	const timeRegWithinDate = timeReg => {
		const tDate = Util.CreateMomentDate(timeReg.node.year, timeReg.node.month, timeReg.node.day);
		return tDate && tDate.isSameOrAfter(startDate) && tDate.isSameOrBefore(endDate);
	};
	if (removeConflicts) {
		return timeRegs
			.filter(timeReg => timeRegWithinDate(timeReg) && !timeRegIsInConflict(timeReg.node, period))
			.sort(sortByTimeRegDate);
	}
	return timeRegs.filter(timeReg => timeRegWithinDate(timeReg)).sort(sortByTimeRegDate);
}

function getCSVData(timeRegs, currency, intl) {
	const getReportedTime = entry => {
		const {task, project} = entry.node;
		return {
			tid: task && task.companyTaskId ? task.companyTaskId : '',
			name: task && task.name ? task.name : project.name,
			date: Util.CreateMomentDate(entry.node.year, entry.node.month, entry.node.day).format('D MMM YYYY'),
			timeEntry: Util.convertMinutesToFullHour(entry.node.billableMinutesRegistered, intl),
			price: Util.GetCurrencySymbol(currency) + entry.node.price,
			person: entry.node.person.firstName + ' ' + entry.node.person.lastName,
			period: entry.node.period.name,
		};
	};

	let csvData = [];
	csvData.push({
		tid: 'T-ID',
		taskProject: 'Task/Project',
		date: 'Date',
		timeEntry: 'Time Entry',
		price: 'Price',
		person: 'Person',
		period: 'Period',
	});
	timeRegs.forEach(entry => {
		csvData.push(getReportedTime(entry));
	});
	return csvData;
}

export function handleCsvDownloadPress(timeRegs, currency, intl) {
	const csvData = getCSVData(timeRegs, currency, intl);
	const exportData = Util.JSONToCSV(csvData, currency, intl);
	const fileName = `reported_time.csv`.toLowerCase();
	Util.exportToCSV(exportData, fileName);
}

export function addPeriodIdToTimeReg(timeReg, period) {
	return {node: {...timeReg.node, period}};
}

export const sortByStartDate = (a, b) => {
	const aNode = a.node || a;
	const bNode = b.node || b;
	const aDate = Util.CreateNonUtcMomentDate(aNode.startYear, aNode.startMonth, aNode.startDay);
	const bDate = Util.CreateNonUtcMomentDate(bNode.startYear, bNode.startMonth, bNode.startDay);
	if (!aDate || !bDate) return 0;
	if (aDate.isBefore(bDate)) return -1;
	if (bDate.isBefore(aDate)) return 1;
	return 0;
};

export const getRolloversFromPeriods = (retainerPeriods, periodIds) => {
	const rollovers = [];
	retainerPeriods.edges
		.map(retainerPeriod => retainerPeriod.node)
		.forEach(retainerPeriod => {
			const periodRolloversFromPeriod = retainerPeriod.retainerPeriodRollovers.edges
				.filter(rollover => rollover.node.periodFrom && periodIds.includes(rollover.node.periodFrom.id))
				.map(rollover => rollover.node);
			periodRolloversFromPeriod.forEach(rollover => rollovers.push(rollover));
		});
	return rollovers;
};

export const getRevertableRolloversFromPeriods = (retainerPeriods, periodIds) => {
	const rollovers = getRolloversFromPeriods(retainerPeriods, periodIds);
	// A rollover is revertable if the impacted period is not locked
	return rollovers.filter(rollover => rollover.periodTo.periodLocked === false);
};

export function getUnitPrice({period, invoiceCurrency, rateCard, company, timeRegs}) {
	const hasRateRounding = hasFeatureFlag('rate_rounding');
	if (period?.periodBudgetType === 'FIXED_HOURS') {
		const projectCurrency = rateCard?.currency || company?.currency || '';
		const quantity = period.periodHoursAmount;
		const periodStartDate = CreateMomentDate(period.startYear, period.startMonth, period.startDay);
		const periodEndDate = CreateMomentDate(period.endYear, period.endMonth, period.endDay);
		const uninvoicedTimeRegs = timeRegs.filter(timeReg => !timeReg.node.invoiced);
		const unInvoicedPeriodTimeRegs = getTimeRegsForPeriod(uninvoicedTimeRegs, period);
		const totalTimeRegAmount = unInvoicedPeriodTimeRegs.reduce(
			(acc, timeReg) => acc + timeReg.node.billableMinutesRegistered,
			0
		);
		const periodTargetMinutes = period.periodHoursAmount * 60;
		const rates = unInvoicedPeriodTimeRegs.map(timeReg => timeReg.node.rate);
		const allSameRate = rates.every(rate => rate === rates[0]);

		let defaultRate = 0;
		if (rateCard) {
			const rateCardUtil = new RateCardUtil(rateCard);
			defaultRate = rateCardUtil.getAverageRateBetweenDates(null, periodStartDate, periodEndDate);
		}

		if (allSameRate && (totalTimeRegAmount >= periodTargetMinutes || defaultRate === rates[0]) && rates.length > 0) {
			return hasRateRounding
				? RoundingUtility.roundBasedOnEntityType(rates[0], RoundingEntities.RATE.name)
				: Math.round(rates[0] * 100) / 100;
		}

		const totalPrice = convertPriceToInvoiceCurrency(
			getTotalPrice(
				unInvoicedPeriodTimeRegs.map(timeReg => timeReg.node) || [],
				periodStartDate,
				periodEndDate,
				quantity,
				defaultRate
			),
			projectCurrency,
			company,
			{currency: invoiceCurrency}
		);
		return !!quantity && totalPrice
			? hasRateRounding
				? RoundingUtility.roundBasedOnEntityType(totalPrice / quantity, RoundingEntities.RATE.name)
				: Math.round((totalPrice / quantity) * 100) / 100
			: 0;
	} else if (period?.periodBudgetType === 'FIXED_PRICE') {
		return period?.periodPriceAmount || 0;
	}
	return 0;
}
