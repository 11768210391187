/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ProjectListPage_projectGroup$ref = any;
export type CreateProjectGroupInput = {|
  name?: ?string,
  projects?: ?$ReadOnlyArray<?string>,
  csrfToken?: ?string,
  socketClientId?: ?string,
  clientMutationId?: ?string,
|};
export type createProjectGroupMutationVariables = {|
  input: CreateProjectGroupInput
|};
export type createProjectGroupMutationResponse = {|
  +createProjectGroup: ?{|
    +projectGroup: ?{|
      +node: ?{|
        +companyProjectGroupId: ?number,
        +$fragmentRefs: ProjectListPage_projectGroup$ref,
      |}
    |}
  |}
|};
export type createProjectGroupMutation = {|
  variables: createProjectGroupMutationVariables,
  response: createProjectGroupMutationResponse,
|};
*/


/*
mutation createProjectGroupMutation(
  $input: CreateProjectGroupInput!
) {
  createProjectGroup(input: $input) {
    projectGroup {
      node {
        companyProjectGroupId
        ...ProjectListPage_projectGroup
        id
      }
    }
  }
}

fragment ProjectListPage_projectGroup on ProjectGroupType {
  id
  companyProjectGroupId
  name
  color
  projects(first: 1000000) {
    edges {
      node {
        id
        status
        name
        budgetType
        isInProjectGroup
        readOnlyAccess
        description
        projectStartDay
        projectStartMonth
        projectStartYear
        projectEndDay
        projectEndMonth
        projectEndYear
        companyProjectId
        customProjectId
        client {
          id
          name
        }
        rateCard {
          id
        }
        projectLabels(first: 10000) {
          edges {
            node {
              label {
                id
              }
              id
            }
          }
        }
        projectColor
        currentProjectStatus {
          id
          color
          person {
            firstName
            lastName
            id
          }
        }
        priorityLevel {
          id
          name
          weight
        }
        projectPersons(first: 1000000, contactsOnly: true) {
          edges {
            node {
              id
              isContactPerson
              person {
                id
                firstName
                lastName
                profilePictureId
                profilePictureDefaultId
                initials
              }
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateProjectGroupInput!"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyProjectGroupId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
},
v6 = {
  "kind": "Literal",
  "name": "first",
  "value": 1000000
},
v7 = [
  (v3/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createProjectGroupMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateProjectGroupPayload",
        "kind": "LinkedField",
        "name": "createProjectGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectGroupTypeEdge",
            "kind": "LinkedField",
            "name": "projectGroup",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectGroupType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ProjectListPage_projectGroup"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createProjectGroupMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateProjectGroupPayload",
        "kind": "LinkedField",
        "name": "createProjectGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectGroupTypeEdge",
            "kind": "LinkedField",
            "name": "projectGroup",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectGroupType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": [
                      (v6/*: any*/)
                    ],
                    "concreteType": "ProjectTypeConnection",
                    "kind": "LinkedField",
                    "name": "projects",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectTypeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProjectType",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "status",
                                "storageKey": null
                              },
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "budgetType",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isInProjectGroup",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "readOnlyAccess",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "description",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "projectStartDay",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "projectStartMonth",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "projectStartYear",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "projectEndDay",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "projectEndMonth",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "projectEndYear",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "companyProjectId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "customProjectId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Client",
                                "kind": "LinkedField",
                                "name": "client",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v4/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "RateCard",
                                "kind": "LinkedField",
                                "name": "rateCard",
                                "plural": false,
                                "selections": (v7/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "first",
                                    "value": 10000
                                  }
                                ],
                                "concreteType": "ProjectLabelTypeConnection",
                                "kind": "LinkedField",
                                "name": "projectLabels",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ProjectLabelTypeEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ProjectLabel",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Label",
                                            "kind": "LinkedField",
                                            "name": "label",
                                            "plural": false,
                                            "selections": (v7/*: any*/),
                                            "storageKey": null
                                          },
                                          (v3/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "projectLabels(first:10000)"
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "projectColor",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProjectStatus",
                                "kind": "LinkedField",
                                "name": "currentProjectStatus",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v5/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Person",
                                    "kind": "LinkedField",
                                    "name": "person",
                                    "plural": false,
                                    "selections": [
                                      (v8/*: any*/),
                                      (v9/*: any*/),
                                      (v3/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PriorityLevel",
                                "kind": "LinkedField",
                                "name": "priorityLevel",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v4/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "weight",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "contactsOnly",
                                    "value": true
                                  },
                                  (v6/*: any*/)
                                ],
                                "concreteType": "ProjectPersonTypeConnection",
                                "kind": "LinkedField",
                                "name": "projectPersons",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ProjectPersonTypeEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ProjectPerson",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v3/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "isContactPerson",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Person",
                                            "kind": "LinkedField",
                                            "name": "person",
                                            "plural": false,
                                            "selections": [
                                              (v3/*: any*/),
                                              (v8/*: any*/),
                                              (v9/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "profilePictureId",
                                                "storageKey": null
                                              },
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "profilePictureDefaultId",
                                                "storageKey": null
                                              },
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "initials",
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "projectPersons(contactsOnly:true,first:1000000)"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "projects(first:1000000)"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "createProjectGroupMutation",
    "operationKind": "mutation",
    "text": "mutation createProjectGroupMutation(\n  $input: CreateProjectGroupInput!\n) {\n  createProjectGroup(input: $input) {\n    projectGroup {\n      node {\n        companyProjectGroupId\n        ...ProjectListPage_projectGroup\n        id\n      }\n    }\n  }\n}\n\nfragment ProjectListPage_projectGroup on ProjectGroupType {\n  id\n  companyProjectGroupId\n  name\n  color\n  projects(first: 1000000) {\n    edges {\n      node {\n        id\n        status\n        name\n        budgetType\n        isInProjectGroup\n        readOnlyAccess\n        description\n        projectStartDay\n        projectStartMonth\n        projectStartYear\n        projectEndDay\n        projectEndMonth\n        projectEndYear\n        companyProjectId\n        customProjectId\n        client {\n          id\n          name\n        }\n        rateCard {\n          id\n        }\n        projectLabels(first: 10000) {\n          edges {\n            node {\n              label {\n                id\n              }\n              id\n            }\n          }\n        }\n        projectColor\n        currentProjectStatus {\n          id\n          color\n          person {\n            firstName\n            lastName\n            id\n          }\n        }\n        priorityLevel {\n          id\n          name\n          weight\n        }\n        projectPersons(first: 1000000, contactsOnly: true) {\n          edges {\n            node {\n              id\n              isContactPerson\n              person {\n                id\n                firstName\n                lastName\n                profilePictureId\n                profilePictureDefaultId\n                initials\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c65e9bdf854fe3171df326341a4eed42';

module.exports = node;
