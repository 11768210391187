import React, {Component} from 'react';
import {createToast} from '../../../shared/components/toasts/another-toast/toaster';
import {FormattedHTMLMessage, injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import ProjectRetainerPeriodHeader from './ProjectRetainerPeriodHeader';
import ProjectRetainerPeriodDataVisuals from './ProjectRetainerPeriodDataVisuals';
import ProjectRetainerPeriodTable from './ProjectRetainerPeriodTable';
import Util from '../../../shared/util/util';
import DeleteRetainerPeriodMutation from '../../../../mutations/delete_retainer_period_mutation';
import {MODAL_TYPE, showModal} from '../../../shared/components/modals/generic_modal_conductor';
import {BUTTON_COLOR, BUTTON_STYLE, PERIOD_BUDGET_TYPE} from '../../../../constants';
import UpdateRetainerPeriodMutation from '../../../../mutations/update_retainer_period_mutation';
import UpdateTimeRegistrationRetainerConflict from '../../../../mutations/update_time_registration_retainer_conflict_mutation';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import {dispatch, EVENT_ID} from '../../../../containers/event_manager';
import {hasFeatureFlag} from '../../../shared/util/FeatureUtil';
import {getRevertableRolloversFromPeriods, getRolloversFromPeriods} from './RetainerPeriodUtil';

class ProjectRetainerPeriod extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: this.props.startExpanded,
			availableColumns: this.getAvailableColumns(),
			taskCellMaxWidth: 200,
		};
		this.onWindowResize = this.onWindowResize.bind(this);
		this.hasFinancialServiceFlag = hasFeatureFlag('planned_values_on_retainer_periods');
	}

	componentDidMount() {
		window.addEventListener('resize', this.onWindowResize);
		this.updateTaskCellMaxWidth();
	}

	componentDidUpdate(prevProps, prevState) {
		const width = this.getTaskCellMaxWidth();
		if (this.props.containerWidth !== prevProps.containerWidth || width !== this.state.taskCellMaxWidth) {
			this.updateTaskCellMaxWidth();
		}
	}

	getContainerWidth() {
		return (
			Math.max(document.documentElement.clientWidth, window.innerWidth) -
			32 * 2 - // margin on each side
			(this.props.sideNavLocked ? 248 : 0)
		);
	}

	getAvailableColumns() {
		const cols = Util.getRetainerColumns();
		if (this.props.project.harvestProject) {
			cols.map(col => (col.name === 'harvest' ? (col.checked = true) : null));
		}
		return cols;
	}

	getTaskCellMaxWidth() {
		// Period header cannot grow smaller than 1020px
		const w = Math.max(this.getContainerWidth(), 1020);
		// Calculate space for other cols only

		// Space used by other headers than task name
		const usedSpace =
			this.state.availableColumns.reduce(
				(acc, col) => ((col.checked || col.maintainSpace) && col.name !== 'task-name' ? col.maxWidth + acc : acc),
				0
			) + 32; // 32 is 2x padding of 16
		if (usedSpace > w) {
			// base minwidth for overflow
			return 200;
		}

		return Math.max(200, w - usedSpace);
	}

	updateTaskCellMaxWidth() {
		const taskCellMaxWidth = this.getTaskCellMaxWidth();
		if (taskCellMaxWidth !== this.state.taskCellMaxWidth) {
			this.setState({taskCellMaxWidth: taskCellMaxWidth});
		}
	}

	onWindowResize() {
		this.updateTaskCellMaxWidth();
	}

	onExpansionClick(e) {
		e.stopPropagation();
		e.preventDefault();
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement;

		if (
			(newTarget &&
				newTarget.className &&
				(newTarget.className.toLowerCase().includes('calendar') ||
					newTarget.className.toLowerCase().includes('-control'))) ||
			newTarget.tagName === 'INPUT' ||
			newTarget.className.toLowerCase().includes('clear-dates') ||
			newTarget.className.toLowerCase().includes('modal')
		) {
			return;
		}
		// Prevent expansion when warning is clicked
		if (e.target && e.target.className && e.target.className.includes('warning')) {
			return;
		}
		this.setState(prevState => {
			return {expanded: !prevState.expanded};
		});
	}

	deletePeriod() {
		const {formatMessage} = this.props.intl;
		const period = this.props.period.node;
		const onSuccess = () => {
			if (this.hasFinancialServiceFlag) {
				dispatch(EVENT_ID.RETAINER_UPDATE_PROJECT);
			}
			createToast({duration: 5000, message: formatMessage({id: 'retainer.period-deleted'})});
			this.props.onPeriodsChanged();
		};
		const callbackPositive = () => {
			Util.CommitMutation(
				DeleteRetainerPeriodMutation,
				{
					id: period.id,
					projectId: this.props.project.id,
				},
				onSuccess
			);
		};

		const hasRolloverFlag = hasFeatureFlag('rollover_values_on_retainer_periods');
		if (hasRolloverFlag) {
			const periodId = this.props.period.node.id;
			const rollovers = getRolloversFromPeriods(this.props.project.retainerPeriods, [periodId]);
			showModal({
				type: MODAL_TYPE.DELETE_RETAINER_PERIOD_MODAL,
				rollovers,
				periodIds: [periodId],
				projectId: this.props.project.id,
				periodBudgetType: this.props.period.node.periodBudgetType,
				currency: this.props.currency,
			});
		} else {
			showModal({
				type: MODAL_TYPE.WARNING,
				warningMessageId: 'retainer.delete_confirmation',
				warningInformation: [formatMessage({id: 'retainer.delete_confirmation.warning'})],
				buttons: [
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: formatMessage({id: 'common.delete'}),
						callback: callbackPositive,
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.RED,
						cy: 'modal-btn-confirm-delete',
					},
				],
			});
		}
	}

	lockPeriod() {
		const {formatMessage} = this.props.intl;
		const hasFinancialServiceFlag = hasFeatureFlag('planned_values_on_retainer_periods');
		const hasRolloverFlag = hasFeatureFlag('rollover_values_on_retainer_periods');
		const period = this.props.period.node;
		const periodEndDate = Util.CreateMomentDate(period.endYear, period.endMonth, period.endDay);
		const unlockedFuturePeriodsAmount = this.props.viewer.project.retainerPeriods.edges.filter(filterPeriod => {
			const filterPeriodStartDate = Util.CreateMomentDate(
				filterPeriod.node.startYear,
				filterPeriod.node.startMonth,
				filterPeriod.node.startDay
			);
			return !filterPeriod.node.periodLocked && filterPeriodStartDate.isAfter(periodEndDate);
		}).length;
		let periodRollover = null;

		const inHours = this.props.inHours;
		const periodDifference =
			hasFinancialServiceFlag && hasRolloverFlag
				? inHours
					? period.financialNumbers.retainerPeriodRolloverMinutes / 60
					: period.financialNumbers.retainerPeriodRolloverPrice
				: inHours
				? period.periodDifferenceHoursAmount + period.sharedPeriodDifferenceHoursAmount
				: period.periodDifferencePriceAmount + period.sharedPeriodDifferencePriceAmount;
		if (inHours) {
			const totalTime = this.props.timeRegs.nonConflictTimeRegs.reduce(
				(acc, timeReg) => acc + timeReg.node.billableMinutesRegistered / 60,
				0
			);
			periodRollover = periodDifference + period.periodHoursAmount - totalTime;
		} else {
			const totalCost = this.props.timeRegs.nonConflictTimeRegs.reduce((acc, timeReg) => acc + timeReg.node.price, 0);
			periodRollover = periodDifference + period.periodPriceAmount - totalCost;
		}

		const selectionText = selection => {
			if (selection === 'roll-next') {
				return (
					(periodRollover < 0
						? formatMessage({id: 'retainer.subtract_next_value'})
						: formatMessage({id: 'retainer.roll_next_value'})) +
					': ' +
					this.props.withSuffix(Math.abs(periodRollover), {format: 'always_two_decimal'})
				);
			} else if (selection === 'roll-split') {
				return (
					(periodRollover < 0
						? formatMessage({id: 'retainer.subtract_split_value'})
						: formatMessage({id: 'retainer.roll_split_value'})) +
					': ' +
					this.props.withSuffix(Math.abs(periodRollover) / unlockedFuturePeriodsAmount, {
						format: 'always_two_decimal',
					})
				);
			}
		};

		const warningText = selection => {
			if (selection === 'roll-split') {
				return formatMessage({id: 'retainer.split_period_warning'});
			} else {
				return null;
			}
		};

		const onSuccess = () => {
			if (hasFinancialServiceFlag) {
				dispatch(EVENT_ID.RETAINER_UPDATE_PROJECT);
			} else {
				this.props.retry();
			}
			createToast({duration: 5000, message: 'Period Locked'});
			trackEvent('Retainer Period', 'Locked', {invoiced: period.invoiced});
		};
		const callbackPositive = params => {
			const mutationObject = {
				id: period.id,
				projectId: this.props.viewer.project.id,
				periodLocked: true,
			};
			if (period.periodBudgetType === PERIOD_BUDGET_TYPE.FIXED_HOURS) {
				// The value of the number has no impact on the data when not splitting, but is required when splitting.
				mutationObject.periodDifferenceHoursAmount = periodRollover;
				if (params === 'roll-next' || params === 'roll-split') {
					// TODO: This means that any conflict decisions taken on this period will be overwritten,
					// but is required to allow rollover on a period which had previously been set to ignore
					mutationObject.ignoredRolloverHours = 0;
				} else if (params === 'ignore') {
					if (hasRolloverFlag) {
						mutationObject.periodDifferenceHoursAmount = 0;
					}
					mutationObject.ignoredRolloverHours = periodRollover;
				}
			} else if (period.periodBudgetType === PERIOD_BUDGET_TYPE.FIXED_PRICE) {
				mutationObject.periodDifferencePriceAmount = periodRollover;
				if (params === 'roll-next' || params === 'roll-split') {
					// TODO: This means that any conflict decisions taken on this period will be overwritten,
					// but is required to allow rollover on a period which had previously been set to ignore
					mutationObject.ignoredRolloverPrice = 0;
				} else if (params === 'ignore') {
					if (hasRolloverFlag) {
						mutationObject.periodDifferenceHoursAmount = 0;
					}
					mutationObject.ignoredRolloverPrice = periodRollover;
				}
			}
			if (params === 'roll-split') {
				mutationObject.splitRollover = true;
			}
			Util.CommitMutation(UpdateRetainerPeriodMutation, mutationObject, onSuccess);
		};
		if (
			period.periodBudgetType === PERIOD_BUDGET_TYPE.TIME_AND_MATERIALS ||
			unlockedFuturePeriodsAmount === 0 ||
			(hasRolloverFlag && periodRollover === 0)
		) {
			callbackPositive();
		} else {
			showModal({
				type: MODAL_TYPE.WARNING_RADIO_BUTTON_MODAL,
				//warningMessageId: 'common.warning',
				warningInformation: [
					periodRollover < 0 ? (
						<FormattedHTMLMessage id={'retainer.warning.lock_period_subtract'} />
					) : (
						<FormattedHTMLMessage id={'retainer.warning.lock_period_roll'} />
					),
				],
				callbackPositive: callbackPositive.bind(this),
				selectedOptionKey:
					this.props.project.defaultPeriodSettingRollValue && periodRollover >= 0
						? 'roll-next'
						: this.props.project.defaultPeriodSettingSubtractValue && periodRollover < 0
						? 'roll-next'
						: 'ignore',
				radioOptions: [
					{
						text:
							periodRollover < 0
								? formatMessage({id: 'retainer.subtract_next'})
								: formatMessage({id: 'retainer.roll_next'}),
						key: 'roll-next',
					},
					{
						text:
							periodRollover < 0
								? formatMessage({id: 'retainer.subtract_split'})
								: formatMessage({id: 'retainer.roll_split'}),
						key: 'roll-split',
					},
					{
						text: formatMessage({id: 'retainer.ignore'}),
						key: 'ignore',
					},
				],
				selectionText: selectionText.bind(this),
				...(hasFeatureFlag('rollover_values_on_retainer_periods') ? {} : {warningText: warningText.bind(this)}),
			});
		}
	}

	unlockPeriod(allTimeRegs) {
		const hasFinancialServiceFlag = hasFeatureFlag('planned_values_on_retainer_periods');
		const hasRolloverFlag = hasFeatureFlag('rollover_values_on_retainer_periods');
		const periodId = this.props.period.node.id;
		const rollovers = getRevertableRolloversFromPeriods(this.props.project.retainerPeriods, [periodId]);

		const unlockPeriodMutations = rolloverIdsToRevert => {
			const revertRollovers = !!rolloverIdsToRevert;

			const onSuccess = response => {
				trackEvent('Retainer Period', 'Unlocked', {
					revertRollovers,
					periodBudgetType: this.props.project.defaultPeriodBudgetType,
				});

				if (hasFinancialServiceFlag) {
					dispatch(EVENT_ID.RETAINER_UPDATE_PROJECT);
				} else {
					this.props.retry();
				}

				if (response.updateRetainerPeriod.errors?.length) {
					createToast({
						duration: 5000,
						message: this.props.intl.formatMessage({id: 'retainer.unlocked_invoiced_period_error'}),
						callback: () => {
							location.reload();
						},
					});
					return;
				}

				const onRetainerUpdateSuccess = () => {
					createToast({duration: 5000, message: 'Period Unlocked'});
					this.props.onPeriodsChanged();
				};

				if (allTimeRegs.length > 0) {
					Util.CommitMutation(
						UpdateTimeRegistrationRetainerConflict,
						{ids: allTimeRegs.map(t => t.node.id), retainerConflictHandled: false, overrideInvoiced: true},
						onRetainerUpdateSuccess
					);
				} else {
					onRetainerUpdateSuccess();
				}
			};

			const callbackPositive = () => {
				Util.CommitMutation(
					UpdateRetainerPeriodMutation,
					{
						id: this.props.period.node.id,
						periodLocked: false,
						periodLockedTime: null,
						rolloverIdsToRevert: rolloverIdsToRevert,
					},
					onSuccess
				);
			};
			if (this.props.timeRegs.conflictTimeRegs.length > 0) {
				showModal({
					type: MODAL_TYPE.WARNING,
					warningInformation: [
						'Unlocking a period will cause all conflicting time registrations to be added to the period. Do you still wish to unlock the period?',
					],
					buttons: [
						{
							text: this.props.intl.formatMessage({id: 'common.cancel'}),
							style: BUTTON_STYLE.FILLED,
							color: BUTTON_COLOR.WHITE,
						},
						{
							text: this.props.intl.formatMessage({id: 'retainer.unlock_period'}),
							callback: callbackPositive,
							style: BUTTON_STYLE.FILLED,
							color: BUTTON_COLOR.GREEN,
						},
					],
				});
			} else {
				callbackPositive();
			}
		};

		if (rollovers.length && hasRolloverFlag) {
			showModal({
				type: MODAL_TYPE.REVERT_ROLLOVER_MODAL,
				rollovers: rollovers,
				periodBudgetType: this.props.period.node.periodBudgetType,
				currency: this.props.currency,
				unlockPeriodMutations: unlockPeriodMutations,
				isBulkUnlock: false,
			});
		} else {
			unlockPeriodMutations(null);
		}
	}

	render() {
		const {period, timeRegs, project} = this.props;
		const allTimeRegs = timeRegs.conflictTimeRegs.concat(timeRegs.nonConflictTimeRegs);
		const isTimeMaterials = project.defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.TIME_AND_MATERIALS;
		const inHours = this.props.inHours;
		return (
			<div className={'retainer-period-container'}>
				<ProjectRetainerPeriodHeader
					userpilotIndex={this.props.userpilotIndex}
					isTimeMaterials={isTimeMaterials}
					withSuffix={this.props.withSuffix}
					inHours={inHours}
					expanded={this.state.expanded}
					onExpansionClick={this.onExpansionClick.bind(this)}
					backgroundColor={project.projectColor}
					timeRegs={timeRegs.nonConflictTimeRegs}
					project={this.props.viewer.project}
					currency={this.props.currency}
					viewer={this.props.viewer}
					period={period}
					phasesForPeriod={this.props.phasesForPeriod}
					planForPeriod={this.props.planForPeriod}
					remainingPlanForPeriod={this.props.remainingPlanForPeriod}
					availableForPeriod={this.props.availableForPeriod}
					deletePeriod={this.deletePeriod.bind(this)}
					resetSelectedPeriods={this.props.resetSelectedPeriods}
					lockPeriod={this.lockPeriod.bind(this)}
					unlockPeriod={this.unlockPeriod.bind(this, allTimeRegs)}
					conflictedTimeEntries={timeRegs.conflictTimeRegs}
					projectLocked={this.props.projectLocked}
					showRemainingCapacity={this.props.showRemainingCapacity}
					useTimeApproval={this.props.useTimeApproval}
					isEstimatedInPoints={this.props.isEstimatedInPoints}
					handleSelectedChanged={this.props.handleSelectedChanged}
					isSelected={this.props.isSelected}
					programBudgetInfo={this.props.programBudgetInfo}
					hasTarget={this.props.hasTarget}
				/>
				{this.state.expanded ? (
					<div className={'retainer-period-contents'}>
						<ProjectRetainerPeriodDataVisuals
							userpilotIndex={this.props.userpilotIndex}
							currency={this.props.currency}
							withSuffix={this.props.withSuffix}
							inHours={inHours}
							isTimeMaterials={isTimeMaterials}
							isFixedPrice={this.props.isFixedPrice}
							conflictedTimeEntries={timeRegs.conflictTimeRegs}
							projectColor={project.projectColor}
							timeRegs={timeRegs.nonConflictTimeRegs}
							lockPeriod={this.lockPeriod.bind(this)}
							period={period}
							viewer={this.props.viewer}
							useTimeApproval={this.props.useTimeApproval}
						/>
						<ProjectRetainerPeriodTable
							cy={'period-table'}
							conflictedTimeEntries={timeRegs.conflictTimeRegs}
							taskCellMaxWidth={this.state.taskCellMaxWidth}
							availableColumns={this.state.availableColumns}
							showTaskModal={this.props.showTaskModal}
							hasFinancialAccess={this.props.hasFinancialAccess}
							viewer={this.props.viewer}
							project={project}
							timeRegs={allTimeRegs}
							projectLocked={project.status === 'HALTED' || project.status === 'DONE'}
							period={period}
							isHarvestProject={this.props.isHarvestProject}
						/>
					</div>
				) : null}
			</div>
		);
	}
}

ProjectRetainerPeriod.propTypes = {
	period: PropTypes.object.isRequired,
	project: PropTypes.object.isRequired,
	timeRegs: PropTypes.object.isRequired,
};
export default injectIntl(ProjectRetainerPeriod);
