import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import {cloneDeep} from 'lodash';
import {NavBarIconButton, PopoverHandler} from '@forecast-it/design-system';
import NotificationContainer from '../../shared/components/notifications/NotificationContainer';
import Util from '../../shared/util/util';
import UpdateViewerMutation from '../../../mutations/update_viewer_mutation.modern';
import {withSocketHandling} from '../../../socket/withSocketHandling';
import {SOCKET_ACTION, SOCKET_EVENT_TYPE} from '../../../constants';
import {createToast} from '../../shared/components/toasts/another-toast/toaster';
import * as tracking from '../../../tracking';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import {createRefetchContainer, graphql} from 'react-relay';

class NotificationMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: false,
			muteNotifications: localStorage.getItem('mute-notifications')
				? JSON.parse(localStorage.getItem('mute-notifications'))
				: false,
			iconBounds: {},
			focusedIndex: null,
		};
		this.updateDimensions = this.updateDimensions.bind(this);
	}

	componentDidMount() {
		if (this.icon) {
			this.setState({iconBounds: this.icon.getBoundingClientRect()});
		}
		const socketEvents = [
			{
				type: SOCKET_EVENT_TYPE.PERSON_NOTIFICATION,
				action: SOCKET_ACTION.UPDATE,
				personIds: [this.props.viewer.backendId],
			},
		];

		this.props.setSocketConfig(socketEvents, this.handleSocketEvent.bind(this));
		this.props.setSocketVariables({});

		window.addEventListener('resize', this.updateDimensions);
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions);
	}
	handleSocketEvent(e) {
		this.props.relay.refetch();
	}

	updateDimensions() {
		if (this.icon) {
			this.setState({iconBounds: this.icon.getBoundingClientRect()});
		}
	}

	handleBlur(e) {
		const newTarget = e ? e.relatedTarget || e.explicitOriginalTarget || document.activeElement : null; // IE11
		if (
			newTarget &&
			newTarget.className &&
			!newTarget.className.includes('all-notifications') &&
			((this.notification_container && this.notification_container.contains(newTarget)) ||
				newTarget.className.includes('notification') ||
				newTarget.className.includes('actions'))
		) {
			this.setState({focusedIndex: 0});
			return;
		}
		this.setState({expanded: false});
	}

	focusNextInList() {
		this.setState(prevState => {
			return {focusedIndex: prevState.focusedIndex + 1};
		});
	}
	focusPreviousInList() {
		this.setState(prevState => {
			return {focusedIndex: prevState.focusedIndex - 1};
		});
	}

	handleKeyPress(e) {
		if (e.keyCode === 27 && this.state.expanded) {
			// Escape
			this.buttonRef.focus();
			this.setState({expanded: false});
		} else if (e.keyCode === 40) {
			// Down arrow
			if (!this.state.expanded) {
				this.setState({expanded: true, focusedIndex: 0});
			} else {
				this.focusNextInList();
			}
		} else if (e.keyCode === 38 && this.state.expanded) {
			// Up arrow
			this.focusPreviousInList();
		} else if (e.keyCode === 13 || e.keyCode === 32) {
			// Enter / Space
			const focusedElement = document.activeElement;
			if (focusedElement !== this.buttonRef) {
				e.preventDefault();
				focusedElement.click();
			} else {
				this.toggleNotifications();
			}
		}
	}

	toggleMuteNotifications() {
		tracking.trackElementClicked('Notifications' + (this.state.muteNotifications ? '-unmute' : '-mute'));
		trackEvent('Notifications', !this.state.muteNotifications ? 'Muted' : 'Unmuted');
		Util.localStorageSetItem('mute-notifications', !this.state.muteNotifications);
		this.setState({muteNotifications: !this.state.muteNotifications});
	}

	toggleNotifications() {
		if (!this.state.expanded) {
			tracking.trackElementClicked(`Notifications`);
			trackEvent('Notification Menu', 'Clicked');
			const recentNotifications = this.props.viewer.recentNotifications;
			if (recentNotifications.length > 0) {
				Util.CommitMutation(UpdateViewerMutation, {
					viewer: this.props.viewer,
					lastSeenInAppNotification: recentNotifications[0].countId,
				});
			}
		}
		this.setState({expanded: !this.state.expanded});
	}

	closeNotifications() {
		if (this.state.expanded) {
			this.setState({expanded: false});
		}
	}

	findPerson(id) {
		return this.props.viewer.company.allPersons.edges.find(p => p.node.id === id);
	}

	populateNotificationData(notifications) {
		const tmpNotifications = cloneDeep(notifications);
		if (this.props.viewer.company.allPersons) {
			tmpNotifications.forEach(
				notification => (notification.publisher = this.findPerson(notification.publisherPersonId))
			);
		}
		return tmpNotifications;
	}

	disableNotificationType(type) {
		const params = {
			viewer: this.props.viewer,
		};
		const onSuccess = res =>
			createToast({
				duration: 5000,
				message: this.props.intl.formatMessage(
					{id: 'notification.notification_disabled'},
					{type: Util.prettifyNotificationType(type, this.props.intl)}
				),
			});
		const notification = Util.getNotificationFlagFromType(type);
		params[notification] = !this.props.viewer[notification];
		Util.CommitMutation(UpdateViewerMutation, params, onSuccess);
	}

	render() {
		const notifications = this.populateNotificationData(this.props.viewer.recentNotifications);
		const unseenCount = Math.min(99, this.props.viewer.unseenNotifications);
		const unseenBadgeText =
			this.props.viewer.unseenNotifications > 0
				? unseenCount + (this.props.viewer.unseenNotifications > unseenCount ? '+' : '')
				: null;
		const projects = this.props.viewer.projects.edges;
		const projectNodes = projects.map(project => project.node);

		return (
			<PopoverHandler>
				<PopoverHandler.Trigger
					onClick={() => {
						trackEvent('Navigation Bar Notifications Menu Button', 'Clicked');
						this.toggleNotifications();
					}}
				>
					<NavBarIconButton
						aria-label="notifications"
						icon="bell"
						counter={this.state.muteNotifications ? null : unseenBadgeText}
					/>
				</PopoverHandler.Trigger>
				<PopoverHandler.Content>
					<NotificationContainer
						focusedIndex={this.state.focusedIndex}
						closeNotifications={this.closeNotifications.bind(this)}
						disableNotificationType={this.disableNotificationType.bind(this)}
						notifications={notifications}
						viewer={this.props.viewer}
						projects={projectNodes}
						muteNotifications={this.state.muteNotifications}
						onBlur={this.handleBlur.bind(this)}
						onKeyDown={this.handleKeyPress.bind(this)}
						toggleMuteNotifications={this.toggleMuteNotifications.bind(this)}
					/>
				</PopoverHandler.Content>
			</PopoverHandler>
		);
	}
}
NotificationMenu.propTypes = {
	viewer: PropTypes.object.isRequired,
};

export default injectIntl(
	withSocketHandling(
		createRefetchContainer(
			NotificationMenu,
			{
				viewer: graphql`
					fragment NotificationMenu_viewer on Viewer {
						backendId
						unseenNotifications
						recentNotifications {
							id
							countId
							publisherPersonId
							subscriberPersonId
							publisherAction
							entityType
							entityId
							params
							createdAt
							read
						}
						notifyInAppOnProjectStatusChange
						notifyInAppOnPersonJoin
						notifyInAppOnAssignedProject
						notifyInAppOnProjectDeadline
						notifyInAppOnAssignedTask
						notifyInAppOnAssignedTaskUpdate
						notifyInAppOnTaskDeadline
						notifyInAppOnMention
						notifyInAppOnTaskDescriptionChange
						notifyInAppOnTaskTitleChange
						notifyInAppOnTaskEstimateChange
						notifyInAppOnTaskStartDateChange
						notifyInAppOnTaskEndDateChange
						notifyInAppOnTaskSprintChange
						notifyInAppOnTaskPhaseChange
						notifyInAppOnTaskStatusColumnChange
						notifyInAppOnTaskProjectChange
						notifyInAppOnTaskSubtaskChange
						notifyInAppOnTaskSubtaskEstimateChange
						notifyInAppOnTaskCommentChange
						notifyInAppOnTaskFileChange
						notifyInAppOnTaskBugChange
						notifyInAppOnTaskBlockedChange
						notifyInAppOnTaskRepeatingChange
						notifyInAppOnTimeOffManager
						notifyInAppOnTimeOffOwner
						notifyInAppOnInvoiceDueDate
						notifyInAppOnInvoiceOverdue
						notifyInAppOnInvoiceDaysOverdue
						notifyInAppOnInvoiceCreatedOrDeleted
						notifyInAppOnInvoiceStatusChange
						notifyInAppOnInvoiceDateReached
						notifyInAppOnInvoicePayment
						notifyInAppOnInvoiceDateChanged
						notifyInAppOnInvoiceDueDateChanged
						company {
							allPersons(first: 48714, includeSystem: true) {
								edges {
									node {
										id
										fullName
										profilePictureId
										profilePictureDefaultId
										initials
									}
								}
							}
						}
						projects(first: 10000) {
							edges {
								node {
									id
									name
									companyProjectId
									customProjectId
									...DeprecatedProjectIndicatorJS_project
								}
							}
						}
					}
				`,
			},
			graphql`
				query NotificationMenu_Query {
					viewer {
						component(name: "notification_menu_refetch")
						...NotificationMenu_viewer
					}
				}
			`
		)
	)
);
