/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type StatusWithDescription_programStatus$ref = any;
export type BUDGET_TYPE = "FIXED_PRICE" | "FIXED_PRICE_V2" | "NON_BILLABLE" | "RETAINER" | "TIME_AND_MATERIALS" | "%future added value";
export type PERIOD_BUDGET_TYPE = "FIXED_HOURS" | "FIXED_PRICE" | "TIME_AND_MATERIALS" | "%future added value";
export type PROGRAM_BUDGET_TYPE = "CAPPED" | "FIXED_PRICE" | "UNCAPPED" | "%future added value";
export type PROJECT_STATUS = "DONE" | "HALTED" | "OPPORTUNITY" | "PLANNING" | "RUNNING" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProgramContextProvider_viewer$ref: FragmentReference;
declare export opaque type ProgramContextProvider_viewer$fragmentType: ProgramContextProvider_viewer$ref;
export type ProgramContextProvider_viewer = {|
  +id: string,
  +backendId: ?number,
  +actualPersonId: ?string,
  +program: ?{|
    +id: string,
    +name: ?string,
    +prefix: ?string,
    +description: ?string,
    +color: ?string,
    +startDate: ?string,
    +endDate: ?string,
    +budgetType: ?PROGRAM_BUDGET_TYPE,
    +budgetValue: ?number,
    +totalAllocation: ?number,
    +isRevenueLocked: ?boolean,
    +revenueSetting: ?string,
    +clients: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +logoId: ?string,
        |}
      |}>
    |},
    +currentProgramStatus: ?{|
      +ragStatus: ?string,
      +$fragmentRefs: StatusWithDescription_programStatus$ref,
    |},
    +stage: ?{|
      +name: ?string
    |},
    +createdAt: ?string,
    +createdBy: ?{|
      +person: ?{|
        +id: string,
        +fullName: ?string,
      |}
    |},
    +projects: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +serviceCompanyId: ?number,
          +companyProjectId: ?number,
          +legacyProjectAsSingleProject: ?{|
            +id: string,
            +customProjectId: ?string,
          |},
          +name: ?string,
          +prefix: ?string,
          +color: ?string,
          +startDate: ?string,
          +endDate: ?string,
          +stage: ?PROJECT_STATUS,
          +budgetType: ?BUDGET_TYPE,
          +budget: ?number,
          +defaultPeriodBudgetType: ?PERIOD_BUDGET_TYPE,
          +clients: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +name: ?string,
                +logoId: ?string,
              |}
            |}>
          |},
          +status: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +description: ?string,
                +color: ?string,
                +addedAt: ?string,
              |}
            |}>
          |},
          +currentProjectStatus: ?{|
            +id: string,
            +color: ?string,
            +description: ?string,
            +person: ?{|
              +id: string,
              +firstName: ?string,
              +lastName: ?string,
              +profilePictureId: ?string,
            |},
          |},
          +financialNumbers: ?{|
            +allActualTimeAndExpenses: ?number,
            +recognitionProfitPercentage: ?number,
            +allTotalTimeAndExpensesAtCompletion: ?number,
            +forecastCostToComplete: ?number,
            +totalCostAtCompletion: ?number,
          |},
          +financialNumbersToDate: ?{|
            +recognitionActualProfitPercentage: ?number,
            +allActualTimeAndExpenses: ?number,
          |},
          +totalRevenueRecognition: ?number,
          +totalRevenueRecognitionToDate: ?number,
          +totalTimeRevenueRecognitionToDate: ?number,
          +totalBillableExpensesAtCompletion: ?number,
          +settings: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +name: ?string,
                +value: ?string,
              |}
            |}>
          |},
          +userRelationTypes: ?$ReadOnlyArray<?string>,
        |}
      |}>
    |},
    +projectsFinancialsTotals: ?{|
      +recognitionForecastRevenue: ?number,
      +allActualTimeAndExpenses: ?number,
      +allTotalTimeAndExpensesAtCompletion: ?number,
      +recognitionProfitPercentage: ?number,
      +recognitionActualProfitPercentage: ?number,
    |},
    +projectsFinancialsToDate: ?{|
      +totalActualRevenueRecognition: ?number,
      +recognitionAmount: ?number,
      +recognitionActualProfitPercentage: ?number,
      +allActualTimeAndExpenses: ?number,
    |},
    +userRelationTypes: ?$ReadOnlyArray<?string>,
    +members: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +role: ?string,
          +person: ?{|
            +id: string,
            +profilePictureId: ?string,
            +fullName: ?string,
            +email: ?string,
            +initials: ?string,
          |},
        |}
      |}>
    |},
  |},
  +company: ?{|
    +id: string,
    +currency: ?string,
    +allPersons: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +supressProgramBudgetTypeTipMessage: ?$ReadOnlyArray<?string>,
        |}
      |}>
    |},
    +clients: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +logoId: ?string,
          +logoDefaultId: ?number,
        |}
      |}>
    |},
    +programStages: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +type: ?number,
        |}
      |}>
    |},
  |},
  +$refType: ProgramContextProvider_viewer$ref,
|};
export type ProgramContextProvider_viewer$data = ProgramContextProvider_viewer;
export type ProgramContextProvider_viewer$key = {
  +$data?: ProgramContextProvider_viewer$data,
  +$fragmentRefs: ProgramContextProvider_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "prefix",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "budgetType",
  "storageKey": null
},
v8 = {
  "kind": "Literal",
  "name": "first",
  "value": 10000
},
v9 = [
  (v8/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logoId",
  "storageKey": null
},
v11 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ClientTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Client",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePictureId",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allActualTimeAndExpenses",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "recognitionProfitPercentage",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allTotalTimeAndExpensesAtCompletion",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "recognitionActualProfitPercentage",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userRelationTypes",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "prefix",
      "type": "String"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "program",
          "projects"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "program",
          "members"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "company",
          "programStages"
        ]
      }
    ]
  },
  "name": "ProgramContextProvider_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "backendId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualPersonId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "prefix",
          "variableName": "prefix"
        }
      ],
      "concreteType": "ProgramType",
      "kind": "LinkedField",
      "name": "program",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "budgetValue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalAllocation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isRevenueLocked",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "revenueSetting",
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v9/*: any*/),
          "concreteType": "ClientTypeConnection",
          "kind": "LinkedField",
          "name": "clients",
          "plural": false,
          "selections": (v11/*: any*/),
          "storageKey": "clients(first:10000)"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectServiceProgramStatusType",
          "kind": "LinkedField",
          "name": "currentProgramStatus",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ragStatus",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "StatusWithDescription_programStatus"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectServiceProgramStageType",
          "kind": "LinkedField",
          "name": "stage",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserType",
          "kind": "LinkedField",
          "name": "createdBy",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Person",
              "kind": "LinkedField",
              "name": "person",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v12/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": "projects",
          "args": null,
          "concreteType": "ProjectServiceProjectTypeConnection",
          "kind": "LinkedField",
          "name": "__Program_projects_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectServiceProjectTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectServiceProjectType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "serviceCompanyId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "companyProjectId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProjectType",
                      "kind": "LinkedField",
                      "name": "legacyProjectAsSingleProject",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "customProjectId",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    (v2/*: any*/),
                    (v4/*: any*/),
                    (v5/*: any*/),
                    (v6/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "stage",
                      "storageKey": null
                    },
                    (v7/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "budget",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "defaultPeriodBudgetType",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ClientTypeConnection",
                      "kind": "LinkedField",
                      "name": "clients",
                      "plural": false,
                      "selections": (v11/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProjectServiceProjectStatusTypeConnection",
                      "kind": "LinkedField",
                      "name": "status",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProjectServiceProjectStatusTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ProjectServiceProjectStatusType",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                (v3/*: any*/),
                                (v4/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "addedAt",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProjectStatus",
                      "kind": "LinkedField",
                      "name": "currentProjectStatus",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v4/*: any*/),
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Person",
                          "kind": "LinkedField",
                          "name": "person",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "firstName",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "lastName",
                              "storageKey": null
                            },
                            (v13/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "FinancialNumbers",
                      "kind": "LinkedField",
                      "name": "financialNumbers",
                      "plural": false,
                      "selections": [
                        (v14/*: any*/),
                        (v15/*: any*/),
                        (v16/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastCostToComplete",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "totalCostAtCompletion",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "FinancialNumbers",
                      "kind": "LinkedField",
                      "name": "financialNumbersToDate",
                      "plural": false,
                      "selections": [
                        (v17/*: any*/),
                        (v14/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalRevenueRecognition",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalRevenueRecognitionToDate",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalTimeRevenueRecognitionToDate",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalBillableExpensesAtCompletion",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProjectServiceProjectSettingTypeConnection",
                      "kind": "LinkedField",
                      "name": "settings",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProjectServiceProjectSettingTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ProjectServiceProjectSettingType",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                (v1/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "value",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v18/*: any*/),
                    (v19/*: any*/)
                  ],
                  "storageKey": null
                },
                (v20/*: any*/)
              ],
              "storageKey": null
            },
            (v21/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FinancialNumbers",
          "kind": "LinkedField",
          "name": "projectsFinancialsTotals",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "recognitionForecastRevenue",
              "storageKey": null
            },
            (v14/*: any*/),
            (v16/*: any*/),
            (v15/*: any*/),
            (v17/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FinancialNumbers",
          "kind": "LinkedField",
          "name": "projectsFinancialsToDate",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalActualRevenueRecognition",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "recognitionAmount",
              "storageKey": null
            },
            (v17/*: any*/),
            (v14/*: any*/)
          ],
          "storageKey": null
        },
        (v18/*: any*/),
        {
          "alias": "members",
          "args": null,
          "concreteType": "ProjectServiceProgramMemberTypeConnection",
          "kind": "LinkedField",
          "name": "__Program_members_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectServiceProgramMemberTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectServiceProgramMemberType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "role",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Person",
                      "kind": "LinkedField",
                      "name": "person",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v13/*: any*/),
                        (v12/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "email",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "initials",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v19/*: any*/)
                  ],
                  "storageKey": null
                },
                (v20/*: any*/)
              ],
              "storageKey": null
            },
            (v21/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "excludeClientUsers",
              "value": true
            },
            (v8/*: any*/)
          ],
          "concreteType": "PersonTypeConnection",
          "kind": "LinkedField",
          "name": "allPersons",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PersonTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Person",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "supressProgramBudgetTypeTipMessage",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "allPersons(excludeClientUsers:true,first:10000)"
        },
        {
          "alias": null,
          "args": (v9/*: any*/),
          "concreteType": "ClientTypeConnection",
          "kind": "LinkedField",
          "name": "clients",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ClientTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Client",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v10/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "logoDefaultId",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "clients(first:10000)"
        },
        {
          "alias": "programStages",
          "args": null,
          "concreteType": "ProjectServiceProgramStageTypeConnection",
          "kind": "LinkedField",
          "name": "__Company_programStages_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectServiceProgramStageTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectServiceProgramStageType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "type",
                      "storageKey": null
                    },
                    (v19/*: any*/)
                  ],
                  "storageKey": null
                },
                (v20/*: any*/)
              ],
              "storageKey": null
            },
            (v21/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fa9d6093ca9527910fde414bbe3f0cc9';

module.exports = node;
