import React, { useState } from 'react';
import { toEntityId } from '../../../../modals/time-registration/time-registration-modal/util/TimeRegistrationModalUtil';
import TimeRegistrationModalCreateQueryWrapper from '../../../../modals/time-registration/time-registration-modal/query-wrappers/TimeRegistrationModalCreateQueryWrapper';
import { Tabs, Scrollbar, Text, Heading, FlexRow, FlexColumn } from '@forecast-it/design-system';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import Util from '../../../../../util/util';
import TimeEntry from '../time_entry';
import { getLockedDate } from '../../../../../../../components/timesheets/time-lock/TimeLockUtil';
const TabsWrapper = styled.div `
	min-width: 452px;
	min-height: 400px;
	padding-top: 12px;
`;
const TabContentWrapper = styled.div `
	padding: 12px;
	height: 100%;
`;
const TimeEntryWrapper = styled.div `
	li.time-registration-line {
		border-top: none !important;
		margin-bottom: 12px !important;
		.forecast-person {
			max-width: 250px !important;
			width: 250px !important;
		}
	}
	.forecast-person .person-info .name {
		font-family: 'ABC Whyte', sans-serif;
		font-weight: 500;
		font-size: 16px;
	}
	.forecast-person .person-info .role {
		font-weight: 350;
		font-size: 14px;
	}
	.person-initials-hexagon-shape .initials {
		top: -5px;
	}
`;
const Paragraph = styled.div `
	display: flex;
	flex-direction: column;
	margin-top: 8px;
`;
const RightSection = styled.div `
	display: flex;
	height: ${props => props.height + 'px'};
	width: 200px;
	padding: 24px 12px;
	background: ${props => props.theme.sys.color.background.neutral['2']};
	border-style: none none none solid;
	border-width: ${props => props.theme.sys.size.borderwidth.thin};
	border-color: ${props => props.theme.sys.color.border.decorative};
	border-radius: 0 ${props => props.theme.sys.size.borderradius['3']} ${props => props.theme.sys.size.borderradius['3']} 0;
`;
function SubtaskTimeRegSummary({ existingTimeRegs, rollupTimeEntries, height, }) {
    const intl = useIntl();
    const totalMinutesRegistered = existingTimeRegs.reduce((totalMinutesRegistered, timeRegistrationEdge) => totalMinutesRegistered + timeRegistrationEdge.node.minutesRegistered, 0);
    const totalMinutesRollup = totalMinutesRegistered + (rollupTimeEntries !== null && rollupTimeEntries !== void 0 ? rollupTimeEntries : 0);
    return (React.createElement(RightSection, { height: height },
        React.createElement(FlexColumn, null,
            React.createElement(Heading, { size: 4 }, "Time Entry Totals"),
            React.createElement(Paragraph, null,
                React.createElement(Text, null, intl.formatMessage({ id: 'task_modal.current_task' })),
                React.createElement(Text, null,
                    React.createElement("b", null, Util.convertMinutesToFullHour(totalMinutesRegistered, intl, true)))),
            React.createElement(Paragraph, null,
                React.createElement(Text, null, intl.formatMessage({ id: 'task_modal.including_subtasks' })),
                React.createElement(Text, null,
                    React.createElement("b", null, Util.convertMinutesToFullHour(totalMinutesRollup, intl, true)))))));
}
const FlexRowWithHeight = styled(FlexRow) `
	max-height: ${props => props.height + 'px'};
	height: 100%;
`;
const TimeRegistrationWithTimeRegList = ({ viewer, taskId, existingTimeRegs, rollupTimeEntries, noNewTimeEntries, onDone, personProjectRoles, disabled, harvestTaskOptions, modalRef, focusTimeEntriesTile, containerHeight, }) => {
    const [selectedTab, setSelectedTab] = useState('new');
    const formatMessage = useIntl().formatMessage;
    let TABS;
    (function (TABS) {
        TABS["NEW"] = "new";
        TABS["EXISTING"] = "existing";
    })(TABS || (TABS = {}));
    return (React.createElement(FlexRowWithHeight, { alignItems: 'flex-start', height: containerHeight },
        React.createElement(TabsWrapper, null,
            React.createElement(Tabs, { value: selectedTab, onValueChange: setSelectedTab },
                React.createElement(Tabs.List, null,
                    React.createElement(Tabs.Tab, { "data-cy": TABS.NEW + '-tab', value: TABS.NEW }, "New Time Entry"),
                    React.createElement(Tabs.Tab, { "data-cy": TABS.EXISTING + '-tab', value: TABS.EXISTING },
                        "Existing Time Entries (",
                        existingTimeRegs.length,
                        ")")),
                React.createElement(Tabs.Content, { value: TABS.NEW },
                    React.createElement(TabContentWrapper, null,
                        React.createElement(TimeRegistrationModalCreateQueryWrapper, { personId: viewer.actualPersonId, showAsModal: false, fromTaskModal: true, entityId: toEntityId({ idleTimeId: undefined, projectId: undefined, taskId: taskId }), closeModal: onDone, onCreateSuccessCallbacks: [onDone] }))),
                React.createElement(Tabs.Content, { value: TABS.EXISTING },
                    React.createElement(TabContentWrapper, null,
                        React.createElement(Scrollbar, { maxHeight: containerHeight - 100, isPageScroller: false, hideAxis: Scrollbar.Axis.Horizontal, hasFocusableContent: false },
                            React.createElement("div", { style: { padding: '2px' } },
                                noNewTimeEntries && !existingTimeRegs.length ? (React.createElement("div", { className: "no-time-registered-text" }, formatMessage({ id: 'common.no_time_registered' }))) : null,
                                existingTimeRegs.map(timeRegistrationEdge => {
                                    const timeRegPerson = timeRegistrationEdge.node.person;
                                    const personLockedDate = getLockedDate(viewer.company, timeRegPerson);
                                    return (React.createElement(TimeEntryWrapper, null,
                                        React.createElement(TimeEntry, { key: timeRegistrationEdge.node.id, viewer: viewer, personProjectRoles: personProjectRoles, task: viewer.task, timeReg: timeRegistrationEdge.node, disabled: disabled, focusTimeEntriesTile: focusTimeEntriesTile, harvestTaskOptions: harvestTaskOptions, modalRef: modalRef, lockedDate: personLockedDate, useTimeApproval: viewer.company.useTimeApproval })));
                                }))))))),
        selectedTab === TABS.EXISTING && rollupTimeEntries != null && (React.createElement(SubtaskTimeRegSummary, { height: containerHeight - 2, existingTimeRegs: existingTimeRegs, rollupTimeEntries: rollupTimeEntries }))));
};
export default TimeRegistrationWithTimeRegList;
