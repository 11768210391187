import {createFragmentContainer, graphql} from 'react-relay';
import {cloneDeep} from 'lodash';
import {ESTIMATION_UNIT} from '../../../../constants';
import util from '../../../shared/util/util';

const setProjectGroupData = (viewer, projectGroup, calculatedDataMap, hasFinancialCategoriesFeatureFlag) => {
	const isHourEstimated = projectGroup.node.projects?.edges?.length
		? projectGroup.node.projects.edges[0].node.estimationUnit === ESTIMATION_UNIT.HOURS
		: null;
	let approvedScope = 0,
		remaining = 0,
		totalScope = 0,
		reported = 0,
		startDate = null,
		endDate = null,
		tasksCount = 0,
		doneTasksCount = 0,
		budget = 0,
		billablePlannedTimeAndExpenses = 0,
		actualRevenue = 0,
		remainingRevenue = 0,
		forecastRevenue = 0,
		recognitionOpenRevenue = 0,
		recognitionForecastRevenue = 0,
		recognitionLockedRevenue = 0,
		plannedProfit = 0,
		forecastProfit = 0,
		plannedCost = 0,
		actualCost = 0,
		remainingCost = 0,
		forecastCost = 0,
		xeroAmountTotal = 0,
		xeroPaid = 0,
		invoiceAmountTotal = 0,
		invoicePaid = 0,
		projectPersonsCount = null,
		factor = null;
	projectGroup.isCalculatedDataReady = true;
	projectGroup.node.projects?.edges?.forEach(project => {
		project.node.showProject = true;
		if (isHourEstimated === false && factor === null) {
			factor = project.node.minutesPerEstimationPoint;
		}

		const calculatedData = calculatedDataMap ? calculatedDataMap.get(project.node.id) : null;
		if (!calculatedData) {
			projectGroup.isCalculatedDataReady = false;
		}
		/*
		 * ESTIMATION, TIME RELATED FIELDS
		 */
		approvedScope += calculatedData ? calculatedData.forecast : 0;
		remaining += calculatedData ? calculatedData.remaining : 0;
		totalScope += calculatedData ? calculatedData.forecastTotal : 0;
		reported += calculatedData ? calculatedData.reported : 0;
		/*
		 * DATES
		 */
		if (project.node.projectStartYear) {
			const projectStartDate = util.CreateNonUtcMomentDate(
				project.node.projectStartYear,
				project.node.projectStartMonth,
				project.node.projectStartDay
			);
			if (startDate === null || (startDate !== null && startDate.isAfter(projectStartDate))) {
				startDate = projectStartDate;
			}
		}
		if (project.node.projectEndYear) {
			const projectEndDate = util.CreateNonUtcMomentDate(
				project.node.projectEndYear,
				project.node.projectEndMonth,
				project.node.projectEndDay
			);
			if (endDate === null || (endDate !== null && endDate.isBefore(projectEndDate))) {
				endDate = projectEndDate;
			}
		}
		/*
		 * COUNTS
		 */
		tasksCount += calculatedData ? calculatedData.tasksCount : 0;
		doneTasksCount += calculatedData ? calculatedData.doneTasksCount : 0;
		if (projectPersonsCount === null) {
			projectPersonsCount = calculatedData ? calculatedData.projectPersonsCount : 0;
		}
		if (!hasFinancialCategoriesFeatureFlag) {
			/*
			 * FINANCIAL FIELDS
			 */
			if (project.node.budgetBaseCurrency) {
				budget += project.node.budgetBaseCurrency;
			}
			billablePlannedTimeAndExpenses += project.node.financialNumbers.billablePlannedTimeAndExpenses;
			actualRevenue += project.node.financialNumbers.actualRevenue;
			remainingRevenue += project.node.financialNumbers.remainingRevenue;
			forecastRevenue += project.node.financialNumbers.forecastRevenue;
			recognitionOpenRevenue += project.node.financialNumbers.recognitionOpenRevenue;
			recognitionForecastRevenue += project.node.financialNumbers.recognitionForecastRevenue;
			recognitionLockedRevenue += project.node.financialNumbers.recognitionLockedRevenue;
			plannedProfit += project.node.financialNumbers.plannedProfit;
			forecastProfit += project.node.financialNumbers.forecastProfit;
			plannedCost += project.node.financialNumbers.plannedCost;
			actualCost += project.node.financialNumbers.actualCost;
			remainingCost += project.node.financialNumbers.remainingCost;
			forecastCost += project.node.financialNumbers.forecastCost;
			/*
			 * INVOICING FIELDS
			 */

			invoiceAmountTotal += project.node.financialNumbers.invoiced;
			invoicePaid += project.node.financialNumbers.paid;

			/*
			 * XERO INTEGRATION FIELDS
			 */
			if (viewer.company.xeroEnabled && project.node.xeroInvoices) {
				xeroAmountTotal += project.node.xeroInvoices.reduce((total, invoice) => total + invoice.amountTotal, 0);
				xeroPaid += project.node.xeroInvoices.reduce((total, invoice) => total + invoice.amountPaid, 0);
			}
		}
	});
	projectGroup.node.isHourEstimated = isHourEstimated;
	projectGroup.node.minutesPerEstimationPoint = factor;
	projectGroup.node.completion = util.calculateElementProgress(
		approvedScope,
		reported,
		remaining,
		tasksCount,
		doneTasksCount,
		isHourEstimated,
		factor
	);
	projectGroup.node.forecast = approvedScope;
	projectGroup.node.forecastTotal = totalScope;
	projectGroup.node.reported = reported;
	projectGroup.node.remaining = remaining;
	projectGroup.node.startDate = startDate;
	projectGroup.node.endDate = endDate;
	projectGroup.node.tasksCount = tasksCount;
	projectGroup.node.doneTasksCount = doneTasksCount;
	projectGroup.node.projectPersonsCount = projectPersonsCount;
	projectGroup.node.budget = budget;

	projectGroup.node.billablePlannedTimeAndExpenses = billablePlannedTimeAndExpenses;
	projectGroup.node.actualRevenue = actualRevenue;
	projectGroup.node.remainingRevenue = remainingRevenue;
	projectGroup.node.forecastRevenue = forecastRevenue;
	projectGroup.node.recognitionLockedRevenue = recognitionLockedRevenue;
	projectGroup.node.recognitionOpenRevenue = recognitionOpenRevenue;
	projectGroup.node.recognitionForecastRevenue = recognitionForecastRevenue;
	projectGroup.node.plannedProfit = plannedProfit;
	projectGroup.node.forecastProfit = forecastProfit;
	projectGroup.node.plannedCost = plannedCost;
	projectGroup.isFinancialDataReady = true;
	projectGroup.node.actualCost = actualCost;
	projectGroup.node.remainingCost = remainingCost;
	projectGroup.node.forecastCost = forecastCost;

	projectGroup.node.invoiceAmountTotal = invoiceAmountTotal;
	projectGroup.node.invoicePaid = invoicePaid;

	if (viewer.company.xeroEnabled) {
		projectGroup.node.xeroAmountTotal = xeroAmountTotal;
		projectGroup.node.xeroPaid = xeroPaid;
	}

	return projectGroup;
};

const ProjectsLoader = ({
	viewer,
	children,
	calculatedDataMap,
	projectFromOutside,
	projectGroupsFromOutside,
	project_status,
	hasFinancialCategoriesFeatureFlag,
}) => {
	let projectsAndGroups = [];
	if (projectGroupsFromOutside) {
		projectGroupsFromOutside.edges.forEach(pGroup => {
			projectsAndGroups.push(
				setProjectGroupData(viewer, cloneDeep(pGroup), calculatedDataMap, hasFinancialCategoriesFeatureFlag)
			);
		});
	}

	// The second clause here is to avoid an error which occurs if you disable the feature flag while the page is active. When the feature flag is cleaned up, it should similarly be removed.
	if (projectFromOutside) {
		projectFromOutside.edges
			.filter(p => p.node.status === project_status)
			.filter(p => viewer.client || !p.node.isInProjectGroup)
			.forEach(p => projectsAndGroups.push(p));
	}

	return children({data: projectsAndGroups});
};

export const projectsLoaderQuery = graphql`
	query ProjectsLoader_Query {
		viewer {
			actualPersonId
			component(name: "projects_loader")
			...ProjectsLoader_viewer
		}
	}
`;

graphql`
	fragment ProjectsLoader_financialsMultiCurrencies on ProjectType {
		financialNumbers {
			billablePlannedTimeAndExpenses
			actualRevenue
			remainingRevenue
			forecastRevenue
			recognitionOpenRevenue
			recognitionForecastRevenue
			recognitionLockedRevenue
			plannedCost
			actualCost
			forecastCost
			remainingCost
			plannedProfit
			forecastProfit
			invoiced
			paid
		}
		financialNumbersProjectCurrency {
			billablePlannedTimeAndExpenses
			actualRevenue
			remainingRevenue
			forecastRevenue
			recognitionOpenRevenue
			recognitionForecastRevenue
			recognitionLockedRevenue
			plannedCost
			actualCost
			forecastCost
			remainingCost
			plannedProfit
			forecastProfit
			invoiced
			paid
		}
	}
`;

graphql`
	fragment ProjectsLoader_financialsCompanyCurrency on ProjectType {
		financialNumbers {
			billablePlannedTimeAndExpenses
			actualRevenue
			remainingRevenue
			forecastRevenue
			recognitionOpenRevenue
			recognitionForecastRevenue
			recognitionLockedRevenue
			plannedCost
			actualCost
			forecastCost
			remainingCost
			plannedProfit
			forecastProfit
			invoiced
			paid
		}
	}
`;

graphql`
	fragment ProjectsLoader_project on ProjectType {
		id
		name
		budgetType
		status
		description
		statusColor
		companyProjectId
		readOnlyAccess
		budget
		budgetBaseCurrency
		projectColor
		estimationUnit
		minutesPerEstimationPoint
		projectStartDay
		projectStartMonth
		projectStartYear
		projectEndDay
		projectEndMonth
		projectEndYear
		baselineWinChance
		sprintTimeBox
		currentProjectStatus {
			id
			color
			person {
				id
				firstName
				lastName
			}
		}
		client {
			id
			name
			logoId
			logoDefaultId
		}
		rateCard {
			id
			name
			currency
			parentRateCardId
		}
		progress
		manualProgressOnProjectEnabled
		manualProgressOnPhasesEnabled
		manualProgressOnTasksEnabled
		priorityLevel {
			id
			name
			weight
		}
		projectLabels(first: 10000) {
			edges {
				node {
					id
					label {
						id
						name
						color
					}
				}
			}
		}
		projectPersons(first: 1000000, contactsOnly: true) {
			edges {
				node {
					id
					isContactPerson
					person {
						id
						firstName
						lastName
						profilePictureId
						profilePictureDefaultId
						initials
					}
				}
			}
		}
		xeroInvoices {
			id
			amountTotal
			amountPaid
		}
	}
`;

// This fragment container barely does anything, but because of the funky LoadMore approach taken in overview_projects it still needs to be a fragment container.
export default createFragmentContainer(ProjectsLoader, {
	viewer: graphql`
		fragment ProjectsLoader_viewer on Viewer {
			availableFeatureFlags {
				key
			}
			client {
				id
			}
			company {
				xeroEnabled
			}
		}
	`,
});
