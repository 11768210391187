// @ts-check
import React, {useEffect, useMemo, useRef, useState} from 'react';
import moment from 'moment';
import {createFragmentContainer, graphql} from 'react-relay';
import {StatusIndicator} from '../../../../containers/project/project_health/project_health_status/StatusIndicator';
import {
	RichTextWrapper,
	TopRow,
} from '../../../../containers/project/project_health/project_health_styles/ProjectStatus.styled';
import RichTextItem from '../../../../components/richtext_item';
import Util from '../../util/util';
import {Hexagon} from 'web-components';
import {DeprecatedRichTextField as RichTextField, RichTextEditor} from '@forecast-it/design-system';
import styled from 'styled-components';
import {profilePicSrc} from '../../../../directApi';
import {hasFeatureFlag} from '../../util/FeatureUtil';
import {RichTextEditorDraftJsWrapper} from '../rich-text-editor-draftjs-wrapper/RichTextEditorDraftJsWrapper';

const COLLAPSED_STATUS_MAX_HEIGHT = 160;

const StatusWithDescriptionWrapper = styled.div`
	margin-bottom: ${props => (props.margin ? props.margin : '12px')};
`;

const EditorReadMoreWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

// quick hack, should make a read-only styling of editor in design system
const EditorStyleOverride = styled.div`
	> div {
		border: none;
		padding: 0;
	}
`;

const ReadMore = styled.button`
	padding: 4px 8px;
	cursor: pointer;
	border: none;
	border-radius: 4px;
	color: #008bfa;
	align-self: flex-end;
	&:hover,
	&:focus {
		background-color: #e7e7f3;
	}
`;

/**
 * Project status and program status currently don't have aligned signatures so this function is a workaround.
 */
const toSharedStatusFormat = ({projectStatus, programStatus}) => {
	if (!projectStatus && !programStatus) return null;
	return {
		id: programStatus ? programStatus.id : projectStatus.id,
		createdByName: programStatus ? programStatus.createdBy?.person?.fullName || '' : projectStatus.person?.fullName || '',
		createdByProfilePictureId: programStatus
			? programStatus.createdBy?.person?.profilePictureId
			: projectStatus.person?.profilePictureId,
		description: programStatus ? programStatus.description : projectStatus.description,
		statusColor: programStatus ? programStatus.ragStatus : projectStatus.color,
		creationDate: programStatus
			? moment(programStatus.createdAt)
			: Util.CreateNonUtcMomentDate(projectStatus.year, projectStatus.month, projectStatus.day),
		isProgramStatus: !!programStatus,
	};
};

const StatusWithDescription = ({projectStatus, programStatus, showStatusIndicator = true}) => {
	const [showReadMore, setShowReadMore] = useState(false);
	const [readMore, setReadMore] = useState(false);

	/** @type {React.MutableRefObject<HTMLDivElement | null>} */
	const editorRef = useRef(null);

	const status = useMemo(() => {
		return toSharedStatusFormat({projectStatus, programStatus});
	}, [projectStatus, programStatus]);

	useEffect(() => {
		if (editorRef?.current?.clientHeight && editorRef.current.clientHeight >= COLLAPSED_STATUS_MAX_HEIGHT) {
			setShowReadMore(true);
		} else {
			setShowReadMore(false);
		}
	}, [status, editorRef?.current]);

	if (!status) return null;

	const toggleReadMore = () => {
		setReadMore(!readMore);
	};

	const handleReadMoreKeyDown = keyboardEvent => {
		if (keyboardEvent.key === 'Enter' || keyboardEvent.key === ' ') {
			keyboardEvent.stopPropagation();
			keyboardEvent.preventDefault();
			toggleReadMore();
		}
	};

	const creationDate = status.creationDate;

	return (
		<StatusWithDescriptionWrapper margin={hasFeatureFlag('plate_editor') ? '12px' : '24px'}>
			<TopRow>
				{showStatusIndicator ? (
					<StatusIndicator projectStatusColor={status.statusColor} fromRagStatus={status.isProgramStatus} />
				) : null}
				<Hexagon text={status.createdByName} imgUrl={profilePicSrc(status.createdByProfilePictureId)} />
				<span>{status.createdByName + ' - ' + creationDate?.format(Util.GetShortYearMomentFormat('ll'))}</span>
			</TopRow>
			{status.description ? (
				<EditorReadMoreWrapper>
					{status.isProgramStatus ? (
						hasFeatureFlag('plate_editor') ? (
							<RichTextEditor
								pluginToggles={{
									link: true,
									paragraphStyles: true,
								}}
								initialHtml={status.description}
								data-cy={'program_description'}
								readOnly={true}
								variant={'noBorder'}
								id={status.id}
							/>
						) : (
							<EditorStyleOverride ref={editorRef}>
								<RichTextField
									limitations={[
										'BlockQuote',
										'Code',
										'CodeBlock',
										'Table',
										'TableToolbar',
										'Indent',
										'MediaEmbed',
										'ImageUpload',
									]}
									disabled={true}
									editor={'InlineEditor'}
									data={status.description}
									maxHeight={readMore ? null : `${COLLAPSED_STATUS_MAX_HEIGHT}px`}
									whiteMode
								/>
							</EditorStyleOverride>
						)
					) : hasFeatureFlag('plate_editor') ? (
						<RichTextEditorDraftJsWrapper
							initialDraftJsContent={status.description}
							readOnly={true}
							variant={'noBorder'}
							id={status.id}
						/>
					) : (
						<RichTextWrapper
							// @ts-ignore
							maxHeight={readMore ? null : `${COLLAPSED_STATUS_MAX_HEIGHT}px`}
						>
							<div ref={editorRef} style={{minWidth: '455px'}}>
								<RichTextItem
									cy={'status-description'}
									projectLocked={false}
									alwaysShowControls={false}
									readOnly={true}
									text={status.description}
									placeholder={''}
								/>
							</div>
						</RichTextWrapper>
					)}
					{showReadMore ? (
						<ReadMore tabIndex={0} onKeyDown={handleReadMoreKeyDown} onClick={toggleReadMore}>
							{readMore ? 'Read Less' : 'Read More'}
						</ReadMore>
					) : null}
				</EditorReadMoreWrapper>
			) : null}
		</StatusWithDescriptionWrapper>
	);
};

export default createFragmentContainer(StatusWithDescription, {
	programStatus: graphql`
		fragment StatusWithDescription_programStatus on ProjectServiceProgramStatusType {
			id
			createdAt
			createdBy {
				person {
					id
					fullName
					profilePictureId
				}
			}
			description
			ragStatus
		}
	`,
	projectStatus: graphql`
		fragment StatusWithDescription_projectStatus on ProjectStatus {
			id
			person {
				id
				fullName
				profilePictureId
				profilePictureDefaultId
			}
			description
			color
			day
			month
			year
		}
	`,
});
