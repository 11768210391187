import {BUDGET_TYPE, PERIOD_BUDGET_TYPE, QUANTITY_LOCKED_REASON} from '../../../../constants';
import {convertPriceToInvoiceCurrency, formatDate, addQuantityDecimals} from './invoicing_generate_lines_utils';
import Util from '../../../../forecast-app/shared/util/util';
import {hasFeatureFlag} from '../../../../forecast-app/shared/util/FeatureUtil';
import {sortByStartDate} from '../../../../forecast-app/project-tab/projects/project-retainer-periods/RetainerPeriodUtil';

const generateLinesForFixedPriceProject = (project, invoice, company) => {
	const projectCurrency = project.rateCard ? project.rateCard.currency : company.currency;
	const alreadyInvoicedForProject = project.invoiceTotals.reduce(
		(totalInvoiced, invoice) => totalInvoiced + invoice.invoicedWithoutTax,
		0
	);
	let fixedPriceRemaining = project.budget - alreadyInvoicedForProject;
	if (!hasFeatureFlag('Expense_Modal_Update_22Q3')) {
		project.expenseItems.edges.forEach(expense => {
			if (!expense.node.invoiced && expense.node.billable) {
				fixedPriceRemaining -= expense.node.price * expense.node.quantity;
			}
		});
	}

	if (fixedPriceRemaining) {
		const description = `${project.name}`;
		const quantity = 1;
		const unitPrice = convertPriceToInvoiceCurrency(fixedPriceRemaining, projectCurrency, company, invoice);
		const projectId = project.id;
		const tax = invoice.tax;
		const discount = invoice.discount;

		return [
			{
				description,
				quantity: addQuantityDecimals(quantity, company),
				unitPrice,
				projectId,
				tax,
				discount,
				quantityLockedReason: QUANTITY_LOCKED_REASON.FIXED_PRICE,
			},
		];
	} else {
		return [];
	}
};

const generateLinesForRetainerProject = (project, invoice, intl, company) => {
	const periodsToInvoice = project.retainerPeriods.edges
		.map(edge => edge.node)
		.sort(sortByStartDate)
		.filter(
			period => period.periodBudgetType === PERIOD_BUDGET_TYPE.FIXED_PRICE && period.periodLocked && !period.invoiced
		);
	if (periodsToInvoice.length > 0) {
		const periodLines = [];
		periodsToInvoice.forEach(period => {
			const periodStartDate = Util.CreateMomentDate(period.startYear, period.startMonth, period.startDay);
			const periodEndDate = Util.CreateMomentDate(period.endYear, period.endMonth, period.endDay);

			const periodName = `${period.name} (${formatDate(intl, periodStartDate)} - ${formatDate(intl, periodEndDate)})`;
			const description = `${periodName}`;
			const quantity = 1;
			let unitPrice = period.periodPriceAmount;
			const projectCurrency = project.rateCard ? project.rateCard.currency : company.currency;
			unitPrice = convertPriceToInvoiceCurrency(unitPrice, projectCurrency, company, invoice);
			const projectId = project.id;
			const tax = invoice.tax;
			const discount = invoice.discount;
			const periodIds = [period.id];
			periodLines.push({
				description,
				quantity: addQuantityDecimals(quantity, company),
				unitPrice,
				projectId,
				tax,
				discount,
				periodIds,
				quantityLockedReason: QUANTITY_LOCKED_REASON.FIXED_PRICE_PERIOD,
			});
		});
		return periodLines;
	} else {
		return [];
	}
};

export const generateLinesForFixedPrice = (intl, invoice, projects, company) => {
	const invoiceLines = [];

	projects.forEach(project => {
		if (project.budgetType === BUDGET_TYPE.FIXED_PRICE || project.budgetType === BUDGET_TYPE.FIXED_PRICE_V2) {
			invoiceLines.push(...generateLinesForFixedPriceProject(project, invoice, company));
		} else if (project.budgetType === BUDGET_TYPE.RETAINER) {
			invoiceLines.push(...generateLinesForRetainerProject(project, invoice, intl, company));
		}
	});

	return invoiceLines;
};
