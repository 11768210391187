import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import PropTypes from 'prop-types';
import Moment from 'moment';
import {withRouter} from 'react-router-dom';
import Person from '../../../shared/components/person/person';
import ProjectFilesDropZone from './ProjectFilesDropZone';
import Util from '../../../shared/util/util';
import dummyImg from '../../../../images/dummy/lego.jpg';
import DeleteFileMutation from '../../../../mutations/delete_file_mutation.modern';
import UpdateFileMutation from '../../../../mutations/update_file_mutation.modern';
import {FormattedMessage, injectIntl} from 'react-intl';
import CreateFolderMutation from '../../../../mutations/create_folder_mutation';
import DeleteFolderMutation from '../../../../mutations/delete_folder_mutation';
import {BUTTON_COLOR, BUTTON_STYLE, ELEMENT_TYPE, fileExtensions, PROJECT_STATUS} from '../../../../constants';
import * as tracking from '../../../../tracking';
import InputField from '../../../../components/inputs/input_field';
import {createToast} from '../../../shared/components/toasts/another-toast/toaster';
import CreateGoogleDriveProjectFileAttachmentMutation from '../../../../mutations/create_google_drive_project_file_attachment';
import CreateGoogleDriveFileAttachmentMutation from '../../../../mutations/create_google_drive_file_attachment';
import DeleteGoogleDriveProjectFileLinkMutation from '../../../../mutations/delete_google_drive_project_file_link';
import DeleteGoogleDriveFileLinkMutation from '../../../../mutations/delete_google_drive_file_link';
import UpdateFolderMutation from '../../../../mutations/update_folder_mutation';
import CustomScrollDiv from '../../../shared/components/scroll-bars/custom_scroll_div';
import UploadingOverlay from '../../../shared/components/uploading-overlay/uploading_overlay';
import ProjectFilesDownloadButton from './ProjectFilesDownloadButton';
import ProjectFilesDeleteButton from './ProjectFilesDeleteButton';
import {MODAL_TYPE, showModal} from '../../../shared/components/modals/generic_modal_conductor';
import UpdateGoogleDriveFileMutation from '../../../../mutations/update_google_drive_file_mutation';
import HeaderBar from '../../../shared/components/headers/header-bar/header_bar';
import {cloneDeep} from 'lodash';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../../shared/components/headers/top-header-bar/TopHeaderBar';
import {trackPage, unregisterPageInfo} from '../../../../tracking/amplitude/TrackingV2';
import ProjectHeader from '../shared/ProjectHeader';
import DirectApi, {bulkDownloadSrc, profilePicSrc} from '../../../../directApi';
import {getTaskUrl, pathIncludesTask} from '../../../shared/util/UrlUtil';

const fileFilterOptions = [
	{value: 'ai', label: 'AI'},
	{value: 'avi', label: 'AVI'},
	{value: 'doc', label: 'DOC'},
	{value: 'eps', label: 'EPS'},
	{value: 'gif', label: 'GIF'},
	{value: 'indo', label: 'INDD'},
	{value: 'jpg', label: 'JPG'},
	{value: 'm4v', label: 'M4V'},
	{value: 'mov', label: 'MOV'},
	{value: 'mpg', label: 'MPG'},
	{value: 'pdf', label: 'PDF'},
	{value: 'png', label: 'PNG'},
	{value: 'ppt', label: 'PPT'},
	{value: 'psd', label: 'PSD'},
	{value: 'rar', label: 'RAR'},
	{value: 'svg', label: 'SVG'},
	{value: 'txt', label: 'TXT'},
	{value: 'xls', label: 'XLS'},
	{value: 'zip', label: 'ZIP'},
];
class ProjectFilesPage extends Component {
	constructor(props) {
		super(props);
		this.state = this.getFreshStateObject(this.props);

		this.handleShortcutProjectFiles = this.handleShortcutProjectFiles.bind(this);
		this.setElements = this.setElements.bind(this);

		this.superPropertyChecksum = trackPage('Project Files');
	}

	UNSAFE_componentWillMount() {
		const path = this.props.history.location.pathname;
		if (!Util.AuthorizeViewerAccess('project-files')) {
			if (pathIncludesTask(path)) {
				const cardPath = getTaskUrl(path);
				this.props.history.push('/project/P-' + this.props.viewer.project.companyProjectId + '/workflow' + cardPath);
			} else {
				// if user doesnt have access rights to view this page redirect to no access page
				this.props.history.push('/not-authorized');
				Util.localStorageSetItem('project-section-last-viewed', 'workflow');
			}
		}
		const files = [];
		this.props.viewer.project.files.edges.forEach(file => {
			files.push(file);
		});
		this.props.viewer.project.googleDriveFiles.edges.forEach(gDriveFile => files.push(gDriveFile));
		cloneDeep(this.props.viewer.project.tasks.edges).forEach(task => {
			task.node.files.edges.forEach(file => files.push(file));
			task.node.googleDriveFiles.edges.forEach(gdriveFile => {
				gdriveFile.node.task = task.node;
				files.push(gdriveFile);
			});
		});
		this.setElements(
			this.props.viewer.project.folders.edges,
			files,
			this.state.selectedFolder,
			this.state.sortBy,
			this.state.searchFilterValue,
			this.state.selectedFileTypeOptions
		);
	}

	componentDidMount() {
		const name =
			this.props.viewer.project.name !== null && this.props.viewer.project.name !== ''
				? this.props.viewer.project.name
				: 'P-' + this.props.viewer.project.companyProjectId;
		document.title = 'Files - ' + name + ' - Forecast';
		document.addEventListener('keydown', this.handleShortcutProjectFiles);

		tracking.trackPage('project-files');
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.viewer.project.id !== nextProps.viewer.project.id) {
			this.setState(this.getFreshStateObject(nextProps));
		} else if (this.state.selectedFolder) {
			const selectedFolder = nextProps.viewer.project.folders.edges.find(
				f => f.node.id === this.state.selectedFolder.node.id
			);
			if (selectedFolder && selectedFolder.node.name !== this.state.selectedFolder.node.name) {
				this.setState({selectedFolder});
			} else if (!selectedFolder) {
				if (this.state.selectedFolder.node.folder) {
					const parentFolder = nextProps.viewer.project.folders.edges.find(
						f => f.node.id === this.state.selectedFolder.node.folder.id
					);
					this.setState({selectedFolder: parentFolder});
				} else {
					this.setState({selectedFolder: null});
				}
			}
		}
	}

	UNSAFE_componentWillUpdate(nextProps, nextState) {
		const files = [];
		nextProps.viewer.project.files.edges.forEach(file => {
			files.push(file);
		});
		nextProps.viewer.project.googleDriveFiles.edges.forEach(gDriveFile => files.push(gDriveFile));
		cloneDeep(nextProps.viewer.project.tasks.edges).forEach(task => {
			task.node.files.edges.forEach(file => file.node && files.push(file));
			task.node.googleDriveFiles.edges.forEach(gdriveFile => {
				gdriveFile.node.task = task.node;
				files.push(gdriveFile);
			});
		});
		this.setElements(
			nextProps.viewer.project.folders.edges,
			files,
			nextState.selectedFolder,
			nextState.sortBy,
			nextState.searchFilterValue,
			nextState.selectedFileTypeOptions
		);
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);

		document.removeEventListener('keydown', this.handleShortcutProjectFiles);
	}

	getFreshStateObject(props) {
		return {
			isUploading: false,
			selectedFile: null,
			selectedFileName: null,
			selectedFolder: null,
			searchFilterValue: '',
			bulkUpdateFiles: [],
			expanded: false,
			sortBy: {column: null, ascending: true},
			selectedFileTypeOptions: [],
		};
	}

	setSortBy(column) {
		let ascending = true;
		if (column === this.state.sortBy.column) {
			ascending = !this.state.sortBy.ascending;
		}
		this.setState({sortBy: {column: column, ascending: ascending}});
	}

	handleShortcutProjectFiles(e) {
		if (e.altKey && e.shiftKey && e.keyCode === 88) {
			this.clearFilters();
		}
	}

	getExtension(fileNode) {
		return fileNode.name ? fileNode.name.toLowerCase().split('.').pop() : '';
	}

	handleEnlarge() {
		if (!this.state.selectedFile.node.link) {
			const selectedFileSrc = DirectApi.fileSrc(this.state.selectedFile.node.id);
			const filesSrcArray = this.files
				.filter(edge => {
					if (!Util.FileHasPreview(edge.node)) {
						return false;
					}
					if (this.state.selectedFolder === null) {
						return !edge.node.folder;
					} else {
						return edge.node.folder && this.state.selectedFolder.node.id === edge.node.folder.id;
					}
				})
				.map(edge => DirectApi.fileSrc(edge.node.id));

			showModal({
				type: MODAL_TYPE.EXPAND_FILE,
				file: selectedFileSrc,
				files: filesSrcArray,
			});
		} else {
			window.open(this.state.selectedFile.node.link, '_blank').focus();
		}
	}

	handleSearchChange(input) {
		this.setState({searchFilterValue: input});
	}

	selectFile(file) {
		this.setState({
			selectedFile: file,
			selectedFileName: file.node.name
				? file.node.name.indexOf('.') === -1
					? file.node.name
					: file.node.name.substr(0, file.node.name.lastIndexOf('.'))
				: '',
		});
	}

	deleteFile(file) {
		const onSuccess = result => {
			createToast({message: this.props.intl.formatMessage({id: 'file.has-been-deleted'}), duration: 3000});
		};
		//Deselect if u are removing the file that is selected
		if (this.state.selectedFile && this.state.selectedFile.node.id === file.node.id) {
			this.setState({
				selectedFile: null,
			});
		}
		Util.CommitMutation(
			DeleteFileMutation,
			{
				files: [file.node.id],
				taskId: file.node.task ? file.node.task.id : null,
				projectId: this.props.viewer.project.id,
			},
			onSuccess
		);
	}

	showTaskModal() {
		if (this.state.selectedFile.node.taskId) {
			Util.showTaskModal(this.state.selectedFile.node.parentTaskId, this.props.history);
		}
	}

	uploadFiles(files, droppedOnFolderId) {
		if (!files) return;

		this.setState({isUploading: true});
		const onSuccess = () => {
			this.setState({isUploading: false});
		};

		const upload = [];

		if (Array.isArray(files)) {
			files.forEach(f =>
				upload.push({
					projectId: this.props.viewer.project.id,
					mimeType: f.type,
					name: f.name,
					file: f,
					folderId: droppedOnFolderId
						? droppedOnFolderId
						: this.state.selectedFolder
						? this.state.selectedFolder.node.id
						: null,
					size: f.size,
				})
			);
		} else {
			upload.push({
				projectId: this.props.viewer.project.id,
				mimeType: files.type,
				name: files.name,
				file: files,
				folderId: droppedOnFolderId
					? droppedOnFolderId
					: this.state.selectedFolder
					? this.state.selectedFolder.node.id
					: null,
				size: files.size,
			});
		}

		Util.uploadFiles(upload, onSuccess);
	}

	uploadFilesFromButton(file) {
		this.setState({expanded: false});
		this.uploadFiles(this._file.files.item(0));
	}

	uploadFilesFromButtonV2(file) {
		if (file) {
			this.setState({isUploading: true});
			this.uploadFiles(file);
		}
	}

	uploadFilesGoogleDrive(data) {
		const onSuccess = result => {
			createToast({duration: 5000, message: this.props.intl.formatMessage({id: 'project_files.file-added'})});
		};
		if (this.state.expanded) {
			this.setState({expanded: false});
		}
		if (data && data.docs) {
			data.docs.forEach(doc => {
				const input = {
					projectId: this.props.viewer.project.id,
					personId: this.props.viewer.actualPersonId,
					googleDriveId: doc.id,
					name: doc.name,
					link: doc.url,
					thumb: doc.iconUrl.replace('/16/', '/64/'),
					folderId: this.state.selectedFolder ? this.state.selectedFolder.node.id : null,
				};
				Util.CommitMutation(CreateGoogleDriveProjectFileAttachmentMutation, input, onSuccess);
			});
		}
	}

	uploadGoogleDriveFileToCard(file, taskId) {
		const input = {
			taskId: taskId,
			personId: this.props.viewer.actualPersonId,
			googleDriveId: file.node.id,
			name: file.node.name,
			link: file.node.link,
			thumb: file.node.thumb,
			folderId: file.node.folder ? file.node.folder.id : null,
		};
		Util.CommitMutation(CreateGoogleDriveFileAttachmentMutation, input, null, true);
	}

	unlinkFile(fileId, taskId = null) {
		if (taskId) {
			const input = {
				id: fileId,
				taskId: taskId,
			};
			Util.CommitMutation(DeleteGoogleDriveFileLinkMutation, input, null, true);
		} else {
			const input = {
				id: fileId,
				projectId: this.props.viewer.project.id,
			};
			Util.CommitMutation(DeleteGoogleDriveProjectFileLinkMutation, input);
		}
	}

	handleFileNameEdit(value) {
		this.setState({selectedFileName: value});
	}

	updateFileNameOnBlur() {
		Util.CommitMutation(UpdateFileMutation, {
			ids: [this.state.selectedFile.node.id],
			name: this.state.selectedFileName + '.' + this.getExtension(this.state.selectedFile.node),
		});
	}

	selectFolder(folder) {
		this.setState({
			selectedFile: null,
			selectedFolder: folder,
			bulkUpdateFiles: [],
		});
	}

	deleteFolder(folder) {
		const onSuccess = result => {
			this.setState(this.getFreshStateObject(this.props));
			createToast({
				duration: 5000,
				message: this.props.intl.formatMessage({id: 'project_files.folder_deleted'}),
			});
		};
		Util.CommitMutation(
			DeleteFolderMutation,
			{
				id: folder.node.id,
				projectId: this.props.viewer.project.id,
			},
			onSuccess
		);
	}

	handleFileMoveToCard(cardId, file) {
		if (file.node.link) {
			if (!file.node.task) {
				// Remove and recreate file, since gDrive files with and without a task are stored in different tables
				this.unlinkFile(file.node.id, file.node.task ? file.node.task.id : null);
				this.uploadGoogleDriveFileToCard(file, cardId);
			} else {
				// Change task id
				Util.CommitMutation(UpdateGoogleDriveFileMutation, {
					id: file.node.id,
					taskId: cardId,
					personId: file.node.person.id,
					name: file.node.name,
					link: file.node.link,
					thumb: file.node.thumb,
					previousTaskId: file.node.task.id,
					projectId: this.props.viewer.project.id,
					folderId: file.node.folder ? file.node.folder.id : null,
				});
			}
		} else {
			Util.CommitMutation(UpdateFileMutation, {
				ids: [file.node.id],
				taskId: cardId,
				projectId: this.props.viewer.project.id,
			});
		}
	}

	showFileLocationModal(file) {
		showModal({
			type: MODAL_TYPE.FILE_LOCATION,
			currentProjectId: this.props.viewer.project.id,
			taskId: file.node.task ? file.node.task.id : null,
			cards: this.props.viewer.project.tasks.edges,
			file: file,
			handleFileMoveToCard: this.handleFileMoveToCard.bind(this),
		});
	}

	handleAddFolder(name) {
		const onSuccess = () => {
			createToast({
				duration: 5000,
				message: this.props.intl.formatMessage({id: 'project_files.folder_added'}),
			});
		};
		Util.CommitMutation(
			CreateFolderMutation,
			{
				projectId: this.props.viewer.project.id,
				name: name,
				folderId: this.state.selectedFolder ? this.state.selectedFolder.node.id : null,
			},
			onSuccess
		);
	}

	showAddFolderModal() {
		this.setState({expanded: false});
		showModal({
			type: MODAL_TYPE.USER_INPUT,
			titleText: <FormattedMessage id="project_files.add_folder" />,
			confirmText: <FormattedMessage id="common.add" />,
			initialValue: '',
			handleConfirm: this.handleAddFolder.bind(this),
			placeholder: this.props.intl.formatMessage({id: 'project_files.enter-folder-name'}),
			label: this.props.intl.formatMessage({id: 'project_files.folder_name'}),
			focusOnMount: true,
		});
	}

	handleFileMoveToRoot(files, isGoogleDriveFile) {
		if (isGoogleDriveFile) {
			const file = files[0];
			Util.CommitMutation(UpdateGoogleDriveFileMutation, {
				id: file.node.id,
				taskId: file.node.taskId,
				personId: file.node.person.id,
				name: file.node.name,
				link: file.node.link,
				thumb: file.node.thumb,
				projectId: this.props.viewer.project.id,
				folderId: null,
				createdAt: file.node.createdAt,
			});
		} else {
			Util.CommitMutation(UpdateFileMutation, {
				ids: files,
				folderId: null,
			});
		}
	}

	sort(files, sortBy) {
		if (sortBy.column) {
			const direction = sortBy.ascending ? 1 : -1;
			if (sortBy.column === 'name') {
				const sortFunc = (a, b) => {
					const aName = a.node.name ? a.node.name.toLowerCase() : '';
					const bName = b.node.name ? b.node.name.toLowerCase() : '';
					return aName > bName ? direction : direction * -1;
				};
				files.sort(sortFunc);
			} else if (sortBy.column === 'format') {
				files.sort((a, b) => {
					const aFormat = this.getExtension(a.node);
					const bFormat = this.getExtension(b.node);
					return aFormat > bFormat ? direction : direction * -1;
				});
			} else if (sortBy.column === 'date') {
				const sortFunc = (a, b) => {
					let aDate;
					if (a.node.link) {
						aDate = Moment.utc(a.node.createdAt);
					} else {
						aDate = Util.CreateMomentDate(a.node.yearCreated, a.node.monthCreated, a.node.dayCreated);
					}
					let bDate;
					if (b.node.link) {
						bDate = Moment.utc(b.node.createdAt);
					} else {
						bDate = Util.CreateMomentDate(b.node.yearCreated, b.node.monthCreated, b.node.dayCreated);
					}
					return aDate.isBefore(bDate) ? direction : direction * -1;
				};
				files.sort(sortFunc);
			} else if (sortBy.column === 'task') {
				files.sort((a, b) => {
					const aTask = a.node.parentTaskId ? a.node.parentTaskId : '';
					const bTask = b.node.parentTaskId ? b.node.parentTaskId : '';
					return aTask > bTask ? direction : direction * -1;
				});
			}
		}
	}

	addFileToBulkUpdate(file, e, sortBy, elements) {
		if (e.shiftKey) {
			const bulkUpdateFiles = this.state.bulkUpdateFiles;
			const visibleFileIds = elements.map(el => el.object.node.id);
			let files = this.props.viewer.project.folders.edges
				.concat(this.files)
				.filter(file => visibleFileIds.includes(file.node.id));
			this.sort(files, sortBy);
			let selectedFileReached = false,
				bulkStartReached = false;
			files.forEach(f => {
				if (
					!f.node.link &&
					((!file.node.folder && !f.node.folder) ||
						(file.node.folder && f.node.folder && file.node.folder.id === f.node.folder.id))
				) {
					if (!selectedFileReached) {
						selectedFileReached = f.node.id === file.node.id;
						if (!selectedFileReached && this.state.bulkUpdateFiles.length === 0) {
							bulkUpdateFiles.push(f.node.id);
						}
					}
					if (f.node.id !== file.node.id) {
						if (this.state.bulkUpdateFiles.length !== 0 && !bulkStartReached) {
							bulkStartReached = this.state.bulkUpdateFiles.includes(f.node.id);
						}
						if (
							!bulkUpdateFiles.includes(f.node.id) &&
							this.state.bulkUpdateFiles.length !== 0 &&
							bulkStartReached &&
							!selectedFileReached
						) {
							bulkUpdateFiles.push(f.node.id);
						} else if (
							!bulkUpdateFiles.includes(f.node.id) &&
							this.state.bulkUpdateFiles.length !== 0 &&
							!bulkStartReached &&
							selectedFileReached
						) {
							bulkUpdateFiles.push(f.node.id);
						}
					}
				}
			});
			bulkUpdateFiles.push(file.node.id);
			this.setState({bulkUpdateFiles: bulkUpdateFiles});
		} else {
			const fileIds = this.state.bulkUpdateFiles;
			fileIds.push(file.node.id);
			this.setState({bulkUpdateFiles: fileIds});
		}
	}

	removeFileFromBulkUpdate(file, shiftKey, sortBy) {
		if (shiftKey) {
			let files = this.files,
				bulkUpdateFiles = [];
			this.sort(files, sortBy);
			let selectedFileReached = false;
			files
				.filter(f => !f.node.link)
				.forEach(f => {
					if (!selectedFileReached && this.state.bulkUpdateFiles.includes(f.node.id)) {
						bulkUpdateFiles.push(f.node.id);
						selectedFileReached = f.node.id === file.node.id;
					}
				});
			this.setState({bulkUpdateFiles: bulkUpdateFiles});
		} else {
			const fileIds = this.state.bulkUpdateFiles.filter(id => id !== file.node.id);
			this.setState({bulkUpdateFiles: fileIds});
		}
	}

	bulkUpdateAnotherCard() {
		const callbackPositive = selected => {
			const onSuccess = () => {
				this.setState({bulkUpdateFiles: []});
			};
			Util.CommitMutation(
				UpdateFileMutation,
				{
					ids: this.state.bulkUpdateFiles,
					taskId: selected.value,
					projectId: this.props.viewer.project.id,
				},
				onSuccess
			);
		};
		const cards_options = this.props.viewer.project.tasks.edges.map(task => ({
			value: task.node.id,
			label: 'T' + task.node.companyTaskId + ' ' + task.node.name,
		}));
		showModal({
			type: MODAL_TYPE.SELECT,
			title: this.props.intl.formatMessage({id: 'project_files.bulk_update_move_to_card'}),
			defaultCallback: callbackPositive,
			options: cards_options,
			label: this.props.intl.formatMessage({id: 'common.card'}),
			multi: false,
		});
	}

	bulkUpdateAnotherFolder() {
		const callbackPositive = selected => {
			const onSuccess = () => {
				this.setState({bulkUpdateFiles: []});
			};
			Util.CommitMutation(
				UpdateFileMutation,
				{
					ids: this.state.bulkUpdateFiles,
					folderId: selected.value === 'top_level' ? null : selected.value,
				},
				onSuccess
			);
		};
		const folder_options = this.props.viewer.project.folders.edges.map(folder => ({
			value: folder.node.id,
			label: folder.node.name,
		}));
		folder_options.unshift({
			value: 'top_level',
			label: this.props.intl.formatMessage({id: 'project_files.move-to-top-level'}),
		});
		showModal({
			type: MODAL_TYPE.SELECT,
			title: this.props.intl.formatMessage({id: 'project_files.bulk_update_move_to_folder'}),
			defaultCallback: callbackPositive,
			options: folder_options,
			label: this.props.intl.formatMessage({id: 'common.folder'}),
			multi: false,
		});
	}

	bulkUpdateDelete() {
		const callbackPositive = () => {
			const onSuccess = result => {
				if (this.state.selectedFile && this.state.bulkUpdateFiles.includes(this.state.selectedFile.node.id)) {
					this.setState({selectedFile: null});
				}
				this.setState({bulkUpdateFiles: []});
				createToast({message: this.props.intl.formatMessage({id: 'file.has-been-deleted'}), duration: 3000});
			};
			Util.CommitMutation(
				DeleteFileMutation,
				{
					files: this.state.bulkUpdateFiles,
					projectId: this.props.viewer.project.id,
				},
				onSuccess
			);
		};

		showModal({
			type: MODAL_TYPE.GENERIC_DELETION_WARNING,
			deleteCallback: callbackPositive,
		});
	}

	removeAllFromBulkUpdate() {
		this.setState({bulkUpdateFiles: []});
	}

	toggleSelectAllFiles(folderId) {
		if (this.state.bulkUpdateFiles.length) {
			this.setState({bulkUpdateFiles: []});
		} else {
			const files = this.props.viewer.project.files.edges
				.filter(
					file =>
						(this.state.selectedFolder &&
							file.node.folder &&
							file.node.folder.id === this.state.selectedFolder.node.id) ||
						(!this.state.selectedFolder && !file.node.folder)
				)
				.map(file => file.node.id);
			this.props.viewer.project.tasks.edges.forEach(task => {
				task.node.files.edges
					.filter(
						file =>
							(this.state.selectedFolder &&
								file.node.folder &&
								file.node.folder.id === this.state.selectedFolder.node.id) ||
							(!this.state.selectedFolder && !file.node.folder)
					)
					.forEach(file => files.push(file.node.id));
			});
			this.setState({bulkUpdateFiles: files});
		}
	}

	addFolderToPath(folder) {
		this.pathFolders.push({value: folder.id, label: folder.name});
		if (folder.folder) {
			//find folder in projects folders array and call addFolderToPath
			const parentFolder = this.props.viewer.project.folders.edges.find(el => el.node.id === folder.folder.id);
			if (parentFolder) {
				this.addFolderToPath(parentFolder.node);
			}
		}
	}

	renameFolder(folder) {
		const callbackPositive = value => {
			const onSuccess = () => {
				createToast({
					duration: 5000,
					message: this.props.intl.formatMessage({id: 'project_files.folder_renamed'}),
				});
			};
			Util.CommitMutation(
				UpdateFolderMutation,
				{
					id: folder.node.id,
					name: value,
				},
				onSuccess
			);
		};
		showModal({
			type: MODAL_TYPE.USER_INPUT,
			initialValue: folder.node.name,
			handleConfirm: callbackPositive,
			titleText: <FormattedMessage id="project_files.edit_folder" />,
			confirmText: <FormattedMessage id="common.save" />,
			placeholder: this.props.intl.formatMessage({id: 'project_files.enter-folder-name'}),
			label: this.props.intl.formatMessage({id: 'project_files.folder_name'}),
		});
	}

	setElements(folders, files, selectedFolder, sortBy, searchFilterValue, selectedFileTypeOptions) {
		this.filesByFolderMap = new Map();
		this.elements = [];
		this.filesByFolderMap.set(null, []);
		if (folders) {
			folders.forEach(folder => {
				if (folder && folder.node && folder.node.id) {
					this.filesByFolderMap.set(folder.node.id, []);
					(this.elements || []).push({object: folder, isFolder: true});
				}
			});
		}
		files.forEach(file => {
			if (file && file.node) {
				const filesList = this.filesByFolderMap.get(file.node.folder ? file.node.folder.id : null) || [];
				filesList.push(file);
				this.filesByFolderMap.set(file.node.folder ? file.node.folder.id : null, filesList);
				this.elements.push({object: file, isFolder: false});
			}
		});
		this.elements = this.elements.filter(el =>
			this.matchesFilterCriteria(el.object, searchFilterValue, el.isFolder, folders, selectedFileTypeOptions)
		);
		if (selectedFolder) {
			this.elements = this.elements.filter(
				el => el.object.node.folder && el.object.node.folder.id === selectedFolder.node.id
			);
		} else {
			this.elements = this.elements.filter(el => !el.object.node.folder);
		}
		this.hasFiles = this.elements.filter(el => !el.isFolder).length !== 0;
		this.sortElements(sortBy);
	}

	sortElements(sortBy) {
		if (sortBy.column) {
			const direction = sortBy.ascending ? 1 : -1;
			if (sortBy.column === 'name') {
				this.elements.sort((a, b) => {
					const aName = a.object.node.name ? a.object.node.name.toLowerCase() : '';
					const bName = b.object.node.name ? b.object.node.name.toLowerCase() : '';
					return aName > bName ? direction : direction * -1;
				});
			} else if (sortBy.column === 'format') {
				this.elements.sort((a, b) => {
					const aFormat = a.object.isFolder ? 'folder' : this.getExtension(a.object.node);
					const bFormat = b.object.isFolder ? 'folder' : this.getExtension(b.object.node);
					return aFormat > bFormat ? direction : direction * -1;
				});
			} else if (sortBy.column === 'date') {
				this.elements.sort((a, b) => {
					let aDate;
					if (!a.object.isFolder && a.object.node.link) {
						aDate = Moment.utc(a.object.node.createdAt);
					} else {
						aDate = Util.CreateMomentDate(
							a.object.node.yearCreated,
							a.object.node.monthCreated,
							a.object.node.dayCreated
						);
					}
					let bDate;
					if (!b.object.isFolder && b.object.node.link) {
						bDate = Moment.utc(b.object.node.createdAt);
					} else {
						bDate = Util.CreateMomentDate(
							b.object.node.yearCreated,
							b.object.node.monthCreated,
							b.object.node.dayCreated
						);
					}
					return aDate.isBefore(bDate) ? direction : direction * -1;
				});
			} else if (sortBy.column === 'task') {
				this.elements.sort((a, b) => {
					const aTask = !a.object.isFolder && a.object.node.task ? a.object.node.task.companyTaskId : null;
					const bTask = !b.object.isFolder && b.object.node.task ? b.object.node.task.companyTaskId : null;
					return aTask > bTask ? direction : direction * -1;
				});
			}
		}
	}

	matchesFilterCriteria(element, searchFilterValue, isFolder, folders, selectedFileTypeOptions) {
		let searchCrit = !searchFilterValue || searchFilterValue === '',
			typeCrit = selectedFileTypeOptions === null || selectedFileTypeOptions.length === 0;
		if (!typeCrit) {
			if (isFolder) {
				if (this.filesByFolderMap.get(element.node.id).length !== 0) {
					this.filesByFolderMap.get(element.node.id).forEach(file => {
						if (!typeCrit) {
							typeCrit = this.fileMatchesCrit(file.node, selectedFileTypeOptions);
						}
					});
				}
				if (!typeCrit) {
					//check if nested folders have matching files
					const nestedFolders = folders.filter(el => el.node.folder && el.node.folder.id === element.node.id);
					nestedFolders.forEach(nestedFolder => {
						if (!typeCrit) {
							typeCrit = this.matchesFilterCriteria(
								nestedFolder,
								searchFilterValue,
								true,
								folders,
								selectedFileTypeOptions
							);
						}
					});
				}
			} else {
				typeCrit = this.fileMatchesCrit(element.node, selectedFileTypeOptions);
			}
		}
		if (!searchCrit) {
			searchCrit = element.node.name && Util.normalizedIncludes(element.node.name, searchFilterValue);
		}
		return searchCrit && typeCrit;
	}

	fileMatchesCrit(file, selectedFileTypeOptions) {
		let matches = false;
		const fileType = this.getExtension(file);
		selectedFileTypeOptions.forEach(typeValue => {
			if (!matches) {
				matches = typeValue === fileType;
			}
		});
		return matches;
	}

	updatedSelectedFile(file) {
		this.setState({selectedFile: file, selectedFileName: file.node.name});
	}

	onFileTypeOptionSelect(selected) {
		if (selected === null) {
			this.setState({selectedFileTypeOptions: []});
			return;
		}
		let selectedFileTypeOptions;
		if (this.state.selectedFileTypeOptions.includes(selected.value)) {
			selectedFileTypeOptions = this.state.selectedFileTypeOptions.filter(value => value !== selected.value);
		} else {
			selectedFileTypeOptions = this.state.selectedFileTypeOptions;
			selectedFileTypeOptions.push(selected.value);
		}
		this.setState({selectedFileTypeOptions});
	}

	getHeaderTitle() {
		const content = [];

		return (
			<TopHeaderBarWrapper>
				<TopHeaderBar title={this.props.intl.formatMessage({id: 'common.files'})} content={content} />
			</TopHeaderBarWrapper>
		);
	}

	getHeader(bulk_update_options) {
		const {formatMessage} = this.props.intl;
		const rightContent = [],
			leftContent = [];
		if (this.state.bulkUpdateFiles && this.state.bulkUpdateFiles.length !== 0) {
			rightContent.push({
				type: ELEMENT_TYPE.BULK_EDIT,
				numTasksSelected: this.state.bulkUpdateFiles.length,
				dropdownOptions: bulk_update_options,
				selectionModeEnabled: this.state.bulkUpdateFiles.length !== 0,
				toggleSelectionMode: null,
				selectionText: formatMessage({id: 'common.select'}),
			});
		}
		rightContent.push({
			type: ELEMENT_TYPE.SEARCH,
			value: this.state.searchFilterValue,
			onChange: this.handleSearchChange.bind(this),
		});

		rightContent.push({
			type: ELEMENT_TYPE.DROPDOWN,
			callback: this.onFileTypeOptionSelect.bind(this),
			dropdownOptions: fileFilterOptions,
			value: this.state.selectedFileTypeOptions,
			multiSelect: true,
			placeholder: formatMessage({id: 'common.filter-by-file-type'}),
			clearable: true,
			clearText: formatMessage({id: 'common.clear'}),
		});
		if (
			this.props.viewer.project.status !== PROJECT_STATUS.HALTED &&
			this.props.viewer.project.status !== PROJECT_STATUS.DONE
		) {
			rightContent.push({
				type: ELEMENT_TYPE.BUTTON,
				text: formatMessage({id: 'project_files.new_folder'}),
				callback: this.showAddFolderModal.bind(this),
				style: BUTTON_STYLE.OUTLINE,
				color: BUTTON_COLOR.PURPLE,
			});
			rightContent.push({
				type: ELEMENT_TYPE.FILE_UPLOAD,
				uploadFile: this.uploadFilesFromButtonV2.bind(this),
				uploadGoogleDriveFile: this.uploadFilesGoogleDrive.bind(this),
			});
		}
		if (
			this.props.viewer.project &&
			(this.props.viewer.project.status === PROJECT_STATUS.DONE ||
				this.props.viewer.project.status === PROJECT_STATUS.HALTED)
		) {
			const indicator = {
				type: ELEMENT_TYPE.INDICATOR,
				status: this.props.viewer.project.status,
			};
			leftContent.push(indicator);
		}

		return (
			<HeaderBar
				innerRef={div => (this.header_bar = div)}
				parentGroup={null}
				leftContent={leftContent}
				rightContent={rightContent}
			/>
		);
	}

	render() {
		const {formatMessage, formatDate} = this.props.intl;
		const projectLocked = this.props.viewer.project.status === 'DONE' || this.props.viewer.project.status === 'HALTED';

		this.files = [];
		this.props.viewer.project.files.edges.forEach(projectFile => this.files.push(projectFile));
		this.props.viewer.project.googleDriveFiles.edges.forEach(gDiveFile => this.files.push(gDiveFile));
		cloneDeep(this.props.viewer.project.tasks.edges).forEach(task => {
			task.node.files.edges.forEach(taskFile => this.files.push(taskFile));
			task.node.googleDriveFiles.edges.forEach(gdriveFile => {
				gdriveFile.node.task = task.node;
				this.files.push(gdriveFile);
			});
		});
		let extension = this.state.selectedFile ? this.getExtension(this.state.selectedFile.node) : '';
		const hasPreview =
			this.state.selectedFile && (Util.FileHasPreview(this.state.selectedFile.node) || this.state.selectedFile.node.link);
		const selectedFilePreview = hasPreview
			? this.state.selectedFile.node.link
				? this.state.selectedFile.node.thumb
				: DirectApi.fileSrc(this.state.selectedFile.node.id)
			: dummyImg;
		const folders = this.props.viewer.project.folders.edges;

		const bulk_update_options = [
			{
				label: formatMessage({id: 'project_files.bulk_update_move_to_card'}),
				callback: this.bulkUpdateAnotherCard.bind(this),
			},
			{
				label: formatMessage({id: 'project_files.bulk_update_move_to_folder'}),
				callback: this.bulkUpdateAnotherFolder.bind(this),
			},
			{label: formatMessage({id: 'common.download'}), callback: null, href: bulkDownloadSrc(this.state.bulkUpdateFiles)},
			{label: formatMessage({id: 'common.delete'}), callback: this.bulkUpdateDelete.bind(this)},
		];

		const isEmpty =
			this.props.viewer.project.folders.edges.length === 0 &&
			this.props.viewer.project.files.edges.length === 0 &&
			this.props.viewer.project.googleDriveFiles.edges.length === 0 &&
			this.props.viewer.project.tasks.edges.filter(task => task.node.files.edges.length !== 0).length === 0;

		this.pathFolders = [];
		if (this.state.selectedFolder) {
			this.addFolderToPath(this.state.selectedFolder.node);
		}
		this.pathFolders.push({value: null, label: 'Files'});
		this.pathFolders.reverse();

		return (
			<div
				className={'section-content project-files ' + (!projectLocked ? ' active' : '') + (isEmpty ? ' empty' : '')}
				data-cy={'project-files-page'}
			>
				{this.props.children}
				{this.state.isUploading ? <UploadingOverlay /> : null}
				<ProjectHeader
					title={formatMessage({id: 'common.files'})}
					project={this.props.viewer.project}
					psProject={this.props.viewer.psProject}
					buttons={this.getHeader(bulk_update_options)}
				/>
				<CustomScrollDiv>
					<div className="section-body" style={{margin: `0 32px`}}>
						<div className={'content-container' + (isEmpty ? ' empty' : '')}>
							<ProjectFilesDropZone
								projectName={this.props.viewer.project.name}
								addFileToBulkUpdate={this.addFileToBulkUpdate.bind(this)}
								removeFileFromBulkUpdate={this.removeFileFromBulkUpdate.bind(this)}
								bulkUpdateFiles={this.state.bulkUpdateFiles}
								files={this.files}
								folders={folders}
								selectFile={this.selectFile.bind(this)}
								deleteFile={this.deleteFile.bind(this)}
								selectedFileId={this.state.selectedFile != null ? this.state.selectedFile.node.id : null}
								uploadFiles={this.uploadFiles.bind(this)}
								showFileLocationModal={this.showFileLocationModal.bind(this)}
								folderExceptionMsg={formatMessage({id: 'file_upload.folder-message'})}
								commonOkMsg={formatMessage({id: 'common.ok'})}
								isEmpty={isEmpty}
								locked={projectLocked}
								gdriveEnabled={this.props.viewer.company.gdriveEnabled}
								uploadFilesGoogleDrive={this.uploadFilesGoogleDrive.bind(this)}
								unlinkFile={this.unlinkFile.bind(this)}
								selectFolder={this.selectFolder.bind(this)}
								deleteFolder={this.deleteFolder.bind(this)}
								selectedFolder={this.state.selectedFolder}
								cards={this.props.viewer.project.tasks.edges}
								projectId={this.props.viewer.project.id}
								handleFileMoveToCard={this.handleFileMoveToCard.bind(this)}
								handleFileMoveToRoot={this.handleFileMoveToRoot.bind(this)}
								searchFilterValue={this.state.searchFilterValue}
								removeAllFromBulkUpdate={this.removeAllFromBulkUpdate.bind(this)}
								toggleSelectAllFiles={this.toggleSelectAllFiles.bind(this)}
								renameFolder={this.renameFolder.bind(this)}
								elements={this.elements}
								sortBy={this.state.sortBy}
								setSortBy={this.setSortBy.bind(this)}
							/>
							{this.state.selectedFile != null ? (
								<div className="file-details photo">
									<div className="details-content">
										<div className="title-wrapper">
											<InputField
												type="text"
												locked={projectLocked || this.state.selectedFile.node.link !== undefined}
												label={formatMessage({id: 'card_modal.file-name'})}
												placeholder="Enter file name"
												value={this.state.selectedFileName}
												onChange={this.handleFileNameEdit.bind(this)}
												onBlur={this.updateFileNameOnBlur.bind(this)}
												blurOnEnter={true}
											/>
										</div>
										<div className="preview-wrapper">
											{hasPreview ? (
												<div className="image-wrapper" onClick={this.handleEnlarge.bind(this)}>
													<img
														crossOrigin={!this.state.selectedFile.node.link && 'use-credentials'}
														src={selectedFilePreview}
														alt="file preview"
													/>
													{!this.state.selectedFile.node.link ? (
														<div className="zoom-button">
															<FormattedMessage id="project_files.enlarge" />
														</div>
													) : null}
												</div>
											) : (
												<div
													className={
														fileExtensions.includes(this.getExtension(this.state.selectedFile.node))
															? 'file-format ' + this.getExtension(this.state.selectedFile.node)
															: 'file-format file'
													}
												>
													<span>{this.state.selectedFile.node.mimeType}</span>
												</div>
											)}
										</div>
										<div className="meta-data">
											<div className={'meta-file_info file-format ' + extension}>
												<span className="upload-date">
													{formatMessage(
														{id: 'project_files.added_date'},
														{
															date: formatDate(
																this.state.selectedFile.node.link
																	? Moment.utc(this.state.selectedFile.node.createdAt)
																	: Util.CreateMomentDate(
																			this.state.selectedFile.node.yearCreated,
																			this.state.selectedFile.node.monthCreated,
																			this.state.selectedFile.node.dayCreated
																	  )
															),
														}
													)}
												</span>
											</div>
											{!this.state.selectedFile.node.person ? null : (
												<div className="meta-person">
													<Person
														name={
															this.state.selectedFile.node.person
																? this.state.selectedFile.node.person.firstName +
																  ' ' +
																  this.state.selectedFile.node.person.lastName
																: ''
														}
														role={
															this.state.selectedFile.node.person.role
																? this.state.selectedFile.node.person.role.name
																: null
														}
														showName={true}
														showRole={!!this.state.selectedFile.node.person.role}
														imageSize="medium"
														imageSrc={profilePicSrc(
															this.state.selectedFile.node.person.profilePictureId
														)}
													/>
												</div>
											)}
											{this.state.selectedFile.node.taskId ? (
												<div className="meta-belongs_to">
													<p className="task" onClick={this.showTaskModal.bind(this)}>
														{this.state.selectedFile.node.parentTask}
													</p>
												</div>
											) : (
												''
											)}
											<div className="buttons">
												<ProjectFilesDownloadButton file={this.state.selectedFile} />
												<ProjectFilesDeleteButton
													file={this.state.selectedFile}
													deleteFile={this.deleteFile.bind(this)}
													unlinkFile={this.unlinkFile.bind(this)}
												/>
											</div>
										</div>
									</div>
								</div>
							) : null}
						</div>
					</div>
				</CustomScrollDiv>
			</div>
		);
	}
}

ProjectFilesPage.propTypes = {
	showTaskModal: PropTypes.func,
};

const ProjectFilesPageQuery = graphql`
	query ProjectFilesPage_Query($projectId: String) {
		viewer {
			actualPersonId
			component(name: "project_files")
			project(id: $projectId) {
				id
			}
			...ProjectFilesPage_viewer @arguments(projectId: $projectId)
		}
	}
`;

export {ProjectFilesPageQuery};

export default withRouter(
	injectIntl(
		createFragmentContainer(ProjectFilesPage, {
			viewer: graphql`
				fragment ProjectFilesPage_viewer on Viewer @argumentDefinitions(projectId: {type: "String"}) {
					actualPersonId
					availableFeatureFlags {
						key
					}
					company {
						gdriveEnabled
					}
					project(id: $projectId) {
						id
						name
						isInProjectGroup
						projectGroupId
						projectColor
						companyProjectId
						status
						projectStartYear
						projectStartMonth
						projectStartDay
						projectEndYear
						projectEndMonth
						projectEndDay
						...ProjectHeader_project
						...SecondaryNavigation_project
						client {
							id
							name
						}
						googleDriveFiles(first: 10000) @connection(key: "Project_googleDriveFiles") {
							edges {
								node {
									id
									name
									link
									thumb
									createdAt
									person {
										id
										profilePictureId
										profilePictureDefaultId
										firstName
										lastName
										role {
											name
										}
									}
									folder {
										id
										name
									}
								}
							}
						}
						files(first: 10000) @connection(key: "Project_files", filters: []) {
							edges {
								node {
									id
									name
									mimeType
									key
									size
									yearCreated
									monthCreated
									dayCreated
									hourCreated
									minuteCreated
									secondCreated
									person {
										id
										profilePictureId
										firstName
										lastName
										role {
											id
											name
										}
									}
									folder {
										id
										name
									}
									task {
										id
										name
										companyTaskId
									}
								}
							}
						}
						folders(first: 10000) @connection(key: "Project_folders") {
							edges {
								node {
									id
									name
									yearCreated
									monthCreated
									dayCreated
									hourCreated
									minuteCreated
									secondCreated
									person {
										id
										profilePictureId
										firstName
										lastName
										role {
											id
											name
										}
									}
									project {
										id
									}
									folder {
										id
										name
									}
								}
							}
						}
						tasks(first: 10000) {
							edges {
								node {
									id
									companyTaskId
									name
									highPriority
									statusColumn {
										id
										name
									}
									googleDriveFiles(first: 10000) @connection(key: "Task_googleDriveFiles", filters: []) {
										edges {
											node {
												id
												name
												link
												thumb
												createdAt
												person {
													id
													profilePictureId
													firstName
													lastName
													role {
														name
													}
												}
												folder {
													id
													name
												}
											}
										}
									}
									files(first: 10000) @connection(key: "Task_files") {
										edges {
											node {
												id
												name
												mimeType
												key
												size
												yearCreated
												monthCreated
												dayCreated
												hourCreated
												minuteCreated
												secondCreated
												person {
													id
													profilePictureId
													firstName
													lastName
													role {
														id
														name
													}
												}
												folder {
													id
													name
												}
												task {
													id
													name
													companyTaskId
												}
											}
										}
									}
								}
							}
						}
					}
					psProject(companyProjectId: $projectId) {
						...ProjectHeader_psProject
					}
				}
			`,
		})
	)
);
