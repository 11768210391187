import { NavBarIconButton } from '@forecast-it/design-system';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { TRACKING_OBJECTS } from '../../../../tracking/amplitude/constants/TrackingObjects';
import { trackEvent } from '../../../../tracking/amplitude/TrackingV2';
import ForecastTooltip, { TooltipColor } from '../../../shared/components/tooltips/ForecastTooltip';
import useEventListener from '../../../shared/hooks/useEventListener';
import GlobalSearchModal from './GlobalSearchModal';
export const SEARCH_SHORTCUT = '/';
const ButtonIndicator = styled.div `
	margin: 0 4px;
	border-radius: 2px;
	padding: 2px 6px;
	background-color: #e7e7f3;
`;
const tooltipContent = (React.createElement("div", { style: { display: 'flex', alignItems: 'center' } },
    "Use ",
    React.createElement(ButtonIndicator, null, SEARCH_SHORTCUT),
    " to search faster"));
const GlobalSearchV2 = ({ companyId, personId }) => {
    const [showSearch, setShowSearch] = useState(false);
    const trackAndShowSearch = () => {
        trackEvent(TRACKING_OBJECTS.GLOBAL_SEARCH_MODAL, 'Opened');
        setShowSearch(true);
    };
    const hideSearch = useCallback(() => {
        setShowSearch(false);
    }, []);
    const handleSearchKeyDown = useCallback(e => {
        var _a, _b, _c;
        if (e.key === SEARCH_SHORTCUT) {
            if (e.defaultPrevented)
                return;
            if (e.ctrlKey || e.altKey || e.metaKey)
                return;
            if (/^(?:input|textarea|select)$/i.test(e.target.tagName))
                return;
            if (((_a = document === null || document === void 0 ? void 0 : document.activeElement) === null || _a === void 0 ? void 0 : _a.className.includes('DraftEditor')) ||
                ((_b = document === null || document === void 0 ? void 0 : document.activeElement) === null || _b === void 0 ? void 0 : _b.className.includes('ck-editor'))) {
                return;
            }
            if (((_c = document === null || document === void 0 ? void 0 : document.activeElement) === null || _c === void 0 ? void 0 : _c.getAttribute('role')) === 'textbox') {
                return;
            }
            e.stopPropagation();
            e.preventDefault();
            trackAndShowSearch();
        }
    }, [showSearch]);
    useEventListener('keydown', handleSearchKeyDown);
    return (React.createElement(React.Fragment, null,
        React.createElement(ForecastTooltip, { content: tooltipContent, color: TooltipColor.PURPLE },
            React.createElement(NavBarIconButton, { title: 'Site-wide search', "aria-label": "search", tabIndex: 0, icon: "search", onClick: trackAndShowSearch })),
        showSearch ? React.createElement(GlobalSearchModal, { companyId: companyId, personId: personId, hideSearch: hideSearch }) : null));
};
export default GlobalSearchV2;
