/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type NotificationMenu_viewer$ref = any;
export type TierType = "CORE" | "ENTERPRISE" | "FREE_FOREVER" | "LITE" | "PRO" | "TRIAL" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type PrimaryNavigationRight_viewer$ref: FragmentReference;
declare export opaque type PrimaryNavigationRight_viewer$fragmentType: PrimaryNavigationRight_viewer$ref;
export type PrimaryNavigationRight_viewer = {|
  +id: string,
  +sisenseUserId: ?string,
  +backendId: ?number,
  +unseenNotifications: ?number,
  +projectOwner: ?boolean,
  +company: ?{|
    +id: string,
    +useTimeApproval: ?boolean,
    +useTimeOffApproval: ?boolean,
    +unit4Enabled: ?boolean,
    +isUsingProjectAllocation: ?boolean,
    +tier: ?TierType,
  |},
  +$fragmentRefs: NotificationMenu_viewer$ref,
  +$refType: PrimaryNavigationRight_viewer$ref,
|};
export type PrimaryNavigationRight_viewer$data = PrimaryNavigationRight_viewer;
export type PrimaryNavigationRight_viewer$key = {
  +$data?: PrimaryNavigationRight_viewer$data,
  +$fragmentRefs: PrimaryNavigationRight_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PrimaryNavigationRight_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sisenseUserId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "backendId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unseenNotifications",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectOwner",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useTimeApproval",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useTimeOffApproval",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unit4Enabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isUsingProjectAllocation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tier",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NotificationMenu_viewer"
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '59d027747720bb6d8270d79508b9bf32';

module.exports = node;
